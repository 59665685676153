/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */
import { FunctionComponent } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Stack as DashboardStack } from "screens/Dashboard";
import { Stack as SignInStack } from "screens/SignIn";
import { Stack as HistoryStack } from "screens/History";
import { Stack as SessionStack } from "screens/Session";
import { Stack as ProfileStack } from "screens/Profile";
import { Stack as MobileUploadStack } from "screens/MobileUpload";
import { Stack as PartnershipStack } from "screens/Partnerships";
import { Stack as PostSessionStack } from "screens/PostSession";
import Config from "helpers/Config";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type AllStacks = DashboardStack &
  SessionStack &
  PostSessionStack &
  SignInStack &
  HistoryStack &
  ProfileStack &
  MobileUploadStack &
  PartnershipStack;

export class Controller {
  init() {
    if (__DEV__ && !Config.testing)
      console.log(`${this.constructor.name.toUpperCase()} INIT`);
  }
  deinit() {
    if (__DEV__ && !Config.testing)
      console.log(`${this.constructor.name.toUpperCase()} DEINIT`);
  }
}

export type ScreenType = {
  name: keyof AllStacks;
  path: string;
  component: FunctionComponent;
  controller: Controller;
  props?: () => object;
};

export type RootStackParamList = AllStacks & { NotFound: undefined };

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;
