import { FunctionComponent } from "react";
import { ScreenType } from "types";

import TokenController from "./controllers/TokenController";
import CameraController from "./controllers/CameraController";
import SuccessController from "./controllers/SuccessController";

import TokenScreen from "./views/Token";
import CameraScreen from "./views/Camera";
import SuccessScreen from "./views/Success";

export type Stack = {
  MobileUploadToken: undefined;
  MobileUploadCamera: { token: string };
  MobileUploadSuccess: { token: string };
};

export const MobileUploadScreens: Array<ScreenType> = [
  {
    name: "MobileUploadToken",
    path: "photos",
    controller: TokenController,
    component: TokenScreen as FunctionComponent
  },
  {
    name: "MobileUploadCamera",
    path: "photos/:token",
    controller: CameraController,
    component: CameraScreen as FunctionComponent
  },
  {
    name: "MobileUploadSuccess",
    path: "photos/success/:token",
    controller: SuccessController,
    component: SuccessScreen as FunctionComponent
  }
];
