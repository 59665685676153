import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import {
  MessageType,
  dedupeMessages,
  successfullySent
} from "components/ChatRoom/types/Message";
import Storage from "helpers/Storage";

const STORE_KEY = "yup-session-messages";
export const storage = new Storage(STORE_KEY, clearMessageInfo);
function clearMessageInfo(): void {
  const { store } = require("./index");
  store.dispatch(messagesSlice.actions.clear());
}

export type StateType = {
  messages: Array<MessageType>;
  latestMessageId: number;
  latestMessagesStatus: { [key: string]: number } | null;
  messageLabels: Array<string>;
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
    latestMessageId: 0,
    latestMessagesStatus: {},
    messageLabels: []
  } as StateType,
  reducers: {
    addMessages: (state, action: PayloadAction<Array<MessageType>>) => {
      if (action.payload.length) {
        state.messages = dedupeMessages([...action.payload, ...state.messages]);
        state.latestMessageId =
          [...state.messages]
            .map((m) => (successfullySent(m) ? m.id : 0))
            .sort()
            .reverse()[0] ?? 0;
      }
    },
    setLatestMessagesStatus: (
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.latestMessagesStatus = {
        ...state.latestMessagesStatus,
        ...action.payload
      };
    },
    setLatestMessageId: (state, action: PayloadAction<number>) => {
      state.latestMessageId = action.payload;
    },
    setMessages: (state, action: PayloadAction<Array<MessageType>>) => {
      state.messages = dedupeMessages(action.payload);
    },
    setMessageLabels: (state, action: PayloadAction<Array<string>>) => {
      state.messageLabels = action.payload;
    },
    addMessageLabel: (state, action: PayloadAction<string>) => {
      state.messageLabels.push(action.payload);
    },
    clear: (state) => {
      state.messages = [];
      state.latestMessageId = 0;
      state.latestMessagesStatus = {};
      state.messageLabels = [];
    }
  }
});

export default messagesSlice.reducer;

export const {
  addMessages,
  setMessages,
  setLatestMessageId,
  setLatestMessagesStatus,
  addMessageLabel,
  setMessageLabels,
  clear
} = messagesSlice.actions;

export function subscriber(state: StateType) {
  if (state.messages.length) {
    storage.setSync(state);
  } else {
    storage.remove();
  }
}

export async function load(store: ToolkitStore) {
  const messageData = await storage.get();
  if (messageData) {
    store.dispatch(messagesSlice.actions.setMessages(messageData.messages));
    store.dispatch(
      messagesSlice.actions.setLatestMessageId(messageData.latestMessageId)
    );
    store.dispatch(
      messagesSlice.actions.setLatestMessagesStatus(
        messageData.latestMessagesStatus
      )
    );
    store.dispatch(
      messagesSlice.actions.setMessageLabels(messageData.messageLabels)
    );
  }
}
