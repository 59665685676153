export interface Point {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface DrawSettings {
  strokeWidth: number;
  strokeColor: string;
}

export enum BackgroundStyle {
  none = 0,
  cover = 1,
  stretch = 2,
  contain = 3
}

export type ScratchBoardOptions = {
  backgroundImg?: string;
  pencilStrokeWidth?: number;
  pencilStrokeColor?: string;
  backgroundStyle?: BackgroundStyle;
  squareSize?: number;
  squareStrokeWidth?: number;
  squareStrokeColor?: string;
};

export const defaultOptions = {
  pencilStrokeWidth: 5,
  pencilStrokeColor: "#FF0000",
  backgroundStyle: BackgroundStyle.none,
  squareSize: 30,
  squareStrokeWidth: 0.5,
  squareStrokeColor: "#CCE5C8"
};
