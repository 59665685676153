import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Platform, StyleSheet } from "react-native";
import { useMediaQuery } from "react-responsive";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { Extension } from "helpers/ChromeExtension";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";
import {
  CameraIcon,
  FileUploadIcon,
  MobileUploadIcon,
  ScreenshotIcon
} from "components/Icons";
import { Event } from "events/Event";

type Option = {
  label: string;
  icon: React.ReactNode;
  onPress: () => void;
};

type UsePictureMenuProps = {
  show: boolean;
  options: Array<Option>;
  onDismiss: () => void;
};

const IconProps = {
  width: 20,
  height: 20,
  color: Colors.ui.black
};

export default function UsePictureOptions() {
  const show = useSelector(
    (state: StoreState) => state.ui.showDashboardPictureOptions
  );

  const onDismiss = Event.dispatcher("dashboard_dismiss_picture_options");

  const isMd = useMediaQuery({ minWidth: Layout.md });

  const options = [
    {
      label: Platform.OS === "web" ? "Take with Webcam" : "Take a Picture",
      icon: <CameraIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_select_camera")
    }
  ];
  if (Platform.OS === "web") {
    options.push({
      label: "Take with Phone",
      icon: <MobileUploadIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_select_mobile_upload")
    });
  }
  if (Extension.isSupported()) {
    options.push({
      label: "Capture a Screen",
      icon: <ScreenshotIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_select_screenshot")
    });
  }
  options.push({
    label: "Upload a File",
    icon: <FileUploadIcon {...IconProps} />,
    onPress: Event.dispatcher("dashboard_select_upload_file")
  });

  if (isMd && Platform.OS === "web") {
    return (
      <UsePictureMenuLarge
        show={show}
        options={options}
        onDismiss={onDismiss}
      />
    );
  }

  return (
    <UsePictureMenuSmall show={show} options={options} onDismiss={onDismiss} />
  );
}

function UsePictureMenuSmall(props: UsePictureMenuProps) {
  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    if (props.show) {
      showActionSheetWithOptions(
        {
          options: props.options.map((action) => action.label).concat(["Cancel"]),
          icons: props.options.map((action) => action.icon),
          cancelButtonIndex: props.options.length,
          textStyle: styles.smallText
        },
        onAction
      );
    }
  }, [props.show]);

  function onAction(index?: number) {
    props.onDismiss();
    if (isNaN(Number(index))) return;

    if (Number(index) < props.options.length) {
      props.options[Number(index)]?.onPress();
    }
  }

  return <></>;
}

function UsePictureMenuLarge(props: UsePictureMenuProps) {
  return (
    <Modal show={props.show} onDismiss={props.onDismiss}>
      <Text tag="h2" style={styles.prompt}>
        How would you like to send a picture?
      </Text>
      {props.options.map((option) => (
        <Button
          key={option.label}
          style={styles.mainButton}
          onClick={option.onPress}
        >
          {option.icon}
          <Text style={styles.mainButtonText}>{option.label}</Text>
        </Button>
      ))}
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3
  },
  mainButton: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...Spacing.ma0,
    ...Spacing.mb3
  },
  mainButtonText: {
    ...Typography.accent.small,
    ...Spacing.ml2
  },
  smallText: {
    fontFamily: Typography.primary.regular
  }
});
