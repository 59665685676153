import { UsersnapHelper, NetworkHelper } from "@yups/utils";
import Config from "helpers/Config";
import User from "models/User";
import { getLogInfo as getSessionLogInfo } from "models/Session";
import { getLogInfo as getSessionHistoryLogInfo } from "models/SessionHistory";
import { Platform } from "react-native";

export const Usersnap = new UsersnapHelper({
  projectApiKey: Config.usersnapKey,
  callbacks: {
    customData: () => ({
      userId: User.get()?.id,
      currentSession: getSessionLogInfo(),
      sessionHistory: getSessionHistoryLogInfo(),
      build: Config.buildId,
      commit: Config.commitRef
    })
  }
});

NetworkHelper.onError(({ error, endPoint, method }) => {
  const timestamp = new Date().toISOString();
  Usersnap.addErrorLog(
    `${timestamp} [API Error] ${method} ${endPoint} threw error: ${error}`
  );
});

export function loadUsersnap() {
  if (Platform.OS === "web") {
    Usersnap.initialize();
  }
}
