import * as React from "react";
import { View, StyleSheet } from "react-native";

import Spinner from "components/Progress/Spinner";
import Text from "components/Text";

import Colors from "assets/theme/Colors";

import Error from "./Error";
import SessionList from "./SessionList";
import { useSelector } from "react-redux";
import { StoreState } from "store";

export default function HistoryContent() {
  const sessions = useSelector(
    (state: StoreState) => state.sessionHistory.sessions
  );
  const loading = useSelector(
    (state: StoreState) => state.ui.loadingSessionHistory
  );
  const error = useSelector(
    (state: StoreState) => state.ui.sessionHistoryError
  );

  if (sessions.length) {
    return <SessionList />;
  } else if (loading) {
    return (
      <View testID="history-loading">
        <Spinner size={96} width={8} color={Colors.grey.slate} />
      </View>
    );
  } else if (error) {
    return (
      <View testID="history-error">
        <Error error={error} />
      </View>
    );
  }
  return (
    <Text testID="history-empty" tag="h3" style={styles.noSessionsFound}>
      No sessions found
    </Text>
  );
}

const styles = StyleSheet.create({
  noSessionsFound: {
    color: Colors.grey.slate,
    textAlign: "center"
  }
});
