import { WhiteboardAction } from "../types/WhiteboardAction";
import { BaseCommand } from "./BaseCommand";

import pencil from "./pencil";
import undo from "./undo";
import redo from "./redo";
import strokeColor from "./strokeColor";

export const commands: {
  [key in WhiteboardAction]: BaseCommand;
} = {
  [WhiteboardAction.pencil]: pencil,
  [WhiteboardAction.undo]: undo,
  [WhiteboardAction.redo]: redo,
  [WhiteboardAction.strokeColor]: strokeColor
};
