import React from "react";
import { Event } from "events/Event";
import CameraComponent from "components/Camera";
import { useSelector } from "react-redux";
import { StoreState } from "store";

export default function Camera() {
  const hasPermission = useSelector(
    (state: StoreState) => state.ui.hasCameraPermission
  );

  const onSetCameraPermission = (hasPermission: boolean) =>
    Event.dispatch("mobile_upload_camera_set_permission", { hasPermission });
  const onPictureTaken = (photo: string) =>
    Event.dispatch("mobile_upload_camera_picture_taken", { photo });
  const onGoToToken = Event.dispatcher("mobile_upload_camera_on_back");

  return (
    <CameraComponent
      hasPermission={hasPermission}
      onSetPermission={onSetCameraPermission}
      onTakePicture={onPictureTaken}
      onBack={onGoToToken}
    />
  );
}
