import { FunctionComponent } from "react";
import { ScreenType } from "types";

import ProfileController from "./controllers/ProfileController";
import ProfileScreen from "./views/Profile";

export type Stack = {
  Profile: undefined;
};

export const ProfileScreens: Array<ScreenType> = [
  {
    name: "Profile",
    path: "profile",
    controller: ProfileController,
    component: ProfileScreen as FunctionComponent
  }
];
