import React from "react";
import { Router } from "navigation/Router";
import { Event } from "events/Event";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, TouchableOpacity } from "react-native";

import Spacing from "assets/theme/Spacing";
import { NextIcon } from "components/Icons";
import Modal from "components/Modal";
import Text from "components/Text";
import Colors from "assets/theme/Colors";

const questions = [
  {
    question: "How do I start a session?",
    link: "https://docs.google.com/presentation/d/e/2PACX-1vRK_zWZPzOfPxaA3reHApDUj6PzJjoaOql5Rlq5aAHIU8ovf6iiCDcRiRADWSsYatXnclYU21XWiN8o/embed?start=false&loop=false&delayms=3000"
  },
  {
    question: "How do I end a session if I need to leave?",
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQVJ6r9ZnkyyyeeBPc9Nh-F5GG2C0UfKQoPNVglUTb6BkPOKHlUoZG0sBD13sSIfxQeqb7tkeMTb5Op/embed?start=false&loop=false&delayms=3000"
  },
  {
    question: "Who are the tutors?",
    link: "https://docs.google.com/presentation/d/e/2PACX-1vTtrqoaKXwq7INpWrFnnHj5DbT1JOtVSeKV6LK7SLbZ8CEslqHaHqOw9YIqzTFwFwBEjTG3gTpON-1G/embed?start=false&loop=false&delayms=3000"
  },
  {
    question: "How do I get a session to go faster?",
    link: "https://docs.google.com/presentation/d/e/2PACX-1vTsYZuzqUn3lP_0a9PxRcXTWGGoR_U__u571YCud5UjehdlIXq-Ph61nE1CGAH6LB7FqoshIOTPrqgf/embed?start=false&loop=false&delayms=3000"
  },
  {
    question: "What can I expect in a Yup session?",
    link: "https://docs.google.com/presentation/d/e/2PACX-1vQ_GBOOqlvp3DwrjLWAQ5TcuhohepPGWgUC7agMM2xiUjx4bJkqJH0_-1IV78jVFktApVOuwndByWhR/embed?start=false&loop=false&delayms=3000"
  }
];

export default function FrequentlyAskedQuestions() {
  const show = useSelector((state: StoreState) => state.ui.showFAQ);

  const onDismiss = Event.dispatcher("dashboard_dismiss_faq");
  const onOpenLink = (url: string) => Router.openLink(url);

  return (
    <Modal show={show} onDismiss={onDismiss}>
      <Text tag="h2" style={styles.prompt} testID="faq">
        Frequently Asked Questions
      </Text>
      {questions.map((question) => (
        <TouchableOpacity
          key={question.link}
          style={styles.question}
          onPress={() => onOpenLink(question.link)}
        >
          <Text style={styles.questionText}>{question.question}</Text>
          <NextIcon width={16} height={16} color={Colors.grey.inactive} />
        </TouchableOpacity>
      ))}
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3
  },
  question: {
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...Spacing.pa3
  },
  questionText: {
    fontSize: 20,
    ...Spacing.mr4
  }
});
