import * as React from "react";
import LottieView from "react-lottie";

type LottieProps = {
  animation: any;
  width: number | string;
  height: number | string;
};

export default function Lottie(props: LottieProps) {
  return (
    <LottieView
      options={{
        animationData: props.animation
      }}
      height={props.height}
      width={props.width}
    />
  );
}
