import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { useMediaQuery } from "react-responsive";
import { View, StyleSheet, useWindowDimensions } from "react-native";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Image from "components/Image";
import Text from "components/Text";

import { Slides } from "../types/Slide";
import Typography from "assets/theme/Typography";

export default function OnboardingSlide() {
  const slide = useSelector((state: StoreState) => state.ui.onboardingSlide);
  const dimensions = useWindowDimensions();
  const isLg = useMediaQuery({ minWidth: Layout.lg });
  const titleStyles = [styles.header];

  if (!isLg) {
    titleStyles.push(styles.textCenter);
  }

  const { title, img, imgRetina, width, height, content } = Slides[slide];

  return (
    <View style={[styles.page, isLg ? styles.pageLg : {}]}>
      <View style={styles.image}>
        <Image
          uri={isLg ? imgRetina : img}
          style={{
            maxWidth: dimensions.width - 16,
            width,
            height
          }}
        />
      </View>
      <View>
        <Text tag="h1" style={titleStyles}>
          {title}
        </Text>
        <View style={[styles.list, { maxWidth: dimensions.width }]}>
          {content.map((item) => (
            <View style={styles.listItem} key={item.text}>
              <View style={styles.listItemBullet}>
                <Text style={styles.listItemBulletText}>{item.bullet}</Text>
              </View>
              <Text>{item.text}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    ...Spacing.pa4
  },
  page: {
    flexDirection: "column",
    alignItems: "center"
  },
  pageLg: {
    flexDirection: "row",
    ...Spacing.px4
  },
  textCenter: {
    textAlign: "center"
  },
  image: {
    ...Spacing.pa4
  },
  header: {
    ...Spacing.mb2
  },
  list: {
    ...Spacing.px3
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    ...Spacing.my2
  },
  listItemBullet: {
    width: Sizes["24px"],
    height: Sizes["24px"],
    backgroundColor: Colors.brand.blue,
    borderRadius: Sizes["24px"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.mr2
  },
  listItemBulletText: {
    color: Colors.ui.white,
    ...Typography.accent.small,
    lineHeight: 24
  }
});
