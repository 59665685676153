import ScratchBoardClass from "./BaseClass";
import { ScratchBoardOptions } from "./types/Scratchboard";

export default class ScratchBoard extends ScratchBoardClass {
  canvas: HTMLCanvasElement;
  background: HTMLImageElement;

  constructor(
    canvas: HTMLCanvasElement,
    options?: ScratchBoardOptions,
    onLoad?: () => void
  ) {
    super(canvas, options);
    this.canvas = canvas;
    this.context = (this.canvas as any).getContext("2d");
    this.background = (globalThis as any).document.createElement("img");
    if (this.options.backgroundImg && this.background) {
      (this.background as any).src = this.options.backgroundImg;
      (this.background as any).onload = () => {
        this.context = (this.canvas as any).getContext("2d");
        this.redraw();
        onLoad?.();
      };
      (globalThis as any).window.onresize = () => {
        this.paths = [];
        this.redraw();
      };
    }
  }
  getPath2D() {
    return new (globalThis as any).Path2D(this.canvas);
  }
}
