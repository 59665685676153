import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import TopicSelection from "../components/TopicSelection";
import ScratchBoardComponent from "components/ScratchBoard";
import ScratchBoardClass from "models/ScratchBoard/BaseClass";
import { Event } from "events/Event";

export default function ScratchBoard() {
  const photo = useSelector((state: StoreState) => state.ui.photo);
  const selectedTopic = useSelector(
    (state: StoreState) => state.ui.selectedTopic
  );
  const creatingSession = useSelector(
    (state: StoreState) => state.ui.creatingSession
  );
  const [scratchBoard, setScratchBoard] = useState<ScratchBoardClass | null>(
    null
  );

  const onRequestTutor = async (scratchboard: ScratchBoardClass) => {
    setScratchBoard(scratchboard);
    Event.dispatch("dashboard_scratchboard_request_tutor", {
      image: await scratchboard.getImageData()
    });
  };

  const onSubmitPhoto = async () => {
    if (scratchBoard) {
      Event.dispatch("dashboard_scratchboard_submit_photo", {
        image: await scratchBoard.getImageData(),
        topic: selectedTopic!
      });
    }
  };

  return (
    <View style={styles.container}>
      <ScratchBoardComponent
        photo={photo}
        submitText="Request math tutor"
        processing={creatingSession}
        onSubmit={onRequestTutor}
      />
      <TopicSelection onSubmit={onSubmitPhoto} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});
