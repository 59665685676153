import React from "react";
import { View, StyleSheet, Platform, SafeAreaView } from "react-native";
import { useMediaQuery } from "react-responsive";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { HomeIcon, BackIcon, LogoIcon } from "components/Icons";
import Text from "components/Text";
import { SessionType } from "models/Session";

type TranscriptNavigationProps = {
  session: SessionType | null;
};

export default function TranscriptNavigation(props: TranscriptNavigationProps) {
  const isSm = useMediaQuery({ maxWidth: Layout.sm });
  const isMobile = isSm || Platform.OS !== "web";

  const { tutor_formal_name, topic } = props.session ?? {};

  return (
    <SafeAreaView style={styles.navWrapper}>
      <View style={[styles.nav, isMobile ? styles.smNav : {}]}>
        <View style={styles.navRow}>
          <View style={styles.navAction}>
            <Button theme="flat" to="Dashboard" testID="transcript-home">
              <HomeIcon height={24} width={24} color={Colors.ui.blue} />
            </Button>
            <Button theme="flat" to="History" testID="transcript-history">
              <BackIcon height={24} width={24} color={Colors.ui.blue} />
            </Button>
          </View>
          <LogoIcon color={Colors.brand.blue} width={64} height={24} />
          <View style={styles.navFiller} />
        </View>
      </View>
      {props.session && (
        <Text style={styles.navText}>
          This is a transcript of your Yup tutoring session with
          <Text style={styles.emphasis}>&nbsp;{tutor_formal_name}&nbsp;</Text>
          on
          <Text style={styles.emphasis}>&nbsp;{topic}</Text>.
        </Text>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  navWrapper: {
    ...Spacing.pa3,
    borderBottomWidth: 1,
    borderColor: Colors.ui.beige
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  smNav: {
    ...Spacing.pt0
  },
  navAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.pa2
  },
  navFiller: {
    width: 88
  },
  navRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  navText: {
    textAlign: "center"
  },
  emphasis: {
    fontWeight: "bold"
  }
});
