import React from "react";
import { StyleSheet, View } from "react-native";

import Spacing from "assets/theme/Spacing";

import Page from "components/Page";

import Header from "../components/Header";
import Achievements from "../components/Achievements";
import FavoriteTutors from "../components/FavoriteTutors";

export default function Profile() {
  return (
    <Page>
      <View style={styles.wrapper} testID="profile">
        <Header />
        <Achievements />
        <FavoriteTutors />
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    ...Spacing.pa3
  }
});
