import Config from "helpers/Config";

export type SlideListItem = {
  bullet: string;
  text: string;
};

export type Slide = {
  title: string;
  img: string;
  imgRetina: string;
  width: number;
  height: number;
  content: Array<SlideListItem>;
};

export const Slides = [
  {
    title: "Getting Started",
    img: `${Config.assetsUrl}welcome-slide-01.png`,
    imgRetina: `${Config.assetsUrl}welcome-slide-01@2x.png`,
    width: 348,
    height: 338,
    content: [
      {
        bullet: "1",
        text: "Take a picture of your math problem"
      },
      {
        bullet: "2",
        text: "Answer a few questions about your progress"
      },
      {
        bullet: "3",
        text: "Connect to an expert tutor in 60 seconds or less"
      },
      {
        bullet: "4",
        text: "Start messaging and learning"
      }
    ]
  },
  {
    title: "What to Expect",
    img: `${Config.assetsUrl}welcome-slide-02.png`,
    imgRetina: `${Config.assetsUrl}welcome-slide-02@2x.png`,
    width: 365,
    height: 336,
    content: [
      {
        bullet: "✓",
        text: "Use Yup to get unstuck on a problem, check homework, and study"
      },
      {
        bullet: "✓",
        text: "Participate with your tutor to solve problems more quickly"
      },
      {
        bullet: "✓",
        text: "You first few problems may take longer as you get used to using Yup"
      }
    ]
  },
  {
    title: "Y.U.P Tips for Success",
    img: `${Config.assetsUrl}welcome-slide-03.png`,
    imgRetina: `${Config.assetsUrl}welcome-slide-03@2x.png`,
    width: 259,
    height: 259,
    ordered: false,
    content: [
      {
        bullet: "Y",
        text: "Tell your tutor what YOU need help with"
      },
      {
        bullet: "U",
        text: "Show UPFRONT WORK and indicate where you are stuck"
      },
      {
        bullet: "P",
        text: "PHOTO SHARE your progress as your work through the problem"
      }
    ]
  }
];
