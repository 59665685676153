import React from "react";

import Colors from "assets/theme/Colors";

import { StaticMathField } from "react-mathquill";
import Text from "components/Text";

type StaticFieldProps = {
  formula: string;
  color?: string;
};

function Field(props: { text: string; color: string; isLatex: boolean }) {
  if (props.isLatex) {
    return <StaticMathField>{props.text.replace(/\$\$/g, "")}</StaticMathField>;
  }
  return (
    <Text style={{ color: props.color }}>
      {props.text.replace(/\$\$/g, "")}
    </Text>
  );
}

function getComponents(text: string, color: string) {
  let startingIndex = 0;
  var matchIndex = text.indexOf("$$", 0);
  let isLatex = true;
  const components = [
    <Field
      color={color}
      text={text.slice(startingIndex, matchIndex)}
      isLatex={false}
    />
  ];

  while (startingIndex >= 0) {
    let nextMatchIndex = text.indexOf("$$", startingIndex + 1);
    if (nextMatchIndex < 0) {
      if (!text.endsWith("$$"))
        components.push(
          <Field
            color={color}
            text={text.slice(startingIndex)}
            isLatex={false}
          />
        );
    } else {
      components.push(
        <Field
          color={color}
          text={text.slice(startingIndex, nextMatchIndex)}
          isLatex={isLatex}
        />
      );
    }
    isLatex = !isLatex;
    startingIndex = nextMatchIndex;
  }

  return components;
}

export default function StaticField(props: StaticFieldProps) {
  const color = props.color ?? Colors.ui.black;
  return (
    <div style={{ color }}>
      {getComponents(props.formula, color).map((component, i) => (
        <React.Fragment key={`${props.formula}-${i}`}>
          {component}
        </React.Fragment>
      ))}
    </div>
  );
}
