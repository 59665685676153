import { Event } from "events/Event";
import { Router } from "navigation/Router";
import User from "models/User";
import { SignInUI } from "models/UI";
import store from "store";

import SignInBaseController from "./SignInBaseController";

class VerificationController extends SignInBaseController {
  init() {
    super.init();
    Event.on("signin_set_code", setCode);
    Event.on("signin_open_video", onClickSignInVideo);
    Event.on("signin_on_verify", onVerify);

    onLoad();
  }
  deinit() {
    super.deinit();
    Event.remove("signin_set_code", setCode);
    Event.remove("signin_open_video", onClickSignInVideo);
    Event.remove("signin_on_verify", onVerify);
  }
}

const controller = new VerificationController();
export default controller;

function onLoad() {
  if (!store.getState().ui.signInIdentifier.length) {
    Router.replace("SignIn");
  }
}

function setCode({ code }: { code: string }) {
  SignInUI.setSignInCode(code);
}

function onClickSignInVideo() {
  Router.openLink("https://vimeo.com/466329347");
}

async function onVerify({
  identifier,
  code
}: {
  identifier: string;
  code: string;
}) {
  SignInUI.setError("");
  SignInUI.setVerifyingUser(true);
  const { success, data, message } = await User.verify(identifier, code);
  if (success) {
    if (!data.has_existing_user || !data.user) Router.replace("SignUp");
    else Router.replace("Dashboard");
  } else if (message) {
    SignInUI.setError(message);
  }
  SignInUI.setVerifyingUser(false);
}
