import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import ErrorBanner from "components/ErrorBanner";

import FeedbackWrapper from "../components/FeedbackWrapper";
import FavoriteTutorPrompt from "../components/FavoriteTutorPrompt";
import FeedbackHeader from "../components/FeedbackHeader";
import FeedbackForm from "../components/FeedbackForm";

export default function SessionFeedback() {
  const error = useSelector((state: StoreState) => state.ui.sessionError);

  return (
    <>
      <FeedbackWrapper>
        <ErrorBanner error={error} />
        <FeedbackHeader />
        <FeedbackForm />
      </FeedbackWrapper>
      <FavoriteTutorPrompt />
    </>
  );
}
