import * as React from "react";
import { StyleSheet, View } from "react-native";

import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Card from "components/Card";
import Text from "components/Text";

import Colors from "assets/theme/Colors";

import TutorCard from "./Tutor";
import { useSelector } from "react-redux";
import { StoreState } from "store";

export default function FavoriteTutors() {
  const favoriteTutors = useSelector(
    (state: StoreState) => state.user.favoriteTutors
  );
  const suggestedTutors = useSelector(
    (state: StoreState) => state.user.suggestedTutors
  );

  function getSubheader() {
    return `We will try to match you with one of your Favorite Tutors if they're available`;
  }

  return (
    <Card style={styles.card}>
      <Text style={styles.header}>Favorite Tutors</Text>
      <Text style={styles.subheader}>{getSubheader()}</Text>
      <View style={styles.tutors}>
        {favoriteTutors.map((tutor) => (
          <TutorCard key={tutor.id} tutor={tutor} favorite={true} />
        ))}
        {!favoriteTutors.length && (
          <Text style={styles.emptyText}>No tutors found</Text>
        )}
      </View>
      <Text style={[styles.header, styles.suggestedHeader]}>
        Suggested Tutors
      </Text>
      <View style={styles.tutors}>
        {suggestedTutors.map((tutor) => (
          <TutorCard key={tutor.id} tutor={tutor} favorite={false} />
        ))}
        {!suggestedTutors.length && (
          <Text style={styles.emptyText}>No tutors found</Text>
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    ...Spacing.pa2
  },
  header: {
    textAlign: "center",
    ...Typography.accent.large,
    fontSize: 20
  },
  suggestedHeader: {
    color: Colors.grey.inactive,
    ...Spacing.mt4
  },
  subheader: {
    textAlign: "center",
    ...Spacing.mb2
  },
  tutors: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  emptyText: {
    ...Spacing.my2,
    color: Colors.grey.inactive
  }
});
