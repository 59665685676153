import { AuthHelper } from "@yups/utils";
import store from "store";
import { setSettings } from "store/Settings";
import {
  setUser,
  setFavoriteTutors,
  setSuggestedTutors,
  addFavoriteTutor,
  removeFavoriteTutor
} from "store/User";
import Session, { SessionType, sessionRequiresRating } from "./Session";
import Settings, { SettingsType } from "./Settings";
import { getDeviceInfo } from "types/Device";
import { cleanup } from "helpers/Cleanup";
import { CleverError, getCleverErrorMessage } from "helpers/Clever";
import { Pusher } from "helpers/Pusher";
import { isEmail } from "helpers/UserIdentifier";
import Logger, { AppEvents } from "helpers/Logger";
import WebService, {
  SignInRequest,
  VerifyRequest,
  SignUpRequest,
  GoogleAuthRequest
} from "helpers/WebService";
import { setUser as setSentryUser } from "helpers/Sentry";
import { YupAPI } from "helpers/YupAPI";
import { setSignInIdentifier } from "store/UI";

export type User = {
  id: number;
  name: string;
  first_name?: string;
  last_name: string;
  email: string;
  phone_number: string;
  identifier: string;
  access_token: string;
  settings: SettingsType;
  last_session?: SessionType;
  can_start_session_errors: any; // type dictionary of key/value
  token: string;
  achievements: Array<{ count: number; type: string }>;
  favorite_tutor_ids: Array<number>;
  school_name: string;
  teacher_name: string;
  has_existing_user: boolean;
  is_new_account: boolean;
  grade?: number;
};

export const Errors = {
  signInInvalid: "Please enter a valid email or phone number",
  verificationInvalid: "The code you entered is invalid",
  googleSignInInvalid:
    "There's no Yup account with that email. You can try again or enter your email manually.",
  default: "Something went wrong. Please try again"
};

class UserClass {
  onUnauthorizedCallback = () => {};

  get() {
    return store.getState().user.user;
  }

  set(user: User) {
    store.dispatch(setUser(user));
    Settings.set(user.settings);
    AuthHelper.setAuthParams({
      identifier: user.identifier,
      access_token: user.access_token,
      settings: user.settings
    });
    setSentryUser(user);
    if (AuthHelper.getPusherAppKey()) {
      Pusher.initialize();
    }
  }

  formatIdentifier(identifier: string) {
    const payload: any = {
      email: null,
      phone_number: null
    };
    if (isEmail(identifier)) {
      payload.email = identifier;
    } else {
      payload.phone_number = identifier;
    }
    return payload;
  }

  async sync() {
    if (!this.get()?.id) return;
    
    const response = await WebService.syncUserData();
    if (response.success) {
      this.set(response.data);
      await this.loadCurrentSessionIfExists(this.get()!);
    } else {
      response.message = Errors.default;
    }
    return response;
  }

  async signIn(identifier: string) {
    const payload = this.formatIdentifier(identifier) as SignInRequest;
    Logger.log(AppEvents.loginSubmitIdentifier, payload);
    const response = await WebService.signIn(payload);
    if (!response.success) {
      response.message = response.data ? Errors.signInInvalid : Errors.default;
    }
    return response;
  }

  async loadCurrentSessionIfExists(user: User) {
    if (
      user.last_session &&
      (!Session.get() || Session.get()!.id <= user.last_session.id)
    ) {
      Session.set(user.last_session);
      await Session.sync();
    }
  }

  async verify(identifier: string, code: string) {
    const payload = {
      code,
      device: await getDeviceInfo(),
      ...this.formatIdentifier(identifier)
    } as VerifyRequest;
    Logger.log(AppEvents.loginAttempt, payload);
    const response = await WebService.verify(payload);
    if (response.success) {
      const user = response.data?.user;
      if (user) {
        await this.loadCurrentSessionIfExists(user);
        this.set(user);
        Logger.log(AppEvents.loginSuccessful, { keepAnonymous: true });
      } else {
        store.dispatch(setSignInIdentifier(response.data?.email || response.data?.phone_number));
        Logger.log(AppEvents.loginVerifySuccessful, {
          keepAnonymous: true
        });
      }
    } else if (response.data) {
      response.message = Errors.verificationInvalid;
    } else {
      response.message = Errors.default;
    }
    return response;
  }

  async create(identifier: string, firstName: string, lastName: string) {
    let payload = {
      user: {
        name: firstName,
        last_name: lastName,
        ...this.formatIdentifier(identifier)
      },
      device: await getDeviceInfo()
    };
    const response = await WebService.signUp(payload as SignUpRequest);
    if (response.success && !response.data?.errors) {
      this.set(response.data?.user ?? response.data);
      Logger.log(AppEvents.onboardingFlowStart);
    } else {
      response.message = Errors.default;
    }
    return response;
  }

  async getGoogleUserEmail(idToken: string) {
    const userResponse = await fetch(
      `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${idToken}`,
      { method: "GET" }
    );
    if (userResponse.status !== 200) throw Error(Errors.default);

    const { email } = await userResponse.json();
    return email;
  }

  async signInWithGoogle(idToken: string, deviceType: string) {
    try {
      const email = await this.getGoogleUserEmail(idToken);
      const payload: GoogleAuthRequest = {
        email,
        id_token: idToken,
        device_type: deviceType,
        scope: "student"
      };
      Logger.log(AppEvents.loginAttempt, {
        email,
        sso: "google"
      });

      const response = await WebService.googleSSO(payload);

      if (response.data?.errors?.user) throw Error(Errors.googleSignInInvalid);
      else if (!response.success || !response.data) throw Error(Errors.default);
      this.set(response.data.user);

      await YupAPI.updateDevice(await getDeviceInfo());

      Logger.log(AppEvents.loginSuccessful, {
        email,
        sso: "google"
      });
      return response;
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  async signInWithClever(auth_code: string) {
    Logger.log(AppEvents.loginAttempt, { sso: "clever", auth_code });

    try {
      const { success, data } = await WebService.cleverSSO(auth_code);
      if (success) {
        this.set(data.user);
        Logger.log(AppEvents.loginSuccessful, {
          sso: "clever",
          auth_code,
          email: data.email
        });
      }
      return { success, message: getCleverErrorMessage(data.error?.code) };
    } catch (error) {
      return {
        success: false,
        message: getCleverErrorMessage(CleverError.other)
      };
    }
  }

  signOut() {
    store.dispatch(setUser(null));
    store.dispatch(setSettings(null));
    cleanup();
  }

  onUnauthorized(callback: () => void) {
    this.onUnauthorizedCallback = callback;
  }

  handleUnauthorized() {
    this.signOut();
    this.onUnauthorizedCallback();
  }

  async getFavoriteTutors() {
    const response = await WebService.getFavoriteTutors();
    if (response.success) {
      store.dispatch(setFavoriteTutors(response.data.favorite_tutors));
      await this.sync();
    } else {
      response.message = Errors.default;
    }
    return response;
  }
  async getSuggestedTutors() {
    const response = await WebService.getSuggestedTutors();
    if (response.success) {
      store.dispatch(setSuggestedTutors(response.data.suggested_tutors));
    } else {
      response.message = Errors.default;
    }
    return response;
  }
  async addFavoriteTutor(tutorId: number, source: string, tutorName: string) {
    store.dispatch(addFavoriteTutor(tutorId));
    const user = this.get();
    if (!user) return;
    const response = await WebService.setFavoriteTutors(
      user.favorite_tutor_ids
    );
    if (!response.success) {
      response.message = Errors.default;
      store.dispatch(removeFavoriteTutor(tutorId));
    } else {
      Logger.log(AppEvents.favoriteTutorToggled, {
        toggle_status: true,
        tutor_name: tutorName,
        source
      });
    }
    return response;
  }
  async removeFavoriteTutor(
    tutorId: number,
    source: string,
    tutorName: string
  ) {
    store.dispatch(removeFavoriteTutor(tutorId));
    const user = this.get();
    if (!user) return;
    const response = await WebService.setFavoriteTutors(
      user.favorite_tutor_ids
    );
    if (!response.success) {
      response.message = Errors.default;
      store.dispatch(addFavoriteTutor(tutorId));
    } else {
      Logger.log(AppEvents.favoriteTutorToggled, {
        toggle_status: false,
        tutor_name: tutorName,
        source
      });
    }
    return response;
  }
  clearSuggestedTutors() {
    store.dispatch(setSuggestedTutors([]));
  }
}
const User = new UserClass();
export default User;

export function getLogInfo() {
  const user = User.get();
  if (user) {
    const userName = [user.name, user.last_name]
      .filter((name) => !!name)
      .join(" ");
    return {
      user_id: user.id,
      student_first_name: user.name,
      student_last_name: user.last_name,
      school_name: user.school_name,
      teacher_name: user.teacher_name,
      user_name: userName
    };
  }
  return {};
}
