import { Event } from "events/Event";
import { SignInUI } from "models/UI";
import { Router } from "navigation/Router";

import SignInBaseController from "./SignInBaseController";

class OnboardingController extends SignInBaseController {
  init() {
    super.init();
    Event.on("signin_on_navigate_onboarding", onOnboardingNavigate);
    Event.on("signin_on_finish_onboarding", onOnboardingFinish);
  }
  deinit() {
    super.deinit();
    Event.remove("signin_on_navigate_onboarding", onOnboardingNavigate);
    Event.remove("signin_on_finish_onboarding", onOnboardingFinish);
  }
}

const controller = new OnboardingController();
export default controller;

function onOnboardingNavigate({ slide }: { slide: number }) {
  SignInUI.setOnboardingSlide(slide);
}

function onOnboardingFinish() {
  SignInUI.onFinishOnboarding();
  Router.navigate("Dashboard");
}
