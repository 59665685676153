import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { CircleIcon, BackIcon, NextIcon } from "components/Icons";

import { Slides } from "../types/Slide";
import { Event } from "events/Event";

export default function OnboardingNavigation() {
  const slide = useSelector((state: StoreState) => state.ui.onboardingSlide);

  const onPrevious = Event.dispatcher("signin_on_navigate_onboarding", {
    slide: slide - 1
  });
  const onNext = Event.dispatcher("signin_on_navigate_onboarding", {
    slide: slide + 1
  });
  const onNavigate = (slide: number) =>
    Event.dispatch("signin_on_navigate_onboarding", { slide });
  const onFinish = Event.dispatcher("signin_on_finish_onboarding");

  function getDotColor(index: number) {
    return index === slide ? Colors.brand.blue : Colors.grey.slate;
  }

  return (
    <View style={styles.navigation}>
      {slide > 0 && (
        <Button
          theme="flat"
          round={true}
          testID="onboarding-back"
          onClick={onPrevious}
        >
          <BackIcon height={20} width={20} color={Colors.brand.blue} />
        </Button>
      )}
      {Slides.map((_: any, index) => (
        <TouchableOpacity
          key={`onboarding-${index}`}
          style={styles.dot}
          testID={`onboarding-next-${index}`}
          onPress={() => onNavigate(index)}
        >
          <CircleIcon width={12} height={12} color={getDotColor(index)} />
        </TouchableOpacity>
      ))}
      {slide == Slides.length - 1 ? (
        <Button theme="secondary" testID="onboarding-submit" onClick={onFinish}>
          I'm ready!
        </Button>
      ) : (
        <Button
          theme="flat"
          round={true}
          testID="onboarding-next"
          onClick={onNext}
        >
          <NextIcon height={20} width={20} color={Colors.brand.blue} />
        </Button>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  navigation: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.pt2
  },
  dot: {
    ...Spacing.ma2
  }
});
