import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, View } from "react-native";
import { maybeCompleteAuthSession } from "expo-web-browser";
import { useIdTokenAuthRequest } from "expo-auth-session/providers/google";
import Config from "helpers/Config";

import Button from "components/Button";
import Image from "components/Image";
import { Prompt } from "expo-auth-session";
import Logger, { AppEvents } from "helpers/Logger";
import Spacing from "assets/theme/Spacing";
import { Event } from "events/Event";

maybeCompleteAuthSession();

export default function GoogleSignIn() {
  const registering = useSelector(
    (state: StoreState) => state.ui.registeringUser
  );
  const [request, response, promptAsync] = useIdTokenAuthRequest({
    expoClientId: Config.googleClientKey(),
    iosClientId: Config.googleClientKeyIos(),
    androidClientId: Config.googleClientKeyAndroid(),
    webClientId: Config.googleClientKey(),
    prompt: Prompt.SelectAccount
  });

  useEffect(() => {
    if (response?.type === "success") {
      const {
        params: { id_token }
      } = response;
      Event.dispatch("signin_on_google_sso", { idToken: id_token });
    }
  }, [response]);

  function onClick() {
    Logger.log(AppEvents.loginSSOStart, { sso: "google" });
    promptAsync();
  }

  return (
    <View style={registering ? styles.registering : {}}>
      <Button disabled={!request || registering} theme="flat" onClick={onClick}>
        <Image
          uri={`${Config.assetsUrl}google-dark%402x.png`}
          style={styles.googleSignIn}
          maintainAspectRatio={true}
          maxSize={200}
        />
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  googleSignIn: {
    ...Spacing.mt2
  },
  registering: {
    opacity: 0.5
  }
});
