import React from "react";
import { Event } from "events/Event";
import { StyleSheet, View, Platform } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Text from "components/Text";
import TextField from "components/TextField";

import SignInWrapper from "../components/SignInWrapper";
import CleverSignIn from "../components/CleverSignIn";
import GoogleSignIn from "../components/GoogleSignIn";

export default function SignIn() {
  const identifier = useSelector(
    (state: StoreState) => state.ui.signInIdentifier
  );
  const registering = useSelector(
    (state: StoreState) => state.ui.registeringUser
  );
  const isAndroid = Platform.OS === "android";

  const onUpdateIdentifier = (identifier: string) =>
    Event.dispatch("signin_set_identifier", { identifier });
  const onSubmit = Event.dispatcher("signin_on_submit", { identifier });

  return (
    <SignInWrapper header="Let's get started!">
      {/*
        Issue where Android google auth submissions always counts as a "dismiss"
        See https://github.com/expo/expo/issues/12044
      */}
      {!isAndroid && (
        <View style={styles.sso}>
          <GoogleSignIn />
          <CleverSignIn />
          <View style={styles.separator}>
            <Text style={styles.separatorText}>or</Text>
          </View>
        </View>
      )}
      <Text>Enter your email address or phone number</Text>
      <TextField
        testID="signin-input"
        value={identifier}
        style={styles.textfield}
        autoFocus={true}
        keyboardType="email-address"
        placeholder="Email address or phone number"
        onChangeText={onUpdateIdentifier}
        autoCapitalize="none"
      />
      <Button
        disabled={!identifier.length}
        processing={registering}
        testID="signin-submit"
        onClick={onSubmit}
      >
        Send Code
      </Button>
    </SignInWrapper>
  );
}

const styles = StyleSheet.create({
  textfield: {
    ...Spacing.my3
  },
  sso: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  separator: {
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    borderStyle: "solid",
    width: "100%",
    ...Spacing.mt4,
    ...Spacing.mb3
  },
  separatorText: {
    backgroundColor: Colors.ui.white,
    marginTop: -Sizes["16px"],
    ...Typography.accent.small,
    ...Spacing.px3,
    alignSelf: "center"
  }
});
