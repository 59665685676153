import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";

import { GeneralUI, SessionUI } from "models/UI";

import ExtensionController from "./ExtensionController";

class SessionCameraController extends Controller {
  init() {
    Event.on("camera_exit", onExitCamera);
    Event.on("camera_set_permission", onSetCameraPermission);
    Event.on("camera_picture_taken", onPictureTaken);

    ExtensionController.init();
  }
  deinit() {
    Event.remove("camera_exit", onExitCamera);
    Event.remove("camera_set_permission", onSetCameraPermission);
    Event.remove("camera_picture_taken", onPictureTaken);

    ExtensionController.deinit();
  }
}

const controller = new SessionCameraController();
export default controller;

function onSetCameraPermission({ hasPermission }: { hasPermission: boolean }) {
  GeneralUI.setHasCameraPermission(hasPermission);
}

function onPictureTaken({ photo }: { photo: string }) {
  SessionUI.setPhoto(photo);
  Router.replace("SessionScratchBoard");
}

function onExitCamera() {
  Router.replace("Session");
  SessionUI.setPhoto("");
}
