import { User } from "models/User";
import * as Sentry from "sentry-expo";
import Config from "helpers/Config";
import { Platform } from "react-native";
import { WebServiceError } from "@yups/utils";

export function loadSentry() {
  if(Config.testing) return;
  Sentry.init({
    dsn: Config.sentryKey,
    enableInExpoDevelopment: false,
    debug: false
  });
}

export function setUser(user: User) {
  if (!user?.id) return;
  if (Platform.OS === "web") {
    Sentry.Browser.setUser({ id: user.id?.toString() });
  } else {
    Sentry.Native.setUser({ id: user.id?.toString() });
  }
}

export function wrapApp(app: any) {
  return Platform.OS === "web" ? app : Sentry.Native.wrap(app);
}

export function reportError(error: Error | WebServiceError | undefined) {
  const sentry = Platform.OS === "web" ? Sentry.Browser : Sentry.Native;
  sentry.captureException(error);
}
