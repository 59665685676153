import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";
import store from "store";

class MobileUploadSuccessController extends Controller {
  init() {
    Event.on("mobile_upload_success_on_back", onBack);

    onLoad();
  }
  deinit() {
    Event.remove("mobile_upload_success_on_back", onBack);
  }
}

const controller = new MobileUploadSuccessController();
export default controller;

function onLoad() {
  if (!store.getState().ui.mobileUploadPhoto) {
    onBack();
  }
}

function onBack() {
  const route = Router.getCurrent();
  if (!route?.params) {
    Router.replace("MobileUploadToken");
  } else {
    Router.replace("MobileUploadCamera", route.params);
  }
}
