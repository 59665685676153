import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

import { setEnvironment, Environment } from "types/Environment";

export default class SignInBaseController extends Controller {
  init() {
    Event.on("signin_open_yup", onGoToSite);
    Event.on("signin_set_environment", onSetEnvironment);
  }
  deinit() {
    Event.remove("signin_open_yup", onGoToSite);
    Event.remove("signin_set_environment", onSetEnvironment);
  }
}

function onGoToSite() {
  Router.openLink("https://yup.com");
}

async function onSetEnvironment({ env }: { env: Environment }) {
  await setEnvironment(env);
}
