import React from "react";
import { Router } from "navigation/Router";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Extension } from "helpers/ChromeExtension";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Text from "components/Text";
import { XIcon } from "components/Icons";

export default function ExtensionError() {
  const show = useSelector((state: StoreState) => state.ui.showExtensionError);

  const onDismiss = Event.dispatcher("session_dismiss_extension_error");
  function onOpenLink() {
    Router.openLink(Extension.getUrl());
    onDismiss();
  }

  if (!show) return null;

  return (
    <View style={styles.banner}>
      <Text style={styles.text}>Please install our</Text>
      <TouchableOpacity testID="install-extension" onPress={onOpenLink}>
        <Text style={styles.link}>Yup Chrome extension</Text>
      </TouchableOpacity>
      <TouchableOpacity testID="dismiss-extension-error" onPress={onDismiss}>
        <XIcon width={16} height={16} color={Colors.ui.white} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.ui.red,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    ...Spacing.px3,
    ...Spacing.py2
  },
  text: {
    color: Colors.ui.white,
    textAlign: "center"
  },
  link: {
    color: Colors.ui.white,
    textDecorationLine: "underline",
    ...Spacing.mx1
  }
});
