import { Event } from "events/Event";
import { Controller } from "types";

import { GeneralUI, MobileUploadUI } from "models/UI";

import { dataURItoBlob, mobileImageUploader } from "helpers/UploadImage";
import { Router } from "navigation/Router";

class MobileUploadCameraController extends Controller {
  init() {
    Event.on("camera_exit", onBack);
    Event.on("camera_set_permission", onSetCameraPermission);
    Event.on("camera_picture_taken", onPictureTaken);
  }
  deinit() {
    Event.remove("camera_exit", onBack);
    Event.remove("camera_set_permission", onSetCameraPermission);
    Event.remove("camera_picture_taken", onPictureTaken);
  }
}

const controller = new MobileUploadCameraController();
export default controller;

function onSetCameraPermission({ hasPermission }: { hasPermission: boolean }) {
  GeneralUI.setHasCameraPermission(hasPermission);
}

async function onPictureTaken({ photo }: { photo: string }) {
  const route = Router.getCurrent();
  if (!route?.params) return;

  MobileUploadUI.setPhoto(photo);
  mobileImageUploader.uploadImage(
    await dataURItoBlob(photo),
    (route.params as any).token
  );
  Router.replace("MobileUploadSuccess", route.params);
}

function onBack() {
  Router.replace("MobileUploadToken");
}
