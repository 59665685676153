import Colors from "assets/theme/Colors";
import useColorScheme from "hooks/useColorScheme";

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  return props[theme] ?? Colors[theme][colorName];
}

export type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};
