import React, { useState } from "react";
import { View, StyleSheet } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import ChatRoom from "components/ChatRoom";
import GoogleTranslate from "components/GoogleTranslate";
import { SessionType } from "models/Session";
import Spinner from "components/Progress/Spinner";

import Error from "../components/Error";
import WhiteboardPlayer from "../components/WhiteboardPlayer";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";

type TranscriptContentProps = {
  session: SessionType | null;
};

export default function TranscriptContent(props: TranscriptContentProps) {
  const error = useSelector(
    (state: StoreState) => state.ui.sessionHistoryError
  );

  const [whiteboardTimestamp, setWhiteboardTimestamp] = useState(0);

  const onSetImage = (imageUrl: string) =>
    Event.dispatch("history_transcript_set_image", { imageUrl });

  if (props.session) {
    const { messages } = props.session;
    const startTime = new Date(messages[0].sent_at).getTime();
    const endTime = new Date(messages[messages.length - 1].sent_at).getTime();
    return (
      <View style={styles.sessionWrapper} testID="transcript-view">
        <WhiteboardPlayer
          timestamp={whiteboardTimestamp}
          startTime={startTime}
          endTime={endTime}
          onTimestampChange={setWhiteboardTimestamp}
        />
        <View style={styles.session}>
          <GoogleTranslate page="SessionTranscript" useTabStyle={true} />
          <ChatRoom
            messages={messages ?? []}
            messageInput=""
            showMessageInput={false}
            startFromTop={true}
            onImageClicked={onSetImage}
          />
        </View>
      </View>
    );
  } else if (error) {
    return (
      <View style={styles.loading} testID="transcript-error">
        <Error error={error} />
      </View>
    );
  }
  return (
    <View style={styles.loading}>
      <Spinner size={96} width={8} color={Colors.grey.slate} />
    </View>
  );
}

const styles = StyleSheet.create({
  loading: {
    backgroundColor: Colors.ui.white,
    flex: 1,
    ...Spacing.pt4
  },
  sessionWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  session: {
    height: "100%",
    flex: 1
  }
});
