export function isEmail(text: string) {
  return text.match(/^.+@.+\..+$/);
}

const InaccessibleEmailDomains = ["iemschools.org"];

export function isInaccessibleEmailDomain(identifier: string) {
  return (
    isEmail(identifier) &&
    InaccessibleEmailDomains.includes(identifier.split("@")[1])
  );
}
