import * as React from "react";
import { Event } from "events/Event";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Text from "components/Text";
import TextField from "components/TextField";

import SignInWrapper from "../components/SignInWrapper";

export default function SignUp() {
  const firstName = useSelector(
    (state: StoreState) => state.ui.signUpFirstName
  );
  const lastName = useSelector((state: StoreState) => state.ui.signUpLastName);
  const creating = useSelector((state: StoreState) => state.ui.creatingUser);
  const identifier = useSelector(
    (state: StoreState) => state.ui.signInIdentifier
  );

  const isValid = firstName.length && lastName.length;

  const onUpdateFirstName = (firstName: string) =>
    Event.dispatch("signin_set_first_name", { firstName });
  const onUpdateLastName = (lastName: string) =>
    Event.dispatch("signin_set_last_name", { lastName });
  const onSubmit = Event.dispatcher("signin_on_sign_up", {
    identifier,
    firstName,
    lastName
  });

  return (
    <SignInWrapper header="One more thing...">
      <Text>
        Please enter your first and last name to complete your registration
      </Text>
      <TextField
        value={firstName}
        style={styles.textfield}
        autoFocus={true}
        placeholder="First Name"
        testID="signup-firstname"
        onChangeText={onUpdateFirstName}
      />
      <TextField
        value={lastName}
        style={styles.textfield}
        placeholder="Last Name"
        testID="signup-lastname"
        onChangeText={onUpdateLastName}
      />
      <Button
        disabled={!isValid}
        processing={creating}
        testID="signup-submit"
        onClick={onSubmit}
      >
        Let's Go!
      </Button>
    </SignInWrapper>
  );
}

const styles = StyleSheet.create({
  textfield: {
    ...Spacing.my3
  }
});
