import { Event } from "events/Event";
import { Router } from "navigation/Router";

import SignInBaseController from "./SignInBaseController";

class WelcomeController extends SignInBaseController {
  init() {
    super.init();
    Event.on("signin_on_finish_welcome", onFinish);
  }
  deinit() {
    super.deinit();
    Event.remove("signin_on_finish_welcome", onFinish);
  }
}

const controller = new WelcomeController();
export default controller;

function onFinish() {
  Router.navigate("Onboarding");
}
