import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { View, Platform, StyleSheet } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Card from "components/Card";
import Layout from "assets/theme/Layout";
import Text from "components/Text";

import Session from "./Session";
import { useSelector } from "react-redux";
import { StoreState } from "store";

export default function SessionList() {
  const sessions = useSelector(
    (state: StoreState) => state.sessionHistory.sessions
  );
  const isSm = useMediaQuery({ maxWidth: Layout.md });

  return (
    <>
      {!isSm && Platform.OS === "web" && (
        <View style={styles.header}>
          <Text style={styles.headerText}>Date</Text>
          <Text style={styles.headerText}>Topic</Text>
          <Text style={styles.headerText}>Tutor</Text>
          <Text style={styles.headerText}>Rating</Text>
          <Text style={styles.headerText}>Achievements</Text>
          <Text style={styles.headerText}></Text>
        </View>
      )}
      <Card>
        {sessions.map((session) => (
          <Session key={session.id} session={session} />
        ))}
      </Card>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row"
  },
  headerText: {
    ...Typography.accent.small,
    color: Colors.grey.inactive,
    flex: 1,
    ...Spacing.mx3
  }
});
