import * as React from "react";
import { Text as DefaultText, StyleSheet } from "react-native";
import { useThemeColor, ThemeProps } from "components/theme";
import { useMediaQuery } from "react-responsive";
import Layout from "assets/theme/Layout";
import Typography from "assets/theme/Typography";

export type TextProps = ThemeProps &
  DefaultText["props"] & {
    tag?: "h1" | "h2" | "h3" | "h4";
  };

export default function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;

  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");
  const styleRules: Array<object> = [{ color }];

  const tag = props.tag ?? "default";
  styleRules.push(styles[tag]);
  const isMd = useMediaQuery({ minWidth: Layout.md });
  if (!isMd) {
    styleRules.push((styles as any)[`sm-${tag}`]);
  }

  if (style) {
    styleRules.push(style);
  }

  return <DefaultText style={styleRules} {...otherProps} />;
}

const styles = StyleSheet.create({
  default: {
    fontSize: 16,
    fontFamily: Typography.primary.regular
  },
  h1: {
    fontSize: 32,
    fontFamily: Typography.primary.bold,
    fontWeight: "700"
  },
  h2: {
    fontSize: 24,
    fontFamily: Typography.primary.medium,
    fontWeight: "bold"
  },
  h3: {
    fontSize: 22,
    fontFamily: Typography.primary.medium,
    fontWeight: "bold"
  },
  h4: {
    fontSize: 18,
    fontFamily: Typography.primary.regular
  },
  "sm-h1": { fontSize: 24 },
  "sm-h2": { fontSize: 22 },
  "sm-h3": { fontSize: 18 },
  "sm-h4": { fontSize: 16 },
  "sm-default": { fontSize: 16 }
});
