import { Event } from "events/Event";
import { Router } from "navigation/Router";
import Session, { closeSession, sessionRequiresRating } from "models/Session";
import Settings from "models/Settings";
import { SessionUI } from "models/UI";
import User from "models/User";
import { Controller } from "types";

class SessionRatingController extends Controller {
  init() {
    Event.on("postsession_submit_rating", submitRating);

    onLoad();
  }
  deinit() {
    Event.remove("postsession_submit_rating", submitRating);
  }
}

const controller = new SessionRatingController();
export default controller;

function onLoad() {
  if (!Settings.get() || !User.get()) {
    Router.replace("SignIn");
  } else if (!Session.get() || !Session.get()!.tutor || !sessionRequiresRating(Session.get()!)) {
    goToDashboard();
  }
}

function goToDashboard() {
  closeSession();
  SessionUI.reset();
  User.sync();
  Router.replace("Dashboard");
}

function submitRating({ positive }: { positive: boolean }) {
  SessionUI.setRating(positive);
  Router.replace("SessionFeedback");
}
