import React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Text from "components/Text";
import Sizes from "assets/theme/Sizes";

type ErrorBannerProps = {
  error: string;
};

export default function ErrorBanner(props: ErrorBannerProps) {
  if (!props.error) return null;
  return (
    <View style={styles.banner} testID="error">
      <Text style={styles.text}>{props.error}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.ui.red,
    borderRadius: Sizes.borderRadius,
    ...Spacing.px3,
    ...Spacing.py2,
    ...Spacing.ma2
  },
  text: {
    color: Colors.ui.white,
    textAlign: "center"
  }
});
