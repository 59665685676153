import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const strokeColor: BaseCommand = {
  execute(
    context: ZwibblerContext,
    props: {
      color: string;
      tool: WhiteboardAction;
    }
  ) {
    context.setColour(props.color, false);
    LogAction(props.tool, {
      stroke_color_changed: props.color
    });
  }
};

export default strokeColor;
