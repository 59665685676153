import React from "react";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { StyleSheet } from "react-native";
import { EndedActions } from "models/Session";
import { Event } from "events/Event";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";

export default function TutorUnavailable() {
  const session = useSelector((state: StoreState) => state.session.current);
  const show = session?.ended_action === EndedActions.ServerTimedOut;
  const onDismiss = Event.dispatcher("session_dismissed");

  return (
    <Modal show={show}>
      <Text tag="h2" style={styles.prompt}>
        Tutor Unavailable
      </Text>
      <Text style={styles.text}>
        We're sorry, all of our tutors are helping other students! Please try
        again in a few minutes.
      </Text>
      <Button onClick={onDismiss}>Okay</Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3,
    ...Spacing.pt3
  },
  text: {
    textAlign: "center"
  }
});
