import React, { useEffect, useRef, useState } from "react";
import {
  NativeScrollEvent,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  NativeSyntheticEvent,
  View
} from "react-native";
import Spacing from "assets/theme/Spacing";

import { MessageType, MessageSender } from "./types/Message";

import Message from "./Message";
import Text from "components/Text";
import Colors from "assets/theme/Colors";
import { TextToSpeech } from "./helpers/TextToSpeech";

type MessageStreamProps = {
  sender: MessageSender;
  messages: Array<MessageType>;
  showTimestamps?: boolean;
  startFromTop?: boolean;
  canReact: boolean;
};

const LAST_MESSAGE_BUFFER = 48;

function lastMessageWasFromOtherUser(
  sender: MessageSender,
  messages: Array<MessageType>
) {
  if (!messages.length) return false;
  return messages[messages.length - 1].sent_from !== sender;
}

let textToSpeech = new TextToSpeech();
export default function MessageStream(props: MessageStreamProps) {
  const stream = useRef<ScrollView>(null);
  const didInit = useRef(false);

  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [bottomInView, setBottomInView] = useState(!props.startFromTop);
  const [messagePlaying, setMessagePlaying] = useState("");

  useEffect(() => {
    textToSpeech.onEnd(() => setMessagePlaying(""));
  }, []);

  useEffect(() => {
    if (bottomInView) {
      setUnreadMessageCount(0);
    }
  }, [bottomInView]);

  function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent;
    setBottomInView(
      contentOffset.y + layoutMeasurement.height + LAST_MESSAGE_BUFFER >=
        contentSize.height
    );
  }

  function handleContentSizeChange() {
    if(!didInit.current && !props.startFromTop) {
      scrollToBottom();
      didInit.current = true;
    } else if (bottomInView && !props.startFromTop) {
      scrollToBottom();
    } else if (lastMessageWasFromOtherUser(props.sender, props.messages)) {
      setUnreadMessageCount(unreadMessageCount + 1);
    } else if(!lastMessageWasFromOtherUser(props.sender, props.messages) && !props.startFromTop) {
      scrollToBottom();
    }
  }

  function scrollToBottom() {
    stream.current?.scrollToEnd({ animated: true });
  }

  function unreadMessageText() {
    return `You have ${unreadMessageCount} new ${
      unreadMessageCount === 1 ? "message" : "messages"
    }`;
  }

  function onStartTextToSpeech(message: MessageType, key: number) {
    setMessagePlaying(String(key));
    textToSpeech.start(message.text);
  }

  function onStopTextToSpeech() {
    setMessagePlaying("");
    textToSpeech.stop();
  }

  return (
    <>
      <ScrollView
        style={styles.stream}
        ref={stream}
        testID="message-stream"
        onScroll={handleScroll}
        onContentSizeChange={handleContentSizeChange}
        scrollEventThrottle={16}
      >
        <View style={styles.messages}>
          {props.messages.map((message, key) => (
            <Message
              key={`message-${key}`}
              sender={props.sender}
              message={message}
              showTimestamp={props.showTimestamps!}
              canReact={props.canReact}
              isPlaying={messagePlaying === String(key)}
              onStartTextToSpeech={() => onStartTextToSpeech(message, key)}
              onStopTextToSpeech={onStopTextToSpeech}
            />
          ))}
        </View>
      </ScrollView>
      {Boolean(unreadMessageCount) && !props.startFromTop && (
        <TouchableOpacity
          style={styles.unreadMessageIndicator}
          onPress={scrollToBottom}
        >
          <Text>{unreadMessageText()}</Text>
        </TouchableOpacity>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  stream: {
    flexGrow: 1
  },
  messages: {
    ...Spacing.py4,
    ...Spacing.px3
  },
  unreadMessageIndicator: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.brand.yellow,
    opacity: 0.7,
    ...Spacing.pa2
  }
});
