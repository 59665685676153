import { setCurrent } from "store/Session";
import store from "store";
import SessionMessages, {
  getLogInfo as getMessageLogInfo
} from "./SessionMessages";
import Logger, { SessionLogs, AppEvents, DebugLogs } from "helpers/Logger";
import { MessageType, MessageSender } from "components/ChatRoom/types/Message";
import { Activity } from "components/ChatRoom/types/Activity";

import { BotScript } from "helpers/BotScript";
import { elapsedTimeMins } from "helpers/Time";

import WebService from "helpers/WebService";
import { Timers, SessionEvent } from "@yups/utils";
import Settings from "./Settings";
import { Event } from "events/Event";
import { Tutor } from "types/Tutor";
import { SessionChannel } from "./SessionChannel";
import { AssistmentRequestData } from "./Partnerships";
import { getDeviceInfo } from "types/Device";
import { reportError } from "helpers/Sentry";

export enum EndedActions {
  ServerTimedOut = "server:timed_out",
  StudentCancelled = "student:cancel_button",
  StudentEnded = "student:end_button",
  TutorEnded = "tutor:end_button"
}

export type SessionType = {
  id: number;
  tutor?: Tutor | null;
  topic: string;
  ended_action?: string;
  ended_by?: string; // TODO: remove once yup-api is deployed
  tutor_comment: string;
  number: number;
  claimed_at?: number;
  created_at: number;
  started_at?: number;
  tutor_ready_at?: number;
  ended_at?: number;
  messages: Array<MessageType>;
  channel_name: string;
  user_id: number; // TODO: figure out if this is used
  pre_session_questions: BotScript;
  student_needs_to_rate: boolean;
  tutor_formal_name?: string;
  decision_criteria?: string;
};

export const Errors = {
  noMinutes:
    "You must have an active plan to start a session. If you have a plan or are having trouble getting one, email support@yup.com",
  supportDefault:
    "Something went wrong! If this issue continues, please contact support@yup.com.",
  default: "Something went wrong. Please try again."
};

const SESSION_INTERVAL_LABEL = "yup-session";
const PUSHER_INIT_RETRY_TIMER_LABEL = "pusher-init-retry";
const Intervals = {
  connectedSyncInterval: 15000,
  disconnectedSyncInterval: 5000,
  pusherInitRetryInterval: 1000
};

const Session = {
  currentInterval: 0,
  isSyncing: false,
  pusherInitializedForId: 0,
  get() {
    return store.getState().session.current;
  },

  getMessages() {
    return store.getState().messages.messages;
  },

  getSessionDuration() {
    const session = this.get();
    if (!session) return -1;
    const startTime =
      session.started_at ||
      session.claimed_at ||
      session.created_at ||
      new Date().getTime();
    const endTime = session.ended_at || new Date().getTime() / 1000.0;
    return Math.round(((endTime - startTime) / 60.0) * 100) / 100;
  },

  set(session: SessionType) {
    const current = this.get();
    if (current === null || session.id === current.id) {
      store.dispatch(setCurrent(session));
    } else if (current.id < session.id) {
      store.dispatch(setCurrent(session));
      Logger.log(SessionLogs.storageOverwroteExpiredSession, {
        cached_session_id: current.id,
        given_session_id: session.id
      });
    } else {
      Logger.log(SessionLogs.storageIgnoredExpiredSession, {
        cached_session_id: current.id,
        given_session_id: session.id
      });
    }
  },

  async create(
    student_subtopic_id: number,
    assistments_data?: AssistmentRequestData
  ) {
    const response = await WebService.createSession({
      student_subtopic_id,
      assistments_data,
      device: await getDeviceInfo()
    });
    if (response.success) {
      this.set(response.data);
      Logger.log(SessionLogs.sessionCreated);
      Logger.log(AppEvents.sessionRequestTutor);
      Logger.log(AppEvents.sessionFindingTutor);
    } else {
      response.message = response.data?.error || Errors.default;
    }
    return response;
  },

  async startSessionListeners() {
    const session = this.get();
    if (!session) return;
    this.sync();
    this.setSyncSessionInterval(Intervals.connectedSyncInterval);
    this.initPusher();
    SessionMessages.load();
  },

  async initPusher(n = 0) {
    try {
      const session = this.get();
      if (!session) return;
      if (this.pusherInitializedForId == session.id) return;

      SessionChannel.destroy();

      await SessionChannel.on(SessionEvent.tutorFound, () => {
        this.sync();
      });
      await SessionChannel.on(SessionEvent.tutorReady, () => {
        Logger.log(SessionLogs.pusherTutorReadyEvent);
        this.sync();
      });
      await SessionChannel.on(SessionEvent.startedTyping, () => {
        Event.dispatcher("chatroom_tutor_activity", {
          activity: Activity.typing
        })();
      });
      await SessionChannel.on(
        SessionEvent.startedDrawing,
        Event.dispatcher("chatroom_tutor_activity", {
          activity: Activity.drawing
        })
      );
      await SessionChannel.on(SessionEvent.sessionEnded, () => this.sync());

      this.pusherInitializedForId = session.id;
    } catch (err) {
      reportError(err);

      Timers.setTimeout({
        label: PUSHER_INIT_RETRY_TIMER_LABEL,
        callback: () => this.initPusher(n + 1),
        delay: n * Intervals.pusherInitRetryInterval
      });
    }
  },

  endSessionListeners() {
    Timers.clear(SESSION_INTERVAL_LABEL);
    SessionChannel.destroy();
  },
  handleSessionEndDetected() {
    Event.dispatch("session_ended");
    this.handleSessionEnd();
  },
  handleTutorFound() {
    const User = require("models/User").default;
    const session = this.get();
    const messages = this.getMessages();
    const user = User.get();
    if (!session || !messages?.length || session?.tutor_ready_at || !user)
      return;
    const isFavorite = user.favorite_tutor_ids.includes(
      session?.tutor?.id ?? -1
    );
    const messageLabel = "tutor-found";
    const message = isFavorite
      ? "You got matched with one of your favorite tutors!"
      : "Tutor found and reviewing problem";
    const botMessage =
      "While you're waiting, learn more about your tutor by clicking their picture above!";
    if (SessionMessages.hasMessageLabel(messageLabel, MessageSender.systemInfo))
      return;
    Logger.log(AppEvents.sessionFoundTutor, {
      wait_time: elapsedTimeMins(session.created_at),
      decision_criteria: session.decision_criteria
    });
    SessionMessages.sendSystemMessage(message, messageLabel);
    SessionMessages.sendBotMessage(botMessage, messageLabel);
    this.sync();
    Event.dispatch("session_tutor_found");
  },
  handleTutorReady() {
    const session = this.get();
    if (!session?.tutor) return;
    SessionChannel.trigger(SessionEvent.tutorReady);
    const messageLabel = "tutor-ready";
    if (SessionMessages.hasMessageLabel(messageLabel, MessageSender.systemInfo))
      return;
    Logger.log(AppEvents.sessionStarted, {
      prep_time: elapsedTimeMins(session.claimed_at ?? session.created_at)
    });
    SessionMessages.sendBotMessage(
      "It is time to start your session now! Happy learning 😃",
      messageLabel
    );
    SessionMessages.sendSystemMessage(
      `Session started with ${session.tutor.profile.polite_name}`,
      messageLabel
    );
    this.sync();
  },

  async submitFeedback(payload: SubmitFeedbackPayload) {
    const session = this.get();
    if (!session) return { success: false, message: Errors.default };

    const complaint = [...payload.reasons];
    if (payload.comment) {
      complaint.push(payload.comment);
    }
    const response = await WebService.submitSessionFeedback({
      id: session.id,
      rating: getRatingValue(payload.rating),
      multiselect_feedback: payload.reasons,
      feedback: payload.comment,
      math_crunch_session_id: session.id, // TODO: Remove once yup-api submit_student_feedback endpoint is live
      complaint // TODO: Remove once yup-api submit_student_feedback endpoint is live
    });
    if (response.success) {
      const User = require("models/User").default;
      await User.sync();

      logRatingSubmitted(payload, complaint, this.getSessionDuration());
    } else {
      response.message = Errors.default;
    }
    return response;
  },

  logStudentSessionEndEvent(reason: number, ended_action: EndedActions) {
    if (ended_action === EndedActions.StudentCancelled) {
      Logger.log(AppEvents.sessionCancelRequest, {
        reason: Settings.get()?.session_cancel_reasons[reason ?? 0]
      });
    } else if (ended_action === EndedActions.StudentEnded) {
      Logger.log(SessionLogs.sessionEndedWithEndButton);
    }
  },

  getSessionEndedMessage() {
    const User = require("models/User").default;
    const session = this.get();
    const user = User.get();
    if (!session || !user) return "";

    switch (session.ended_action) {
      case EndedActions.StudentEnded:
      case EndedActions.StudentCancelled:
        const name = user.first_name ?? "Student";
        return `${name} has ended the session`;
      case EndedActions.ServerTimedOut:
        return "Session has timed out";
      case EndedActions.TutorEnded:
        const tutorName = session.tutor?.profile.polite_name ?? "Tutor";
        return `${tutorName} has ended the session`;
      default:
        return "Session has ended";
    }
  },
  sendSessionEndedMessage() {
    const message = this.getSessionEndedMessage();
    SessionMessages.sendSystemMessage(message, "session-ended");
  },

  async endSession(reason: number, ended_action: EndedActions) {
    const session = this.get();
    if (!session) return { success: false };
    const response = await WebService.endSession({
      id: session.id,
      reasons: reason ? [reason] : [],
      ended_at: Math.round(new Date().getTime() / 1000),
      ended_action,
      ended_by: ended_action
    });
    if (response.success) {
      Logger.log(AppEvents.sessionEndRequested, {
        ...getMessageLogInfo(),
        session_length: this.getSessionDuration()
      });
      SessionChannel.trigger(SessionEvent.sessionEnded);
      await this.handleSessionEnd();
      this.logStudentSessionEndEvent(reason, ended_action);
    } else {
      response.message = Errors.default;
    }
    return response;
  },

  async handleSessionEnd() {
    await this.sync();
    this.endSessionListeners();
  },

  onStartedTyping() {
    SessionChannel.triggerThrottled(SessionEvent.startedTyping, 500, {
      data: { typing: "true" }
    });
  },
  onStartedDrawing() {
    SessionChannel.triggerThrottled(SessionEvent.startedDrawing, 500, {
      data: { drawing: "true" }
    });
  },

  setSyncSessionInterval(delay: number): void {
    if (
      this.currentInterval === delay &&
      Timers.hasTimer(SESSION_INTERVAL_LABEL)
    )
      return;
    // the timers library will clear an existing interval if it's already set
    Timers.setInterval({
      label: SESSION_INTERVAL_LABEL,
      callback: () => this.sync(),
      delay
    });
    this.currentInterval = delay;
  },
  getSyncAction(newSessionData: SessionType) {
    const session = this.get();
    if (!session) return () => {};
    if (!session.ended_action && newSessionData.ended_action)
      return () => this.handleSessionEndDetected();
    if (!session.tutor && newSessionData.tutor)
      return () => this.handleTutorFound();
    if (!session.tutor_ready_at && newSessionData.tutor_ready_at)
      return () => this.handleTutorReady();
    return () => {};
  },
  async sync() {
    let session = this.get();
    if (!session || this.isSyncing) return;

    try {
      this.isSyncing = true;
      const response = await WebService.getSession(session.id);
      if (!response.success) {
        this.setSyncSessionInterval(Intervals.disconnectedSyncInterval);
        throw new Error("Syncing request failed");
      }

      session = this.get(); // Refresh session in case it was updated during request
      if (!session) throw new Error("Session has been cleared");
      this.setSyncSessionInterval(Intervals.connectedSyncInterval);
      this.getSyncAction(response.data)();

      if (session.id < response.data.id) SessionMessages.reload();
      this.set(response.data);
    } catch {
      /* Gracefully handle errors */
    } finally {
      this.isSyncing = false;
    }
  },
  async onLikeDetail(name: string, text: string) {
    Logger.log(AppEvents.sessionTutorProfileBioFavorited, {
      card_name: name,
      text
    });
    const tutor = this.get()?.tutor;
    if (!tutor) return { success: false, message: Errors.default };
    const response = await WebService.likeTutorBioDetail(name, tutor.id);
    await this.sync();
    if (!response.success) {
      response.message = Errors.default;
    }
    return response;
  },
  async onUnlikeDetail(name: string, text: string) {
    Logger.log(AppEvents.sessionTutorProfileBioUnfavorited, {
      card_name: name,
      text
    });
    const tutor = this.get()?.tutor;
    if (!tutor) return { success: false, message: Errors.default };
    const response = await WebService.unlikeTutorBioDetail(name, tutor.id);
    await this.sync();
    if (!response.success) {
      response.message = Errors.default;
    }
    return response;
  },
  clear() {
    this.pusherInitializedForId = 0
    store.dispatch(setCurrent(null));
  }
};

export function sessionIsInReview(session: SessionType) {
  return session.claimed_at && !session.started_at;
}

export function sessionRequiresRating(session: SessionType) {
  return (
    (session?.ended_action || session?.ended_by) &&
    session.tutor &&
    session.tutor_ready_at &&
    session.student_needs_to_rate
  );
}

export function sessionIsReady(session: SessionType) {
  return Boolean(session.tutor && session.tutor_ready_at);
}

function getRatingValue(thumbsUp: boolean) {
  return thumbsUp ? 5 : 1;
}

export default Session;

export type SubmitFeedbackPayload = {
  rating: boolean;
  reasons: Array<string>;
  comment: string;
};

export function closeSession() {
  Session.clear();
  SessionMessages.clear();
  Timers.clear(PUSHER_INIT_RETRY_TIMER_LABEL);
  SessionChannel.destroy();
}

export function getLogInfo() {
  const User = require("models/User").default;
  let info: any = {};
  const session = Session.get();
  const user = User.get();
  if (session) {
    info.session_id = session.id;
    info.session_link = `https://go.yup.com/sr/${session.id}`;
    info.session_status = "finding_tutor";
    info.message_count = SessionMessages.messages.length;
    if (session.claimed_at) {
      info.session_claimed_at = session.claimed_at;
      info.session_status = "tutor_found";
    }
    if (session.tutor) {
      info.tutor_id = session.tutor.id;
      info.favorite_tutor = user?.favorite_tutor_ids.some(
        (id: number) => id == session.tutor?.id
      );
      info.tutor_name = session.tutor.profile.polite_name;
    }
    if (session.started_at) {
      info.session_started_at = session.started_at;
      info.session_status = "tutor_ready";
    }
    if (session.tutor_ready_at) {
      info.session_status = "in_progress";
    }
    if (session.ended_action) {
      info.session_ended_action = session.ended_action;
      info.session_status = "completed";
    }
    if (sessionRequiresRating(session)) {
      info.session_needs_rating = true;
    }
  }
  return info;
}

function logRatingSubmitted(
  payload: SubmitFeedbackPayload,
  complaint: string[],
  sessionDuration: Number
) {
  try {
    Logger.log(AppEvents.sessionRatingSubmitted, {
      rating: getRatingValue(payload.rating),
      reasons: complaint.map((c) => c.substring(0, 255)),
      session_length: sessionDuration,
      ...getMessageLogInfo()
    });
  } catch (error) {
    reportError(error);
    Logger.log(DebugLogs.ratingSubmittedLogError, { error: error });
  }
}
