import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivityBulletin as ActivityBulletinType } from "types/ActivityBulletin";

export type StateType = {
  bulletin: ActivityBulletinType | null;
};

export const activityBulletinSlice = createSlice({
  name: "activityBulletin",
  initialState: {
    bulletin: null
  } as StateType,
  reducers: {
    setActivityBulletin: (
      state,
      action: PayloadAction<ActivityBulletinType | null>
    ) => {
      state.bulletin = action.payload;
    }
  }
});

export default activityBulletinSlice.reducer;

export const { setActivityBulletin } = activityBulletinSlice.actions;
