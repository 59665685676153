import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import Sizes from "assets/theme/Sizes";
import Typography from "assets/theme/Typography";

import Image from "components/Image";
import Text from "components/Text";
import { HeartIcon, HeartOutlineIcon } from "components/Icons";

import { getTutorAvatar } from "types/Tutor";
import { sessionIsReady } from "models/Session";
import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Tooltip from "components/Tooltip";

export default function TutorStatus() {
  const session = useSelector((state: StoreState) => state.session.current);
  const user = useSelector((state: StoreState) => state.user.user);

  if (!user || !session || !session.tutor || !sessionIsReady(session))
    return <View/>;

  const profile = getTutorAvatar(session.tutor.id, session.tutor.gender);
  const isFavorite = user.favorite_tutor_ids.includes(session.tutor?.id ?? -1);

  const onFavoriteToggle = (event: any) => {
    event?.stopPropagation();
    Event.dispatch(
      isFavorite ? "session_on_unfavorite_tutor" : "session_on_favorite_tutor"
    );
  };
  const onPress = Event.dispatcher("session_on_show_tutor_profile");

  return (
    <TouchableOpacity
      testID="tutor-profile-open"
      style={styles.status}
      onPress={onPress}
    >
      <Image uri={profile} style={styles.tutorProfile} />
      <Text style={styles.tutorName} textBreakStrategy="simple">
        {session.tutor.profile.polite_name}
      </Text>
      <TouchableOpacity
        testID="favorite-tutor-toggle"
        onPress={onFavoriteToggle}
      >
        {isFavorite ? (
          <Tooltip text="Remove tutor from favorites">
            <HeartIcon height={24} width={24} color={Colors.ui.red} />
          </Tooltip>
        ) : (
            <Tooltip text="Add tutor to favorites">
              <HeartOutlineIcon
                height={24}
                width={24}
                color={Colors.grey.inactive}
              />
            </Tooltip>
          )}
      </TouchableOpacity>
      <View style={styles.onlineStatus}></View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  status: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1
  },
  tutorProfile: {
    height: Sizes.navigationHeight,
    width: Sizes.navigationHeight
  },
  tutorName: {
    flexShrink: 1,
    ...Typography.accent.small,
    lineHeight: undefined,
    ...Spacing.mr2
  },
  onlineStatus: {
    width: Sizes["8px"],
    height: Sizes["8px"],
    backgroundColor: Colors.ui.green,
    borderRadius: Sizes["8px"],
    ...Spacing.mx2
  }
});
