import * as React from "react";
import { KeyboardAvoidingView, StyleSheet, useWindowDimensions, View } from "react-native";

import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Card from "components/Card";
import ErrorBanner from "components/ErrorBanner";
import Page from "components/Page";
import Text from "components/Text";
import { LogoIcon } from "components/Icons";
import Colors from "assets/theme/Colors";

import EnvironmentSwitcher from "components/EnvironmentSwitcher";
import { Environment } from "types/Environment";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";

export type SignInWrapperProps = {
  header: string;
  children: React.ReactNode;
};

export default function SignInWrapper(props: SignInWrapperProps) {
  const error = useSelector((state: StoreState) => state.ui.signInError);
  const environment = useSelector((state: StoreState) => state.ui.environment);
  const { height } = useWindowDimensions();

  const onGoToSite = Event.dispatcher("signin_open_yup");
  const onSelectEnvironment = (env: Environment) =>
    Event.dispatch("signin_set_environment", { env });

  return (
    <Page>
      <KeyboardAvoidingView style={[styles.wrapper, { minHeight: height }]}>
        {onSelectEnvironment && environment ? (
          <EnvironmentSwitcher
            environment={environment}
            onSelect={onSelectEnvironment}
          >
            <View style={styles.logo}>
              <LogoIcon
                width={100}
                height={Sizes["32px"]}
                color={Colors.brand.blue}
              />
            </View>
          </EnvironmentSwitcher>
        ) : (
          <View style={styles.logo}>
            <LogoIcon
              width={100}
              height={Sizes["32px"]}
              color={Colors.brand.blue}
            />
          </View>
        )}
        <Text tag="h1">{props.header}</Text>
        <Card style={styles.card}>
          <ErrorBanner error={error ?? ""} />
          {props.children}
        </Card>
        <Button testID="site-url" onClick={onGoToSite} theme="flat">
          Yup Technologies Inc.
        </Button>
      </KeyboardAvoidingView>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa2
  },
  logo: {
    ...Spacing.mb4
  },
  card: {
    width: 390,
    maxWidth: "100%",
    textAlign: "center",
    ...Spacing.my3,
    ...Spacing.pa3
  }
});
