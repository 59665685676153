import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";

import TutorProfileImage from "components/TutorProfileImage";

export default function FavoriteTutorPrompt() {
  const session = useSelector((state: StoreState) => state.session.current);
  const positiveRating = useSelector((state: StoreState) => state.ui.rating);
  const show = useSelector(
    (state: StoreState) => state.ui.showFavoriteTutorPrompt
  );
  const user = useSelector((state: StoreState) => state.user.user);

  const onFavoriteTutor = Event.dispatcher("postsession_on_favorite_tutor");
  const onUnfavoriteTutor = Event.dispatcher("postsession_on_unfavorite_tutor");
  const onDismiss = Event.dispatcher("postsession_on_dismiss_tutor_prompt");

  if (!session?.tutor || !user) return null;

  const isFavorite = user.favorite_tutor_ids.includes(session.tutor.id);
  const tutorName = session.tutor.profile.polite_name;
  const content = {
    header: `Add ${tutorName} to your favorite tutors?`,
    text: `For your next session, we'll try to match you with one of your
      favorite tutors if they are available`,
    button: "Add to Favorites",
    secondaryButton: "Not Now",
    action: onFavoriteTutor,
    secondaryAction: onDismiss
  };

  if (isFavorite && positiveRating) {
    content.header = `Keep ${tutorName} as one of your Favorite Tutors?`;
    content.text = `${tutorName} is currently one of your favorite tutors.
      Would you like to keep this tutor on your list?`;
    content.button = "Keep as Favorite";
    content.secondaryButton = "Remove from Favorites";
    content.action = onDismiss;
    content.secondaryAction = onUnfavoriteTutor;
  } else if (isFavorite && !positiveRating) {
    content.header = `Remove ${tutorName} from your favorite tutors?`;
    content.text = `${tutorName} is currently one of your favorite tutors.
      Would you like to remove this tutor from your list?`;
    content.button = "Remove from Favorites";
    content.action = onUnfavoriteTutor;
  }

  return (
    <Modal show={show} width={350}>
      <View style={styles.content} testID="favorite-tutor-prompt">
        <TutorProfileImage tutor={session.tutor} />
        <Text tag="h2" style={styles.prompt}>
          {content.header}
        </Text>
        <Text style={styles.text}>{content.text}</Text>
        <Button testID="action" theme="secondary" onClick={content.action}>
          {content.button}
        </Button>
        <Button
          textStyle={styles.dismissButton}
          theme="flat"
          testID="dismiss-favorite-tutor"
          onClick={content.secondaryAction}
        >
          {content.secondaryButton}
        </Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...Spacing.pt2
  },
  prompt: {
    textAlign: "center",
    ...Spacing.mb3,
    ...Spacing.pt3
  },
  text: {
    textAlign: "center",
    ...Spacing.mb2
  },
  dismissButton: {
    color: Colors.brand.blue,
    ...Typography.accent.small
  }
});
