import { StatusBar } from "expo-status-bar";
import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  AppState,
  AppStateStatus,
  NativeEventSubscription
} from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  ActionSheetProvider,
  connectActionSheet
} from "@expo/react-native-action-sheet";
import SplashScreen from "components/SplashScreen";
import { wrapApp } from "helpers/Sentry";

import useCachedResources from "hooks/useCachedResources";
import store from "store";
import { hideAsync } from "expo-splash-screen";
import Screens from "navigation";

import Notifications from "helpers/Notifications";

let listener: NativeEventSubscription;
export function App() {
  const loadingProgress = useCachedResources();
  useEffect(() => {
    hideAsync();
    Notifications.register();
    listener = AppState.addEventListener("change", handleStateChange);
    return () => {
      listener?.remove();
    };
  }, []);

  function handleStateChange(state: AppStateStatus) {
    if (state === "active") {
      Notifications.resetBadgeCount();
    }
  }

  if (loadingProgress < 100) {
    return <SplashScreen progress={loadingProgress} />;
  }
  return (
    <GestureHandlerRootView style={styles.wrapper}>
      <ActionSheetProvider>
        <KeyboardAvoidingView
          style={styles.wrapper}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
          enabled={true}
        >
          <SafeAreaProvider>
            <Provider store={store}>
              <Suspense fallback={<SplashScreen progress={loadingProgress} />}>
                <Screens />
              </Suspense>
              <StatusBar />
            </Provider>
          </SafeAreaProvider>
        </KeyboardAvoidingView>
      </ActionSheetProvider>
    </GestureHandlerRootView>
  );
}

export default wrapApp(connectActionSheet(App));

const styles = StyleSheet.create({
  wrapper: {
    flex: 1
  }
});
