import { SignInUI } from "models/UI";
import User from "models/User";
import { Router } from "navigation/Router";

import SignInBaseController from "./SignInBaseController";

class CleverController extends SignInBaseController {
  init() {
    super.init();
    onLoad();
  }
}

const controller = new CleverController();
export default controller;

async function onLoad() {
  SignInUI.setError("");
  SignInUI.setRegisteringUser(true);
  const route = Router.getCurrent() as any;

  const { success, message } = await User.signInWithClever(route.params?.code);
  if (success) {
    Router.replace("Dashboard");
  } else if (message) {
    SignInUI.setError(message);
  }
  SignInUI.setRegisteringUser(false);
}
