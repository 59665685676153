import * as React from "react";
import { load } from "helpers/Load";

export default function useCachedResources() {
  const [progress, setProgress] = React.useState(0);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await load(setProgress);
      } catch (e) {
        console.warn(e);
      } finally {
        setProgress(100);
      }
    }
    loadResourcesAndDataAsync();
  }, []);

  return progress;
}
