import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as Linking from "expo-linking";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import QRCode from "react-native-qrcode-svg";
import Button from "components/Button";
import Text from "components/Text";
import Modal from "components/Modal";
import Spinner from "components/Progress/Spinner";

import { mobileImageUploader } from "helpers/UploadImage";
import { Event } from "events/Event";

type MobileUploadCodeProps = {
  show: boolean;
  token: string;
};

const UploadUrl = "photos";

export default function MobileUploadCode(props: MobileUploadCodeProps) {
  const [polling, setPolling] = useState(false);
  const [url, setUrl] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const displayToken = `${props.token.slice(0, 4)}-${props.token.slice(
    4,
    7
  )}-${props.token.slice(-4)}`;

  useEffect(() => {
    loadUrl();
  }, []);

  useEffect(() => {
    if (props.show) {
      mobileImageUploader.onImageFound(onUpload);
      mobileImageUploader.onPollStopped(() => setPolling(false));
      startPoll();
    } else {
      stopPoll();
    }
  }, [props.show]);

  async function loadUrl() {
    const url = Linking.parse(await Linking.getInitialURL() ?? "");
    setUrl(`${url.hostname}/${UploadUrl}`)
    setFullUrl(`${url.scheme}://${url.hostname}/${UploadUrl}/${props.token}`)
  }

  function startPoll() {
    setPolling(true);
    mobileImageUploader?.pollForImage();
  }

  function stopPoll() {
    setPolling(false);
    mobileImageUploader?.stopPolling();
  }

  function onUpload(image: string) {
    Event.dispatch("mobile_upload_submitted", { image });
  }
  const onDismiss = Event.dispatcher("mobile_upload_dismissed");

  return (
    <Modal show={props.show} onDismiss={onDismiss}>
      <View style={styles.content} testID="mobile-upload">
        <Text tag="h2" style={styles.prompt}>
          Scan the code
        </Text>
        <View style={styles.code}>
          {fullUrl && <QRCode value={fullUrl} />}
        </View>
        {url && <>
            <Text style={styles.token}>{displayToken}</Text>
            <Text>{`Or go to ${url} and enter the code`}</Text>
          </>
        }
        {polling ? (
          <View style={styles.polling}>
            <Spinner color={Colors.grey.inactive} />
            <Text style={styles.pollingText}>Checking for picture...</Text>
          </View>
        ) : (
          <Button onClick={startPoll}>Yes, I took a picture</Button>
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  prompt: {
    textAlign: "center"
  },
  code: {
    ...Spacing.my3
  },
  token: {
    fontWeight: "bold",
    ...Spacing.mt2
  },
  polling: {
    display: "flex",
    flexDirection: "row",
    ...Spacing.mt4
  },
  pollingText: {
    color: Colors.grey.inactive,
    ...Spacing.ml3
  }
});
