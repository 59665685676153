import WebService from "helpers/WebService";
import { setOffset } from "store/ServerTime";
import store from "store";

export async function syncTime(): Promise<void> {
  const startTime = new Date().getTime() / 1000;

  WebService.setRetries(3);
  const response = await WebService.syncTime();

  const endTime = new Date().getTime() / 1000;
  const diff = endTime - startTime;

  if (diff < 1) {
    const serverSyncOffset = response.data.time_stamp - endTime - diff / 2;
    store.dispatch(setOffset(serverSyncOffset));
  }
}

export function serverTime(): number {
  const serverSyncOffset = store.getState().serverTime.offset;
  const clientTimestamp = new Date().getTime() / 1000;
  return serverSyncOffset
    ? clientTimestamp + Number(serverSyncOffset)
    : clientTimestamp;
}
