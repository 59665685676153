import React, { useState } from "react";
import { format } from "date-fns";
import {
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
  LayoutChangeEvent
} from "react-native";

import {
  MessageType,
  MessageSender,
  MessageContentType,
  MessageReaction as MessageReactionType,
  successfullySent
} from "./types/Message";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Hoverable from "components/Hoverable";
import Image from "components/Image";
import Text from "components/Text";

import MessageReaction from "./MessageReaction";
import MessageInputButton from "./MessageInputButton";
import { InputButtonType } from "./types/MessageInputButton";
import StaticField from "components/Equations/StaticField";
import { Event } from "events/Event";

const MAX_IMAGE_SIZE = 300;
const MAX_BADGE_SIZE = 100;
const IMAGE_PADDING = Sizes["16px"];

type MessageComponentProps = {
  sender: MessageSender;
  message: MessageType;
  showTimestamp: boolean;
  canReact: boolean;
  isPlaying: boolean;
  onStartTextToSpeech: () => void;
  onStopTextToSpeech: () => void;
};

type MessageContentProps = MessageComponentProps & {
  showOptions: boolean;
  maxWidth?: number;
  onLongPress: () => void;
};

function TextToSpeechOption(props: {
  isPlaying: boolean;
  onStart: () => void;
  onStop: () => void;
}) {
  if (props.isPlaying) {
    return (
      <MessageInputButton
        type={InputButtonType.StopTextToSpeech}
        onClick={props.onStop}
      />
    );
  }
  return (
    <MessageInputButton
      type={InputButtonType.TextToSpeech}
      onClick={props.onStart}
    />
  );
}

function ImageContent(props: MessageContentProps) {
  const dimensions = useWindowDimensions();

  if (
    props.message.additional_attributes?.is_badge ||
    props.message.text.includes("/math-crunch-assets/achievements/") // Achievement data not sent on session transcripts
  ) {
    return <BadgeContent {...props} />;
  }

  const onImageClicked = Event.dispatcher("chatroom_image_clicked", {
    image: props.message.text
  });
  return (
    <TouchableOpacity
      testID="image"
      onPress={onImageClicked}
      onLongPress={props.onLongPress}
    >
      <View style={styles.imageMessage}>
        <Image
          uri={props.message.text}
          maintainAspectRatio={true}
          maxSize={Math.min(MAX_IMAGE_SIZE, dimensions.width - IMAGE_PADDING)}
        />
      </View>
    </TouchableOpacity>
  );
}

function BadgeContent(props: MessageContentProps) {
  const dimensions = useWindowDimensions();
  // react-native-svg currently crops svgs with height/width set in the <svg> tag.
  // Use the .png files for now for proper resizing on mobile devices
  const uri = props.message.text.replace("_GE.svg", ".png");

  return (
    <Image
      uri={uri}
      maintainAspectRatio={true}
      maxSize={Math.min(MAX_BADGE_SIZE, dimensions.width - IMAGE_PADDING)}
    />
  );
}

function SystemContent(props: MessageContentProps) {
  return (
    <View testID="system-message">
      <View style={[styles.systemMessageContent, { maxWidth: props.maxWidth }]}>
        <Text style={styles.systemMessageText}>{props.message.text}</Text>
      </View>
    </View>
  );
}

function TextContent(props: MessageContentProps) {
  const [hovering, setHovering] = useState(false);
  const isLatex =
    props.message.additional_attributes?.is_latex ||
    /<latex>/g.test(props.message.text);
  const isSent = props.message.sent_from === props.sender;

  const contentStyles: any = [styles.content, { maxWidth: props.maxWidth }];
  const textStyles = [];
  if (isSent) {
    contentStyles.push(styles.sentMessageContent);
    textStyles.push(styles.sentMessageText);
  } else {
    contentStyles.push(styles.receivedMessageContent);
  }
  if (props.isPlaying) {
    textStyles.push(styles.textPlaying);
  }

  const showOptions = Platform.OS === "web" ? hovering : props.showOptions;

  if (isLatex) {
    const formula = props.message.text
      .replace(/<latex>/g, "$$$$")
      .replace(/<\/latex>/g, "$$$$");
    return (
      <TouchableOpacity
        testID="equation"
        style={contentStyles}
        onLongPress={props.onLongPress}
      >
        <StaticField
          color={isSent ? Colors.ui.white : Colors.grey.iron}
          formula={formula}
        />
      </TouchableOpacity>
    );
  }
  return (
    <Hoverable
      onHoverStart={() => setHovering(true)}
      onHoverLeave={() => setHovering(false)}
    >
      <View
        style={[
          styles.textWrapper,
          { flexDirection: isSent ? "row" : "row-reverse" }
        ]}
      >
        <View style={styles.speechWrapper}>
          {(showOptions || props.isPlaying) && (
            <TextToSpeechOption
              isPlaying={props.isPlaying}
              onStart={props.onStartTextToSpeech}
              onStop={props.onStopTextToSpeech}
            />
          )}
        </View>
        <TouchableOpacity
          testID="message"
          style={contentStyles}
          onLongPress={props.onLongPress}
        >
          <Text style={textStyles}>{props.message.text}</Text>
        </TouchableOpacity>
      </View>
    </Hoverable>
  );
}

function MessageContent(props: MessageContentProps) {
  const isImage = props.message.content_type === MessageContentType.image;
  const isSystemMessage = props.message.sent_from === MessageSender.systemInfo;
  if (isImage) {
    return <ImageContent {...props} />;
  } else if (isSystemMessage) {
    return <SystemContent {...props} />;
  }
  return <TextContent {...props} />;
}

export default function Message(props: MessageComponentProps) {
  const [showOptions, setShowOptions] = useState(false);
  const [maxMessageWidth, setMaxMessageWidth] = useState<number | undefined>(
    undefined
  );

  const isSystemMessage = props.message.sent_from === MessageSender.systemInfo;
  const isFromUser = props.message.sent_from === props.sender;
  const supportsReaction = props.canReact && [MessageSender.student, MessageSender.tutor].includes(props.message.sent_from);

  const messageStyles: any = [styles.message];
  const timestampStyles: any = [styles.timestamp];
  if (isSystemMessage) {
    messageStyles.push(styles.systemMessage);
    timestampStyles.push(styles.systemTimestamp);
  } else if (props.message.sent_from === props.sender) {
    messageStyles.push(styles.sentMessage);
    timestampStyles.push(styles.sentTimestamp);
  } else {
    messageStyles.push(styles.receivedMessage);
  }

  if (isFromUser && !successfullySent(props.message)) {
    messageStyles.push(styles.pendingMessage);
  }

  const timestamp = format(
    new Date(props.message.created_at ?? props.message.sent_at * 1000),
    "hh:mm:ssa"
  ).toLowerCase();

  function onLayout(event: LayoutChangeEvent) {
    setMaxMessageWidth(
      Math.min(500, event.nativeEvent.layout.width - Sizes["48px"])
    );
  }

  function onShowOptions() {
    setShowOptions(true);
  }

  function onDismissOptions() {
    setShowOptions(false);
  }

  function onStopTextToSpeech() {
    setShowOptions(false);
    props.onStopTextToSpeech();
  }
  const onReact = (reaction: MessageReactionType) => {
    Event.dispatch("chatroom_reaction_sent", {
      reaction,
      key: props.message.key
    });
  };

  return (
    <View style={messageStyles} onLayout={onLayout}>
      <View>
        <MessageContent
          {...props}
          showOptions={showOptions}
          maxWidth={maxMessageWidth}
          onLongPress={onShowOptions}
          onStopTextToSpeech={onStopTextToSpeech}
        />
        {!props.isPlaying && (
          <MessageReaction
            show={showOptions && supportsReaction}
            message={props.message}
            sent={props.message.sent_from === props.sender}
            onSelect={onReact}
            onDismiss={onDismissOptions}
          />
        )}
        {props.showTimestamp && (
          <Text style={timestampStyles}>{timestamp}</Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  message: {
    display: "flex",
    flexDirection: "row",
    ...Spacing.mt2
  },
  content: {
    flexShrink: 1,
    borderRadius: Sizes.borderRadius,
    textAlign: "left",
    zIndex: -1,
    ...Spacing.py2,
    ...Spacing.px3
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  speechWrapper: {
    width: Sizes["32px"]
  },

  textPlaying: {
    textDecorationStyle: "dotted",
    textDecorationLine: "underline"
  },

  systemMessage: {
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    ...Spacing.mt4,
    ...Spacing.mb2
  },
  systemMessageContent: {
    backgroundColor: Colors.ui.white,
    marginTop: -12,
    ...Spacing.px3
  },
  systemMessageText: {
    color: Colors.grey.inactive,
    textAlign: "center"
  },
  systemTimestamp: {
    textAlign: "center"
  },

  sentMessage: {
    justifyContent: "flex-end",
    textAlign: "right"
  },
  sentTimestamp: {
    textAlign: "right"
  },
  sentMessageContent: {
    alignSelf: "flex-end",
    backgroundColor: Colors.brand.blue
  },
  sentMessageText: {
    color: Colors.ui.white
  },
  pendingMessage: {
    opacity: 0.5
  },

  receivedMessage: {
    justifyContent: "flex-start",
    textAlign: "left"
  },
  receivedMessageContent: {
    alignSelf: "flex-start",
    backgroundColor: Colors.grey.concrete,
    width: "75%"
  },

  imageMessage: {
    backgroundColor: "transparent",
    borderRadius: Sizes.borderRadius,
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    ...Spacing.my3
  },
  timestamp: {
    color: Colors.grey.inactive,
    fontSize: 12,
    ...Spacing.px1,
    ...Spacing.mb3
  }
});
