import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import ColorPalette from "./ColorPalette";
import { ToolbarButton, ToolbarMenu } from "./ToolbarButton";
import { WhiteboardAction } from "./types/WhiteboardAction";
import { Color } from "./types/Color";
import Spacing from "assets/theme/Spacing";
import Colors from "assets/theme/Colors";

import { UndoIcon, RedoIcon, PencilIcon } from "components/Icons";

type ToolbarProps = {
  canUndo: boolean;
  canRedo: boolean;
  onAction: (action: WhiteboardAction, args?: object) => void;
};

function capitalize(word: string) {
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}

const iconProps = {
  width: 16,
  height: 16,
  color: Colors.grey.slate
};

export default function Toolbar(props: ToolbarProps) {
  const [showPencilMenu, setShowPencilMenu] = useState(false);
  const [strokeColor, setStrokeColor] = useState(Color.blue);

  useEffect(() => {
    (window as any).addEventListener("click", closeMenus);
    return () => {
      (window as any).removeEventListener("click", closeMenus);
    };
  }, []);

  const getToolInfo = (tool: WhiteboardAction) => {
    const info = {
      disabled: false,
      title: capitalize(tool),
      icon: <></>,
      onClick: () => props.onAction(tool)
    };

    switch (tool) {
      case WhiteboardAction.undo:
        return {
          ...info,
          disabled: !props.canUndo,
          icon: <UndoIcon {...iconProps} />
        };
      case WhiteboardAction.redo:
        return {
          ...info,
          disabled: !props.canRedo,
          icon: <RedoIcon {...iconProps} />
        };
      case WhiteboardAction.pencil:
        return {
          ...info,
          icon: <PencilIcon {...iconProps} color={strokeColor} />
        };
      default:
        return info;
    }
  };

  function closeMenus() {
    setShowPencilMenu(false);
  }

  function onSetStrokeColor(color: Color) {
    props.onAction(WhiteboardAction.strokeColor, { color });
    setStrokeColor(color);
    closeMenus();
  }

  return (
    <View style={styles.toolbar}>
      <ToolbarMenu
        {...getToolInfo(WhiteboardAction.pencil)}
        show={showPencilMenu}
        onClick={() => setShowPencilMenu(true)}
      >
        <ColorPalette
          currentColor={strokeColor}
          onSetColor={onSetStrokeColor}
        />
      </ToolbarMenu>
      <ToolbarButton {...getToolInfo(WhiteboardAction.undo)} />
      <ToolbarButton {...getToolInfo(WhiteboardAction.redo)} />
    </View>
  );
}

const styles = StyleSheet.create({
  toolbar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    ...Spacing.px2
  }
});
