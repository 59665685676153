import React, { useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { Achievement as AchievementType } from "models/Settings";

import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Text from "components/Text";
import Colors from "assets/theme/Colors";

import ProfileCard from "./ProfileCard";

export type AchievementsProps = {
  count: number;
  achievement: AchievementType;
};

export default function Achievements(props: AchievementsProps) {
  const [showDescription, setShowDescription] = useState(false);

  const { portal_url_gray, portal_url, name, earn_by_student_copy } =
    props.achievement;
  const uri = props.count > 0 ? portal_url : portal_url_gray;

  function toggleDescription() {
    setShowDescription(!showDescription);
  }

  return (
    <TouchableOpacity
      testID="achievement"
      style={styles.wrapper}
      onPress={toggleDescription}
    >
      <ProfileCard imageUrl={uri} title={name}>
        {props.count > 0 && (
          <View style={styles.count}>
            <Text style={styles.countText}>{props.count}</Text>
          </View>
        )}
      </ProfileCard>
      {showDescription && (
        <Text testID="achievement-description" style={styles.description}>
          {earn_by_student_copy}
        </Text>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    width: 160,
    maxWidth: 160,
    ...Spacing.pa1
  },
  description: {
    textAlign: "center",
    ...Spacing.mt1,
    ...Spacing.mb3,
    ...Spacing.px2
  },
  count: {
    width: Sizes["24px"],
    height: Sizes["24px"],
    borderRadius: Sizes["24px"],
    backgroundColor: Colors.brand.yellow,
    top: Sizes["4px"],
    right: Sizes["4px"],
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  countText: {
    ...Typography.accent.large,
    lineHeight: 24
  }
});
