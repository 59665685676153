import React, { useEffect } from "react";
import { Event } from "events/Event";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import ErrorBanner from "components/ErrorBanner";
import EquationModal from "components/Equations/EquationModal";
import { LogoIcon } from "components/Icons";
import Page from "components/Page";
import MobileUploadCode from "components/MobileUploadCode";
import CaptureRequestOverlay from "components/CaptureRequest/CaptureRequestOverlay";

import ActivityBulletin from "../components/ActivityBulletin";
import DashboardActions from "../components/DashboardActions";
import DashboardActionWrapper from "../components/DashboardActionsWrapper";
import ExtensionError from "../components/ExtensionError";
import ExtensionInstallPrompt from "../components/ExtensionInstallPrompt";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions";
import UsePictureOptions from "../components/UsePictureOptions";

export default function Dashboard() {
  const { height } = useWindowDimensions();

  const user = useSelector((state: StoreState) => state.user.user);
  const session = useSelector((state: StoreState) => state.session.current);
  const error = useSelector((state: StoreState) => state.ui.dashboardError);
  const showCapturePrompt = useSelector(
    (state: StoreState) => state.ui.showCapturePrompt
  );
  const showExtensionError = useSelector(
    (state: StoreState) => state.ui.showExtensionError
  );
  const extensionIsInstalled = useSelector(
    (state: StoreState) => state.ui.extensionIsInstalled
  );
  const showMobileUpload = useSelector(
    (state: StoreState) => state.ui.showDashboardMobileUpload
  );
  const showEquationEditor = useSelector(
    (state: StoreState) => state.ui.showDashboardEquationEditor
  );
  const equation = useSelector((state: StoreState) => state.ui.equation);

  useEffect(() => {
    if (session) Event.dispatch("dashboard_session_in_progress", { session });
  }, [session?.id]);

  return (
    <View style={[{ maxHeight: height }, styles.container]} testID="dashboard">
      <Page>
        {showCapturePrompt && <CaptureRequestOverlay />}
        <MobileUploadCode show={showMobileUpload} token={user?.token ?? ""} />
        <UsePictureOptions />
        <EquationModal show={showEquationEditor} initialEquation={equation} />
        <FrequentlyAskedQuestions />
        <View style={[styles.wrapper, { minHeight: height }]}>
          <LogoIcon
            width={100}
            height={Sizes["32px"]}
            color={Colors.brand.blue}
          />
          {!extensionIsInstalled && <ExtensionInstallPrompt />}
          {showExtensionError && <ExtensionError />}
          <ErrorBanner error={error} />
          <DashboardActionWrapper>
            <DashboardActions />
          </DashboardActionWrapper>
        </View>
      </Page>
      <ActivityBulletin />
    </View>
  );
}

export const MAIN_BUTTON_MIN_WIDTH = 224;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden"
  },
  wrapper: {
    ...Spacing.py5,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }
});
