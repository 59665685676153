export enum MessageSender {
  tutor = "tutor",
  student = "student",
  systemAlert = "system alert",
  systemInfo = "system info",
  bot = "bot"
}

export enum MessageReaction {
  gotIt = "got_it",
  confused = "confused"
}

export enum MessageContentType {
  text = "text",
  image = "image"
}

export type MessageAdditionalAttributes = {
  is_latex?: boolean;
  is_badge?: boolean;
  badge_type?: string;
};

export type MessageReactionAttributes = {
  emoji: string;
  name: MessageReaction;
  label: string;
};

type ReactionMapType = {
  [key in MessageReaction]: {
    emoji: string;
    label: string;
  };
};

export const ReactionMap: ReactionMapType = {
  [MessageReaction.confused]: {
    emoji: "🤔",
    label: "I'm confused"
  },
  [MessageReaction.gotIt]: {
    emoji: "👌",
    label: "I understand"
  }
};

export type MessageType = {
  id?: number;
  key: string;
  text: string;
  content_type: MessageContentType;
  sent_from: MessageSender;
  sent_to?: MessageSender;
  sent_at: number;
  sender_id?: number;
  chat_id?: number;
  created_at?: number;
  additional_attributes?: MessageAdditionalAttributes;
  reaction?: MessageReactionAttributes;
  image?: string;
  source?: string;
};

export function dedupeMessages(
  messages: Array<MessageType>
): Array<MessageType> {
  const messageKeys = new Set();
  return messages.filter(Boolean).filter((message: MessageType) => {
    if (!messageKeys.has(message.key)) {
      messageKeys.add(message.key);
      return true;
    }
    return false;
  });
}

/**
 * Performs sorting and deduping, and transforms MessageType to Message objects.
 * Message objects are used by the ChatRoom component.
 */
export function transformMessages(
  messages: Array<MessageType>
): Array<MessageType> {
  messages = dedupeMessages(messages);
  return messages.sort((a: any, b: any) => {
    a = Number(a.created_at ?? a.sent_at);
    b = Number(b.created_at ?? b.sent_at);
    return a - b;
  });
}

/**
 * Generates a message object as defined by the app server.
 * Most of these values are no longer in use so the return value isn't typed.
 */
export function createMessage(props: {
  message: string;
  isImage: boolean;
  additionalAttributes?: MessageAdditionalAttributes;
  sessionId?: number;
  senderId?: number;
  senderToken?: string;
  sender?: MessageSender;
  sentTo?: MessageSender;
}) {
  const { serverTime } = require("models/ServerTime");
  const sentAt = new Date().getTime() / 1000;
  const delta = Math.abs(serverTime() - sentAt);
  const isLatex = /<\/?latex>/i.test(props.message);
  return {
    additional_attributes: {
      exclude_time: false,
      style_class: "",
      client_timestamp: String(sentAt),
      timestamp_delta: delta,
      was_seen: true,
      was_saved: true,
      was_processed: true,
      resend_attempts: 0,
      resave_attempts: 0,
      is_latex: isLatex,
      ...(props.additionalAttributes ?? {})
    },
    reaction: undefined,
    content_type: props.isImage
      ? MessageContentType.image
      : MessageContentType.text,
    sent_at: sentAt,
    sender_id: props.senderId ?? 0,
    sent_to: props.sentTo ?? MessageSender.tutor,
    sent_from: props.sender ?? MessageSender.student,
    text: props.message,
    sender_token: props.senderToken ?? "",
    chat_id: props.sessionId ?? 0,
    math_crunch_session_id: props.sessionId ?? 0,
    chat_type: "MathCrunchSession",
    key: `${Math.random()}.${sentAt}`,
    source: ""
  };
}

export function successfullySent(message: MessageType) {
  return Boolean(message.id);
}

export function messageIsAchievement(message: MessageType) {
  return Boolean(message.additional_attributes?.badge_type);
}