import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { View, StyleSheet } from "react-native";
import {
  BotScriptActionType,
  BotScriptAction,
  BotScriptCtaType
} from "helpers/BotScript";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import { ActionButton } from "./ActionButton";
import { ActionCamera } from "./ActionCamera";
import { ActionText } from "./ActionText";

export type PreSessionActionProps = {
  theme: "primary" | "secondary-outlined";
  action: BotScriptAction;
};

function PreSessionAction(props: { action: BotScriptAction }) {
  const { cta_type, action_type } = props.action;
  const theme =
    cta_type === BotScriptCtaType.primary ? "primary" : "secondary-outlined";

  switch (action_type) {
    case BotScriptActionType.button:
      return <ActionButton theme={theme} action={props.action} />;
    case BotScriptActionType.camera:
      return <ActionCamera theme={theme} action={props.action} />;
    case BotScriptActionType.textArea:
      return <ActionText theme={theme} action={props.action} />;
  }
}

export default function PreSessionQuestions() {
  const currentBotScriptNode = useSelector(
    (state: StoreState) => state.ui.botScriptNode
  );
  const onCancel = Event.dispatcher("session_show_end_session");
  const onFinish = Event.dispatcher("session_presession_finish");

  return (
    <Modal show={Boolean(currentBotScriptNode)}>
      <View style={styles.wrapper} testID="presession">
        <Button
          style={styles.cancelSession}
          testID="cancel-session"
          theme="flat"
          onClick={onCancel}
        >
          <Text style={styles.cancel}>Cancel Session</Text>
        </Button>
        <Text tag="h3" style={styles.question}>
          {currentBotScriptNode?.messages[0][0]}
        </Text>
        {currentBotScriptNode?.actions ? (
          currentBotScriptNode.actions.map((action: BotScriptAction) => (
            <PreSessionAction key={action.token} action={action} />
          ))
        ) : (
            <Button onClick={onFinish} testID="presession-submit">
              Let's go!
            </Button>
          )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column"
  },
  question: {
    ...Spacing.mb2
  },
  cancel: {
    fontSize: 14,
    color: Colors.ui.blue
  },
  cancelSession: {
    ...Spacing.mt3,
    ...Spacing.mb2
  }
});
