import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import ProblemCapture from "../components/ProblemCapture";

export default function AscendMath() {
  const problem = useSelector(
    (state: StoreState) => state.partnerships.ascendMathProblem
  );

  return (
    <ProblemCapture partnerName="Ascend Math">
      {problem ? <img src={problem} /> : null}
    </ProblemCapture>
  );
}
