import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import Storage from "helpers/Storage";

const STORE_KEY = "server-sync-offset";
export const storage = new Storage(STORE_KEY);

export type StateType = {
  offset: number;
};

export const serverTimeSlice = createSlice({
  name: "serverTime",
  initialState: {
    offset: 0
  } as StateType,
  reducers: {
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    }
  }
});

export default serverTimeSlice.reducer;

export const { setOffset } = serverTimeSlice.actions;

export function subscriber(state: StateType) {
  storage.setSync({ offset: state?.offset || 0 });
}

export async function load(store: ToolkitStore) {
  const offset = await storage.get();
  store.dispatch(serverTimeSlice.actions.setOffset(offset?.offset || 0));
}
