import React from "react";
import { StyleSheet, View } from "react-native";
import Button from "components/Button";
import Text from "components/Text";
import Spacing from "assets/theme/Spacing";
import Spinner from "components/Progress/Spinner";
import Colors from "assets/theme/Colors";
import { Event } from "events/Event";

export function LoadingView(props: {
  loading: boolean;
  error: string;
  children: React.ReactNode;
  showContactOption?: boolean;
}) {
  const onOpenContact = Event.dispatcher("partnerships_open_contact");
  const onReload = Event.dispatcher("partnerships_on_reload");

  if (props.loading) {
    return (
      <View style={styles.wrapper}>
        <Text tag="h3" style={styles.text}>
          Loading...
        </Text>
        <Spinner color={Colors.grey.slate} size={64} width={8} />
      </View>
    );
  } else if (props.error) {
    return (
      <View style={styles.wrapper}>
        <Text tag="h4" style={styles.text}>
          {props.error}
        </Text>
        <Text>
          Please
          <Button theme="flat" testID="retry" onClick={onReload}>
            try again
          </Button>
          {props.showContactOption && (
            <>
              or
              <Button theme="flat" testID="contact" onClick={onOpenContact}>
                contact us
              </Button>
            </>
          )}
        </Text>
      </View>
    );
  }
  return <View style={styles.wrapper}>{props.children}</View>;
}

const styles = StyleSheet.create({
  wrapper: {
    ...Spacing.pa2
  },
  text: {
    ...Spacing.mb3
  }
});
