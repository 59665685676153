import React from "react";
import { StyleSheet } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import SignInWrapper from "../components/SignInWrapper";
import Spinner from "components/Progress/Spinner";
import Text from "components/Text";

export default function Clever() {
  return (
    <SignInWrapper header="">
      <Text tag="h2" style={styles.header}>
        Connecting to Clever...
      </Text>
      <Spinner color={Colors.grey.slate} size={64} width={8} />
    </SignInWrapper>
  );
}

const styles = StyleSheet.create({
  header: {
    ...Spacing.mb4
  }
});
