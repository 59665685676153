import React from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { EndedActions } from "models/Session";
import { Event } from "events/Event";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";

export default function SessionEnded() {
  const session = useSelector((state: StoreState) => state.session.current);
  const show = (session?.ended_action ?? session?.ended_by) === EndedActions.TutorEnded;
  const onDismiss = Event.dispatcher("session_dismissed");

  return (
    <Modal show={show}>
      <Text tag="h2" style={styles.prompt}>
        Session Ended
      </Text>
      <Text style={styles.text}>
        It seems you stopped sending messages, or got disconnected. Still need
        help? You can start a new session at any time.
      </Text>
      <Button testID="session-end-confirm" onClick={onDismiss}>
        Okay
      </Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3,
    ...Spacing.pt3
  },
  text: {
    textAlign: "center"
  }
});
