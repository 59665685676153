import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SessionType } from "models/Session";
import Storage from "helpers/Storage";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

const STORE_KEY = "yup-session";
export const storage = new Storage(STORE_KEY, clearSession);
function clearSession(): void {
  const { store } = require("./index");
  store.dispatch(sessionSlice.actions.setCurrent(null));
}

export type StateType = {
  current: SessionType | null;
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    current: null
  } as StateType,
  reducers: {
    setCurrent: (state, action: PayloadAction<SessionType | null>) => {
      state.current = action.payload;
    }
  }
});

export default sessionSlice.reducer;

export const { setCurrent } = sessionSlice.actions;

export function subscriber(state: StateType) {
  if (state.current) {
    storage.setSync(state.current);
  } else {
    storage.remove();
  }
}

export async function load(store: ToolkitStore) {
  const session = await storage.get();
  if (session) {
    store.dispatch(sessionSlice.actions.setCurrent(session));
  }
}
