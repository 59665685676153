const SpeechRecognition =
  (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

export class SpeechToText {
  speechRecognition: any;

  constructor() {
    this.speechRecognition = new SpeechRecognition();
    this.speechRecognition.continuous = true;
  }

  onResult(callback: (result: string) => void) {
    this.speechRecognition.onresult = (event: any) => {
      let result = event.results[event.resultIndex][0].transcript;
      result = result[0].toUpperCase() + result.slice(1);
      callback(result);
    };
  }

  onEnd(callback: () => void) {
    this.speechRecognition.onspeechend = () => {
      callback();
    };
    this.speechRecognition.onerror = () => {
      callback();
    };
  }

  start() {
    this.speechRecognition.start();
  }

  stop() {
    this.speechRecognition.stop();
  }
}
