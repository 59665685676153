import { Timers } from "@yups/utils";
import {
  setConnectionState,
  WhiteboardConnectionState
} from "store/Whiteboard";
import store from "store";
import Config from "helpers/Config";

import Session from "models/Session";
import {
  ZwibblerClass,
  ZwibblerContext
} from "components/Whiteboard/WhiteboardService/zwibbler2";
import { WhiteboardAction } from "components/Whiteboard/types/WhiteboardAction";
import { commands } from "components/Whiteboard/commands/commands";

import Logger, { AppEvents } from "helpers/Logger";
import { Color } from "components/Whiteboard/types/Color";

declare let Zwibbler: ZwibblerClass;

const THROTTLE_RESIZE_LABEL = "whiteboard-resize-label";
const DEFAULT_SETTINGS = {
  strokeStyle: Color.blue,
  lineWidth: 3,
  opacity: 1
};

class WhiteboardClass {
  context: ZwibblerContext | null = null;
  drawCallback = () => {};
  changeCallback = () => {};
  attachedCallback = () => {};

  attachZwibbler(element: HTMLElement): void {
    const scope = Zwibbler.attach(element, {
      collaborationServer: Config.zwibblerServerUrl
    });
    this.context = scope.ctx;
    this.attachedCallback();
  }

  detachZwibbler() {
    this.context?.destroy();
  }

  init() {
    if (!this.context) return;

    this.context.on("connected", () => this.onConnected());
    this.context.on("connect-error", () => this.onConnectError());
    this.context.on("local-changes", () => this.handleDraw());
    this.context.on("resize", () => this.onResize());
    this.context.on("nodes-added", () => this.onNodeAdded());
    this.context.on("document-changed", () => this.handleDocumentChange());
    this.setDefault();
  }
  deinit() {
    Timers.clear(THROTTLE_RESIZE_LABEL);
    this.context?.leaveSharedSession();
    this.context?.destroy();
    store.dispatch(setConnectionState(WhiteboardConnectionState.disconnected));
  }

  setDefault() {
    this.context?.useBrushTool(DEFAULT_SETTINGS);
  }

  onDraw(callback: () => void) {
    this.drawCallback = callback;
  }

  onChange(callback: () => void) {
    this.changeCallback = callback;
  }

  onAttached(callback: () => void) {
    this.attachedCallback = callback;
  }

  onConnected() {
    this.setDefault();
    store.dispatch(setConnectionState(WhiteboardConnectionState.connected));
    this.context?.setCursor("initial");
    Logger.log(AppEvents.sessionWhiteboardConnected);
  }
  onConnectError() {
    store.dispatch(setConnectionState(WhiteboardConnectionState.reconnecting));
    this.context?.setCursor("not-allowed");
    Logger.log(AppEvents.sessionWhiteboardConnectionFailed);
  }
  joinSession() {
    const session = Session.get();
    if (!session || !this.context) return;
    this.context.joinSharedSession(session.channel_name);
  }

  execute(command: WhiteboardAction, args?: Array<any>) {
    if (this.context) {
      commands[command].execute(this.context, ...(args || []));
    }
  }

  handleDraw() {
    this.drawCallback();
  }
  handleDocumentChange() {
    this.changeCallback();
  }
  onResize() {
    // Throttle to avoid excessive CPU
    Timers.setTimeout({
      label: THROTTLE_RESIZE_LABEL,
      callback: () => {
        if (!this.context) return;
        const size = this.context.getDocumentSize();
        const element = this.context.getElement() as any;
        const ratio = size.width / element.clientWidth;
        size.height = element.clientHeight * ratio; // vertical scroll only
        this.context.setViewRectangle(size);
      },
      delay: 500
    });
  }

  onNodeAdded() {
    const tool = this.context?.getCurrentTool();
    if (tool === "pick") return;
    Logger.log(AppEvents.sessionWhiteboardUsed, {
      tool_used: tool,
      page_number: this.context?.getCurrentPage() || 0
    });
  }
}

export const Whiteboard = new WhiteboardClass();
