import { EnvironmentHelper, Timers } from "@yups/utils";
import { StandardEnvironment } from "@yups/utils/build/EnvironmentHelper";
import WebService from "helpers/WebService";

const FEATURE_DECISION_POLL_INTERVAL = 300000 // 5 minutes

export async function loadFeatures() {
  await FeatureDecision.syncFeatures();
  FeatureDecision.startPoll();
}

export default class FeatureDecision {
  static features: { [key: string]: boolean } = {};

  static lastUpdatedAt?: string; //iso8601 string

  static startPoll() {
    Timers.setRecursiveTimeout({
      label: "feature_decision_poll",
      callback: async () => this.syncFeatures(),
      delay: FEATURE_DECISION_POLL_INTERVAL
    });
  }

  static async syncFeatures() {
    const result = await WebService.getFeatures();
    if (result.success) {
      const lastUpdatedAt = result.data.last_updated_at;
      this.features = result.data.feature_toggles;

      if (!this.lastUpdatedAt) this.lastUpdatedAt = lastUpdatedAt;

      if (this.needsRefresh(lastUpdatedAt)) {
        // TODO: alert user to refresh with msg "Some updates require a refresh. Please refresh the window!";
      }
    } else {
      console.error(`GET feature decision failed`, result);
    }
  }

  static needsRefresh(lastUpdatedAt: string) {
    return this.lastUpdatedAt !== lastUpdatedAt;
  }

  static enableYupAPI(apiName: string) {
    let env = EnvironmentHelper.env;
    if (
      !env?.isProduction() &&
      !env?.isDevelopment() &&
      env?.name !== StandardEnvironment.qa3
    ) {
      console.error(
        "Invalid Env: Only development, qa3, and production are supported right now"
      );

      return false;
    }
    return !this.features[`disableYupAPI_${apiName}`];
  }

  static get toggleStatuses() {
    let features: string[] = Object.keys(this.features).map(
      (k) => `${k}:${this.features[k]}`
    );

    return features;
  }
}
