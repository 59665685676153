import React from "react";
import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Spacing from "assets/theme/Spacing";
import Text from "components/Text";

import FeedbackWrapper from "../components/FeedbackWrapper";
import SessionRatingTutor from "../components/SessionRatingTutor";
import SessionRatingOption from "../components/SessionRatingOption";

export default function SessionRating() {
  const session = useSelector((state: StoreState) => state.session.current);

  if (!session?.tutor) return null;

  return (
    <FeedbackWrapper>
      <SessionRatingTutor />
      <Text tag="h2" style={styles.prompt}>
        How was your session with {session.tutor.profile.polite_name}?
      </Text>
      <View style={styles.ratingOptions} testID="session-rating">
        <SessionRatingOption isPositive={true} />
        <SessionRatingOption />
      </View>
    </FeedbackWrapper>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.my3
  },
  ratingOptions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%"
  }
});
