import * as React from "react";
import { AllStacks } from "types";
import { Router } from "navigation/Router";
import { TouchableOpacity, StyleSheet } from "react-native";
import Text from "components/Text";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";
import Lottie from "components/Lottie";

type ButtonProps = {
  children: React.ReactNode | string;
  theme?: "primary" | "secondary" | "secondary-outlined" | "flat";
  disabled?: boolean;
  processing?: boolean;
  style?: object;
  textStyle?: object;
  round?: boolean;
  testID?: string;
  onClick?: () => void;
  to?: keyof AllStacks;
  replace?: boolean;
};

function ButtonContent(props: ButtonProps) {
  const theme = props.theme ?? "primary";
  if (props.processing) {
    return (
      <Lottie
        animation={require("assets/animations/pending.json")}
        height={64}
        width={64}
      />
    );
  } else if (typeof props.children === "string") {
    return (
      <Text
        style={[
          (textStyles as any)[theme],
          props.disabled ? textStyles.disabled : {},
          props.textStyle
        ]}
      >
        {props.children}
      </Text>
    );
  }
  return <>{props.children}</>;
}

export default function Button(props: ButtonProps) {
  const theme = props.theme ?? "primary";

  const buttonStyles = [(styles as any)[theme]];
  if (props.disabled || props.processing) {
    buttonStyles.push((disabledStyles as any)[theme]);
  }
  if (props.style) {
    buttonStyles.push(props.style);
  }
  if (props.round) {
    buttonStyles.push(styles.round);
  }

  function onClick() {
    props.onClick?.();
    if (!props.to) {
    } else if (props.replace) Router.replace(props.to);
    else Router.navigate(props.to);
  }

  return (
    <TouchableOpacity
      style={buttonStyles}
      disabled={props.disabled}
      testID={props.testID}
      onPress={onClick}
    >
      <ButtonContent {...props} />
    </TouchableOpacity>
  );
}

const BaseButtonStyle = {
  height: Sizes["48px"],
  paddingHorizontal: Sizes["24px"],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...Spacing.ma2,
  borderRadius: Sizes["48px"] / 2
};

const styles = StyleSheet.create({
  flat: {
    backgroundColor: "transparent",
    ...Spacing.px2
  },
  primary: {
    ...(BaseButtonStyle as any),
    backgroundColor: Colors.brand.yellow
  },
  secondary: {
    ...(BaseButtonStyle as any),
    backgroundColor: Colors.brand.blue
  },
  "secondary-outlined": {
    ...(BaseButtonStyle as any),
    borderColor: Colors.brand.blue,
    borderWidth: 2
  },
  round: {
    width: Sizes["48px"],
    paddingHorizontal: Sizes["4px"],
    borderRadius: Sizes["48px"] / 2,
    paddingVertical: Sizes["48px"] / 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }
});

const textStyles = StyleSheet.create({
  primary: {
    color: Colors.ui.text,
    ...(Typography.accent.small as any)
  },
  secondary: {
    color: Colors.ui.white,
    ...(Typography.accent.small as any)
  },
  "secondary-outlined": {
    color: Colors.brand.blue,
    ...(Typography.accent.small as any)
  },
  flat: {
    color: Colors.ui.blue,
    fontWeight: "bold"
  },
  disabled: {
    color: Colors.grey.inactive
  }
});

const disabledStyles = StyleSheet.create({
  primary: {
    backgroundColor: Colors.grey.aluminum,
    opacity: 0.7
  },
  secondary: {
    backgroundColor: Colors.grey.aluminum,
    opacity: 0.7
  },
  "secondary-outlined": {
    borderColor: Colors.grey.aluminum,
    opacity: 0.7
  },
  flat: {
    color: Colors.grey.inactive
  }
});
