import * as React from "react";
import { View, StyleSheet } from "react-native";
import { LogoIcon } from "components/Icons";
import ProgressBar from "components/Progress/ProgressBar";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

type SplashScreenProps = {
  progress: number;
};

export default function SplashScreen(props: SplashScreenProps) {
  return (
    <View style={styles.page}>
      <LogoIcon
        style={styles.logo}
        color={Colors.ui.white}
        width={150}
        height={50}
      />
      <View style={styles.progress}>
        <ProgressBar
          progress={props.progress}
          color={Colors.ui.white}
          height={Sizes["8px"]}
          backgroundColor={Colors.grey.slate}
          duration={100}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: Colors.brand.blue,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...Spacing.py5,
    ...Spacing.px4
  },
  logo: {
    ...Spacing.mt5,
    ...Spacing.mb4
  },
  progress: {
    overflow: "hidden",
    width: "100%",
    borderRadius: Sizes.borderRadius,
    ...Spacing.mt4
  }
});
