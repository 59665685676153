import {
  setEnvironment,
  setHasCameraPermission,

  // SIGN-IN
  setVerifyingUser,
  setRegisteringUser,
  setSignInCode,
  setSignUpFirstName,
  setSignUpLastName,
  setSignInIdentifier,
  setCreatingUser,
  setSignInError,
  setOnboardingSlide,
  resetSignIn,

  // DASHBOARD
  setShowTopicSelection,
  setSelectedTopic,
  setPhoto,
  setCreatingSession,
  setDashboardError,
  setShowFAQ,
  setShowDashboardMobileUpload,
  setShowDashboardPictureOptions,
  setDashboardProblemText,
  setDashboardEquation,
  setShowDashboardEquationEditor,
  resetDashboard,

  // SESSION
  setSessionMessage,
  setShowEndSession,
  setRating,
  toggleFeedbackReason,
  setFeedbackComment,
  resetSession,
  setBotScriptNode,
  setCancelReason,
  setShowImageGallery,
  setGalleryPreviewImage,
  setShowTutorProfile,
  setShowTutorProfileModal,
  setTutorProfileIndex,
  setTutorActivity,
  setSessionError,
  setEndingSession,
  setSubmittingFeedback,
  setShowFavoriteTutorPrompt,
  setSessionStartCountdown,
  setShowSessionMobileUpload,
  setShowSessionEquationEditor,

  // SESSION HISTORY
  setLoadingSessionHistory,
  setLoadingSessionTranscript,
  setLoadingSessionWhiteboardCaptures,
  setTranscriptImage,
  resetSessionHistory,
  setSessionHistoryError,

  // EXTENSION
  setExtensionInstalled,
  setShowExtensionError,
  setShowCapturePrompt,
  resetExtension,

  // MOBILE UPLOAD
  setMobileUploadPhoto,
  setMobileUploadPolling,
  resetMobileUpload,

  // PARTNERSHIPS
  setLoadingPartnershipProblem,
  setSigningInPartnership,
  setSignInPartnershipSuccess,
  resetPartnerships
} from "store/UI";
import store from "store";

import BotScriptReader from "helpers/BotScript";
import Logger, { SessionLogs, AppEvents } from "helpers/Logger";
import { SessionType, SubmitFeedbackPayload } from "models/Session";
import { Subtopic } from "./Settings";
import { Activity } from "components/ChatRoom/types/Activity";
import { Timers } from "@yups/utils";
import { Environment } from "types/Environment";
import Config from "helpers/Config";
import SessionMessages from "./SessionMessages";

export const GeneralUI = {
  setEnvironment(environment: Environment) {
    store.dispatch(setEnvironment(environment));
  },
  setHasCameraPermission(hasPermission: boolean) {
    store.dispatch(setHasCameraPermission(hasPermission));
  }
};

export const SessionUI = {
  reader: null as BotScriptReader | null,
  setMessage(message: string) {
    store.dispatch(setSessionMessage(message));
  },
  getFeedback(): SubmitFeedbackPayload {
    const { rating, feedbackComment, feedbackReasons } = store.getState().ui;

    return {
      rating: rating ?? false,
      comment: feedbackComment,
      reasons: feedbackReasons
    };
  },
  setShowEndSession(show: boolean) {
    store.dispatch(setShowEndSession(show));
  },

  setRating(isPositive: boolean) {
    store.dispatch(setRating(isPositive));
  },

  toggleReason(reason: string) {
    store.dispatch(toggleFeedbackReason(reason));
  },

  setFeedbackComment(comment: string) {
    store.dispatch(setFeedbackComment(comment));
  },

  setShowFavoriteTutorPrompt(show: boolean) {
    store.dispatch(setShowFavoriteTutorPrompt(show));
  },

  setPhoto(photo: string) {
    store.dispatch(setPhoto(photo));
  },
  setSubmittingFeedback(submitting: boolean) {
    store.dispatch(setSubmittingFeedback(submitting));
  },

  reset() {
    store.dispatch(resetSession());
  },

  async startBotScript(session: SessionType) {
    if (session.pre_session_questions) {
      this.reader = new BotScriptReader(session.id);
      await this.reader.loadScript(session.pre_session_questions);
      await this.updateBotScript(this.reader.currentKey);
    }
  },

  async updateBotScript(nextId: string) {
    const node = await this.reader?.process(nextId);
    if (node) {
      store.dispatch(setBotScriptNode(node));
      SessionMessages.sendBotMessage(node.messages[0][0], nextId);
    }
  },

  endBotScript() {
    this.reader?.stop();
    this.reader = null;
    store.dispatch(setBotScriptNode(null));
  },

  setCancelReason(reason: number) {
    store.dispatch(setCancelReason(reason));
  },

  setShowImageGallery(show: boolean) {
    store.dispatch(setShowImageGallery(show));
    if (!show) {
      Logger.log(AppEvents.imageClickBack);
    }
  },

  setGalleryPreviewImage(image: string, source: string) {
    store.dispatch(setGalleryPreviewImage(image));
    if (image) Logger.log(AppEvents.imageClickSelect, { source });
  },

  setShowTutorProfile(show: boolean) {
    store.dispatch(setShowTutorProfile(show));
    if (show) {
      Logger.log(AppEvents.sessionTutorProfileClicked);
    }
  },
  setShowTutorProfileModal(show: boolean) {
    store.dispatch(setShowTutorProfileModal(show));
  },
  setTutorProfileIndex(index: number) {
    store.dispatch(setTutorProfileIndex(index));
    Logger.log(AppEvents.sessionTutorProfileNavigated);
  },

  setTutorActivity(activity: Activity | null) {
    store.dispatch(setTutorActivity(activity));
    Timers.clear("tutor-activity");
    Timers.setTimeout({
      label: "tutor-activity",
      callback: () => {
        store.dispatch(setTutorActivity(null));
      },
      delay: 10000
    });
  },

  setError(error: string) {
    store.dispatch(setSessionError(error));
  },

  setEndingSession(ending: boolean) {
    store.dispatch(setEndingSession(ending));
  },

  setShowMobileUpload(show: boolean) {
    store.dispatch(setShowSessionMobileUpload(show));
    if (show) {
      Logger.log(AppEvents.sessionMobileUpload, { source: "session" });
    }
  },
  setShowEquationEditor(show: boolean) {
    store.dispatch(setShowSessionEquationEditor(show));
  },

  startSessionCountdown(claimedAt: number) {
    Timers.setInterval({
      label: "session-start-countdown",
      callback: () => {
        const secondsLeft =
          Config.sessionStartDelayInSeconds -
          Math.floor(new Date().getTime() / 1000 - claimedAt);
        if (secondsLeft <= 0) {
          Timers.clear("session-start-countdown");
          store.dispatch(setSessionStartCountdown(0));
        } else {
          store.dispatch(setSessionStartCountdown(secondsLeft));
        }
      },
      delay: 1000
    });
  },

  onStateChange(state: string) {
    if (state === "active") {
      Logger.log(SessionLogs.browserFocus);
    } else if (state === "background") {
      Logger.log(SessionLogs.browserBlur);
    }
  },

  onImageUpload() {
    Logger.log(AppEvents.sessionFileUploaded, { source: "session" });
  },

  onTakePicture() {
    Logger.log(AppEvents.sessionCameraOpen, { source: "session" });
  },

  onSubmitPhoto() {
    Logger.log(AppEvents.imageUploadSubmit);
  },
  onSendButtonClicked() {
    Logger.log(AppEvents.sessionSendButtonClicked);
  },
  onScreenshotSelected() {
    Logger.log(AppEvents.sessionScreenshotSelected, { source: "session" });
  },
  onScreenshotSubmitted() {
    Logger.log(AppEvents.sessionScreenShotCaptured, { source: "session" });
  },
  onMobileUpload() {
    Logger.log(AppEvents.sessionMobileUploadCaptured, { source: "session" });
  }
};

export const SignInUI = {
  setVerifyingUser(verifying: boolean) {
    store.dispatch(setVerifyingUser(verifying));
  },
  setRegisteringUser(registering: boolean) {
    store.dispatch(setRegisteringUser(registering));
  },
  setCreatingUser(creating: boolean) {
    store.dispatch(setCreatingUser(creating));
  },
  setSignInCode(code: string) {
    store.dispatch(setSignInCode(code));
  },
  setSignUpFirstName(firstName: string) {
    store.dispatch(setSignUpFirstName(firstName));
  },
  setSignUpLastName(lastName: string) {
    store.dispatch(setSignUpLastName(lastName));
  },
  setSignInIdentifier(identifier: string) {
    store.dispatch(setSignInIdentifier(identifier));
  },
  setError(error: string) {
    store.dispatch(setSignInError(error));
  },
  setOnboardingSlide(slide: number) {
    store.dispatch(setOnboardingSlide(slide));
    Logger.log(AppEvents.onboardingFlowProceed);
  },
  onFinishOnboarding() {
    Logger.log(AppEvents.onboardingFlowComplete);
  },
  setError(error: string) {
    store.dispatch(setSignInError(error));
  },
  reset() {
    store.dispatch(resetSignIn());
  }
};

export const DashboardUI = {
  setShowTopicSelection(show: boolean) {
    store.dispatch(setShowTopicSelection(show));
  },
  setSelectedTopic(topic: Subtopic | null) {
    store.dispatch(setSelectedTopic(topic));
  },
  setPhoto(photo: string) {
    store.dispatch(setPhoto(photo));
  },
  setCreatingSession(creating: boolean) {
    store.dispatch(setCreatingSession(creating));
  },
  setError(error: string) {
    store.dispatch(setDashboardError(error));
  },
  setProblemText(text: string) {
    store.dispatch(setDashboardProblemText(text));
  },
  setEquation(equation: string) {
    store.dispatch(setDashboardEquation(equation));
  },
  onUploadFile() {
    Logger.log(AppEvents.dashboardSelectUploadFile, { source: "home" });
  },
  onCamera() {
    Logger.log(AppEvents.dashboardSelectCamera, { source: "home" });
  },
  onPhotoTaken() {
    Logger.log(AppEvents.presessionCameraShutter);
  },
  onScreenshotSelected() {
    Logger.log(AppEvents.dashboardSelectScreenshot, { source: "home" });
  },
  onScreenshotSubmitted() {
    Logger.log(AppEvents.dashboardScreenShotCaptured, { source: "home" });
  },
  onMobileUpload() {
    Logger.log(AppEvents.dashboardMobileUploadCaptured, { source: "home" });
  },
  onTextSubmitted(text: string, has_equation: boolean) {
    Logger.log(AppEvents.dashboardTextSubmitted, {
      source: "home",
      text_length: text.length,
      has_equation
    });
  },
  onTopicSubmitted(subject: string) {
    Logger.log(AppEvents.presessionModalSubject, {
      subject
    });
  },
  setShowFAQ(show: boolean) {
    store.dispatch(setShowFAQ(show));
  },
  setShowMobileUpload(show: boolean) {
    store.dispatch(setShowDashboardMobileUpload(show));
    if (show) {
      Logger.log(AppEvents.dashboardSelectMobileUpload, { source: "home" });
    }
  },
  setShowPictureOptions(show: boolean) {
    store.dispatch(setShowDashboardPictureOptions(show));
  },
  setShowEquationEditor(show: boolean) {
    store.dispatch(setShowDashboardEquationEditor(show));
  },
  reset() {
    store.dispatch(resetDashboard());
  }
};

export const SessionHistoryUI = {
  setTranscriptImage(imageUrl: string) {
    store.dispatch(setTranscriptImage(imageUrl));
  },
  setLoadingSessionHistory(show: boolean) {
    store.dispatch(setLoadingSessionHistory(show));
  },
  setLoadingSessionTranscript(show: boolean) {
    store.dispatch(setLoadingSessionTranscript(show));
  },
  setLoadingWhiteboardCaptures(loading: boolean) {
    store.dispatch(setLoadingSessionWhiteboardCaptures(loading));
  },
  setError(error: string) {
    store.dispatch(setSessionHistoryError(error));
  },
  reset() {
    store.dispatch(resetSessionHistory());
  }
};

export const ExtensionUI = {
  setShowCapturePrompt(show: boolean) {
    store.dispatch(setShowCapturePrompt(show));
  },
  setExtensionInstalled(installed: boolean) {
    store.dispatch(setExtensionInstalled(installed));
  },
  setShowExtensionError(show: boolean) {
    store.dispatch(setShowExtensionError(show));
  },
  reset() {
    store.dispatch(resetExtension());
  }
};

export const MobileUploadUI = {
  setPhoto(photo: string) {
    store.dispatch(setMobileUploadPhoto(photo));
  },
  setPolling(polling: boolean) {
    store.dispatch(setMobileUploadPolling(polling));
  },
  reset() {
    store.dispatch(resetMobileUpload());
  }
};

export const PartnershipsUI = {
  setLoadingProblem(loading: boolean) {
    store.dispatch(setLoadingPartnershipProblem(loading));
  },
  setSigningIn(signingIn: boolean) {
    store.dispatch(setSigningInPartnership(signingIn));
  },
  setSignInSuccess(success: boolean) {
    store.dispatch(setSignInPartnershipSuccess(success));
  },
  reset() {
    store.dispatch(resetPartnerships());
  }
};

export function reset() {
  store.dispatch(resetSignIn());
  store.dispatch(resetDashboard());
  store.dispatch(resetSession());
  store.dispatch(resetSessionHistory());
  store.dispatch(resetExtension());
  store.dispatch(resetMobileUpload());
  store.dispatch(resetPartnerships());
}
