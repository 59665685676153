import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import ProblemCapture from "../components/ProblemCapture";

export default function Assistments() {
  const problem = useSelector(
    (state: StoreState) => state.partnerships.assistmentsProblem
  );

  return (
    <ProblemCapture partnerName="ASSISTments">
      {problem ? (
        <div
          style={problemStyle}
          dangerouslySetInnerHTML={{ __html: problem }}
        />
      ) : null}
    </ProblemCapture>
  );
}

const problemStyle = {
  fontFamily: Typography.primary.regular,
  ...Spacing.pa2
};
