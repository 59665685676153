import React from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { RootStackScreenProps } from "types";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Page from "components/Page";
import Text from "components/Text";
import { LogoIcon } from "components/Icons";
import Image from "components/Image";
import { Event } from "events/Event";

export default function MobileUploadSuccess(
  props: RootStackScreenProps<"MobileUploadSuccess">
) {
  const { width } = useWindowDimensions();
  const photo = useSelector((state: StoreState) => state.ui.mobileUploadPhoto);

  const onGoToCamera = Event.dispatcher("mobile_upload_success_on_back");

  return (
    <Page>
      <View style={styles.wrapper}>
        <LogoIcon
          width={100}
          height={Sizes["32px"]}
          color={Colors.brand.blue}
        />
        <Text tag="h2" style={styles.header}>
          Image Uploaded
        </Text>
        <Image maintainAspectRatio={true} uri={photo} maxSize={width - 16} />
        <Text style={styles.prompt}>
          Please continue your session on your desktop
        </Text>
        <Button
          style={styles.prompt}
          theme="flat"
          testID="retake"
          onClick={onGoToCamera}
        >
          or take another photo
        </Button>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa2,
    ...Spacing.mt4
  },
  header: {
    ...Spacing.mb2,
    ...Spacing.mt4
  },
  promptWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  prompt: {
    textAlign: "center"
  }
});
