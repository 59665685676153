import { LocalStorageHelper } from "@yups/utils";

export enum Environment {
  development = "development",
  production = "production",
  staging1 = "staging1",
  staging2 = "staging2",
  giddy = "giddy",
  qa1 = "qa1",
  qa2 = "qa2",
  qa3 = "qa3",
  rapha = "rapha",
  jordan = "jordan",
  ruturaj = "ruturaj",
  yupdev1 = "yupdev1",
  yupdev2 = "yupdev2",
  yupdev3 = "yupdev3"
}

export async function setEnvironment(env: Environment) {
  await LocalStorageHelper.set("yup-env", env);
}
