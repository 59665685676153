import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

class MobileUploadTokenController extends Controller {
  init() {
    Event.on("mobile_upload_token_on_submit", onSubmit);
  }
  deinit() {
    Event.remove("mobile_upload_token_on_submit", onSubmit);
  }
}

const controller = new MobileUploadTokenController();
export default controller;

function onSubmit({ token }: { token: string }) {
  Router.replace("MobileUploadCamera", { token });
}
