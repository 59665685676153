import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BotScriptNode } from "helpers/BotScript";
import { Subtopic } from "models/Settings";
import { Activity } from "components/ChatRoom/types/Activity";
import { Environment } from "types/Environment";

export type StateType = {
  environment: Environment;
  hasCameraPermission: boolean;

  // Sign-in
  verifyingUser: boolean;
  registeringUser: boolean;
  creatingUser: boolean;
  signInCode: string;
  signUpFirstName: string;
  signUpLastName: string;
  signInIdentifier: string;
  onboardingSlide: number;
  signInError: string;
  submittingSignUp: boolean;

  // Dashboard
  showTopicSelection: boolean;
  selectedTopic: Subtopic | null;
  photo: string;
  creatingSession: boolean;
  dashboardError: string;
  showFAQ: boolean;
  showDashboardMobileUpload: boolean;
  showDashboardPictureOptions: boolean;
  problemText: string;
  equation: string;
  showDashboardEquationEditor: boolean;

  // Session
  showImageGallery: boolean;
  galleryPreviewImage: string;
  sessionMessage: string;
  showEndSession: boolean;
  rating: boolean | null;
  feedbackReasons: Array<string>;
  feedbackComment: string;
  showSessionEquationEditor: boolean;
  botScriptNode: BotScriptNode | null;
  cancelReason: number;
  showTutorProfile: boolean;
  tutorProfileIndex: number;
  tutorActivity: Activity | null;
  sessionError: string;
  endingSession: boolean;
  submittingFeedback: boolean;
  showFavoriteTutorPrompt: boolean;
  showTutorProfileModal: boolean;
  showSessionMobileUpload: boolean;
  sessionStartCountdown: number;

  // Session history
  loadingSessionHistory: boolean;
  loadingSessionTranscript: boolean;
  loadingSessionWhiteboardCaptures: boolean;
  sessionHistoryError: string;
  transcriptImage: string;

  // Extension
  extensionIsInstalled: boolean;
  showExtensionError: boolean;
  showCapturePrompt: boolean;

  // Mobile upload
  mobileUploadPhoto: string;
  mobileUploadPolling: boolean;

  // Partnerships
  signingInPartnership: boolean;
  signInPartnershipSuccess: boolean;
  loadingPartnershipProblem: boolean;
};

export const DefaultSignInState = {
  verifyingUser: false,
  registeringUser: false,
  creatingUser: false,
  signInCode: "",
  signUpFirstName: "",
  signUpLastName: "",
  signInIdentifier: "",
  submittingSignUp: false,
  onboardingSlide: 0,
  signInError: ""
};

export const DefaultDashboardState = {
  showTopicSelection: false,
  selectedTopic: null,
  photo: "",
  creatingSession: false,
  dashboardError: "",
  showFAQ: false,
  showDashboardMobileUpload: false,
  showDashboardPictureOptions: false,
  problemText: "",
  equation: "",
  showDashboardEquationEditor: false
};

export const DefaultSessionState = {
  showImageGallery: false,
  sessionMessage: "",
  showEndSession: false,
  rating: null,
  feedbackReasons: [],
  feedbackComment: "",
  botScriptNode: null,
  cancelReason: 0,
  galleryPreviewImage: "",
  showTutorProfile: false,
  tutorProfileIndex: 0,
  sessionError: "",
  tutorActivity: null,
  endingSession: false,
  submittingFeedback: false,
  showFavoriteTutorPrompt: false,
  showTutorProfileModal: false,
  sessionStartCountdown: 0,
  showSessionMobileUpload: false,
  showSessionEquationEditor: false
};

export const DefaultSessionHistoryState = {
  loadingSessionHistory: false,
  loadingSessionTranscript: false,
  loadingSessionWhiteboardCaptures: false,
  sessionHistoryError: "",
  transcriptImage: ""
};

export const DefaultExtensionState = {
  extensionIsInstalled: false,
  showExtensionError: false,
  showCapturePrompt: false
};

export const DefaultMobileUploadState = {
  mobileUploadPolling: false,
  mobileUploadPhoto: ""
};

export const DefaultPartnershipsState = {
  signingInPartnership: false,
  signInPartnershipSuccess: false,
  loadingPartnershipProblem: false
};

export const DefaultState = {
  environment: Environment.production,
  hasCameraPermission: false,
  ...DefaultExtensionState,
  ...DefaultSignInState,
  ...DefaultDashboardState,
  ...DefaultSessionState,
  ...DefaultSessionHistoryState,
  ...DefaultMobileUploadState,
  ...DefaultPartnershipsState
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: { ...DefaultState } as StateType,
  reducers: {
    setEnvironment: (state, action: PayloadAction<Environment>) => {
      state.environment = action.payload;
    },
    setHasCameraPermission: (state, action: PayloadAction<boolean>) => {
      state.hasCameraPermission = action.payload;
    },

    // SIGN IN DISPLAY
    setVerifyingUser: (state: StateType, action: PayloadAction<boolean>) => {
      state.verifyingUser = action.payload;
    },
    setRegisteringUser: (state: StateType, action: PayloadAction<boolean>) => {
      state.registeringUser = action.payload;
    },
    setCreatingUser: (state: StateType, action: PayloadAction<boolean>) => {
      state.creatingUser = action.payload;
    },
    setSignInCode: (state: StateType, action: PayloadAction<string>) => {
      state.signInCode = action.payload;
    },
    setSignUpFirstName: (state: StateType, action: PayloadAction<string>) => {
      state.signUpFirstName = action.payload;
    },
    setSignUpLastName: (state: StateType, action: PayloadAction<string>) => {
      state.signUpLastName = action.payload;
    },
    setSignInIdentifier: (state: StateType, action: PayloadAction<string>) => {
      state.signInIdentifier = action.payload;
    },
    setOnboardingSlide: (state, action: PayloadAction<number>) => {
      state.onboardingSlide = action.payload;
    },
    setSignInError: (state, action: PayloadAction<string>) => {
      state.signInError = action.payload;
    },
    resetSignIn: (state: StateType) => {
      Object.assign(state, DefaultSignInState);
    },

    // DASHBOARD DISPLAY
    setShowTopicSelection: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.showTopicSelection = action.payload;
    },
    setSelectedTopic: (
      state: StateType,
      action: PayloadAction<Subtopic | null>
    ) => {
      state.selectedTopic = action.payload;
    },
    setPhoto: (state: StateType, action: PayloadAction<string>) => {
      state.photo = action.payload;
    },
    setCreatingSession: (state: StateType, action: PayloadAction<boolean>) => {
      state.creatingSession = action.payload;
    },
    setDashboardError: (state, action: PayloadAction<string>) => {
      state.dashboardError = action.payload;
    },
    setShowFAQ: (state, action: PayloadAction<boolean>) => {
      state.showFAQ = action.payload;
    },
    setShowDashboardMobileUpload: (state, action: PayloadAction<boolean>) => {
      state.showDashboardMobileUpload = action.payload;
    },
    setShowDashboardPictureOptions: (state, action: PayloadAction<boolean>) => {
      state.showDashboardPictureOptions = action.payload;
    },
    setDashboardProblemText: (state, action: PayloadAction<string>) => {
      state.problemText = action.payload;
    },
    setDashboardEquation: (state, action: PayloadAction<string>) => {
      state.equation = action.payload;
    },
    setShowDashboardEquationEditor: (state, action: PayloadAction<boolean>) => {
      state.showDashboardEquationEditor = action.payload;
    },
    resetDashboard: (state: StateType) => {
      Object.assign(state, DefaultDashboardState);
    },

    // SESSION DISPLAY
    setShowImageGallery: (state: StateType, action: PayloadAction<boolean>) => {
      state.showImageGallery = action.payload;
    },
    setGalleryPreviewImage: (
      state: StateType,
      action: PayloadAction<string>
    ) => {
      state.galleryPreviewImage = action.payload;
    },
    setSessionMessage: (state: StateType, action: PayloadAction<string>) => {
      state.sessionMessage = action.payload;
    },
    setShowEndSession: (state: StateType, action: PayloadAction<boolean>) => {
      state.showEndSession = action.payload;
    },
    setRating: (state: StateType, action: PayloadAction<boolean>) => {
      state.rating = action.payload;
    },
    toggleFeedbackReason: (state: StateType, action: PayloadAction<string>) => {
      if (state.feedbackReasons.includes(action.payload)) {
        state.feedbackReasons = state.feedbackReasons.filter(
          (r) => r !== action.payload
        );
      } else {
        state.feedbackReasons = state.feedbackReasons.concat([action.payload]);
      }
    },
    setFeedbackComment: (state: StateType, action: PayloadAction<string>) => {
      state.feedbackComment = action.payload;
    },
    setSessionError: (state, action: PayloadAction<string>) => {
      state.sessionError = action.payload;
    },
    setEndingSession: (state, action: PayloadAction<boolean>) => {
      state.endingSession = action.payload;
    },
    setShowFavoriteTutorPrompt: (state, action: PayloadAction<boolean>) => {
      state.showFavoriteTutorPrompt = action.payload;
    },
    setShowSessionMobileUpload: (state, action: PayloadAction<boolean>) => {
      state.showSessionMobileUpload = action.payload;
    },
    setShowSessionEquationEditor: (state, action: PayloadAction<boolean>) => {
      state.showSessionEquationEditor = action.payload;
    },
    setBotScriptNode: (
      state: StateType,
      action: PayloadAction<BotScriptNode | null>
    ) => {
      state.botScriptNode = action.payload;
    },
    setCancelReason: (state: StateType, action: PayloadAction<number>) => {
      state.cancelReason = action.payload;
    },
    setShowTutorProfile: (state: StateType, action: PayloadAction<boolean>) => {
      state.showTutorProfile = action.payload;
    },
    setShowTutorProfileModal: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.showTutorProfileModal = action.payload;
    },
    setTutorProfileIndex: (state: StateType, action: PayloadAction<number>) => {
      state.tutorProfileIndex = action.payload;
    },
    setTutorActivity: (state, action: PayloadAction<Activity | null>) => {
      state.tutorActivity = action.payload;
    },
    setSubmittingFeedback: (state, action: PayloadAction<boolean>) => {
      state.submittingFeedback = action.payload;
    },
    setSessionStartCountdown: (state, action: PayloadAction<number>) => {
      state.sessionStartCountdown = action.payload;
    },
    resetSession: (state: StateType) => {
      Object.assign(state, DefaultSessionState);
    },

    // SESSION HISTORY DISPLAY
    setLoadingSessionHistory: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.loadingSessionHistory = action.payload;
    },
    setLoadingSessionTranscript: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.loadingSessionTranscript = action.payload;
    },
    setLoadingSessionWhiteboardCaptures: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loadingSessionWhiteboardCaptures = action.payload;
    },
    setSessionHistoryError: (state, action: PayloadAction<string>) => {
      state.sessionHistoryError = action.payload;
    },
    setTranscriptImage: (state, action: PayloadAction<string>) => {
      state.transcriptImage = action.payload;
    },
    resetSessionHistory: (state: StateType) => {
      Object.assign(state, DefaultSessionHistoryState);
    },

    // EXTENSION DISPLAY
    setExtensionInstalled: (state, action: PayloadAction<boolean>) => {
      state.extensionIsInstalled = action.payload;
    },
    setShowExtensionError: (state, action: PayloadAction<boolean>) => {
      state.showExtensionError = action.payload;
    },
    setShowCapturePrompt: (state, action: PayloadAction<boolean>) => {
      state.showCapturePrompt = action.payload;
    },
    resetExtension: (state: StateType) => {
      Object.assign(state, DefaultExtensionState);
    },

    // MOBILE UPLOAD DISPLAY
    setMobileUploadPhoto: (state, action: PayloadAction<string>) => {
      state.mobileUploadPhoto = action.payload;
    },
    setMobileUploadPolling: (state, action: PayloadAction<boolean>) => {
      state.mobileUploadPolling = action.payload;
    },
    resetMobileUpload: (state: StateType) => {
      Object.assign(state, DefaultMobileUploadState);
    },

    // PARTNERSHIPS DISPLAY
    setLoadingPartnershipProblem: (state, action: PayloadAction<boolean>) => {
      state.loadingPartnershipProblem = action.payload;
    },
    setSigningInPartnership: (state, action: PayloadAction<boolean>) => {
      state.signingInPartnership = action.payload;
    },
    setSignInPartnershipSuccess: (state, action: PayloadAction<boolean>) => {
      state.signInPartnershipSuccess = action.payload;
    },
    resetPartnerships: (state: StateType) => {
      Object.assign(state, DefaultPartnershipsState);
    }
  }
});

export default uiSlice.reducer;

export const {
  setEnvironment,
  setHasCameraPermission,

  // SIGN-IN
  setVerifyingUser,
  setRegisteringUser,
  setCreatingUser,
  setSignInCode,
  setSignUpFirstName,
  setSignUpLastName,
  setSignInIdentifier,
  setOnboardingSlide,
  resetSignIn,
  setSignInError,

  // DASHBOARD
  setShowTopicSelection,
  setSelectedTopic,
  setPhoto,
  setCreatingSession,
  resetDashboard,
  setShowFAQ,
  setDashboardError,
  setShowDashboardMobileUpload,
  setShowDashboardPictureOptions,
  setDashboardProblemText,
  setDashboardEquation,
  setShowDashboardEquationEditor,

  // SESSION
  setSessionMessage,
  setShowEndSession,
  setRating,
  toggleFeedbackReason,
  setFeedbackComment,
  resetSession,
  setShowImageGallery,
  setGalleryPreviewImage,
  setBotScriptNode,
  setCancelReason,
  setShowTutorProfile,
  setTutorActivity,
  setSessionError,
  setSubmittingFeedback,
  setEndingSession,
  setShowFavoriteTutorPrompt,
  setTutorProfileIndex,
  setShowTutorProfileModal,
  setSessionStartCountdown,
  setShowSessionMobileUpload,
  setShowSessionEquationEditor,

  // SESSION HISTORY
  setLoadingSessionHistory,
  setLoadingSessionTranscript,
  setLoadingSessionWhiteboardCaptures,
  setTranscriptImage,
  setSessionHistoryError,
  resetSessionHistory,

  // EXTENSION
  setExtensionInstalled,
  setShowExtensionError,
  setShowCapturePrompt,
  resetExtension,

  // MOBILE UPLOAD
  setMobileUploadPhoto,
  setMobileUploadPolling,
  resetMobileUpload,

  // PARTNERSHIPS
  setLoadingPartnershipProblem,
  setSigningInPartnership,
  setSignInPartnershipSuccess,
  resetPartnerships
} = uiSlice.actions;
