import React, { useEffect, useRef, useState } from "react";
import Config from "helpers/Config";
import {
  View,
  StyleSheet,
  Animated,
  Easing,
  LayoutChangeEvent,
  useWindowDimensions,
  Platform
} from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";

type ProgressBarProps = {
  maxProgress?: number;
  height?: number;
  color?: string;
  backgroundColor?: string;
  duration?: number;
  progress?: number;
};

ProgressBar.defaultProps = {
  maxProgress: 100,
  height: Sizes["4px"],
  duration: 1000
};

export default function ProgressBar(props: ProgressBarProps) {
  const progressAnim = useRef(new Animated.Value(0)).current;
  const dimensions = useWindowDimensions();
  const [width, setWidth] = useState(dimensions.width);

  useEffect(() => {
    if (!Config.testing) {
      Animated.timing(progressAnim, {
        toValue: props.maxProgress!,
        duration: props.duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web"
      }).start();
    }
  }, [props.duration, props.maxProgress]);

  useEffect(() => {
    if (props.progress !== undefined)
      Animated.timing(progressAnim, {
        toValue: props.progress,
        duration: props.duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web"
      }).start();
  }, [props.progress]);

  function handleLayout(event: LayoutChangeEvent) {
    setWidth(event.nativeEvent.layout.width);
  }

  function getProgress() {
    return progressAnim.interpolate({
      inputRange: [0, 100],
      outputRange: [-width, 0],
      extrapolate: "clamp"
    });
  }

  function getStyle(color?: string) {
    return {
      height: props.height,
      backgroundColor: color
    };
  }

  return (
    <View
      style={[
        styles.progress,
        getStyle(props.backgroundColor ?? Colors.grey.concrete)
      ]}
      onLayout={handleLayout}
    >
      <Animated.View
        style={[
          getStyle(props.color ?? Colors.ui.blue),
          StyleSheet.absoluteFill,
          { transform: [{ translateX: getProgress() }] }
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  progress: {
    flexDirection: "row",
    width: "100%",
    overflow: "hidden"
  }
});
