import { LocalStorageHelper } from "@yups/utils";

export const StorageManager = {
  cleanupFunctions: {} as { [key: string]: Function },

  async get(storePrefix: string) {
    const data = await LocalStorageHelper.get(storePrefix);
    return data ? JSON.parse(data) : null;
  },

  async set(storePrefix: string, data: object) {
    try {
      await LocalStorageHelper.set(storePrefix, JSON.stringify(data));
    } catch (error) {
      // TODO: handle if localStorage limit is exceeded
    }
  },

  getSync(storePrefix: string) {
    const data = LocalStorageHelper.getSync(storePrefix);
    return data ? JSON.parse(data) : null;
  },

  setSync(storePrefix: string, data: object) {
    try {
      LocalStorageHelper.setSync(storePrefix, JSON.stringify(data));
    } catch (error) {
      // TODO: handle if localStorage limit is exceeded
    }
  },

  async remove(storePrefix: string) {
    await LocalStorageHelper.remove(storePrefix);
  },

  addCleanupMethod(storePrefix: string, onCleanup: Function) {
    this.cleanupFunctions[storePrefix] = onCleanup;
  },

  cleanup() {
    Object.values(this.cleanupFunctions).forEach((cleanup) => cleanup());
  }
};

export class Storage {
  storePrefix: string;
  constructor(storePrefix: string, onCleanup?: Function) {
    this.storePrefix = storePrefix;
    onCleanup && StorageManager.addCleanupMethod(storePrefix, onCleanup);
  }

  async get() {
    return await StorageManager.get(this.storePrefix);
  }

  async set(data: object) {
    await StorageManager.set(this.storePrefix, data);
  }

  getSync() {
    return StorageManager.getSync(this.storePrefix);
  }

  setSync(data: object) {
    StorageManager.setSync(this.storePrefix, data);
  }

  async remove() {
    await StorageManager.remove(this.storePrefix);
  }
}

export default Storage;
