import React from "react";
import { StyleSheet, ImageSourcePropType } from "react-native";

import Sizes from "assets/theme/Sizes";

import Button from "components/Button";
import Image from "components/Image";
import { Event } from "events/Event";

type SessionRatingOptionProps = {
  isPositive?: boolean;
};

export default function SessionRatingOption(props: SessionRatingOptionProps) {
  const image = props.isPositive
    ? require("assets/images/thumbs-up.png")
    : require("assets/images/thumbs-down.png");
  const testID = props.isPositive
    ? "session-positive-rating"
    : "session-negative-rating";
  const onClick = Event.dispatcher("postsession_submit_rating", {
    positive: props.isPositive ?? false
  });

  return (
    <Button theme="flat" testID={testID} onClick={onClick}>
      <Image src={image} style={styles.ratingImage} />
    </Button>
  );
}

const styles = StyleSheet.create({
  ratingImage: {
    width: Sizes["96px"],
    height: Sizes["96px"]
  }
});
