import { Event } from "events/Event";
import { Controller } from "types";

import { SessionHistoryUI } from "models/UI";

import SessionHistory from "models/SessionHistory";
import User from "models/User";

class HistoryController extends Controller {
  init() {
    Event.on("history_add_tutor_to_favorites", onAddTutorToFavorites);
    Event.on("history_remove_tutor_from_favorites", onRemoveTutorFromFavorites);
    onLoad();
  }
  deinit() {
    Event.remove("history_add_tutor_to_favorites", onAddTutorToFavorites);
    Event.remove(
      "history_remove_tutor_from_favorites",
      onRemoveTutorFromFavorites
    );
  }
}

const controller = new HistoryController();
export default controller;

async function onLoad() {
  SessionHistoryUI.setError("");
  SessionHistoryUI.setLoadingSessionHistory(true);
  const { success, message } = await SessionHistory.load();
  if (!success && message) {
    SessionHistoryUI.setError(message);
  }
  SessionHistoryUI.setLoadingSessionHistory(false);
}

async function onAddTutorToFavorites({
  tutorId,
  tutorName
}: {
  tutorId: number;
  tutorName: string;
}) {
  await User.addFavoriteTutor(tutorId, "history", tutorName);
}

async function onRemoveTutorFromFavorites({
  tutorId,
  tutorName
}: {
  tutorId: number;
  tutorName: string;
}) {
  await User.removeFavoriteTutor(tutorId, "history", tutorName);
}
