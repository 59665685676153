import React from "react";
import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import ScratchBoardClass from "models/ScratchBoard";
import ScratchBoardComponent from "components/ScratchBoard";
import { Event } from "events/Event";

export default function ScratchBoard() {
  const photo = useSelector((state: StoreState) => state.ui.photo);

  const onSubmit = async (scratchBoard: ScratchBoardClass) => {
    if (scratchBoard) {
      Event.dispatch("session_scratchboard_submit", {
        image: await scratchBoard.getImageData()
      });
    }
  };

  if (!photo) return null;

  return (
    <View style={styles.container}>
      <ScratchBoardComponent
        photo={photo}
        submitText="Upload"
        onSubmit={onSubmit}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});
