import React from "react";

import { StyleSheet, TouchableOpacity } from "react-native";
import { Symbol } from "./types/Symbol";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import StaticField from "./StaticField";

type EquationSymbolProps = {
  symbol: Symbol;
  onClick: () => void;
};

export default function EquationSymbol(props: EquationSymbolProps) {
  return (
    <TouchableOpacity style={styles.button} onPress={props.onClick}>
      <StaticField
        formula={`$$${props.symbol.preview ?? props.symbol.key}$$`}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 64,
    height: 64,
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    zIndex: -1,
    ...Spacing.ma1
  }
});
