import React from "react";

export type HoverableProps = {
  children: React.ReactNode;
  onHoverStart: () => void;
  onHoverLeave: () => void;
};

export default function Hoverable(props: HoverableProps) {
  return (
    <div onMouseEnter={props.onHoverStart} onMouseLeave={props.onHoverLeave}>
      {props.children}
    </div>
  );
}
