import "./shim"
import "react-native-gesture-handler";
import { NativeModules, Platform } from "react-native";
import { registerRootComponent } from "expo";
import LogBox from "adapters/LogBox";

import App from "./App";

LogBox.ignoreAllLogs();
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

if (__DEV__ && Platform.OS === "ios") {
  NativeModules.DevSettings.setIsDebuggingRemotely(true);
}
