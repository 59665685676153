import Config from "helpers/Config";
import { Platform } from "react-native";
import { detect } from "detect-browser";
import { Event } from "events/Event";

enum Messages {
  CheckScreenshot = "check-screenshot",
  Version = "version"
}

enum MessageType {
  sendImage = "send-image"
}

type Message = {
  type: MessageType;
  message: any;
};

export interface ExtensionEvent {
  // System-triggered events
  extension_on_captured: { dataUrl: string };
}

export const Extension = {
  isSupported() {
    return Platform.OS === "web" && detect()?.name === "chrome";
  },

  async isInstalled() {
    try {
      await checkExtension();
      return true;
    } catch (error) {
      return false;
    }
  },

  getUrl() {
    return `https://chrome.google.com/webstore/detail/${Config.googleChromeExtensionId}`;
  },

  subscribe() {
    // Check for existing image stored in chrome extension
    (window as any).chrome?.runtime?.sendMessage(
      Config.googleChromeExtensionId,
      Messages.CheckScreenshot,
      (message: Message) => this.handleMessage(message)
    );
  },
  handleMessage(message: Message) {
    if (message?.type === MessageType.sendImage) {
      Event.dispatch("extension_on_captured", { dataUrl: message.message });
    }
  }
};

export function setUpListener() {
  if (Platform.OS === "web") {
    (window as any).addEventListener("message", (message: any) => {
      if (message.data?.type) {
        Extension.handleMessage(message.data);
      }
    });
  }
}
setUpListener();

type VersionedResponse = {
  version: number;
  body: string | null;
};

export async function checkExtension() {
  return await new Promise((resolve, reject) => {
    if (!(window as any).chrome.runtime) {
      reject(new Error("Extension is not available"));
    }
    (window as any).chrome.runtime.sendMessage(
      Config.googleChromeExtensionId,
      Messages.Version,
      (response: VersionedResponse) => {
        if ((window as any).chrome.runtime.lastError) {
          reject(new Error("Extension is not installed"));
        } else if (!response) {
          reject(new Error("Chrome extension is not reachable."));
        } else {
          const { version, body } = response;
          if (!(version && version >= Config.googleChromeExtensionVersion)) {
            reject(new Error("Extension is not updated"));
          } else {
            resolve(body);
          }
        }
      }
    );
  });
}
