import { Event } from "events/Event";
import { Controller } from "types";
import { DashboardUI } from "models/UI";
import { Router } from "navigation/Router";
import { Assistments } from "models/Partnerships";

class ProblemCaptureController extends Controller {
  init() {
    Event.on("partnerships_open_contact", onOpenContact);
    Event.on("partnerships_on_submit", onSubmit);
    Event.on("partnerships_on_go_to_dashboard", onGoToDashboard);
  }
  deinit() {
    Event.remove("partnerships_open_contact", onOpenContact);
    Event.remove("partnerships_on_submit", onSubmit);
    Event.remove("partnerships_on_go_to_dashboard", onGoToDashboard);
  }
}

const controller = new ProblemCaptureController();
export default controller;

function onOpenContact() {
  Router.openLink("https://yup.com/contact/");
}

function onSubmit({ dataUri }: { dataUri: string }) {
  DashboardUI.setPhoto(dataUri);
  Router.replace("ScratchBoard");
}

function onGoToDashboard() {
  Assistments.reset();
  Router.navigate("Dashboard");
}