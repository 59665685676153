/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  LinkingOptions,
  createNavigationContainerRef
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import useColorScheme from "hooks/useColorScheme";
import * as Linking from "expo-linking";

import NotFoundScreen from "screens/NotFoundScreen";
import { RootStackParamList, ScreenType } from "types";

import { DashboardScreens } from "screens/Dashboard";
import { HistoryScreens } from "screens/History";
import { SignInScreens } from "screens/SignIn";
import { SessionScreens } from "screens/Session";
import { PostSessionScreens } from "screens/PostSession";
import { ProfileScreens } from "screens/Profile";
import { MobileUploadScreens } from "screens/MobileUpload";
import { PartnershipsScreens } from "screens/Partnerships";
import RouteController from "./Controller";

export const screens = [
  ...DashboardScreens,
  ...HistoryScreens,
  ...SignInScreens,
  ...ProfileScreens,
  ...MobileUploadScreens,
  ...PartnershipsScreens,
  ...SessionScreens,
  ...PostSessionScreens
];

function getLinks(screens: Array<ScreenType>) {
  const screenMap: any = {};
  screens.forEach((screen) => (screenMap[screen.name] = screen.path));
  return screenMap;
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      ...getLinks(screens),
      NotFound: "*"
    }
  }
};

export default function Navigation() {
  const colorScheme = useColorScheme();

  function navigateToRoute() {
    if (!NavigationRef.isReady()) return;
    const name = NavigationRef.getCurrentRoute()?.name;
    if (name) RouteController?.onChange(name);
  }

  function onNavigate(data: any) {
    if (data.type === "stack") navigateToRoute();
  }

  function onReady() {
    // Need to navigate to route to make sure controllers are initialized
    navigateToRoute();
  }

  return (
    <NavigationContainer
      ref={NavigationRef}
      linking={linking}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      onStateChange={onNavigate}
      onReady={onReady}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
export const Stack = createNativeStackNavigator<RootStackParamList>();
export const NavigationRef = createNavigationContainerRef();

function RootNavigator() {
  const screenOptions = {
    presentation: "card" as "card",
    gestureEnabled: false,
    headerShown: false,
    title: "Yup - Math Tutoring App",
    gestureResponseDistance: 0
  };
  return (
    <Stack.Navigator screenOptions={screenOptions}>
      {screens.map((screen) => (
        <Stack.Screen
          key={screen.name.toString()}
          name={screen.name}
          component={screen.component}
        />
      ))}
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
    </Stack.Navigator>
  );
}
