import { AuthHelper, pusherHelper } from "@yups/utils";
import Logger, { PusherLogs } from "./Logger";
import { reportError } from "./Sentry";
import { YupAPI } from "./YupAPI";
import {
  AuthStrategy,
  getAuthHeaders
} from "@yups/utils/build/AuthHelper/AuthHeaders";
import FeatureDecision from "./FeatureDecision";

const pusherConfig: any = {
  activityTimeout: 3000,
  pongTimeout: 5000
};

export enum PusherEvent {
  newMessages = "new-messages",
  newDeliveries = "new-deliveries"
}

// Workaround for java fileNotFound error triggered on Android
// However, as of 1.2.1 keeping this will crash the app
// see: https://github.com/pusher/pusher-websocket-react-native/issues/57
// if(Platform.OS === "android") {
//   pusherConfig.onAuthorizer = async (channelName: string, socketId: string) => {
//     const response = await WebService.authenticatePusherUser(socketId, channelName)
//     return response.data;
//   };
// }

class PusherClass {
  async initialize() {
    let authOverrides = {};
    if (FeatureDecision.enableYupAPI("pusherAuth")) {
      authOverrides = {
        authEndpoint: `${YupAPI.url()}/${AuthHelper.getPusherAuthEndpoint()}`,
        auth: {
          headers: getAuthHeaders(AuthStrategy.BearerToken)
        }
      };
    }

    await pusherHelper.init({
      ...pusherConfig,
      ...authOverrides
    });
    pusherHelper.onConnected(() => Logger.log(PusherLogs.pusherConnected));
    pusherHelper.onError((error) => {
      Logger.log(PusherLogs.pusherConnectionFailed);
      reportError(new Error(`Pusher connection failed: ${error}`));
    });
    pusherHelper.onDisconnected(() => {
      Logger.log(PusherLogs.pusherDisconnected);
      reportError(new Error("Pusher disconnected"));
    });
  }
  async deinitialize() {
    pusherHelper.clear();
  }
  async subscribeToChannel(channelName: string) {
    return await pusherHelper.subscribeToChannel(channelName);
  }
}

export const Pusher = new PusherClass();
