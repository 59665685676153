import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import { PromptArrowIcon } from "components/Icons";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Text from "components/Text";
import { Event } from "events/Event";

export default function CaptureRequestOverlay() {
  const onDismiss = Event.dispatcher("capture_overlay_dismissed");
  return (
    <>
      <TouchableOpacity
        testID="capture-overlay"
        style={styles.overlay}
        onPress={onDismiss}
      />
      <View style={styles.prompt}>
        <View style={styles.promptBubble}>
          <Text style={styles.promptText}>
            Switch to the tab where you want to take a screenshot. When ready,
            click the Yup extension icon.
          </Text>
        </View>
        <PromptArrowIcon width={200} height={100} color={Colors.brand.yellow} />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "black",
    opacity: 0.4,
    zIndex: 16
  },
  prompt: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: Sizes["96px"],
    zIndex: 16,
    alignItems: "flex-end"
  },
  promptBubble: {
    backgroundColor: Colors.ui.white,
    marginRight: -Sizes["4px"],
    maxWidth: 400,
    borderRadius: Sizes["32px"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "max-content",
    ...Spacing.pa2
  },
  promptText: {
    textAlign: "center"
  }
});
