import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Color } from "./types/Color";

type ColorPaletteProps = {
  currentColor: string;
  onSetColor: (color: Color) => void;
};

export default function ColorPalette(props: ColorPaletteProps) {
  return (
    <View style={styles.palette}>
      {Object.values(Color).map((color) => (
        <TouchableOpacity
          key={color}
          style={[styles.option, { backgroundColor: color }]}
          onPress={() => props.onSetColor(color)}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  palette: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: Sizes["32px"] * 2.5
  },
  option: {
    width: Sizes["32px"],
    height: Sizes["32px"],
    borderRadius: Sizes["32px"],
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    ...Spacing.ma1
  }
});
