import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Animated, Easing, Platform } from "react-native";

import Text from "components/Text";
import Sizes from "assets/theme/Sizes";
import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

type SnackbarProps = {
  text: string;
  testID?: string;
  icon?: React.ReactNode;
  style?: object;
  textStyle?: object;
  delay?: number;
  onDismiss?: () => void;
};

const Size = Sizes["32px"];

export default function Snackbar(props: SnackbarProps) {
  const anim = useRef(new Animated.Value(0)).current;
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (props.text && !isAnimating) {
      animate();
    }
  }, [props.text]);

  function animate() {
    setIsAnimating(true);
    Animated.sequence([
      Animated.timing(anim, {
        toValue: -Size * 5,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web"
      }),
      Animated.timing(anim, {
        toValue: 0,
        duration: 300,
        delay: props.delay ?? 7000,
        useNativeDriver: Platform.OS !== "web"
      })
    ]).start(() => {
      setIsAnimating(false);
      props.onDismiss?.();
    });
  }

  return (
    <Animated.View
      style={[
        styles.snackbar,
        { transform: [{ translateY: anim }] },
        props.style ?? {}
      ]}
    >
      {props.icon}
      <Text testID={props.testID} style={[styles.text, props.textStyle ?? {}]}>
        {props.text}
      </Text>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  snackbar: {
    backgroundColor: Colors.grey.iron,
    opacity: 0.85,
    borderRadius: Sizes.borderRadius,
    minHeight: Size,
    position: "absolute",
    bottom: -Size * 2 - Sizes["8px"],
    left: Sizes["8px"],
    zIndex: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.pa2
  },
  text: {
    color: Colors.ui.white
  }
});
