import { StackActions } from "@react-navigation/native";
import { ScreenType, Controller, AllStacks } from "types";

// While importing NavigationRef feels hacky, it's the recommended
// way of navigating without props. We can't use props because
// events need to be triggered outside of components
// (see https://reactnavigation.org/docs/navigating-without-navigation-prop/)
import { screens, NavigationRef } from "navigation";

import { Event } from "events/Event";
import { openURL } from "adapters/openURL";
import Config from "helpers/Config";

class RouteController extends Controller {
  currentScreen: ScreenType | undefined;

  init() {
    if (__DEV__ && !Config.testing)
      console.log(`${this.constructor.name} init`.toUpperCase());
    Event.on("open_link", (...args) => this.openLink(...args));
    Event.on("on_replace", (...args) => this.replace(...args));
    Event.on("on_navigate", (...args) => this.navigate(...args));
  }
  getScreen(routeName: string) {
    return screens.find((screen) => screen.name === routeName);
  }
  onChange(routeName: string) {
    this.currentScreen?.controller?.deinit();
    this.logControllerAction("deinit");
    this.currentScreen = this.getScreen(routeName);
    this.currentScreen?.controller?.init();
    this.logControllerAction("init");
  }
  logControllerAction(action: string) {
    if (this.currentScreen?.controller && __DEV__ && !Config.testing)
      console.log(
        `${this.currentScreen.controller.constructor.name} ${action}`.toUpperCase()
      );
  }
  openLink({ url }: { url: string }) {
    openURL(url);
  }
  navigate({
    routeName,
    params
  }: {
    routeName: keyof AllStacks;
    params?: object;
  }) {
    NavigationRef.dispatch(StackActions.push(routeName, params));
  }
  replace({
    routeName,
    params
  }: {
    routeName: keyof AllStacks;
    params?: object;
  }) {
    NavigationRef.dispatch(StackActions.replace(routeName, params));
  }
}

export default new RouteController();
