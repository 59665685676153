import { FunctionComponent } from "react";
import { ScreenType } from "types";

import HistoryScreen from "./views/History";
import TranscriptScreen from "./views/Transcript";
import HistoryController from "./controllers/HistoryController";
import TranscriptController from "./controllers/TranscriptController";

export type Stack = {
  History: undefined;
  Transcript: {
    id: number;
  };
};

export const HistoryScreens: Array<ScreenType> = [
  {
    name: "History",
    path: "history",
    controller: HistoryController,
    component: HistoryScreen as FunctionComponent
  },
  {
    name: "Transcript",
    path: "history/:id",
    controller: TranscriptController,
    component: TranscriptScreen as FunctionComponent
  }
];
