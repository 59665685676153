import { Platform } from "react-native";
import {
  MediaTypeOptions,
  requestMediaLibraryPermissionsAsync,
  launchImageLibraryAsync
} from "expo-image-picker";
import AWS from "aws-sdk";
import { format } from "date-fns";
import { writeFile, Bucket, deleteFile, getSignedUrl } from "helpers/AwsS3";
import {
  cacheDirectory,
  writeAsStringAsync,
  EncodingType,
  deleteAsync
} from "expo-file-system";
import { EnvironmentHelper, Timers } from "@yups/utils";

class ImageUploader {
  upload: AWS.S3.ManagedUpload | null = null;
  uploadError: Error | null = null;
  onProgressHandler: Function | null = null;
  onCompletionHandler: Function | null = null;
  onFailureHandler: Function | null = null;
  progress: number = 0;
  path!: string;

  constructor(path: string) {
    this.path = path;
  }

  abortImageUploadInProgress(): void {
    this.upload?.abort();
  }

  onProgress(onProgressHandler: Function): number {
    this.onProgressHandler = onProgressHandler;
    return this.progress;
  }

  onCompletion(onCompletionHandler: Function): void {
    this.onCompletionHandler = onCompletionHandler;
  }

  onFailure(onFailureHandler: Function): void {
    this.onFailureHandler = onFailureHandler;
  }

  getError() {
    return this.uploadError;
  }

  uploadImage(
    imageData: Blob,
    userId?: number,
    contentType: string = defaultContentType()
  ): void {
    this.upload?.abort();
    this.uploadError = null;
    this.upload = writeFile({
      imageData,
      contentType,
      path: this.filePath(userId),
      onProgressCallback: (percentsComplete) => {
        this.progress = percentsComplete;
        this.onProgressHandler?.(this.progress);
      },
      onCompletionCallback: (url) => this.onCompletionHandler?.(url),
      onFailureCallback: (error) => {
        this.uploadError = error;
        this.onFailureHandler?.(error);
      }
    });
  }

  filePath(userId?: number): string {
    const User = require("models/User").default;
    const timestamp = Math.ceil(new Date().getTime() / 1000);
    const date = format(new Date(), "yyyy/MM/dd");
    const studentId = userId ?? User.get()?.id;
    return `${this.path}s/${date}/${this.path}_${studentId}_${timestamp}.jpg`;
  }
}

export const scratchBoardImageUploader = new ImageUploader("problem-image");

export async function dataURItoBlob(dataURI: string): Promise<Blob> {
  const imageData = dataURI.replace(/"/g, "");
  if (Platform.OS === "android") {
    const uri = cacheDirectory + "signature-image-temp.png";
    try {
      const base64 = imageData.replace("data:image/png;base64,", "");
      await writeAsStringAsync(uri, base64, { encoding: EncodingType.Base64 });
      const result = await fetch(uri);
      return await result.blob();
    } finally {
      deleteAsync(uri);
    }
  }
  const res = await fetch(imageData);
  return await res?.blob();
}

export function defaultContentType(): string {
  return "image/jpeg";
}

async function requestPermission() {
  if (Platform.OS !== "web") {
    const { status } = await requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      throw new Error("Please grant access to photo library in settings");
    }
    return status;
  }
  return "granted";
}

export async function openImageSelector() {
  await requestPermission();
  const result = await launchImageLibraryAsync({
    mediaTypes: MediaTypeOptions.Images,
    base64: true,
    quality: 0.7
  });
  if (result.cancelled) {
    return "";
  }
  return `data:image/jpeg;base64,${(result as any).base64}`;
}

export async function getImageFromUrl(imageUrl: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(imageUrl);
      if (response.status === 403) {
        throw Error("File not found");
      }
      const blob = await response.blob();
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.toString());
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
}

class MobileImageUploader {
  upload: AWS.S3.ManagedUpload | null = null;
  bucket = Bucket.mobileUploads;
  contentType = "image/png";
  deleteAttempts = 5;
  onImageFoundCallback = (image: string) => {};
  onPollStoppedCallback = () => {};

  filePath(token?: string): string {
    const User = require("models/User").default;
    token = token || User.get()?.token;
    return `${EnvironmentHelper.currentEnvironment}/mobile-upload-${token}.png`;
  }

  onPollStopped(callback: () => void) {
    this.onPollStoppedCallback = callback;
  }

  onImageFound(callback: (image: string) => void) {
    this.onImageFoundCallback = callback;
  }

  async pollForImage() {
    Timers.setRecursiveTimeout({
      label: "mobile-upload",
      callback: () => this.onPoll(),
      delay: 1500
    });
    Timers.setTimeout({
      label: "mobile-upload-timeout",
      callback: () => this.stopPolling(),
      delay: 60000
    });
  }

  async onPoll() {
    const image = await this.getImage();
    if (image) {
      this.stopPolling();
      this.onImageFoundCallback(image);
      this.deleteImage();
    }
  }

  stopPolling() {
    Timers.clear("mobile-upload");
    Timers.clear("mobile-upload-timeout");
    this.onPollStoppedCallback();
  }

  async getImage(): Promise<string> {
    try {
      const url = getSignedUrl({
        bucket: this.bucket,
        path: this.filePath()
      });
      return await getImageFromUrl(url);
    } catch {
      return "";
    }
  }

  async uploadImage(imageData: Blob, token?: string): Promise<string> {
    this.upload?.abort();

    return new Promise((resolve, reject) => {
      this.upload = writeFile({
        imageData,
        contentType: this.contentType,
        path: this.filePath(token),
        bucket: this.bucket,
        onCompletionCallback: resolve,
        onFailureCallback: reject
      });
    });
  }

  async deleteImage(): Promise<boolean> {
    for (let i = this.deleteAttempts; i > 0; --i) {
      try {
        await deleteFile({
          bucket: this.bucket,
          path: this.filePath()
        });
        return true;
      } catch (error) {}
    }
    return false;
  }
}

export const mobileImageUploader = new MobileImageUploader();
