export function loadScript(src: string): Promise<void> | undefined {
  if (!(window as any).AsyncScripts) {
    (window as any).AsyncScripts = {};
  } else if ((window as any).AsyncScripts[src]) return;
  (window as any).AsyncScripts[src] = true;

  return new Promise(function (resolve, reject) {
    const script = (window as any).document.createElement("script");
    script.onload = () => resolve();
    script.onerror = () => {
      (window as any).AsyncScripts[src] = false;
      reject();
    };
    script.src = src;
    (window as any).document.head.appendChild(script);
  });
}
