import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Router } from "navigation/Router";
import { Extension } from "helpers/ChromeExtension";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Sizes from "assets/theme/Sizes";

import Text from "components/Text";

export default function ExtensionError() {
  const onOpenLink = () => Router.openLink(Extension.getUrl());

  return (
    <View style={styles.banner}>
      <Text style={styles.text}>Please install our</Text>
      <TouchableOpacity testID="install-extension" onPress={onOpenLink}>
        <Text style={styles.link}>Yup Chrome extension</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.ui.red,
    borderRadius: Sizes.borderRadius,
    display: "flex",
    flexDirection: "row",
    ...Spacing.px3,
    ...Spacing.py2,
    ...Spacing.ma2
  },
  text: {
    color: Colors.ui.white,
    textAlign: "center"
  },
  link: {
    color: Colors.ui.white,
    textDecorationLine: "underline",
    ...Spacing.ml1
  }
});
