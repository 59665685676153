import { WhiteboardAction } from "../types/WhiteboardAction";
import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { BaseCommand, LogAction } from "./BaseCommand";

export const redo: BaseCommand = {
  async execute(context: ZwibblerContext) {
    context.redo();
    context.clearSelection();
    LogAction(WhiteboardAction.redo);
  }
};

export default redo;
