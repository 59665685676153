import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";
import { openImageSelector } from "helpers/UploadImage";

import Session, { sessionRequiresRating, SessionType } from "models/Session";
import User from "models/User";
import { DashboardUI, ExtensionUI } from "models/UI";
import { Extension } from "helpers/ChromeExtension";
import ActivityBulletin from "models/ActivityBulletin";
import Settings from "models/Settings";

import ExtensionController from "./ExtensionController";

class DashboardController extends Controller {
  init() {
    Event.on("dashboard_change_text", setText);
    Event.on("dashboard_select_equation", onShowEquationEditor);
    Event.on("dashboard_clear_equation", clearEquation);
    Event.on("dashboard_select_picture_options", onSelectPictureOptions);
    Event.on("dashboard_select_mobile_upload", onSelectMobileUpload);
    Event.on("dashboard_select_screenshot", onAttemptScreenshot);
    Event.on("dashboard_select_camera", onUseCamera);
    Event.on("dashboard_select_upload_file", onUploadFile);
    Event.on("dashboard_submit_text", onTextSubmit);
    Event.on("dashboard_go_to_history", onGoToHistory);
    Event.on("dashboard_go_to_profile", onGoToProfile);
    Event.on("dashboard_open_faq", onShowFaq);
    Event.on("dashboard_sign_out", onSignOut);
    Event.on("dashboard_dismiss_picture_options", onDismissPictureOptions);
    Event.on("dashboard_dismiss_faq", onDismissFaq);
    Event.on("dashboard_load_activity_bulletin", loadActivityBulletin);
    Event.on("dashboard_session_in_progress", handleSession);

    Event.on("mobile_upload_submitted", onMobileUpload);
    Event.on("mobile_upload_dismissed", onDismissMobileUpload);
    Event.on("capture_overlay_dismissed", onDismissCapturePrompt);
    Event.on("equation_submitted", setEquation);
    Event.on("equation_dismissed", onDismissEquationEditor);

    ExtensionController.init();

    onLoad();
  }
  deinit() {
    Event.remove("dashboard_change_text", setText);
    Event.remove("dashboard_select_equation", onShowEquationEditor);
    Event.remove("dashboard_clear_equation", clearEquation);
    Event.remove("dashboard_select_picture_options", onSelectPictureOptions);
    Event.remove("dashboard_select_mobile_upload", onSelectMobileUpload);
    Event.remove("dashboard_select_screenshot", onAttemptScreenshot);
    Event.remove("dashboard_select_camera", onUseCamera);
    Event.remove("dashboard_select_upload_file", onUploadFile);
    Event.remove("dashboard_submit_text", onTextSubmit);
    Event.remove("dashboard_go_to_history", onGoToHistory);
    Event.remove("dashboard_go_to_profile", onGoToProfile);
    Event.remove("dashboard_open_faq", onShowFaq);
    Event.remove("dashboard_sign_out", onSignOut);
    Event.remove("dashboard_dismiss_picture_options", onDismissPictureOptions);
    Event.remove("dashboard_dismiss_faq", onDismissFaq);
    Event.remove("dashboard_load_activity_bulletin", loadActivityBulletin);
    Event.remove("dashboard_session_in_progress", handleSession);

    Event.remove("mobile_upload_submitted", onMobileUpload);
    Event.remove("mobile_upload_dismissed", onDismissMobileUpload);
    Event.remove("capture_overlay_dismissed", onDismissCapturePrompt);
    Event.remove("equation_submitted", setEquation);
    Event.remove("equation_dismissed", onDismissEquationEditor);

    ExtensionController.deinit();
  }
}

const controller = new DashboardController();
export default controller;

async function onLoad() {
  const user = User.get();
  const settings = Settings.get();
  const lastSession = Session.get() ?? user?.last_session;

  if (!user || !settings?.subtopics.length) {
    Router.replace("SignIn");
  } else if (
    lastSession &&
    (!lastSession.ended_at || sessionRequiresRating(lastSession))
  ) {
    handleSession({ session: lastSession });
  } else {
    User.onUnauthorized(() => Router.replace("SignIn"));
    loadActivityBulletin();
  }
}

function handleSession({ session }: { session: SessionType }) {
  if (!session.ended_at) {
    Router.replace("Session");
  } else if (sessionRequiresRating(session)) {
    Router.replace("SessionRating");
  }
  Session.set(session);
}

// Text options
function setText({ text }: { text: string }) {
  DashboardUI.setProblemText(text);
}

function setEquation({ equation }: { equation: string }) {
  DashboardUI.setEquation(equation);
  DashboardUI.setShowEquationEditor(false);
}

function clearEquation() {
  DashboardUI.setEquation("");
}

function onShowEquationEditor() {
  DashboardUI.setShowEquationEditor(true);
}

function onDismissEquationEditor() {
  DashboardUI.setShowEquationEditor(false);
}

function onTextSubmit({
  dataUrl,
  text,
  equation
}: {
  dataUrl: string;
  text: string;
  equation: string;
}) {
  DashboardUI.onTextSubmitted(text, Boolean(equation.length));
  DashboardUI.setPhoto(dataUrl);
  Router.replace("ScratchBoard");
}

// Picture options
function onSelectPictureOptions() {
  DashboardUI.setShowPictureOptions(true);
}

function onDismissPictureOptions() {
  DashboardUI.setShowPictureOptions(false);
}

function onUseCamera() {
  Router.replace("Camera");
  DashboardUI.setShowPictureOptions(false);
  DashboardUI.onCamera();
}

async function onUploadFile() {
  try {
    const photo = await openImageSelector();
    onDismissPictureOptions();
    if (photo) {
      DashboardUI.setPhoto(photo);
      Router.replace("ScratchBoard");
    }
  } catch (e) {
    DashboardUI.setError(e.message);
  }
}

async function onAttemptScreenshot() {
  DashboardUI.setShowPictureOptions(false);
  DashboardUI.onScreenshotSelected();
  if (await Extension.isInstalled()) {
    ExtensionUI.setShowExtensionError(false);
    ExtensionUI.setShowCapturePrompt(true);
  } else {
    DashboardUI.setError("");
    ExtensionUI.setShowExtensionError(true);
  }
}

function onDismissCapturePrompt() {
  ExtensionUI.setShowCapturePrompt(false);
}

function onSelectMobileUpload() {
  DashboardUI.setShowPictureOptions(false);
  DashboardUI.setShowMobileUpload(true);
}

function onMobileUpload({ image }: { image: string }) {
  DashboardUI.onMobileUpload();
  DashboardUI.setPhoto(image);
  DashboardUI.setShowMobileUpload(false);
  Router.replace("ScratchBoard");
}

function onDismissMobileUpload() {
  DashboardUI.setShowMobileUpload(false);
}

// Account actions
function onSignOut() {
  User.signOut();
  Router.replace("SignIn");
}

function onGoToHistory() {
  Router.navigate("History");
}

function onGoToProfile() {
  Router.navigate("Profile");
}

function onShowFaq() {
  DashboardUI.setShowFAQ(true);
}

function onDismissFaq() {
  DashboardUI.setShowFAQ(false);
}

function loadActivityBulletin() {
  if (ActivityBulletin.isSupported()) {
    ActivityBulletin.load();
  }
}
