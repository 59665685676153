import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Text from "components/Text";

export default function ProfileStats() {
  const session = useSelector((state: StoreState) => state.session.current);
  const { years_teaching_experience, number_of_students, thumbs_up_ratio } =
    session!.tutor!.profile;

  const stats = [
    {
      icon: "🎓",
      value: number_of_students,
      text: "Yup students helped"
    },
    {
      icon: "📚",
      value: `${years_teaching_experience} years`,
      text: "of teaching experience"
    },
    {
      icon: "👍",
      value: `${(thumbs_up_ratio * 100).toFixed()}%`,
      text: "rating"
    }
  ];

  return (
    <View style={styles.stats}>
      <Text style={[styles.subheader, styles.statsHeader]}>By the numbers</Text>
      {stats.map((stat) => (
        <Text key={stat.text} style={styles.stat}>
          {stat.icon}&nbsp;
          <Text style={styles.statValue}>{stat.value}</Text>
          &nbsp;{stat.text}
        </Text>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  subheader: {
    ...Typography.accent.small,
    lineHeight: 12,
    color: Colors.grey.slate,
    textAlign: "center"
  },
  statsHeader: {
    textAlign: "left",
    ...Spacing.mb2
  },
  stats: {
    borderBottomWidth: 1,
    borderColor: Colors.ui.beige,
    marginHorizontal: "auto",
    ...Spacing.px2,
    ...Spacing.py3
  },
  stat: {
    ...Spacing.my1
  },
  statValue: {
    fontWeight: "bold"
  }
});
