import React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Text from "components/Text";

type ErrorProps = {
  error: string;
};

export default function Error(props: ErrorProps) {
  if (!props.error) return null;
  return (
    <View style={styles.banner}>
      <Text style={styles.text} tag="h3">
        {props.error}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  banner: {
    ...Spacing.ma4
  },
  text: {
    color: Colors.ui.red,
    fontWeight: "bold",
    textAlign: "center"
  }
});
