import React from "react";
import { StyleSheet } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Tooltip from "components/Tooltip";

import {
  SendIcon,
  CameraIcon,
  FileUploadIcon,
  ChatImageIcon,
  ScreenshotIcon,
  EquationIcon,
  StopIcon,
  SpeakerIcon,
  MicrophoneIcon,
  MobileUploadIcon
} from "components/Icons";

import { InputButtonType } from "./types/MessageInputButton";
import { Event } from "events/Event";

const iconMap = {
  [InputButtonType.Send]: SendIcon,
  [InputButtonType.Camera]: CameraIcon,
  [InputButtonType.FileUpload]: FileUploadIcon,
  [InputButtonType.Send]: SendIcon,
  [InputButtonType.SessionImage]: ChatImageIcon,
  [InputButtonType.Equation]: EquationIcon,
  [InputButtonType.Screenshot]: ScreenshotIcon,
  [InputButtonType.MobileUpload]: MobileUploadIcon,
  [InputButtonType.SpeechToText]: MicrophoneIcon,
  [InputButtonType.StopSpeechToText]: StopIcon,
  [InputButtonType.TextToSpeech]: SpeakerIcon,
  [InputButtonType.StopTextToSpeech]: StopIcon
};

const tooltipMap = {
  [InputButtonType.Camera]: "Camera",
  [InputButtonType.FileUpload]: "Upload",
  [InputButtonType.Send]: "Send",
  [InputButtonType.SessionImage]: "Session image",
  [InputButtonType.Screenshot]: "Screenshot",
  [InputButtonType.Equation]: "Equation",
  [InputButtonType.MobileUpload]: "Take picture with phone",
  [InputButtonType.SpeechToText]: "Capture message",
  [InputButtonType.StopSpeechToText]: "Stop capturing",
  [InputButtonType.TextToSpeech]: "Play message",
  [InputButtonType.StopTextToSpeech]: "Stop playing"
};

type MessageInputButtonComponentProps = {
  type: InputButtonType;
  onClick?: () => void;
};

export default function MessageInputButton(
  props: MessageInputButtonComponentProps
) {
  const Icon = iconMap[props.type];
  if (!Icon) return null;

  function onClick() {
    props.onClick?.();
    Event.dispatch("chatroom_button_clicked", { buttonType: props.type });
  }

  return (
    <Tooltip text={tooltipMap[props.type]} position="top">
      <Button
        style={styles.button}
        theme="flat"
        testID={`message-button-${props.type}`}
        onClick={onClick}
      >
        <Icon height={24} width={24} color={Colors.ui.blue} />
      </Button>
    </Tooltip>
  );
}

const styles = StyleSheet.create({
  button: {
    ...Spacing.mx1,
    ...Spacing.py2,
    ...Spacing.px2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: Sizes["32px"],
    height: Sizes["32px"]
  }
});
