import { FunctionComponent } from "react";
import { ScreenType } from "types";

import DashboardController from "./controllers/DashboardController";
import DashboardScreen from "./views/Dashboard";
import CameraController from "./controllers/CameraController";
import CameraScreen from "./views/Camera";
import ScratchboardController from "./controllers/ScratchboardController";
import ScratchBoardScreen from "./views/ScratchBoard";

export type Stack = {
  Dashboard: undefined;
  Camera: undefined;
  ScratchBoard: undefined;
};

export const DashboardScreens: Array<ScreenType> = [
  {
    name: "Dashboard",
    path: "home",
    controller: DashboardController,
    component: DashboardScreen as FunctionComponent
  },
  {
    name: "Camera",
    path: "camera",
    controller: CameraController,
    component: CameraScreen as FunctionComponent
  },
  {
    name: "ScratchBoard",
    path: "scratch-board",
    controller: ScratchboardController,
    component: ScratchBoardScreen as FunctionComponent
  }
];
