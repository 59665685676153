import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";
import { useMediaQuery } from "react-responsive";
import { HistoricalSession } from "models/SessionHistory";
import { format } from "date-fns";

import Layout from "assets/theme/Layout";

import Button from "components/Button";
import { NextIcon, HeartIcon, HeartOutlineIcon } from "components/Icons";
import Text from "components/Text";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

type SessionProps = {
  session: HistoricalSession;
};

export default function SessionList(props: SessionProps) {
  const user = useSelector((state: StoreState) => state.user.user);
  const isMd = useMediaQuery({ minWidth: Layout.md });

  const {
    id,
    rating,
    created_at,
    tutor_formal_name,
    topic,
    n_achievements,
    tutor_id
  } = props.session;

  const onGoToSession = () => Router.navigate("Transcript", { id });

  const isFavoriteTutor = (user?.favorite_tutor_ids ?? []).includes(tutor_id);
  const testID = isFavoriteTutor ? "unfavorite-tutor" : "favorite-tutor";

  function getRating() {
    if (rating === undefined) {
      return "";
    } else if (rating >= 3) {
      return "👍";
    }
    return "👎";
  }

  function getDate() {
    return format(new Date(created_at), "MM/dd/yy");
  }

  function handleFavoriteToggle() {
    const event = isFavoriteTutor
      ? "history_remove_tutor_from_favorites"
      : "history_add_tutor_to_favorites";
    Event.dispatch(event, { tutorId: tutor_id, tutorName: tutor_formal_name });
  }

  if (!isMd || Platform.OS !== "web") {
    return (
      <TouchableOpacity
        style={styles.session}
        onPress={onGoToSession}
        testID="transcript-item"
      >
        <Text style={[styles.cell, styles.smCell]}>{getRating()}</Text>

        <View style={[styles.cell, styles.smInfo]}>
          <Text>{getDate()}</Text>

          <View style={styles.smName}>
            <Text style={styles.smNameText}>{tutor_formal_name}</Text>
            <TouchableOpacity testID={testID} onPress={handleFavoriteToggle}>
              {isFavoriteTutor ? (
                <HeartIcon height={16} width={16} color={Colors.ui.red} />
              ) : (
                <HeartOutlineIcon
                  height={16}
                  width={16}
                  color={Colors.grey.inactive}
                />
              )}
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.cell, styles.smCell]}>
          <NextIcon width={24} height={24} color={Colors.ui.blue} />
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      style={styles.session}
      onPress={onGoToSession}
      testID="transcript-item"
    >
      <Text style={styles.cell}>{getDate()}</Text>
      <Text style={styles.cell}>{topic}</Text>
      <View style={styles.cell}>
        <Text style={styles.mdText}>{tutor_formal_name}</Text>
        <TouchableOpacity testID={testID} onPress={handleFavoriteToggle}>
          {isFavoriteTutor ? (
            <HeartIcon height={16} width={16} color={Colors.ui.red} />
          ) : (
            <HeartOutlineIcon
              height={16}
              width={16}
              color={Colors.grey.inactive}
            />
          )}
        </TouchableOpacity>
      </View>
      <Text style={styles.cell}>{getRating()}</Text>
      <Text style={styles.cell}>{n_achievements}</Text>
      <View style={styles.cell}>
        <Button theme="flat" onClick={onGoToSession}>
          View
        </Button>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  session: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  cell: {
    ...Spacing.px3,
    ...Spacing.py2,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  mdText: {
    ...Spacing.mr1
  },

  // Small sizing
  smCell: {
    ...Spacing.pa2,
    width: Sizes["48px"],
    textAlign: "center",
    flex: 0
  },
  smInfo: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  smName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  smNameText: {
    color: Colors.grey.slate,
    ...Spacing.mr1
  }
});
