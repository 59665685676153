import { EnvironmentHelper } from "@yups/utils";
import Storage from "helpers/Storage";
import { TokenGeneratorHelper } from "@yups/utils";
import { detect } from "detect-browser";

import Layout from "assets/theme/Layout";
import Config from "helpers/Config";
import { Platform } from "react-native";
import { modelName } from "expo-device";
import Notifications from "helpers/Notifications";

const STORE_KEY = "yup-uuid";
export const storage = new Storage(STORE_KEY);

export type Device = {
  environment: string;
  platform: string;
  uuid: string;
  app_version: string;
  os_version: string;
  model: string;
  screen_width: number;
  screen_height: number;
  push_token?: string;
  feature_flags: any;
};

export default Device;

const Flags = {
  tutorReviewCapable: "tutor_review_capable",
  minimumChargeable: "minimum_chargeable",
  trialV2Eligible: "trial_v2_eligible",
  dayPassEligible: "day_pass_eligible",
  weeklySubscriptionEligible: "weekly_subscription_eligible",
  multiMonthSubscriptionEligible: "multi_month_subscription_eligible",
  lastNameEligible: "last_name_eligible",
  payerInfoV2Eligible: "payer_info_v2_eligible",
  dollarOffEligible: "dollar_off_eligible",
  unlimitedTrialEligible: "unlimited_trial_eligible",
  webappPaymentApiEligible: "webapp_payment_api_eligible",
  creditCardScannerEligible: "credit_card_scanner_eligible",
  chargeByHourEligible: "charge_by_hour_eligible",
  studentSessionRatingV2Eligible: "student_session_rating_v2_eligible",
  upgradeFlowEligible: "upgrade_flow_eligible",
  badgesEligible: "badges_eligible",
  latexEligible: "latex_eligible",
  botGradeSelectionEligible: "bot_grade_selection_eligible",
  facebookInviteAllDollarOffRewardEligible:
    "facebook_invite_all_dollar_off_reward_eligible",
  teacherExperienceEligible: "teacher_experience_eligible",
  paypalCreditEligible: "paypal_credit_eligible",
  showEmailModalEligible: "show_email_modal_eligible",
  achievementsEligible: "achievements_eligible",
  tutorMessageSeenEligible: "tutor_message_seen_eligible",
  liveWhiteboardEligible: "live_whiteboard_eligible",
  achievementsEnabled: "is_enabled_for_achievements"
};

export async function getDeviceInfo(): Promise<Device> {
  const feature_flags: any = {};
  feature_flags[Flags.tutorReviewCapable] = true;
  feature_flags[Flags.latexEligible] = true;
  feature_flags[Flags.studentSessionRatingV2Eligible] = true;
  feature_flags[Flags.achievementsEnabled] = true;
  feature_flags[Flags.badgesEligible] = true;
  feature_flags[Flags.achievementsEligible] = true;

  let platform = Platform.OS as string;
  if (Platform.OS === "web") {
    const browser = detect();
    platform = `${browser?.name}:${browser?.os?.toLowerCase()}`;
  }

  const push_token = (await Notifications.getToken()) ?? "";
  const device_id = await deviceId();
  return {
    environment: EnvironmentHelper.env?.name ?? "production",
    platform,
    uuid: device_id,
    app_version: Config.version,
    os_version: Platform.Version?.toString(),
    model: modelName ?? "x86_64",
    screen_width: Layout.window.width,
    screen_height: Layout.window.height,
    push_token,
    feature_flags
  };
}

export async function deviceId(): Promise<string> {
  const data = await storage.get();
  let uuid = data?.uuid;

  if (!uuid) {
    uuid = TokenGeneratorHelper.uuid();
    await storage.set({ uuid });
  }
  return uuid;
}

export async function getLogInfo() {
  return {
    source: "nativeapp",
    device_id: await deviceId(),
    screen_width: Layout.window.width,
    screen_height: Layout.window.height,
    platform: Platform.OS,
    model: modelName ?? "x86_64",
    push_token: await Notifications.getToken(),
    client: "student",
    feature_toggles: ["react_native:true"]
  };
}
