import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { sessionIsReady } from "models/Session";

import { transformMessages } from "components/ChatRoom/types/Message";
import CaptureRequestOverlay from "components/CaptureRequest/CaptureRequestOverlay";
import MobileUploadCode from "components/MobileUploadCode";
import Whiteboard from "components/Whiteboard";

import ChatRoom from "components/ChatRoom";
import GoogleTranslate from "components/GoogleTranslate";
import ExtensionError from "../components/ExtensionError";
import EndSession from "../components/EndSession";
import TutorUnavailable from "../components/TutorUnavailable";
import SessionEnded from "../components/SessionEnded";
import Snackbar from "components/Snackbar";
import PreSessionQuestions from "../components/PreSessionQuestions";
import ImageGallery from "../components/ImageGallery";
import Toolbar from "../components/Toolbar";
import TutorProfileDropdown from "../components/TutorProfile/Dropdown";
import TutorProfileModal from "../components/TutorProfile/Modal";
import FindingTutor from "../components/FindingTutor";
import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import { useMediaQuery } from "react-responsive";
import EquationModal from "components/Equations/EquationModal";
import { Event } from "events/Event";

export default function Session() {
  const session = useSelector((state: StoreState) => state.session.current);
  const messages = useSelector((state: StoreState) => state.messages.messages);
  const message = useSelector((state: StoreState) => state.ui.sessionMessage);
  const showEndSession = useSelector(
    (state: StoreState) => state.ui.showEndSession
  );
  const endingSession = useSelector((state: StoreState) => state.ui.endingSession);
  const currentBotScriptNode = useSelector(
    (state: StoreState) => state.ui.botScriptNode
  );
  const settings = useSelector((state: StoreState) => state.settings.settings);
  const tutorActivity = useSelector(
    (state: StoreState) => state.ui.tutorActivity
  );
  const error = useSelector((state: StoreState) => state.ui.sessionError);
  const user = useSelector((state: StoreState) => state.user.user);
  const showCapturePrompt = useSelector(
    (state: StoreState) => state.ui.showCapturePrompt
  );
  const showMobileUpload = useSelector(
    (state: StoreState) => state.ui.showSessionMobileUpload
  );
  const showEquationEditor = useSelector(
    (state: StoreState) => state.ui.showSessionEquationEditor
  );
  const whiteboardConnectionState = useSelector(
    (state: StoreState) => state.whiteboard.state
  );
  const isMd = useMediaQuery({ maxWidth: Layout.md });

  if (!session || !settings || !user) return null;

  const transformedMessages = transformMessages(messages);
  const showingSessionEnd = endingSession || showEndSession || Boolean(session.ended_action) || Boolean(session.ended_by);
  const canShowPresessionQuestions = !showingSessionEnd;
  const canShowTutorDropdown = sessionIsReady(session) && !showingSessionEnd;
  const canShowTutorModal = !showingSessionEnd && !currentBotScriptNode;
  const canShowImageGallery = sessionIsReady(session);
  const canShowWhiteboard = Platform.OS === "web";

  const onDismissError = Event.dispatcher("session_dismiss_error");

  return (
    <View style={styles.wrapper} testID="session">
      {showCapturePrompt && <CaptureRequestOverlay />}
      <Snackbar
        testID="session-error"
        text={error}
        onDismiss={onDismissError}
      />
      <EndSession />
      <TutorUnavailable />
      <SessionEnded />
      <MobileUploadCode show={showMobileUpload} token={user?.token ?? ""} />
      {canShowPresessionQuestions && <PreSessionQuestions />}
      <Toolbar />
      <FindingTutor />
      {canShowTutorDropdown && <TutorProfileDropdown />}
      {canShowTutorModal && <TutorProfileModal />}
      {canShowImageGallery && <ImageGallery />}
      <ExtensionError />
      <EquationModal show={showEquationEditor} />
      <View style={styles.main}>
        <GoogleTranslate page="Session" useTabStyle={true} />
        {canShowWhiteboard && (
          <View
            style={[
              sessionIsReady(session) && styles.whiteboard,
              isMd && { display: "none" }
            ]}
          >
            <Whiteboard
              connectionState={whiteboardConnectionState}
              ready={sessionIsReady(session)}
            />
          </View>
        )}
        <ChatRoom
          name={session.tutor?.profile?.name}
          messages={transformedMessages}
          showTimestamps={false}
          messageInput={currentBotScriptNode ? "" : message}
          activity={tutorActivity}
          showMessageInput={sessionIsReady(session)}
          canReact={true}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "column",
    overflow: "hidden"
  },
  main: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    position: "relative",
    zIndex: -1
  },
  whiteboard: {
    width: "60%",
    borderRightWidth: 1,
    borderRightColor: Colors.ui.beige
  }
});
