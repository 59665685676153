import * as React from "react";
import { View as DefaultView, StyleSheet } from "react-native";
import { useThemeColor, ThemeProps } from "components/theme";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

export type ViewProps = ThemeProps & DefaultView["props"];

export default function Page(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "cardBackground"
  );

  return (
    <DefaultView
      style={[{ backgroundColor }, styles.card, style]}
      {...otherProps}
    />
  );
}

const styles = StyleSheet.create({
  card: {
    ...Spacing.ma2,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    shadowOffset: {
      width: 2,
      height: 2
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    shadowColor: Colors.grey.inactive
  }
});
