import { Audio } from "expo-av";
import { reportError } from "helpers/Sentry";

const ShutterSound = require("assets/audio/shutter.mp3");
const TutorFoundSound = require("assets/audio/tutor_found.mp3");
const IncomingMessageSound = require("assets/audio/incoming_message.mp3");
const SessionEndedSound = require("assets/audio/session_ended.mp3");

// Fix based on https://github.com/expo/expo/issues/5472
Audio.setAudioModeAsync({
  allowsRecordingIOS: false,
  staysActiveInBackground: true
});

export enum Sound {
  shutter,
  tutorFound,
  incomingMessage,
  sessionEnded
}

const Sounds = {
  [Sound.shutter]: ShutterSound,
  [Sound.tutorFound]: TutorFoundSound,
  [Sound.incomingMessage]: IncomingMessageSound,
  [Sound.sessionEnded]: SessionEndedSound
};

export const LoadedSounds: { [key: string]: any } = {
  [Sound.shutter]: null,
  [Sound.tutorFound]: null,
  [Sound.incomingMessage]: null,
  [Sound.sessionEnded]: null
};

export async function loadSounds() {
  Object.entries(Sounds).forEach(async ([key, value]) => {
    try {
      const { sound } = await Audio.Sound.createAsync(value);
      LoadedSounds[key] = sound;
    } catch (error) {
      reportError(error);
    }
  });
}

export async function unloadSounds() {
  Object.values(LoadedSounds).forEach(async (sound) => {
    try {
      await sound?.unloadAsync();
    } catch (error) {
      reportError(error);
    }
  });
}

export async function playSound(sound: Sound) {
  try {
    LoadedSounds[sound]?.replayAsync();
  } catch (error) {
    reportError(error);
  }
}
