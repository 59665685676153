import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { EndedActions } from "models/Session";
import { StyleSheet, useWindowDimensions } from "react-native";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import SelectCollection from "components/SelectCollection";
import Modal from "components/Modal";
import Text from "components/Text";

export default function EndSession() {
  const session = useSelector((state: StoreState) => state.session.current);
  const show = useSelector((state: StoreState) => state.ui.showEndSession);
  const cancelReason = useSelector(
    (state: StoreState) => state.ui.cancelReason
  );
  const settings = useSelector((state: StoreState) => state.settings.settings);
  const endingSession = useSelector(
    (state: StoreState) => state.ui.endingSession
  );
  const { width } = useWindowDimensions();
  const reasons = settings!.session_cancel_reasons;
  const reasonLabels = reasons.map((r) => r.reason);
  const sessionStarted = Boolean(session!.tutor_ready_at);
  const reason = reasons.find((r) => r.id === cancelReason)?.reason ?? "";
  const header = sessionStarted
    ? "Are you ready to end your session?"
    : "Why are you cancelling?";
  const buttonText = sessionStarted ? "End Session" : "Cancel Request";

  const onDismiss = Event.dispatcher("session_dismiss_end_session");
  function onSelect(reason: string | string[]) {
    const match = reasons.find((r) => r.reason === reason);
    Event.dispatch("session_set_cancel_reason", { reason: match?.id ?? 0 });
  }
  const onEndSession = Event.dispatcher("session_end_session", {
    reason: cancelReason,
    EndedAction: session?.started_at
      ? EndedActions.StudentEnded
      : EndedActions.StudentCancelled
  });

  return (
    <Modal
      show={show || endingSession}
      width={Math.min(350, width)}
      onDismiss={onDismiss}
    >
      <Text tag="h2" style={styles.prompt}>
        {header}
      </Text>
      {!sessionStarted && (
        <SelectCollection
          options={reasonLabels}
          selected={reason}
          style={styles.select}
          optionStyle={styles.option}
          onSelect={onSelect}
          testID="end-reason"
        />
      )}

      <Button
        testID="confirm-end-session"
        processing={endingSession}
        disabled={!sessionStarted && !reason}
        onClick={onEndSession}
      >
        {buttonText}
      </Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3
  },
  select: {
    ...Spacing.my2
  },
  option: {
    width: "45%"
  }
});
