import { ZwibblerContext } from "../WhiteboardService/zwibbler2";
import { WhiteboardAction } from "../types/WhiteboardAction";
import Logger, { AppEvents } from "helpers/Logger";

export interface BaseCommand {
  execute: (context: ZwibblerContext, value?: any) => void;
}

export function LogAction(toolUsed: WhiteboardAction | string, args?: any) {
  Logger.log(AppEvents.sessionWhiteboardUsed, {
    tool_used: toolUsed,
    ...(args ?? {})
  });
}
