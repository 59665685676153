import React, { useRef, useState } from "react";
import {
  TextInput,
  View,
  StyleSheet,
  Platform,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
  Keyboard,
  SafeAreaView
} from "react-native";
import { Event } from "events/Event";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import { InputButtonType } from "./types/MessageInputButton";
import MessageInputButton from "./MessageInputButton";

import { Extension } from "helpers/ChromeExtension";
import { SpeechToText } from "./helpers/SpeechToText";

type MessageInputComponentProps = {
  value: string;
};

function SpeechToTextOption(props: {
  isRecording: boolean;
  onStart: () => void;
  onStop: () => void;
}) {
  if (props.isRecording) {
    return (
      <MessageInputButton
        type={InputButtonType.StopSpeechToText}
        onClick={props.onStop}
      />
    );
  }
  return (
    <MessageInputButton
      type={InputButtonType.SpeechToText}
      onClick={props.onStart}
    />
  );
}

let speechToText: SpeechToText;
export default function MessageInput(props: MessageInputComponentProps) {
  const [isRecording, setIsRecording] = useState(false);
  const input = useRef<TextInput>(null);
  const style: any = [styles.textfield];
  if (Platform.OS === "web") {
    style.push({ outline: "none" });
  }

  function onMessageChange(message: string) {
    Event.dispatch("chatroom_message_changed", { message });
  }

  function onChange(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    onMessageChange(event.nativeEvent.text);
  }

  function onSendMessage() {
    Keyboard.dismiss();
    Event.dispatch("chatroom_message_sent", { message: props.value });
  }

  function onKeyPress(event: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if ((event as any).key === "Enter" && !(event as any).shiftKey) {
      event.preventDefault();
      onSendMessage();
    }
  }

  function onStartSpeechToText() {
    let message = "";
    setIsRecording(true);
    onMessageChange(message);
    speechToText = new SpeechToText();
    speechToText.onResult((result) => {
      message += result;
      onMessageChange(message);
      input.current?.focus();
    });
    speechToText.onEnd(onStopSpeechToText);
    speechToText.start();
  }

  function onStopSpeechToText() {
    speechToText?.stop();
    setIsRecording(false);
  }

  return (
    <SafeAreaView style={styles.input}>
      <View style={styles.inputWrapper}>
        <View style={styles.textfieldWrapper}>
          {Platform.OS === "web" && (
            <SpeechToTextOption
              isRecording={isRecording}
              onStart={onStartSpeechToText}
              onStop={onStopSpeechToText}
            />
          )}
          <TextInput
            ref={input}
            editable={!isRecording}
            value={props.value}
            style={[...style]}
            placeholder={
              isRecording ? "Listening for message" : "Type a message"
            }
            autoCapitalize="none"
            testID="message-input"
            multiline={true}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </View>
        <View style={styles.buttons}>
          <View style={styles.chatActions}>
            <MessageInputButton type={InputButtonType.SessionImage} />
            <MessageInputButton type={InputButtonType.Camera} />
            {Platform.OS === "web" && (
              <MessageInputButton type={InputButtonType.MobileUpload} />
            )}
            <MessageInputButton type={InputButtonType.FileUpload} />
            {Extension.isSupported() && (
              <MessageInputButton type={InputButtonType.Screenshot} />
            )}
            {Platform.OS === "web" && (
              <MessageInputButton type={InputButtonType.Equation} />
            )}
          </View>
          <MessageInputButton
            type={InputButtonType.Send}
            onClick={onSendMessage}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  input: {
    borderTopWidth: 1,
    borderColor: Colors.ui.beige
  },
  inputWrapper: {
    ...Spacing.ma2,
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius
  },
  textfieldWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  textfield: {
    ...Spacing.mt2,
    ...Spacing.pb3,
    ...Spacing.px3,
    fontFamily: Typography.primary.regular,
    fontSize: Typography.defaultSize,
    borderRadius: Sizes.borderRadius,
    maxHeight: 200,
    zIndex: -1,
    flexGrow: 1
  },
  buttons: {
    ...Spacing.pa1,
    zIndex: -1,
    backgroundColor: Colors.grey.concrete,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  chatActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1
  }
});
