import {
  LogEvent,
  LogStart,
  LogClear,
  LogBackUp,
  EnvironmentHelper
} from "@yups/utils";
import Config from "./Config";

export enum DebugLogs {
  ratingSubmittedLogError = "debug_rating_submitted_log_error"
}

export enum SessionLogs {
  browserBlur = "client_student_multitasking_started",
  browserFocus = "client_student_multitasking_ended",
  sessionCreated = "client_session_created",
  sessionFirstStudentMessageSent = "client_student_sent_first_message",
  sessionEndedWithEndButton = "client_session_ended_student_end_button",
  storageIgnoredExpiredSession = "storage_ignored_expired_session",
  storageOverwroteExpiredSession = "storage_overwrote_expired_session",
  pusherTutorReadyEvent = "client_student_received_notification_tutor_ready",
  pusherMessageReceived = "client_session_message_received_real_time"
}

export enum PusherLogs {
  pusherConnected = "client_student_pusher_connected",
  pusherConnectionFailed = "client_student_pusher_connection_failed",
  pusherDisconnected = "client_student_pusher_disconnected",
  pusherSubscribedToChannel = "client_session_joined_channel",
  pusherSubscribedToChannelFailed = "client_session_joined_channel_failed"
}

export enum APILogs {
  api = "student_native_app.api"
}

// https://docs.google.com/document/d/1lVEhYX6GdxJHF5R5wXUQfYRBUCL88nj-mRsPAsn89-Y
export enum AppEvents {
  webLoaded = "student_web_app.loaded",
  appLoaded = "mobile.app_loaded",
  loginSubmitIdentifier = "login_submit_identifier",
  loginAttempt = "login_attempt",
  loginSSOStart = "login_sso_start",
  loginSuccessful = "login_successful",
  loginVerifySuccessful = "login_verify_successful",
  onboardingFlowStart = "onboarding_flow_start",
  onboardingFlowProceed = "onboarding_flow_proceed",
  onboardingFlowComplete = "onboarding_flow_complete",
  dashboardSelectCamera = "dashboard_select_camera",
  dashboardSelectMobileUpload = "dashboard_select_mobile_upload",
  dashboardSelectScreenshot = "dashboard_select_screenshot",
  dashboardScreenShotCaptured = "dashboard_screenshot_captured",
  dashboardMobileUploadCaptured = "dashboard_mobile_upload_captured",
  dashboardSelectUploadFile = "dashboard_select_upload_file",
  dashboardTextSubmitted = "dashboard_submit_text",
  presessionCameraShutter = "presession_camera_shutter",
  presessionModalSubject = "presession_modal_subject",
  sessionRequestTutor = "session_request_tutor",
  sessionFindingTutor = "session_finding_tutor",
  sessionFoundTutor = "session_found_tutor",
  sessionCancelRequest = "session_cancel_request",
  sessionStarted = "session_started",
  sessionMessageSend = "session_message_send",
  sessionMessageReceived = "session_message_received",
  sessionCameraOpen = "session_camera_open",
  sessionFileUploaded = "session_file_uploaded",
  sessionSendButtonClicked = "session_send_button_clicked",
  sessionMobileUpload = "session_mobile_upload_open",
  sessionMobileUploadCaptured = "session_mobile_upload_captured",
  sessionTutorProfileClicked = "session_tutor_profile_clicked",
  sessionTutorProfileNavigated = "session_tutor_profile_navigated",
  sessionTutorProfileBioFavorited = "session_tutor_profile_bio_favorited",
  sessionTutorProfileBioUnfavorited = "session_tutor_profile_bio_unfavorited",
  sessionScreenshotSelected = "session_screenshot_selected",
  sessionEndRequested = "session_end_requested",
  sessionScreenShotCaptured = "session_screenshot_captured",
  sessionSpeechToTextUsed = "session_speech_to_text_used",
  sessionTextToSpeechUsed = "session_text_to_speech_used",
  imageUploadSubmit = "image_upload_submit",
  imageClickBack = "image_click_back",
  imageClickSelect = "image_click_select",
  translationToggled = "translation_toggled",
  favoriteTutorToggled = "favorite_tutor_toggled",
  sessionRatingSubmitted = "session_rating_submitted",
  sessionAchievementReceived = "session_achievement_received",
  sessionEmotiveReactionSent = "session_emotive_reaction_sent",
  sessionWhiteboardUsed = "session_whiteboard_used",
  sessionWhiteboardConnected = "session_whiteboard_connected",
  sessionWhiteboardConnectionFailed = "session_whiteboard_connection_failed",
  assistmentsProblemInvalid = "assistments_problem_invalid"
}

type AllLogs = PusherLogs | SessionLogs | APILogs | AppEvents | DebugLogs;

export function getLogTagFromEndPoint(endPoint: string) {
  return endPoint
    .split("?")[0]
    .replace(/\//g, "_")
    .replace(/^_|_$|_\d+$/g, "");
}

const Logger = {
  start() {
    if(Config.testing) return;
    LogStart();
  },
  stop() {
    LogBackUp();
    LogClear();
  },
  async getLogInfo() {
    const getDeviceLogInfo = require("types/Device").getLogInfo;
    const getSessionLogInfo = require("models/Session").getLogInfo;
    const getUserLogInfo = require("models/User").getLogInfo;
    return {
      branch: EnvironmentHelper.env,
      ...(await getDeviceLogInfo()),
      ...getUserLogInfo(),
      ...getSessionLogInfo()
    };
  },
  async log(event: AllLogs, params: any = {}) {
    const defaults = await this.getLogInfo();
    LogEvent(event, {
      ...defaults,
      ...params
    });
  },

  logTime: function (
    evt: AllLogs,
    tag: string | Array<string> = "",
    timestamp: Date,
    params: any = {}
  ) {
    const delta = new Date().getTime() - timestamp.getTime();
    this.log(evt, {
      monitor: {
        type: "time",
        value: delta,
        tags: tag
      },
      ...params
    });
  }
};

export default Logger;
