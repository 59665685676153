import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Storage from "helpers/Storage";
import { Tutor } from "types/Tutor";
import { User as UserType } from "models/User";

const STORE_KEY = "yup-user-data";
export const storage = new Storage(STORE_KEY, clearUser);
function clearUser(): void {
  const { store } = require("./index");
  store.dispatch(userSlice.actions.setUser(null));
}

export type StateType = {
  user: UserType | null;
  favoriteTutors: Array<Tutor>;
  suggestedTutors: Array<Tutor>;
};

export const userSlice = createSlice({
  name: "signin",
  initialState: {
    user: null,
    favoriteTutors: [],
    suggestedTutors: []
  } as StateType,
  reducers: {
    setUser: (state, action: PayloadAction<UserType | null>) => {
      if (action.payload) {
        state.user = {
          ...action.payload,
          favorite_tutor_ids:
            state.user?.favorite_tutor_ids ?? action.payload.favorite_tutor_ids
        };
      } else {
        state.user = action.payload;
      }
    },
    setFavoriteTutors: (state, action: PayloadAction<Array<Tutor>>) => {
      state.favoriteTutors = action.payload;
    },
    setSuggestedTutors: (state, action: PayloadAction<Array<Tutor>>) => {
      state.suggestedTutors = action.payload;
    },
    addFavoriteTutor: (state, action: PayloadAction<number>) => {
      const favorites = new Set(
        state.user?.favorite_tutor_ids.concat([action.payload])
      );
      state.user!.favorite_tutor_ids = [...favorites];
    },
    removeFavoriteTutor: (state, action: PayloadAction<number>) => {
      const favorites = state.user!.favorite_tutor_ids.filter(
        (id: number) => id !== action.payload
      );
      state.user!.favorite_tutor_ids = favorites;
    }
  }
});

export default userSlice.reducer;

export const {
  setUser,
  setFavoriteTutors,
  setSuggestedTutors,
  addFavoriteTutor,
  removeFavoriteTutor
} = userSlice.actions;

export function subscriber(state: StateType) {
  if (state.user) {
    storage.setSync(state.user);
  } else if (state.user === null) {
    storage.remove();
  }
}

export async function load() {
  const User = require("models/User").default;
  const user = await storage.get();
  if (user) {
    User.set(user);
  }
}
