import React, { useEffect } from "react";
import { Event } from "events/Event";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Text from "components/Text";
import TextField from "components/TextField";

import SignInWrapper from "../components/SignInWrapper";
import { isEmail, isInaccessibleEmailDomain } from "helpers/UserIdentifier";

export default function Verification() {
  const code = useSelector((state: StoreState) => state.ui.signInCode);
  const verifying = useSelector((state: StoreState) => state.ui.verifyingUser);
  const identifier = useSelector(
    (state: StoreState) => state.ui.signInIdentifier
  );

  const getReturnLabel = () => {
    if (isInaccessibleEmailDomain(identifier)) {
      return `Not ${identifier}?`;
    } else if (isEmail(identifier)) {
      return "Checked your spam folder? Wrong email address?";
    }
    return "Wrong phone number?";
  };

  const onUpdateCode = (code: string) =>
    Event.dispatch("signin_set_code", { code });
  const onClickVideo = Event.dispatcher("signin_open_video");
  const onSubmit = Event.dispatcher("signin_on_verify", { identifier, code });

  useEffect(() => {
    if (code.length === 4) onSubmit();
  }, [code]);

  return (
    <SignInWrapper header="Verify your account">
      <Text>We sent a verification code to</Text>
      <Text style={styles.emphasis}>{identifier}</Text>
      <TextField
        value={code}
        style={styles.textfield}
        autoFocus={true}
        keyboardType="numeric"
        placeholder="Enter code"
        maxLength={4}
        onChangeText={onUpdateCode}
        testID="verify-input"
      />
      <Button
        disabled={code.length !== 4}
        processing={verifying}
        onClick={onSubmit}
        testID="verify-submit"
      >
        Verify
      </Button>
      <Text style={styles.text}>
        {getReturnLabel()}
        <Button testID="back" theme="flat" to="SignIn" replace={true}>
          Go back
        </Button>
      </Text>
      <Button testID="signin-video" theme="flat" onClick={onClickVideo}>
        Trouble logging in? Watch this video
      </Button>
    </SignInWrapper>
  );
}

const styles = StyleSheet.create({
  emphasis: {
    fontWeight: "bold" as "bold",
    ...Spacing.mx1
  },
  textfield: {
    ...Spacing.my3
  },
  text: {
    ...Spacing.my2
  }
});
