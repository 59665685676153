import React, { useRef, useEffect } from "react";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

type TextAreaProps = {
  value: string;
  style?: object;
  placeholder?: string;
  testID?: string;
  onChangeText: (text: string) => void;
};

const PADDING = Sizes["4px"];

export default function TextArea(props: TextAreaProps) {
  const textarea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    handleKeyDown();
  }, []);

  function handleKeyDown() {
    if (textarea.current) {
      textarea.current.style.height = "inherit";
      textarea.current.style.height = `${
        textarea.current.scrollHeight + PADDING
      }px`;
    }
  }

  function onChange() {
    props.onChangeText(textarea.current?.value ?? "");
  }

  return (
    <textarea
      ref={textarea}
      style={{
        borderWidth: 2,
        borderColor: Colors.ui.beige,
        borderRadius: Sizes.borderRadius,
        backgroundColor: Colors.ui.white,
        fontFamily: Typography.primary.regular,
        fontSize: Typography.defaultSize,
        ...Spacing.pa2,
        resize: "none"
      }}
      data-testid={props.testID}
      placeholder={props.placeholder}
      value={props.value}
      onKeyDown={handleKeyDown}
      onChange={onChange}
    />
  );
}
