import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { RootStackScreenProps } from "types";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Colors from "assets/theme/Colors";

import SessionImage from "../components/SessionImage";
import TranscriptContent from "../components/TranscriptContent";
import TranscriptNavigation from "../components/TranscriptNavigation";

export default function Transcript(props: RootStackScreenProps<"Transcript">) {
  const transcripts = useSelector(
    (state: StoreState) => state.sessionHistory.transcripts
  );

  const { height } = useWindowDimensions();
  const transcriptId = Number(props.route.params.id);
  const session = transcripts[transcriptId] ?? null;

  return (
    <View
      style={[styles.wrapper, { minHeight: height, height }]}
      testID="transcript"
    >
      <TranscriptNavigation session={session} />
      <TranscriptContent session={session} />
      <SessionImage />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: Colors.light.background
  }
});
