import Sizes from "./Sizes";

const sizes = [
  0,
  Sizes["4px"],
  Sizes["8px"],
  Sizes["16px"],
  Sizes["32px"],
  Sizes["64px"]
];

const Spacing: { [key: string]: object } = {};

sizes.forEach((spacing, index) => {
  // Margins
  Spacing[`ma${index}`] = { margin: spacing };
  Spacing[`mx${index}`] = { marginHorizontal: spacing };
  Spacing[`my${index}`] = { marginVertical: spacing };
  Spacing[`mt${index}`] = { marginTop: spacing };
  Spacing[`mb${index}`] = { marginBottom: spacing };
  Spacing[`mr${index}`] = { marginRight: spacing };
  Spacing[`ml${index}`] = { marginLeft: spacing };

  // Paddings
  Spacing[`pa${index}`] = { padding: spacing };
  Spacing[`px${index}`] = { paddingHorizontal: spacing };
  Spacing[`py${index}`] = { paddingVertical: spacing };
  Spacing[`pt${index}`] = { paddingTop: spacing };
  Spacing[`pb${index}`] = { paddingBottom: spacing };
  Spacing[`pr${index}`] = { paddingRight: spacing };
  Spacing[`pl${index}`] = { paddingLeft: spacing };
});

export default Spacing;
