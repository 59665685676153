import React from "react";
import { useMediaQuery } from "react-responsive";
import { View, StyleSheet } from "react-native";

import Layout from "assets/theme/Layout";
import Spacing from "assets/theme/Spacing";

import Lottie from "components/Lottie";

type DashboardActionWrapperProps = {
  children: React.ReactNode;
};

export default function DashboardActionWrapper(
  props: DashboardActionWrapperProps
) {
  const isLg = useMediaQuery({ minWidth: Layout.lg });
  const isMd = useMediaQuery({ minWidth: Layout.md });

  return (
    <View style={[styles.page, isMd ? styles.pageLg : {}]}>
      <View style={styles.animation}>
        <Lottie
          animation={require("../assets/girl-desk-cropped.json")}
          height={isLg ? 350 : 250}
          width="100%"
        />
      </View>
      <View>{props.children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexShrink: 1,
    flexDirection: "column",
    ...Spacing.pt4
  },
  pageLg: {
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  animation: {
    alignSelf: "center",
    ...Spacing.ma3
  }
});
