import * as React from "react";
import { Event } from "events/Event";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { PreSessionActionProps } from ".";
import { StyleSheet, View } from "react-native";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Text from "components/Text";
import TextArea from "components/TextArea";

const MINLENGTH = 15;

export function ActionText(props: PreSessionActionProps) {
  const message = useSelector((state: StoreState) => state.ui.sessionMessage);

  const onInput = (message: string) =>
    Event.dispatch("chatroom_message_changed", { message });
  const onSubmit = Event.dispatcher("session_presession_text_submit", {
    message,
    nextNodeId: props.action.on_success_script_node_id
  });

  const charsLeft = Math.max(0, MINLENGTH - message.length);
  return (
    <>
      <Text style={styles.charsLeft}>
        {`Minimum ${MINLENGTH} characters. ${charsLeft} left.`}
      </Text>
      <View style={styles.textarea}>
        <TextArea
          value={message}
          testID="presession-textfield"
          onChangeText={onInput}
        />
      </View>
      <Button
        disabled={message.length < MINLENGTH}
        theme={props.theme}
        testID="presession-textsubmit"
        onClick={onSubmit}
      >
        {props.action.ctas[0]}
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  textarea: {
    ...Spacing.my2
  },
  charsLeft: {
    fontSize: 14
  }
});
