import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import { StyleSheet } from "react-native";

import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Image from "components/Image";
import Text from "components/Text";

export default function SessionFeedbackHeader() {
  const session = useSelector((state: StoreState) => state.session.current);
  const positiveRating = useSelector((state: StoreState) => state.ui.rating);

  if (!session?.tutor) return null;

  const { tutor } = session;
  const header = positiveRating
    ? `Glad you enjoyed your session with ${tutor.profile.polite_name}`
    : `We'll try not to match you with ${tutor.profile.polite_name} again`;
  const prompt = positiveRating ? "What went well?" : "What went wrong?";
  const rating = positiveRating
    ? require("assets/images/thumbs-up.png")
    : require("assets/images/thumbs-down.png");

  return (
    <>
      <Text tag="h2" style={styles.prompt}>
        {header}
      </Text>
      <Image src={rating} style={styles.rating} />
      <Text tag="h2" style={styles.prompt}>
        {prompt}
      </Text>
    </>
  );
}

const styles = StyleSheet.create({
  rating: {
    height: Sizes["128px"],
    width: Sizes["128px"],
    ...Spacing.mt2,
    ...Spacing.mb3
  },
  prompt: {
    textAlign: "center",
    ...Spacing.my3
  }
});
