import React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Image from "components/Image";
import { Tutor, getTutorAvatar } from "types/Tutor";

type TutorProfileImage = {
  tutor: Tutor;
};

export default function TutorProfileImage(props: TutorProfileImage) {
  return (
    <View style={styles.tutorProfile}>
      <Image
        uri={getTutorAvatar(props.tutor.id, props.tutor.gender)}
        style={styles.tutorProfileImage}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  tutorProfile: {
    height: Sizes["128px"] * 1.2,
    width: Sizes["128px"] * 1.2,
    backgroundColor: Colors.ui.beige,
    borderRadius: Sizes["128px"],
    overflow: "hidden",
    ...Spacing.mt2,
    ...Spacing.mb3
  },
  tutorProfileImage: {
    height: Sizes["128px"] * 1.2,
    width: Sizes["128px"] * 1.2
  }
});
