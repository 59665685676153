import * as React from "react";
import { useRoute } from "@react-navigation/native";
import { ScrollView, StyleSheet, SafeAreaView } from "react-native";
import { useThemeColor, ThemeProps } from "../theme";
import GoogleTranslate from "components/GoogleTranslate";

export type ViewProps = ThemeProps & ScrollView["props"];

export default function Page(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );
  const viewStyle = [{ backgroundColor }, styles.page, style];
  const route = useRoute();

  return (
    <SafeAreaView style={viewStyle}>
      <ScrollView style={viewStyle} {...otherProps} testID="page-scroll">
        <GoogleTranslate page={route.name} style={styles.translate} />
        {otherProps.children}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  translate: {
    position: "absolute",
    right: 32,
    top: 16
  },
  page: {
    flex: 1
  }
});
