import { Animated, Easing, Platform } from "react-native";
import Sizes from "assets/theme/Sizes";

export const Size = Sizes["128px"];
export const TutorImageCount = 10;

export class AnimatedHelper {
  animation: Animated.CompositeAnimation | null = null;
  stopped = false;

  start(image: Animated.Value) {
    this.stopped = false;
    this.animate(image);
  }
  stop() {
    this.stopped = true;
    this.animation?.stop();
  }

  animate(image: Animated.Value) {
    const animations: Array<any> = [];
    [...Array(TutorImageCount)].forEach((_, i) => {
      animations.push(
        Animated.timing(image, {
          toValue: -Size / 2 - Size * i * 1.5,
          duration: 800,
          easing: Easing.linear,
          useNativeDriver: Platform.OS !== "web"
        })
      );
      animations.push(Animated.delay(1000));
    });
    animations.push(
      Animated.timing(image, {
        toValue: -(Size * TutorImageCount * 1.5) + Size / 2.5,
        duration: 400,
        useNativeDriver: Platform.OS !== "web"
      })
    );
    animations.push(
      Animated.timing(image, {
        toValue: Size,
        duration: 0,
        useNativeDriver: Platform.OS !== "web"
      })
    );
    this.animation = Animated.loop(Animated.sequence(animations));
    this.animation.start();
  }
}
