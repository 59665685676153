import { AuthHelper } from "@yups/utils";
import { StorageManager } from "helpers/Storage";
import { unloadSounds } from "helpers/Audio";
import Logger from "helpers/Logger";
import RouteController from "navigation/Controller";
import { Pusher } from "./Pusher";

export function cleanup() {
  const { reset } = require("models/UI");
  AuthHelper.clearAuthParams();
  StorageManager.cleanup();
  Logger.stop();
  unloadSounds();
  reset();
  RouteController.deinit();
  Pusher.deinitialize();
}
