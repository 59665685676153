import Logger, { AppEvents } from "helpers/Logger";
import { reportError } from "helpers/Sentry";
import WebService from "helpers/WebService";
import store from "store";
import {
  setAssistmentsProblem,
  setAssistmentsAssignmentId,
  setAssistmentsProblemId,
  setAscendMathProblem,
  resetAssistments
} from "store/Partnerships";
import User from "./User";

export type AssistmentRequestData = {
  problem_ids: Array<string>;
  assignment_id: string;
}
export const Assistments = {
  imageUrl: "https://assistments.s3.us-west-2.amazonaws.com/images/assistments",
  problemUrl: "https://app.assistments.org/api/content/problems",

  async signIn(externalReference: string) {
    const response = await WebService.signInByAssistments(externalReference);
    if (response.success) User.set(response.data.user);
    return response;
  },

  async getProblem(params: {
    assistmentId?: string;
    problemId?: string;
    assignmentId?: string;
  }) {
    const url = `${this.problemUrl}?xrefs=${params.assistmentId || params.problemId}`;
    try {
      await WebService.publishAssistmentProblem(url);
      const response = await fetch(url);
      const problemData = await response.json();
      if (response.status === 200) {
        store.dispatch(setAssistmentsAssignmentId(params.assignmentId ?? ""));
        store.dispatch(setAssistmentsProblemId(params.problemId ?? ""));
        store.dispatch(
          setAssistmentsProblem(
            problemData[0]?.body.replace(
              /\/images\/assistments/g,
              this.imageUrl
            )
          )
        );
      } else {
        throw new Error(`Assistments problem not found`);
      }
    } catch {
      Logger.log(AppEvents.assistmentsProblemInvalid, { problemSetUrl: url });
    }
  },
  getRequestData() : AssistmentRequestData | undefined {
    const problemId = store.getState().partnerships.assistmentsProblemId;
    const assignmentId = store.getState().partnerships.assistmentsAssignmentId;
    if(!problemId && !assignmentId) return;

    return {
      problem_ids: [problemId],
      assignment_id: assignmentId
    }
  },
  reset() {
    store.dispatch(resetAssistments());
  }
};

export const AscendMath = {
  problemUrl: __DEV__
    ? "https://student.yup.com/images/ascendmath"
    : "/images/ascendmath",

  async signIn(externalReference: string) {
    const response = await WebService.signInByAscendMath(externalReference);
    if (response.success) User.set(response.data.user);
    return response;
  },

  async getProblem(params: { problemId: string }) {
    try {
      const response = await fetch(`${this.problemUrl}/${params.problemId}`);
      const image = await response.text();
      if (response.status === 200) {
        store.dispatch(setAscendMathProblem(`data:image/png;base64,${image}`));
      } else {
        throw new Error(`Ascend Math problem not found: ${params.problemId}`);
      }
    } catch (error) {
      reportError(error);
    }
  }
};
