import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";

import { ExtensionUI, SessionUI } from "models/UI";

import ChatRoomController from "./ChatRoomController";
import ExtensionController from "./ExtensionController";
import Session, {
  closeSession,
  EndedActions,
  sessionIsInReview,
  sessionIsReady,
  sessionRequiresRating
} from "models/Session";
import User from "models/User";
import { AppState, NativeEventSubscription } from "react-native";
import { getImageFromUrl } from "helpers/UploadImage";
import { playSound, Sound } from "helpers/Audio";
import { MessageSender } from "components/ChatRoom/types/Message";
import SessionMessages from "models/SessionMessages";

class SessionController extends Controller {
  init() {
    Event.on("session_presession_button_click", sendBotScriptResponse);
    Event.on("session_presession_camera_click", onPreSessionCameraClick);
    Event.on("session_presession_text_submit", sendBotScriptResponse);
    Event.on("session_presession_finish", endBotScript);

    Event.on("whiteboard_on_draw", onStartedDrawing);
    Event.on("session_tutor_found", onTutorFound);

    Event.on("session_on_show_tutor_profile", showTutorProfile);
    Event.on("session_dismiss_tutor_profile", dismissTutorProfile);
    Event.on("session_on_favorite_tutor", onAddTutorToFavorites);
    Event.on("session_on_unfavorite_tutor", onRemoveTutorFromFavorites);
    Event.on("session_on_navigate_tutor_detail", onTutorProfileNavigate);
    Event.on("session_on_favorite_tutor_detail", onLikeDetail);
    Event.on("session_on_unfavorite_tutor_detail", onUnlikeDetail);

    Event.on("session_set_preview_image", setGalleryPreviewImage);
    Event.on("session_dismiss_image_gallery", dismissImageGallery);
    Event.on("session_draw_on_image", onDraw);

    Event.on("mobile_upload_submitted", onMobileUpload);
    Event.on("mobile_upload_dismissed", dismissMobileUpload);
    Event.on("capture_overlay_dismissed", onDismissCapturePrompt);
    Event.on("session_dismiss_extension_error", dismissExtensionError);

    Event.on("equation_submitted", onSubmitEquation);
    Event.on("equation_dismissed", dismissEquationEditor);

    Event.on("session_dismiss_error", dismissError);

    Event.on("session_show_end_session", showEndSession);
    Event.on("session_dismiss_end_session", dismissEndSession);
    Event.on("session_set_cancel_reason", onSetCancelReason);
    Event.on("session_end_session", endSession);
    Event.on("session_ended", onSessionEnded);
    Event.on("session_dismissed", onSessionDismissed);

    onLoad();
    ExtensionController.init();
    ChatRoomController.init();
  }
  deinit() {
    Event.remove("session_presession_button_click", sendBotScriptResponse);
    Event.remove("session_presession_camera_click", onPreSessionCameraClick);
    Event.remove("session_presession_text_submit", sendBotScriptResponse);
    Event.remove("session_presession_finish", endBotScript);

    Event.remove("whiteboard_on_draw", onStartedDrawing);
    Event.remove("session_tutor_found", onTutorFound);

    Event.remove("session_on_show_tutor_profile", showTutorProfile);
    Event.remove("session_dismiss_tutor_profile", dismissTutorProfile);
    Event.remove("session_on_favorite_tutor", onAddTutorToFavorites);
    Event.remove("session_on_unfavorite_tutor", onRemoveTutorFromFavorites);
    Event.remove("session_on_navigate_tutor_detail", onTutorProfileNavigate);
    Event.remove("session_on_favorite_tutor_detail", onLikeDetail);
    Event.remove("session_on_unfavorite_tutor_detail", onUnlikeDetail);

    Event.remove("session_set_preview_image", setGalleryPreviewImage);
    Event.remove("session_dismiss_image_gallery", dismissImageGallery);
    Event.remove("session_draw_on_image", onDraw);

    Event.remove("mobile_upload_submitted", onMobileUpload);
    Event.remove("mobile_upload_dismissed", dismissMobileUpload);
    Event.remove("capture_overlay_dismissed", onDismissCapturePrompt);
    Event.remove("session_dismiss_extension_error", dismissExtensionError);

    Event.remove("equation_submitted", onSubmitEquation);
    Event.remove("equation_dismissed", dismissEquationEditor);

    Event.remove("session_dismiss_error", dismissError);

    Event.remove("session_show_end_session", showEndSession);
    Event.remove("session_dismiss_end_session", dismissEndSession);
    Event.remove("session_set_cancel_reason", onSetCancelReason);
    Event.remove("session_end_session", endSession);
    Event.remove("session_ended", onSessionEnded);
    Event.remove("session_dismissed", onSessionDismissed);

    onUnload();
    ExtensionController.deinit();
    ChatRoomController.deinit();
  }
}

const controller = new SessionController();
export default controller;

let listener : NativeEventSubscription;
async function onLoad() {
  const session = Session.get();
  if (!User.get()) goToSignIn();
  else if (!session || (session.ended_action && !sessionRequiresRating(session)))
    goToDashboard();
  else if (sessionRequiresRating(session)) goToRating();
  else if (sessionIsInReview(session))
    SessionUI.startSessionCountdown(session.claimed_at!);
  else await setupSession();
}

function onUnload() {
  listener?.remove();
  SessionUI.reset();
}

async function setupSession() {
  const session = Session.get();
  listener = AppState.addEventListener("change", SessionUI.onStateChange);
  User.onUnauthorized(goToSignIn);

  SessionUI.startBotScript(session!);
  await Session.startSessionListeners();
}

async function sendBotScriptResponse({
  message,
  nextNodeId
}: {
  message: string;
  nextNodeId: string;
}) {
  if (message) {
    SessionMessages.sendMessage(
      message,
      MessageSender.student,
      false,
      MessageSender.bot
    );
    SessionUI.setMessage("");
  }
  await SessionUI.updateBotScript(nextNodeId);
}

function endBotScript() {
  SessionUI.endBotScript();
}

function onPreSessionCameraClick(props: {
  message: string;
  nextNodeId: string;
}) {
  sendBotScriptResponse(props);
  goToCamera();
}

function goToCamera() {
  SessionUI.onTakePicture();
  Router.replace("SessionCamera");
}

function showTutorProfile() {
  const session = Session.get();
  if (!session?.tutor) return;
  else if (sessionIsReady(session)) SessionUI.setShowTutorProfile(true);
  else SessionUI.setShowTutorProfileModal(true);
}
function dismissTutorProfile() {
  SessionUI.setShowTutorProfile(false);
  SessionUI.setShowTutorProfileModal(false);
}
async function onAddTutorToFavorites() {
  const session = Session.get();
  if (!session?.tutor) return;
  await User.addFavoriteTutor(
    session.tutor.id,
    "session",
    session.tutor.profile.polite_name
  );
}
async function onRemoveTutorFromFavorites() {
  const session = Session.get();
  if (!session?.tutor) return;
  await User.removeFavoriteTutor(
    session.tutor.id,
    "session",
    session.tutor.profile.polite_name
  );
}
async function onLikeDetail({
  name,
  answer
}: {
  name: string;
  answer: string;
}) {
  const { success, message } = await Session.onLikeDetail(name, answer);
  if (!success) {
    SessionUI.setError(message ?? "");
  }
}
async function onUnlikeDetail({
  name,
  answer
}: {
  name: string;
  answer: string;
}) {
  const { success, message } = await Session.onUnlikeDetail(name, answer);
  if (!success) {
    SessionUI.setError(message ?? "");
  }
}
function onTutorProfileNavigate({ index }: { index: number }) {
  SessionUI.setTutorProfileIndex(index);
}

function setGalleryPreviewImage({ url }: { url: string }) {
  SessionUI.setGalleryPreviewImage(url, "gallery");
}
function dismissImageGallery() {
  SessionUI.setShowImageGallery(false);
}
async function onDraw({ url }: { url: string }) {
  const photo = await getImageFromUrl(url);
  if (photo) {
    SessionUI.setPhoto(photo);
    Router.replace("SessionScratchBoard");
  }
  dismissImageGallery();
}

function showEndSession() {
  SessionUI.setShowEndSession(true);
}

function dismissEndSession() {
  SessionUI.setShowEndSession(false);
}

function onSetCancelReason({ reason }: { reason: number }) {
  SessionUI.setCancelReason(reason);
}

async function endSession({
  reason,
  EndedAction
}: {
  reason: number;
  EndedAction: EndedActions;
}) {
  SessionUI.setShowEndSession(false);
  SessionUI.setEndingSession(true);
  const { success, message } = await Session.endSession(reason, EndedAction);
  if (success) onSessionDismissed();
  else if (message) SessionUI.setError(message);
  SessionUI.setEndingSession(false);
}
function onSessionEnded() {
  const session = Session.get();
  const studentEndedActionsReasons = [
    EndedActions.StudentCancelled,
    EndedActions.StudentEnded
  ];
  if (!studentEndedActionsReasons.includes(session!.ended_action as EndedActions)) {
    playSound(Sound.sessionEnded);
  }
}
function onSessionDismissed() {
  const session = Session.get();
  if (sessionRequiresRating(session!)) {
    goToRating();
  } else {
    closeSession();
    SessionUI.reset();
    User.sync();
    goToDashboard();
  }
}

function onStartedDrawing() {
  Session.onStartedDrawing();
}

function dismissMobileUpload() {
  SessionUI.setShowMobileUpload(false);
}

function onMobileUpload({ image }: { image: string }) {
  SessionUI.setShowMobileUpload(false);
  SessionUI.onMobileUpload();
  SessionUI.setPhoto(image);
  Router.replace("SessionScratchBoard");
}
function onDismissCapturePrompt() {
  ExtensionUI.setShowCapturePrompt(false);
}

function dismissEquationEditor() {
  SessionUI.setShowEquationEditor(false);
}

function onSubmitEquation({ equation }: { equation: string }) {
  SessionMessages.sendMessage(`<latex>${equation}</latex>`);
  dismissEquationEditor();
}

function dismissError() {
  SessionUI.setError("");
}

function dismissExtensionError() {
  ExtensionUI.setShowExtensionError(false);
}

function goToDashboard() {
  Router.replace("Dashboard");
}

function goToRating() {
  Router.replace("SessionRating");
}

function goToSignIn() {
  Router.replace("SignIn");
}

function onTutorFound() {
  const session = Session.get();
  SessionUI.setShowTutorProfileModal(true);
  SessionUI.startSessionCountdown(
    session!.claimed_at ?? new Date().getTime() / 1000
  );
  playSound(Sound.tutorFound);
}
