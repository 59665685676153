import React from "react";
import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import { HeartIcon } from "components/Icons";
import Text from "components/Text";

import TutorProfileImage from "components/TutorProfileImage";

export default function SessionRatingTutor() {
  const session = useSelector((state: StoreState) => state.session.current);
  const user = useSelector((state: StoreState) => state.user.user);

  if (!session?.tutor || !user) return null;

  const isFavoriteTutor = user.favorite_tutor_ids.includes(session.tutor.id);
  const thumbsUpPercentage = `${(
    session.tutor.profile.thumbs_up_ratio * 100
  ).toFixed()}%`;

  return (
    <>
      <TutorProfileImage tutor={session.tutor} />
      <View style={styles.rating}>
        {isFavoriteTutor ? (
          <>
            <HeartIcon height={24} width={24} color={Colors.ui.green} />
            <Text style={styles.ratingText}>Favorite</Text>
          </>
        ) : (
          <>
            <Text>👍</Text>
            <Text style={styles.ratingText}>{thumbsUpPercentage}</Text>
          </>
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  rating: {
    backgroundColor: Colors.ui.white,
    borderRadius: Sizes["24px"],
    marginTop: -Sizes["32px"],
    borderWidth: 2,
    zIndex: 1,
    borderColor: Colors.brand.green,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.px3,
    ...Spacing.py1
  },
  ratingText: {
    color: Colors.brand.green,
    fontWeight: "bold",
    ...Spacing.ml2
  }
});
