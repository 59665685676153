import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

import { SessionUI } from "models/UI";

import ExtensionController from "./ExtensionController";
import store from "store";
import SessionMessages from "models/SessionMessages";

class SessionScratchboardController extends Controller {
  init() {
    Event.on("scratchboard_exit", onExitScratchboard);
    Event.on("session_scratchboard_submit", onSubmitPhoto);

    ExtensionController.init();
    onLoad();
  }
  deinit() {
    Event.remove("scratchboard_exit", onExitScratchboard);
    Event.remove("session_scratchboard_submit", onSubmitPhoto);

    ExtensionController.deinit();
  }
}

const controller = new SessionScratchboardController();
export default controller;

function onLoad() {
  if (!store.getState().ui.photo) Router.replace("Session");
}

function onExitScratchboard() {
  SessionUI.setPhoto("");
  Router.replace("Session");
}

async function onSubmitPhoto({ image }: { image: string }) {
  SessionUI.onSubmitPhoto();
  await SessionMessages.sendImage(image);
  Router.replace("Session");
}
