import React, { useEffect, useState } from "react";
import { Event } from "events/Event";
import { WhiteboardAction } from "./types/WhiteboardAction";
import { Whiteboard } from "models/Whiteboard";
import { ZwibblerComponent } from "./WhiteboardService/ZwibblerComponent";
import { View, StyleSheet } from "react-native";
import Toolbar from "./Toolbar";
import Colors from "assets/theme/Colors";
import Spinner from "components/Progress/Spinner";
import { WhiteboardConnectionState } from "store/Whiteboard";
import Text from "components/Text";
import Spacing from "assets/theme/Spacing";

const CURSOR =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAhUlEQVRIx+2TOwqAMBAFx8v5v4ReQwtvYO8dUxsL0SaBENTCrIqQBw9SzSzLBmJeSA4oYAQSaXgGzMBmOklLegduO0qAS6A178ETKAm4BtYDyWzWdjuFgdtpXUknDXclTeha/Gtxq4Eqwv8FT7+E1yFwLuDBP9TmscnPBIsk3BeIw2MOswPW6WxowEPwSAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNS0yNVQyMDowMDozNyswMDowMBhlktkAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDUtMjVUMjA6MDA6MzcrMDA6MDBpOCplAAAAAElFTkSuQmCC";

type WhiteboardProps = {
  ready: boolean;
  connectionState: WhiteboardConnectionState;
};

async function loadZwibbler() {
  return import(
    /* webpackChunkName: 'zwibbler' */ "./WhiteboardService/zwibbler2"
  );
}

export default function WhiteboardComponent(props: WhiteboardProps) {
  const [showWhiteboard, setShowWhiteboard] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const isConnected =
    props.connectionState === WhiteboardConnectionState.connected;
  const isReconnecting =
    props.connectionState === WhiteboardConnectionState.reconnecting;

  useEffect(() => {
    Whiteboard.onAttached(handleAttached);
    // Zwibbler tends to break on page refreshes if Zwibbler hasn't been initialized,
    // so defer setting up context until after the library has been imported
    loadZwibbler().then(() => setShowWhiteboard(true));

    const styleTag = (window as any).document.createElement("style");
    styleTag.type = "text/css";
    styleTag.innerHTML = `
      .zwibbler-canvas-holder {
        cursor: url("${CURSOR}") 2 20, auto !important;
      }
      iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
        display: none;
      }
    `;
    (window as any).document.head.appendChild(styleTag);

    return () => Whiteboard.deinit();
  }, []);

  useEffect(() => {
    if (props.ready) {
      try {
        // Zwibbler throws if it hasn't been initialized yet
        Whiteboard.joinSession();
      } catch {}
    }
  }, [props.ready]);

  const handleDocumentChange = () => {
    setCanUndo(Whiteboard.context?.canUndo() || false);
    setCanRedo(Whiteboard.context?.canRedo() || false);
  };

  function onAction(action: WhiteboardAction, args?: object) {
    Whiteboard.execute(action, [args]);
  }

  function handleAttached() {
    try {
      // Zwibbler throws if it hasn't been initialized yet
      Whiteboard.init();
      Whiteboard.onDraw(Event.dispatcher("whiteboard_on_draw"));
      Whiteboard.onChange(handleDocumentChange);
      Whiteboard.joinSession();
    } catch {}
  }

  if (!showWhiteboard || !props.ready) return null;

  return (
    <>
      {isReconnecting && (
        <View style={styles.connecting}>
          <Spinner color={Colors.grey.slate} />
          <Text style={styles.connectingText}>
            Connecting to the tutor's whiteboard
          </Text>
        </View>
      )}
      <View
        style={[styles.whiteboard, { display: isConnected ? "flex" : "none" }]}
      >
        <ZwibblerComponent />
        <Toolbar canUndo={canUndo} canRedo={canRedo} onAction={onAction} />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  whiteboard: {
    backgroundColor: Colors.grey.inactive,
    flex: 1
  },
  connecting: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.ui.white
  },
  connectingText: {
    color: Colors.grey.slate,
    ...Spacing.mx3
  }
});
