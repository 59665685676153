import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import { EditableMathField, MathField } from "react-mathquill";
import { StyleSheet, ScrollView } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Sizes from "assets/theme/Sizes";

type EquationEditorProps = {
  latex: string;
  onChange: (latex: string) => void;
};

export interface EquationEditor {
  append: (symbol: string) => void;
}

const MQConfig = {
  spaceBehavesLikeTab: true
};

export default forwardRef<EquationEditor, EquationEditorProps>((props, ref) => {
  const [focused, setFocused] = useState(false);
  const mathField = useRef<MathField | null>(null);
  const onLoad = (field: MathField) => {
    mathField.current = field;
    onFocus();
  };
  const onFocus = () => mathField.current?.focus();
  const onChange = (field: MathField) => props.onChange(field.latex());

  useImperativeHandle(ref, () => ({
    get append() {
      return (symbol: string) => {
        if (symbol.startsWith("\\")) {
          mathField.current?.cmd(symbol);
        } else {
          mathField.current?.typedText(symbol);
        }
        onFocus();
      };
    }
  }));

  useEffect(() => {
    onFocus();
  }, []);

  return (
    <ScrollView style={[styles.wrapper, focused && styles.focused]}>
      <EditableMathField
        style={editorStyle}
        latex={props.latex}
        config={MQConfig}
        onChange={onChange}
        mathquillDidMount={onLoad}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </ScrollView>
  );
});

const editorStyle = {
  fontSize: 24,
  borderWidth: 0,
  textAlign: "center" as "center",
  outline: 0,
  boxShadow: "none",
  ...Spacing.pa2
};

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 2,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    maxHeight: 200,
    ...Spacing.my2
  },
  focused: {
    borderColor: Colors.ui.blue
  }
});
