import React, { useState, useRef } from "react";
import {
  StyleSheet,
  View,
  Platform,
  GestureResponderEvent,
  useWindowDimensions
} from "react-native";
import Canvas from "react-native-canvas";
import { LinearGradient } from "expo-linear-gradient";

import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { XIcon, LogoIcon, UndoIcon } from "components/Icons";
import Spinner from "components/Progress/Spinner";

import ScratchboardClass from "models/ScratchBoard";
import { BackgroundStyle } from "models/ScratchBoard/types/Scratchboard";
import Colors from "assets/theme/Colors";
import Text from "components/Text";
import Typography from "assets/theme/Typography";
import ScratchBoardClass from "models/ScratchBoard";
import { Event } from "events/Event";

type ScratchBoardProps = {
  photo: string;
  submitText: string;
  backgroundStyle?: BackgroundStyle;
  onSubmit: (scratchboard: ScratchBoardClass) => void;
  processing?: boolean;
};

let scratchBoard: ScratchboardClass;
export default function ScratchBoard(props: ScratchBoardProps) {
  const didInit = useRef(false);
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [drawing, setDrawing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const onBack = Event.dispatcher("scratchboard_exit");
  const onSubmit = () => props.onSubmit(scratchBoard);

  function handleCanvas(canvas: Canvas) {
    if ((canvas && !didInit.current) || !scratchBoard) {
      let backgroundStyle = props.backgroundStyle;
      if (!backgroundStyle) {
        backgroundStyle = BackgroundStyle.contain;
      }
      scratchBoard = new ScratchboardClass(
        canvas,
        {
          backgroundImg: props.photo,
          backgroundStyle
        },
        () => {
          didInit.current = true;
          setLoading(false);
        }
      );
    }
  }

  function startResponder(event: GestureResponderEvent) {
    setDrawing(true);
    setIsDirty(true);
    scratchBoard.startDrawing(
      event.nativeEvent.locationX,
      event.nativeEvent.locationY
    );
  }

  function onResponderMove(event: GestureResponderEvent) {
    if (drawing) {
      scratchBoard.draw(event.nativeEvent.locationX, event.nativeEvent.locationY);
    }
  }

  function stopResponder() {
    setDrawing(false);
    scratchBoard.stopDrawing();
  }

  function onUndo() {
    scratchBoard.undo();
    setIsDirty(scratchBoard.isDirty);
  }

  return (
    <View
      style={styles.container}
      onStartShouldSetResponder={() => true}
      onMoveShouldSetResponder={() => drawing}
      onResponderStart={startResponder}
      onResponderRelease={stopResponder}
      onResponderMove={onResponderMove}
      testID="scratchboard"
    >
      <LinearGradient
        // Background Linear Gradient
        style={[styles.overlay, { width, height: height / 4 }]}
        pointerEvents="none"
        colors={["rgba(0,0,0,0.2)", "transparent"]}
      />
      {loading && (
        <View style={[styles.loading, { width, height }]} testID="scratchboard-loading">
          <Spinner
            size={72}
            color={Colors.ui.white}
            backgroundColor="transparent"
          />
        </View>
      )}
      {Platform.OS === "web" ? (
        <canvas width="100vw" height="100vh" ref={handleCanvas} />
      ) : (
          <Canvas style={styles.canvas} ref={handleCanvas} />
        )}

      {!drawing && (
        <>
          <View style={[styles.row, styles.topBar]}>
            <Button
              theme="flat"
              round={true}
              testID="scratchboard-cancel"
              onClick={onBack}
            >
              <XIcon color={Colors.ui.white} height={24} width={24} />
            </Button>
            <View>
              <View style={styles.logoContainer}>
                <LogoIcon color={Colors.ui.white} height={32} width={100} />
              </View>
              {!isDirty && !loading && (
                <View style={styles.prompt}>
                  <Text>Touch to draw</Text>
                </View>
              )}
            </View>
            <View>
              {isDirty && (
                <Button theme="flat" onClick={onUndo}>
                  <View style={styles.undoButton}>
                    <UndoIcon color={Colors.ui.white} height={24} width={24} />
                    <Text style={styles.undoText}>Undo</Text>
                  </View>
                </Button>
              )}
            </View>
          </View>
          <View style={[styles.row, styles.buttonContainer]}>
            <Button
              style={styles.pictureButton}
              processing={props.processing}
              testID="scratchboard-submit"
              onClick={onSubmit}
            >
              {props.submitText}
            </Button>
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  overlay: {
    position: "absolute",
    zIndex: 2
  },
  canvas: {
    flex: 1
  },
  topBar: {
    zIndex: 200,
    ...Spacing.mt3
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    ...Spacing.pa4
  },
  backIcon: {
    width: Sizes["24px"],
    height: Sizes["24px"]
  },
  buttonContainer: {
    position: "absolute",
    bottom: Sizes["16px"],
    justifyContent: "center"
  },
  pictureButton: {
    ...Spacing.px4
  },
  logoContainer: {
    marginHorizontal: "auto"
  },
  prompt: {
    backgroundColor: Colors.grey.concrete,
    borderRadius: Sizes["24px"],
    ...Spacing.mt3,
    ...Spacing.px2,
    ...Spacing.py1
  },
  loading: {
    backgroundColor: Colors.grey.iron,
    opacity: 0.8,
    position: "absolute",
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  undoButton: {
    display: "flex",
    flexDirection: "row"
  },
  undoText: {
    color: Colors.ui.white,
    ...Typography.accent.small,
    ...Spacing.ml2
  }
});
