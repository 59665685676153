import { EnvironmentHelper, LocalStorageHelper } from "@yups/utils";
import { Platform } from "react-native";
import { loadUUIDGenerator } from "./uuid";
import { syncTime } from "models/ServerTime";
import { loadState } from "store";
import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import Logger, { AppEvents } from "helpers/Logger";
import Config from "./Config";
import { loadSentry } from "helpers/Sentry";
import { loadSounds } from "helpers/Audio";
import { loadUsersnap } from "helpers/Usersnap";
import RouteController from "navigation/Controller";
import { loadFeatures } from "./FeatureDecision";
import { loadYupAPI } from "./YupAPI";
import User from "models/User";

function loadRouter() {
  RouteController.init();
}

async function loadStoredEnvVariable() {
  return (await LocalStorageHelper.get("yup-env")) ?? Config.env;
}

async function loadEnvironment() {
  if (Platform.OS === "web") {
    await EnvironmentHelper.init();
  } else {
    await EnvironmentHelper.init(await loadStoredEnvVariable());
  }
}

async function loadData() {
  const { GeneralUI } = require("models/UI");
  await loadState();
  GeneralUI.setEnvironment(await loadStoredEnvVariable());
}

async function loadLogger() {
  Logger.start();
}

async function loadTime() {
  await syncTime();
}

function loadMathJax() {
  if (Platform.OS === "web") {
    const { addStyles } = require("react-mathquill");
    addStyles();
  }
}

async function loadFonts() {
  await Font.loadAsync({
    ...FontAwesome.font,
    "post-grotesk-medium": require("../assets/fonts/postgrotesk-medium.ttf"),
    "post-grotesk-book": require("../assets/fonts/postgrotesk-book.ttf"),
    "post-grotesk-bold": require("../assets/fonts/postgrotesk-bold.ttf"),
    "euclid-circular-a": require("../assets/fonts/euclidcirculara-semibold.ttf")
  });
}

async function loadUser(){
  await User.sync()
}

export async function load(onProgress: (progress: number) => void) {
  let error = null;

  const loadFunctions = [
    loadUUIDGenerator,
    loadEnvironment,
    loadYupAPI,
    loadFeatures,
    loadData,
    loadLogger,
    loadSentry,
    loadUsersnap,
    loadTime,
    loadSounds,
    loadFonts,
    loadMathJax,
    loadUser,
    loadRouter
  ];
  const progressStep = 100 / loadFunctions.length;

  for (let i = 0; i < loadFunctions.length; ++i) {
    try {
      await loadFunctions[i]();
    } catch (e) {
      error = e;
    } finally {
      onProgress(progressStep * (i + 1));
    }
  }
  if (error) throw error;

  Logger.log(AppEvents.appLoaded);
}
