import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";

import { ExtensionUI, SessionUI } from "models/UI";

import Session from "models/Session";
import SessionMessages, { getSessionImages } from "models/SessionMessages";
import { openImageSelector } from "helpers/UploadImage";
import { InputButtonType } from "components/ChatRoom/types/MessageInputButton";
import { Extension } from "helpers/ChromeExtension";
import { MessageReaction } from "components/ChatRoom/types/Message";
import { playSound, Sound } from "helpers/Audio";
import { Activity } from "components/ChatRoom/types/Activity";
import store from "store";

class ChatRoomController extends Controller {
  init() {
    Event.on("chatroom_message_changed", setMessage);
    Event.on("chatroom_button_clicked", onButtonAction);
    Event.on("chatroom_message_sent", sendMessage);
    Event.on("chatroom_reaction_sent", onMessageReaction);
    Event.on("chatroom_image_clicked", onImageClicked);
    Event.on("chatroom_message_received", onMessageReceived);
    Event.on("chatroom_tutor_activity", onTutorActivity);
  }
  deinit() {
    Event.remove("chatroom_message_changed", setMessage);
    Event.remove("chatroom_button_clicked", onButtonAction);
    Event.remove("chatroom_message_sent", sendMessage);
    Event.remove("chatroom_reaction_sent", onMessageReaction);
    Event.remove("chatroom_image_clicked", onImageClicked);
    Event.remove("chatroom_message_received", onMessageReceived);
    Event.remove("chatroom_tutor_activity", onTutorActivity);
  }
}

const controller = new ChatRoomController();
export default controller;

function setMessage({ message }: { message: string }) {
  SessionUI.setMessage(message);
  Session.onStartedTyping();
}

function sendMessage({ message }: { message: string }) {
  SessionMessages.sendMessage(message);
  SessionUI.setMessage("");
}

async function onButtonAction({ buttonType }: { buttonType: InputButtonType }) {
  switch (buttonType) {
    case InputButtonType.Camera:
      onCamera();
      break;
    case InputButtonType.FileUpload:
      await onImageUpload();
      break;
    case InputButtonType.Send:
      onSend();
      break;
    case InputButtonType.SessionImage:
      onUseImageFromSession();
      break;
    case InputButtonType.Screenshot:
      await onAttemptScreenshot();
      break;
    case InputButtonType.MobileUpload:
      onUseMobileUpload();
      break;
    case InputButtonType.Equation:
      onUseEquation();
      break;
  }
}

function onCamera() {
  SessionUI.onTakePicture();
  Router.replace("SessionCamera");
}

async function onImageUpload() {
  try {
    SessionUI.onImageUpload();
    const photo = await openImageSelector();
    if (photo) {
      SessionUI.setPhoto(photo);
      Router.replace("SessionScratchBoard");
    }
  } catch (e) {
    SessionUI.setError(e.message);
  }
}

function onSend() {
  SessionUI.onSendButtonClicked();
}

function onUseImageFromSession() {
  const image =
    store.getState().ui.galleryPreviewImage ||
    getSessionImages(Session.getMessages())[0];
  SessionUI.setGalleryPreviewImage(image, "default");
  SessionUI.setShowImageGallery(true);
}

async function onAttemptScreenshot() {
  SessionUI.onScreenshotSelected();
  if (await Extension.isInstalled()) {
    ExtensionUI.setShowExtensionError(false);
    ExtensionUI.setShowCapturePrompt(true);
  } else {
    ExtensionUI.setShowExtensionError(true);
  }
}

function onUseMobileUpload() {
  SessionUI.setShowMobileUpload(true);
}

function onUseEquation() {
  SessionUI.setShowEquationEditor(true);
}

async function onMessageReaction({
  key,
  reaction
}: {
  key: string;
  reaction: MessageReaction;
}) {
  await SessionMessages.sendReaction(key, reaction);
}

function onImageClicked({ image }: { image: string }) {
  SessionUI.setGalleryPreviewImage(image, "session");
  SessionUI.setShowImageGallery(true);
}

function onMessageReceived() {
  SessionUI.setTutorActivity(null);
  playSound(Sound.incomingMessage);
}

function onTutorActivity({ activity }: { activity: Activity }) {
  SessionUI.setTutorActivity(activity);
}
