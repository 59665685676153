import { Event } from "events/Event";
import { Controller } from "types";

import { PartnershipsUI } from "models/UI";

import ProblemCaptureController from "./ProblemCaptureController";
import { Assistments } from "models/Partnerships";
import { Router } from "navigation/Router";

class AssistmentsController extends Controller {
  init() {
    Event.on("partnerships_on_reload", onLoad);

    ProblemCaptureController.init();

    onLoad();
  }
  deinit() {
    Event.remove("partnerships_on_reload", onLoad);

    ProblemCaptureController.deinit();
  }
}

const controller = new AssistmentsController();
export default controller;

function onLoad() {
  Promise.all([signInWithAssistments(), loadAssistmentProblem()]);
}

async function loadAssistmentProblem() {
  const route = Router.getCurrent();
  if (!route) return;
  const qParams = route.params ?? ({} as any);
  if (!qParams.assistmentId && !qParams.problemId) {
    Router.navigate("Dashboard");
    return;
  }

  PartnershipsUI.setLoadingProblem(true);
  await Assistments.getProblem(qParams);
  PartnershipsUI.setLoadingProblem(false);
}

async function signInWithAssistments() {
  const route = Router.getCurrent();
  if (!route) return;

  PartnershipsUI.setSigningIn(true);
  const qParams = route.params ?? { studentId: "" };
  const { success } = await Assistments.signIn((qParams as any).studentId);
  PartnershipsUI.setSignInSuccess(success);
  PartnershipsUI.setSigningIn(false);
}
