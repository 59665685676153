import React, { useEffect, useRef } from "react";
import {
  View,
  StyleSheet,
  Animated,
  Easing,
  TouchableOpacity,
  useWindowDimensions,
  Platform
} from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import { sessionIsReady } from "models/Session";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Text from "components/Text";
import Typography from "assets/theme/Typography";
import Spacing from "assets/theme/Spacing";

import ProgressBar from "components/Progress/ProgressBar";
import FindingTutorAnimation from "./FindingTutorAnimation";
import { Event } from "events/Event";
import Config from "helpers/Config";

const FINDING_TUTOR_MAX_PROGRESS = 50;
const FINDING_TUTOR_DURATION = 120000;
const TUTOR_REVIEWING_MAX_PROGRESS = 99;
const TUTOR_REVIEWING_DURATION = 40000;
const TUTOR_READY_MAX_PROGRESS = 100;
const TUTOR_READY_DURATION = 1000;

export default function FindingTutor() {
  const session = useSelector((state: StoreState) => state.session.current);
  const sessionStartCountdown = useSelector(
    (state: StoreState) => state.ui.sessionStartCountdown
  );
  const { width, height } = useWindowDimensions();
  const onClick = Event.dispatcher("session_on_show_tutor_profile");

  const ready = sessionIsReady(session!);
  const slideAnim = useRef(new Animated.Value(ready ? -height : 0)).current;

  useEffect(() => {
    if (ready && !Config.testing) {
      Animated.timing(slideAnim, {
        toValue: -height,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== "web",
        delay: 1500
      }).start();
    }
  }, [ready]);

  function getStatusInfo() {
    if (!session!.tutor) {
      return {
        duration: FINDING_TUTOR_DURATION,
        maxProgress: FINDING_TUTOR_MAX_PROGRESS,
        header: "Finding tutor",
        subheader: "Less than a minute...",
        testID: "finding-tutor",
        progressColor: Colors.ui.blue
      };
    } else if (!ready) {
      return {
        duration: TUTOR_REVIEWING_DURATION,
        maxProgress: TUTOR_REVIEWING_MAX_PROGRESS,
        header: `${session!.tutor.profile.polite_name} is reviewing`,
        subheader: `Starting in ${sessionStartCountdown} ${sessionStartCountdown === 1 ? "second" : "seconds"
          }`,
        testID: "tutor-reviewing",
        progressColor: Colors.ui.blue
      };
    } else {
      return {
        duration: TUTOR_READY_DURATION,
        maxProgress: TUTOR_READY_MAX_PROGRESS,
        header: `${session!.tutor.profile.polite_name} is ready`,
        subheader: `${session!.tutor.profile.years_teaching_experience
          } years experience`,
        testID: "tutor-ready",
        progressColor: Colors.ui.green
      };
    }
  }

  return (
    <Animated.View
      style={[
        ready ? styles.closing : {},
        { transform: [{ translateY: slideAnim }] }
      ]}
    >
      <View
        style={[styles.wrapper, { maxHeight: height / 2, width }]}
        testID={getStatusInfo().testID}
      >
        <TouchableOpacity testID="tutor-profile" onPress={onClick}>
          <FindingTutorAnimation tutor={session!.tutor ?? null} ready={ready} />
        </TouchableOpacity>
        <Text style={styles.header}>{getStatusInfo().header}</Text>
        <Text style={styles.subheader}>{getStatusInfo().subheader}</Text>
        <ProgressBar
          duration={getStatusInfo().duration}
          maxProgress={getStatusInfo().maxProgress}
          color={getStatusInfo().progressColor}
          backgroundColor="transparent"
        />
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  closing: {
    position: "absolute",
    zIndex: 1,
    top: Sizes.navigationHeight
  },
  wrapper: {
    backgroundColor: Colors.light.background,
    borderBottomColor: Colors.ui.beige,
    borderBottomWidth: 1,
    top: 0,
    overflow: "hidden",
    ...Spacing.pt3
  },
  header: {
    textAlign: "center",
    ...Typography.accent.large,
    ...Spacing.pt3
  },
  subheader: {
    textAlign: "center",
    color: Colors.ui.blue,
    fontSize: 12,
    fontWeight: "bold",
    ...Spacing.mb3
  }
});
