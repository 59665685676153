import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Animated, Easing, View, Platform } from "react-native";
import Config from "helpers/Config";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import { Svg, Path, G } from "react-native-svg";

type SpinnerProps = {
  size?: number;
  width?: number;
  color?: string;
  children?: React.ReactNode;
  backgroundColor?: string;
  duration?: number;
  animated?: boolean;
};

Spinner.defaultProps = {
  size: Sizes["32px"],
  width: Sizes["4px"],
  color: Colors.ui.blue,
  backgroundColor: Colors.grey.concrete,
  duration: 2000,
  animated: true
};

export default function Spinner(props: SpinnerProps) {
  const rotateAnim = useRef(new Animated.Value(0)).current;
  const [animation, setAnimation] =
    useState<Animated.CompositeAnimation | null>(null);

  useEffect(() => {
    animate();
    return () => {
      animation?.stop();
    };
  }, []);

  function animate() {
    if (props.animated && !Config.testing) {
      rotateAnim.setValue(0);
      const anim = Animated.loop(
        Animated.sequence([
          Animated.timing(rotateAnim, {
            toValue: 360,
            duration: props.duration,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== "web"
          }),
          Animated.timing(rotateAnim, {
            toValue: 0,
            duration: 0,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== "web"
          })
        ])
      );
      setAnimation(anim);
      anim.start();
    }
  }

  function getDegrees() {
    return rotateAnim.interpolate({
      inputRange: [0, 360],
      outputRange: ["0deg", "360deg"],
      extrapolate: "clamp"
    });
  }

  const radius = props.size! / 2;
  const offset = radius - props.width!;
  const innerSize = props.size! - props.width! * 3;
  const arcProps = {
    stroke: props.animated ? props.backgroundColor : props.color,
    strokeWidth: props.width,
    fill: "none"
  };

  return (
    <View style={styles.spinner}>
      <Animated.View
        style={{ position: "absolute", transform: [{ rotateZ: getDegrees() }] }}
      >
        <Svg style={{ width: props.size, height: props.size }}>
          <G transform={`translate(${props.width},${props.width})`}>
            <Path
              d={`M${offset},0 a${offset},${offset} 0 0,1 ${offset},${offset}`}
              {...arcProps}
              stroke={props.color}
            />
            <Path
              d={`M${
                offset * 2
              },${offset} a${offset},${offset} 0 0,1 -${offset},${offset}`}
              {...arcProps}
            />
            <Path
              d={`M${offset},${
                offset * 2
              } a${offset},${offset} 0 0,1 -${offset},-${offset}`}
              {...arcProps}
            />
            <Path
              d={`M0,${offset} a${offset},${offset} 0 0,1 ${offset},-${offset}`}
              {...arcProps}
            />
          </G>
        </Svg>
      </Animated.View>
      <View
        style={[
          styles.children,
          { width: innerSize, height: innerSize, borderRadius: innerSize }
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinner: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  children: {
    overflow: "hidden",
    display: "flex"
  }
});
