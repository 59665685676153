import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Sizes from "assets/theme/Sizes";

import Text from "components/Text";

type SelectCollectionProps = {
  options: Array<string>;
  selected: string;
  onSelect: (selected: string) => void;
  style?: object;
  optionStyle?: object;
  testID?: string;
};

export default function SelectCollection(props: SelectCollectionProps) {
  function isSelected(option: string) {
    return props.selected === option;
  }

  function getOptionStyle(option: string) {
    if (isSelected(option)) return [styles.option, styles.selectedOption];
    else return [styles.option];
  }

  function getTextStyle(option: string) {
    const style = [styles.optionText];
    if (isSelected(option)) {
      style.push(styles.selectedOptionText);
    }
    return style;
  }

  return (
    <View style={[styles.options, props.style]}>
      {props.options.map((option) => (
        <TouchableOpacity
          key={`select-option-${option}`}
          style={getOptionStyle(option)}
          testID={props.testID}
          onPress={() => props.onSelect(option)}
        >
          <Text style={getTextStyle(option)}>{option}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  options: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
    justifyContent: "center",
    ...Spacing.px2
  },
  option: {
    borderWidth: 2,
    borderColor: Colors.brand.blue,
    borderRadius: Sizes["24px"],
    backgroundColor: Colors.ui.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    ...Spacing.px2,
    ...Spacing.ma1,
    ...Spacing.py1
  },
  optionText: {
    color: Colors.brand.blue,
    textAlign: "center"
  },
  selectedOption: {
    backgroundColor: Colors.brand.blue
  },
  selectedOptionText: {
    color: Colors.ui.white
  }
});
