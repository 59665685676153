import * as React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { HomeIcon } from "components/Icons";
import Text from "components/Text";

export default function Header() {
  return (
    <View style={styles.nav}>
      <Button testID="back" round={true} to={"Dashboard"}>
        <HomeIcon height={24} width={24} color={Colors.grey.iron} />
      </Button>
      <Text tag="h2" style={styles.navText}>
        My profile
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  nav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.mb3
  },
  navText: {
    ...Spacing.ml2
  }
});
