import React, { forwardRef } from "react";
import { TextInput, StyleSheet, View } from "react-native";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

export type TextFieldProps = TextInput["props"];

export default forwardRef<TextInput, TextFieldProps>((props, ref) => (
  <View style={styles.wrapper}>
    <TextInput
      {...props}
      style={[styles.textfield, props.style]}
      ref={ref}
      placeholderTextColor={Colors.grey.iron}
    />
  </View>
));

const styles = StyleSheet.create({
  wrapper: {
    alignItems: "stretch"
  },
  textfield: {
    ...Spacing.pa3,
    ...Spacing.mt2,
    ...Spacing.mb2,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    backgroundColor: Colors.ui.white,
    fontFamily: Typography.primary.regular,
    fontSize: Typography.defaultSize
  }
});
