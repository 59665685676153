const Fonts = {
  primary: {
    regular: "post-grotesk-book",
    medium: "post-grotesk-medium",
    bold: "post-grotesk-bold"
  },
  secondary: {
    regular: "euclid-circular-a"
  }
};

export default {
  ...Fonts,
  defaultSize: 14,
  accent: {
    large: {
      fontSize: 16,
      fontFamily: Fonts.secondary.regular,
      letterSpacing: 1.75,
      lineHeight: 36,
      textTransform: "uppercase" as "uppercase",
      fontWeight: "bold" as "bold"
    },
    small: {
      fontSize: 13,
      fontFamily: Fonts.secondary.regular,
      letterSpacing: 1.42,
      lineHeight: 30,
      textTransform: "uppercase" as "uppercase",
      fontWeight: "bold" as "bold"
    }
  }
};
