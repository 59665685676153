import React from "react";
import { View, StyleSheet } from "react-native";
import Lottie from "components/Lottie";

import { Activity } from "./types/Activity";

import Text from "components/Text";
import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

type ActivityIndicatorProps = {
  activity: Activity;
  name: string;
};

const animations: any = {
  [Activity.typing]: require("./assets/typing.json"),
  [Activity.drawing]: require("./assets/drawing.json")
};

export default function ActivityIndicator(props: ActivityIndicatorProps) {
  if (!animations[props.activity]) {
    return null;
  }

  return (
    <View style={styles.indicator} testID="chatroom-activity">
      <Text style={styles.text}>{`${props.name} is ${props.activity}`}</Text>
      <View>
        <Lottie animation={animations[props.activity]} height={32} width={32} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  indicator: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderTopColor: Colors.ui.beige,
    borderTopWidth: 1,
    ...Spacing.px2
  },
  text: {
    color: Colors.grey.inactive
  }
});
