import { speak as speechStart, stop as speechStop } from "expo-speech";
import { Audio } from "expo-av";
import { Platform } from "react-native";

export class TextToSpeech {
  onEndCallback = () => {};

  constructor() {
    this.stop();
    if (Platform.OS !== "web") this.load();
  }

  async load() {
    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true
    });

    // Play a silent sound to allow speech in silent mode
    if (Platform.OS === "ios") {
      const { sound } = await Audio.Sound.createAsync(
        require("assets/audio/silence.mp3")
      );
      await sound.playAsync();
      await sound.pauseAsync();
    }
  }

  onEnd(callback: () => void) {
    this.onEndCallback = callback;
  }

  start(text: string) {
    this.stop();
    speechStart(text, {
      onDone: this.onEndCallback,
      onError: this.onEndCallback,
      onStopped: this.onEndCallback
    });
  }

  stop() {
    speechStop();
  }
}
