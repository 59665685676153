import React from "react";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { StyleSheet, View } from "react-native";
import Config from "helpers/Config";

import Button from "components/Button";
import Image from "components/Image";
import Spacing from "assets/theme/Spacing";
import { Event } from "events/Event";

export default function CleverSignIn() {
  const registering = useSelector(
    (state: StoreState) => state.ui.registeringUser
  );

  if (!Config.cleverClientId || !Config.cleverRedirectUri) {
    return null;
  }

  const onCleverSignIn = Event.dispatcher("signin_on_clever_sso");

  return (
    <View style={registering ? styles.registering : {}}>
      <Button
        disabled={registering}
        theme="flat"
        testID="clever"
        onClick={onCleverSignIn}
      >
        <Image
          uri={`${Config.assetsUrl}LogInWithClever--Secondary2x.png`}
          style={styles.cleverSignIn}
          maintainAspectRatio={true}
          maxSize={200}
        />
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  cleverSignIn: {
    ...Spacing.mt2
  },
  registering: {
    opacity: 0.5
  }
});
