import React from "react";
import { Environment } from "types/Environment";

type EnvironmentSwitcherProps = {
  environment: Environment;
  children: React.ReactNode;
  onSelect: (env: Environment) => void;
};

export default function EnvironmentSwitcher(props: EnvironmentSwitcherProps) {
  return <>{props.children}</>;
}
