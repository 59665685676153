import { Event } from "events/Event";
import { Router } from "navigation/Router";
import User from "models/User";
import { SignInUI } from "models/UI";
import store from "store";

import SignInBaseController from "./SignInBaseController";

class SignUpController extends SignInBaseController {
  init() {
    super.init();
    Event.on("signin_set_first_name", setFirstName);
    Event.on("signin_set_last_name", setLastName);
    Event.on("signin_on_sign_up", onSignUp);

    onLoad();
  }
  deinit() {
    super.deinit();
    Event.remove("signin_set_first_name", setFirstName);
    Event.remove("signin_set_last_name", setLastName);
    Event.remove("signin_on_sign_up", onSignUp);
  }
}

const controller = new SignUpController();
export default controller;

function onLoad() {
  if (!store.getState().ui.signInIdentifier) {
    Router.replace("SignIn");
  }
}

function setFirstName({ firstName }: { firstName: string }) {
  SignInUI.setSignUpFirstName(firstName);
}

function setLastName({ lastName }: { lastName: string }) {
  SignInUI.setSignUpLastName(lastName);
}

async function onSignUp({
  identifier,
  firstName,
  lastName
}: {
  identifier: string;
  firstName: string;
  lastName: string;
}) {
  SignInUI.setCreatingUser(true);
  const { success, message } = await User.create(
    identifier,
    firstName,
    lastName
  );
  if (success) {
    Router.replace("Welcome");
  } else if (message) {
    SignInUI.setError(message);
  }
  SignInUI.setCreatingUser(false);
}
