export default {
  brand: {
    blue: "#2e69e1",
    yellow: "#ffd50d",
    green: "#11a54a"
  },
  ui: {
    blue: "#578fff",
    lightBlue: "#d7e4ff",
    red: "#ff5768",
    green: "#28d169",
    beige: "#eae5df",
    tan: "#ccbfb1",
    brown: "#7a6750",
    white: "#fff",
    black: "#000",
    text: "#1a1919"
  },
  grey: {
    iron: "#535353",
    slate: "#a7a9ac",
    concrete: "#f2f2f2",
    aluminum: "#e3e3e3",
    inactive: "#73716c"
  },
  light: {
    text: "#1a1919",
    background: "#fffbf5",
    cardBackground: "#fff"
  },
  dark: {
    text: "#1a1919",
    background: "#fffbf5",
    cardBackground: "#fff"
  }
};
