import * as React from "react";
import { Event } from "events/Event";
import { StyleSheet, View, TouchableOpacity, Platform } from "react-native";
import { useMediaQuery } from "react-responsive";
import { Tutor as TutorType, getTutorAvatar } from "types/Tutor";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import { HeartIcon, HeartOutlineIcon } from "components/Icons";
import Image from "components/Image";
import Text from "components/Text";

import ProfileCard from "./ProfileCard";

export type TutorProps = {
  tutor: TutorType;
  favorite: boolean;
};

export default function Tutor(props: TutorProps) {
  const isMd = useMediaQuery({ minWidth: Layout.md });
  const uri = getTutorAvatar(props.tutor.id, props.tutor.gender);
  const testID = props.favorite ? "unfavorite-tutor" : "favorite-tutor";

  const onFavoriteTutor = Event.dispatcher("profile_on_favorite_tutor", {
    tutorId: props.tutor.id,
    tutorName: props.tutor.profile.polite_name
  });
  const onUnfavoriteTutor = Event.dispatcher("profile_on_unfavorite_tutor", {
    tutorId: props.tutor.id,
    tutorName: props.tutor.profile.polite_name
  });

  function onPress() {
    props.favorite ? onUnfavoriteTutor() : onFavoriteTutor();
  }

  if (Platform.OS !== "web" || !isMd) {
    return (
      <View style={styles.tutor}>
        <Image style={styles.image} uri={uri} />
        <Text style={styles.name}>{props.tutor.profile.polite_name}</Text>
        <TouchableOpacity
          testID={testID}
          style={styles.favorite}
          onPress={onPress}
        >
          {props.favorite ? (
            <HeartIcon height={24} width={24} color={Colors.ui.red} />
          ) : (
              <HeartOutlineIcon
                height={24}
                width={24}
                color={Colors.grey.inactive}
              />
            )}
        </TouchableOpacity>
      </View>
    );
  }
  return (
    <View style={styles.tutorCard}>
      <ProfileCard imageUrl={uri} title={props.tutor.profile.polite_name}>
        <TouchableOpacity testID={testID} onPress={onPress}>
          {props.favorite ? (
            <HeartIcon height={24} width={24} color={Colors.ui.red} />
          ) : (
              <HeartOutlineIcon
                height={24}
                width={24}
                color={Colors.grey.inactive}
              />
            )}
        </TouchableOpacity>
      </ProfileCard>
    </View>
  );
}

const styles = StyleSheet.create({
  tutorCard: {
    width: 160,
    maxWidth: 160,
    height: "100%",
    ...Spacing.pa1
  },
  tutor: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    width: "100%"
  },
  image: {
    width: Sizes["48px"],
    height: Sizes["48px"],
    ...Spacing.mr2
  },
  name: {
    fontSize: 18,
    flexShrink: 1
  },
  favorite: {
    ...Spacing.px1
  }
});
