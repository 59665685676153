import React, { useEffect } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";

import { RootStackScreenProps } from "types";
import Spacing from "assets/theme/Spacing";

import Page from "components/Page";

export default function NotFoundScreen({
  navigation
}: RootStackScreenProps<"NotFound">) {
  const { height } = useWindowDimensions();

  useEffect(() => {
    navigation.replace("Dashboard");
  }, []);

  return (
    <Page>
      <View style={[styles.container, { minHeight: height }]}>
        <Text style={styles.title}>Redirecting to dashboard...</Text>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    ...Spacing.mb3
  }
});
