import WebService from "helpers/WebService";
import { Timers, Queue } from "@yups/utils";
import {
  createMessage,
  MessageContentType,
  messageIsAchievement,
  MessageReaction,
  MessageSender,
  MessageType,
  successfullySent,
  transformMessages
} from "components/ChatRoom/types/Message";
import Storage from "helpers/Storage";
import store from "store";
import {
  addMessageLabel,
  addMessages,
  clear,
  setLatestMessagesStatus,
  setMessages
} from "store/Messages";
import { SessionChannel } from "./SessionChannel";
import Logger, { AppEvents, SessionLogs } from "helpers/Logger";
import { Event } from "events/Event";
import { dataURItoBlob, scratchBoardImageUploader } from "helpers/UploadImage";
import { PusherEvent } from "helpers/Pusher";
import Session from "models/Session";
import User from "models/User";

export const storage = new Storage("yup-unprocessed-messages");

const MESSAGE_POLL_LABEL = "yup-session-messages";
const MESSAGE_POLL_INTERVAL = 5000;
const MESSAGE_SAVE_LABEL = "yup-save-messages";
const MESSAGE_SAVE_INTERVAL = 3000;

class SessionMessagesClass {
  queue: Queue<string>;
  isSyncing: boolean = false;

  constructor() {
    this.queue = new Queue("yup-messages");
  }
  get session() {
    return Session.get();
  }
  get messages() {
    return store.getState().messages.messages;
  }
  get latestMessageId() {
    return store.getState().messages.latestMessageId;
  }
  get messageLabels() {
    return store.getState().messages.messageLabels;
  }

  async load() {
    const unsavedMessages = (await storage.get()) ?? [];
    unsavedMessages.forEach((message: MessageType) => {
      this.queue.enqueue(message.key);
    });
    Timers.setInterval({
      label: MESSAGE_POLL_LABEL,
      callback: () => this.getNewMessages(),
      delay: MESSAGE_POLL_INTERVAL
    });
    await this.getNewMessages();
    await SessionChannel.on(PusherEvent.newMessages, handleNewMessageEvent);
    await SessionChannel.on(
      PusherEvent.newDeliveries,
      handleNewDeliveriesEvent
    );
  }
  add(messages: Array<MessageType>) {
    const messagesFromTutor = getMessagesBySender(
      messages,
      MessageSender.tutor
    );
    if (messagesFromTutor.length && this.latestMessageId > 0) {
      this.handleNewMessages(messagesFromTutor);
    }
    store.dispatch(addMessages(messages));
  }
  update(message: MessageType) {
    store.dispatch(addMessages([message]));
  }
  set(messages: Array<MessageType>) {
    store.dispatch(setMessages(messages));
  }
  get(key: string) {
    return this.messages.find((message) => message.key === key);
  }
  hasMessageLabel(label: string, sender: MessageSender) {
    return this.messageLabels.includes(
      `${this.session?.id}-${sender}-${label}`
    );
  }
  clear() {
    store.dispatch(clear());
    Timers.clear(MESSAGE_POLL_LABEL);
    this.isSyncing = false;
    SessionChannel.remove(PusherEvent.newMessages, handleNewMessageEvent);
    SessionChannel.remove(PusherEvent.newDeliveries, handleNewDeliveriesEvent);
  }
  async reload() {
    this.clear();
    await this.load();
  }
  async getNewMessages() {
    if (!this.session || this.isSyncing) return;
    try {
      this.isSyncing = true;
      const latestMessageIdWas = this.latestMessageId;
      const response = await WebService.sessionGetNewMessages(
        this.session.id,
        this.latestMessageId
      );
      if (response.success) {
        this.add(
          response.data.data.messages.concat(response.data.data.reactions ?? [])
        );
      }

      if (this.latestMessageId != latestMessageIdWas) {
        await WebService.sessionSendLatestMessageId(
          this.session.id,
          this.latestMessageId
        );
      }
    } catch {
      /* Gracefully handle errors, errors will be logged by NetworkHelper */
    } finally {
      this.isSyncing = false;
    }
  }
  async getNewDeliveries() {
    if (!this.session || !this.latestMessageId) return;
    const response = await WebService.getNewDeliveries(this.session.id);
    if (response.success) {
      store.dispatch(setLatestMessagesStatus(response.data));
    }
  }
  sendMessage(
    message: string,
    sender?: MessageSender,
    isImage?: boolean,
    sentTo?: MessageSender,
    label?: string
  ) {
    if (message && this.session) {
      if (label) {
        store.dispatch(
          addMessageLabel(`${this.session.id}-${sender}-${label}`)
        );
      }
      const newMessage = createMessage({
        ...getMessageDefaults(),
        message,
        sender: sender ?? MessageSender.student,
        isImage: isImage ?? false,
        sentTo: sentTo ?? MessageSender.tutor
      });
      this.handleFirstMessageIfNeeded(newMessage);
      this.update(newMessage);
      this.saveMessage(newMessage.key);

      Logger.log(AppEvents.sessionMessageSend, {
        message: newMessage,
        messageCounts: getMessageCounts(this.messages)
      });
    }
  }
  sendBotMessage(message: string, label: string) {
    if (!this.hasMessageLabel(label, MessageSender.bot)) {
      this.sendMessage(message, MessageSender.bot, false, undefined, label);
    }
  }
  sendSystemMessage(message: string, label: string) {
    if (!this.hasMessageLabel(label, MessageSender.systemInfo)) {
      this.sendMessage(
        message,
        MessageSender.systemInfo,
        false,
        undefined,
        label
      );
    }
  }
  async sendImage(imageData: string) {
    if (!imageData || !this.session) return;
    return await new Promise(async (resolve) => {
      scratchBoardImageUploader.onCompletion((url: string) => {
        this.sendMessage(url, MessageSender.student, true);
        resolve(url);
      });
      scratchBoardImageUploader.onFailure((error: Error) => {
        console.error(error);
        resolve("");
      });
      scratchBoardImageUploader.uploadImage(await dataURItoBlob(imageData));
    });
  }
  async sendReaction(key: string, reaction: MessageReaction) {
    // TODO: Handle user information on the backend so we won't need
    //       to call User.get here
    const user = User.get();
    const message = this.messages.find((m) => m.key === key);
    if (!message || !this.session || !user) return;

    const response = await WebService.reactToMessage({
      message_id: message.id ?? 0,
      user_id: user.id,
      reaction_name: reaction,
      message_key: message.key,
      reacted_at: new Date().getTime() / 1000,
      user_name: user.name
    });

    if (response.success) {
      // TODO: Figure out how to send message reactions with new messaging system
      Logger.log(AppEvents.sessionEmotiveReactionSent, {
        text: message.text,
        message_author: message.sent_from,
        reaction
      });
    }
  }

  private handleFirstMessageIfNeeded(message: MessageType) {
    if (!this.session?.tutor_ready_at) return;
    const hasNoStudentMessages = !this.messages.some((message) => {
      return (
        message.sent_from === MessageSender.student &&
        message.sent_at > this.session!.tutor_ready_at!
      );
    });
    if (hasNoStudentMessages && message.sent_from === MessageSender.student) {
      Logger.log(SessionLogs.sessionFirstStudentMessageSent);
    }
  }
  private handleNewMessages(newMessageData: Array<MessageType>) {
    const localMessageKeys = new Set<string>(
      this.messages.map((message) => message.key)
    );
    newMessageData.forEach((message: MessageType) => {
      if (!localMessageKeys.has(message.key)) {
        localMessageKeys.add(message.key);
        this.messageReceived(message);
      }
    });
  }
  private messageReceived(message: MessageType) {
    Event.dispatch("chatroom_message_received");
    const messageCounts = getMessageCounts(this.messages);
    Logger.logTime(
      AppEvents.sessionMessageReceived,
      ["event:message_received", "from:tutor", "to:student"],
      new Date(Number(message.sent_at) * 1000),
      { message, messageCounts }
    );

    if (messageIsAchievement(message)) {
      Logger.log(AppEvents.sessionAchievementReceived, {
        achievement_type: message.additional_attributes!.badge_type
      });
    }
  }

  /**
   * Processes unsaved messages in local storage if needed.
   * This function is invoked by this.saveMessage(...).
   */
  private async processUnsavedMessages() {
    if (!this.queue.length) {
      Timers.clear(MESSAGE_SAVE_LABEL);
      return;
    }

    const key = this.queue.peek();
    const message = key && this.get(key);
    if (!message || successfullySent(message)) {
      this.queue.dequeue();
    } else {
      const { success } = await WebService.sendMessage(message);
      if (success) this.queue.dequeue();
    }
    storage.setSync(this.queue.toArray());

    if (!Timers.hasTimer(MESSAGE_SAVE_LABEL)) {
      Timers.setRecursiveTimeout({
        label: MESSAGE_SAVE_LABEL,
        callback: async () => await this.processUnsavedMessages(),
        delay: MESSAGE_SAVE_INTERVAL
      });
    }
  }

  /**
   * Adds a message to the queue and tries to send it right away.
   * If saving the message on the app server fails, the message
   * will remain in the local storage for future processing.
   */
  private async saveMessage(key: string): Promise<void> {
    this.queue.enqueue(key);
    await this.processUnsavedMessages();
  }
}

const SessionMessages = new SessionMessagesClass();
export default SessionMessages;

async function handleNewMessageEvent() {
  await SessionMessages.getNewMessages();
}
async function handleNewDeliveriesEvent() {
  await SessionMessages.getNewDeliveries();
}

function getMessageDefaults() {
  const session = Session.get();
  const user = User.get();
  return {
    isImage: false,
    sessionId: session?.id,
    senderId: user?.id,
    senderToken: user?.token,
    sender: MessageSender.student
  };
}

function getMessagesBySender(
  messages: Array<MessageType>,
  sender: MessageSender
) {
  return messages.filter((message) => message.sent_from === sender);
}

function getMessagesByType(
  messages: Array<MessageType>,
  contentType: MessageContentType
) {
  let matches = messages.filter(
    (message) => message.content_type === contentType
  );
  if (contentType === MessageContentType.image) {
    matches = matches.filter(
      (message) => !message.additional_attributes?.is_badge
    );
  }
  return matches;
}

function getMessageCounts(messages: Array<MessageType>) {
  return {
    sent: getMessagesBySender(messages, MessageSender.student).length,
    received: getMessagesBySender(messages, MessageSender.tutor).length
  };
}

function getMessageCharacterCount(message: MessageType) {
  switch (message.content_type) {
    case MessageContentType.text:
      return message.text?.length ?? 0;
    case MessageContentType.image:
      return 400;
    default:
      return 0;
  }
}

function getCharacterCount(messages: Array<MessageType>) {
  return messages.reduce(
    (sum, message) => sum + getMessageCharacterCount(message),
    0
  );
}

export function getSessionImages(messages: Array<MessageType>) {
  const imageMessages = getMessagesByType(messages, MessageContentType.image);
  const transformedMessages = transformMessages(imageMessages);
  return transformedMessages.map((message) => message.text).reverse();
}

export function getLogInfo() {
  const tutorMessages = getMessagesBySender(
    SessionMessages.messages,
    MessageSender.tutor
  );
  const studentMessages = getMessagesBySender(
    SessionMessages.messages,
    MessageSender.student
  );
  const info: any = {
    message_count: SessionMessages.messages.length,
    total_student_messages_sent: studentMessages.length,
    total_tutor_messages_received: tutorMessages.length,
    total_tutor_characters_sent: getCharacterCount(tutorMessages),
    total_student_characters_sent: getCharacterCount(studentMessages)
  };
  return info;
}
