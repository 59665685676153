import React from "react";
import { Event } from "events/Event";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import Colors from "assets/theme/Colors";
import Typography from "assets/theme/Typography";

import { HeartIcon, HeartOutlineIcon } from "components/Icons";
import Text from "components/Text";

import { BioDetail } from "models/Session";
import Card from "components/Card";
import Spacing from "assets/theme/Spacing";

type ProfileDetailProps = {
  detail: BioDetail;
};

export default function ProfileDetail(props: ProfileDetailProps) {
  const { question, answer, liked, name } = props.detail;
  const iconProps = {
    width: 24,
    height: 24
  };

  const onNavigate = Event.dispatcher("session_on_navigate_tutor_detail", {
    index: props.index
  });
  const onLikeDetail = Event.dispatcher("session_on_favorite_tutor_detail", {
    name,
    answer
  });
  const onUnlikeDetail = Event.dispatcher(
    "session_on_unfavorite_tutor_detail",
    { name, answer }
  );

  return (
    <TouchableOpacity
      style={styles.item}
      testID="detail-card"
      onPress={onNavigate}
    >
      <Card style={styles.bioDetail}>
        <Text style={styles.question}>{question}</Text>
        <Text style={styles.answer}>{answer}</Text>
        <View style={styles.like}>
          {liked ? (
            <TouchableOpacity
              testID="unfavorite-detail"
              onPress={onUnlikeDetail}
            >
              <HeartIcon {...iconProps} color={Colors.ui.blue} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity testID="favorite-detail" onPress={onLikeDetail}>
              <HeartOutlineIcon {...iconProps} color={Colors.grey.inactive} />
            </TouchableOpacity>
          )}
        </View>
      </Card>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  item: {
    maxWidth: 300
  },
  bioDetail: {
    flex: 1,
    textAlign: "center",
    ...Spacing.pa3
  },
  question: {
    ...Typography.accent.small,
    lineHeight: 14,
    textAlign: "center",
    ...Spacing.mb2
  },
  answer: {
    fontSize: 18,
    textAlign: "center"
  },
  like: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }
});
