import { FunctionComponent } from "react";
import { ScreenType } from "types";

import SessionController from "./controllers/SessionController";
import CameraController from "./controllers/CameraController";
import ScratchboardController from "./controllers/ScratchboardController";

import SessionScreen from "./views/Session";
import CameraScreen from "./views/Camera";
import ScratchBoardScreen from "./views/ScratchBoard";

export type Stack = {
  Session: undefined;
  SessionRating: undefined;
  SessionFeedback: undefined;
  SessionCamera: undefined;
  SessionScratchBoard: undefined;
};

export const SessionScreens: Array<ScreenType> = [
  {
    name: "Session",
    path: "session",
    controller: SessionController,
    component: SessionScreen as FunctionComponent
  },
  {
    name: "SessionCamera",
    path: "session-camera",
    controller: CameraController,
    component: CameraScreen as FunctionComponent
  },
  {
    name: "SessionScratchBoard",
    path: "session-scratch-board",
    controller: ScratchboardController,
    component: ScratchBoardScreen as FunctionComponent
  }
];
