import store from "store";
import { setSettings } from "store/Settings";

export interface Achievement {
  name: string;
  achievement_type: string;
  portal_url: string;
  portal_url_gray: string;
  earn_by_student_copy: string;
}

export interface CancelReason {
  id: number;
  reason: string;
}

export interface Subtopic {
  id: number;
  name: string;
  label: string;
}

export interface ComplaintReasons {
  thumbs_up: Array<string>;
  thumbs_down: Array<string>;
}

export interface SettingsType {
  subtopics: Array<Subtopic>;
  session_cancel_reasons: Array<CancelReason>;
  complaint_reasons: ComplaintReasons;
  achievements: {
    definitions: Array<Achievement>;
  };
}

export const Settings = {
  get() {
    return store.getState().settings.settings;
  },
  set(settings: SettingsType) {
    store.dispatch(setSettings(settings));
  }
};

export default Settings;
