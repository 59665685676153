import { EnvironmentHelper, NetworkHelperClass, retry } from "@yups/utils";
import { Response } from "types/Response";
import { Usersnap } from "./Usersnap";
import Debug, { DebugLog } from "helpers/Debug";
import { reportError } from "./Sentry";
import { AuthStrategy } from "@yups/utils/build/AuthHelper/AuthHeaders";
import User from "models/User";
import {
  CreateSessionRequest,
  EndSessionRequest,
  GoogleAuthRequest,
  SubmitFeedbackRequest
} from "./WebService";
import Device, { getDeviceInfo } from "types/Device";
import { MessageType } from "components/ChatRoom/types/Message";

enum Method {
  GET = "get",
  POST = "post",
  PUT = "put"
}

let retryCount = 0;
// This class will replace Webservice once all of the endpoints are fully migrated.
export class YupAPI {
  static networkHelper: NetworkHelperClass = new NetworkHelperClass();
  static url() {
    let env = EnvironmentHelper.env;
    if (env?.isDevelopment()) {
      return "http://localhost:3001";
    } else if (env?.isProduction()) {
      return "https://api.yupinternal.com";
    } else if (env?.isStandardEnvironment()) {
      return "https://yup-api-qa.herokuapp.com";
    } else if (env?.isDeveloperEnvironment()) {
      // See the yup-api project README on Github for instructions on how to setup ngrok
      return `https://${env.name}.ngrok.io`;
    }

    return "http://localhost:3001";
  }
  static init() {
    this.networkHelper.baseURL = this.url();
    this.networkHelper.authStrategy = AuthStrategy.BearerToken;
  }

  static setRetries(retries: number) {
    retryCount = retries;
  }

  static async fetchFeatures() {
    return await makeRequest(Method.GET, "/features");
  }

  static async googleSSO(info: GoogleAuthRequest) {
    return await makeRequest(Method.POST, "/google_sso", info);
  }

  static async createSession(params: CreateSessionRequest) {
    // Ensure push_token and app_version are up-to-date
    await YupAPI.updateDevice(await getDeviceInfo());

    return await makeRequest(Method.POST, "/session", params);
  }

  static async getSession(sessionId: number) {
    return await makeRequest(Method.GET, `/session/${sessionId}`, {
      user_type: "student"
    });
  }

  static async endSession(sessionId: number, params: EndSessionRequest) {
    return await makeRequest(
      Method.PUT,
      `/session/${sessionId}/end_session`,
      params
    );
  }

  static async submitStudentFeedback(
    sessionId: number,
    params: SubmitFeedbackRequest
  ) {
    return await makeRequest(
      Method.PUT,
      `session/${sessionId}/submit_student_feedback`,
      params
    );
  }

  static async updateDevice(params: Device) {
    return await makeRequest(Method.POST, "/user/device", params);
  }

  static async getStudent(userId?: number) {
    return await makeRequest(Method.GET, `/student/${userId}`);
  }

  static async sendMessage(message: MessageType) {
    return await makeRequest(
      Method.POST,
      `/session/${message.chat_id}/send_message`,
      message
    );
  }

  static async getNewMessages(sessionId: number, latestMessageId: number) {
    return await makeRequest(
      Method.GET,
      `/session/${sessionId}/latest-messages/${latestMessageId}`
    );
  }

  static async sendLatestMessageId(sessionId: number, latestMessageId: number) {
    return await makeRequest(
      Method.POST,
      `/session/${sessionId}/latest-messages/${latestMessageId}`
    );
  }

  static async getNewMessageDeliveries(sessionId: number) {
    return await makeRequest(
      Method.GET,
      `/session/${sessionId}/latest-messages-status`
    );
  }
}

YupAPI.networkHelper.onError(({ error, endPoint, method }) => {
  if (endPoint?.includes("log_entries")) {
    return;
  }

  const timestamp = new Date().toISOString();
  Usersnap.addErrorLog(
    `${timestamp} [API Error] ${method} ${endPoint} threw error: ${error}`
  );

  reportError(error);
});

const inProgress: { [key: string]: boolean } = {};
async function makeRequest(
  method: Method,
  endPoint: string,
  params: any = {},
  disableParallelRequests: boolean = false
): Promise<Response> {
  const response: Response = { success: false };

  const retries = retryCount;
  retryCount = 0;

  if (inProgress[endPoint] && disableParallelRequests) {
    return { success: false };
  }

  inProgress[endPoint] = true;

  try {
    await retry(async () => {
      const ts = new Date();

      const result = await YupAPI.networkHelper[method]({
        endPoint,
        params
      });

      if (Debug.get(DebugLog.APIRequests)) {
        const delta = new Date().getTime() - ts.getTime();
        if (delta > 500) {
          // highlight requests that take over 500ms in red
          console.log(
            `%c [API REQUEST] ${endPoint} - ${delta}ms`,
            "color: red"
          );
        } else {
          console.log(`[API REQUEST] ${endPoint} - ${delta}ms`);
        }
      }

      if (!("success" in result)) {
        // if the success flag isn't set, use status_code === 200
        // to determine if the request was successful
        result.success = result.status_code === 200;
      }

      if (result.status_code === 401) {
        User.handleUnauthorized();
      }

      response.data = result;
      response.success = Boolean(result.success);
    }, retries);
  } catch (error) {
  } finally {
    inProgress[endPoint] = false;
  }

  return response;
}

export async function loadYupAPI() {
  YupAPI.init();
}
