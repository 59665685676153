import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from "react";
import { Whiteboard } from "models/Whiteboard";
import { ZwibblerContext } from "./zwibbler2";

export interface WhiteboardComponentAPI {
  ctx: ZwibblerContext | null;
}

export const ZwibblerComponent = forwardRef<WhiteboardComponentAPI>(
  (props, ref) => {
    const zwibblerEl = useRef<HTMLDivElement | null>(null);

    // Zwibbler configuration, see
    // https://zwibbler.com/configurator.html
    // All names are lower case due to react convention.
    const zwibblerConfig = {
      zwibbler: "",
      showtoolbar: "false",
      showcolourpanel: "false",
      defaultarrowsize: "10",
      defaultarrowstyle: "solid",
      defaultbrushwidth: "3",
      adaptivebrushwidth: "true",
      defaultfillstyle: "transparent",
      background: "white",
      pageview: "true",
      scrollbars: "true",
      pageshadow: "false",
      pageinflation: "0",
      autopicktool: "false",
      allowsystemclipboard: "false"
    };

    useEffect(() => {
      if (!zwibblerEl.current) {
        console.error("Element ref was null");
        return;
      }

      Whiteboard.attachZwibbler(zwibblerEl.current);

      return () => {
        Whiteboard.detachZwibbler();
      };
    }, []);

    // Here we define things that we want to make available in our ref
    useImperativeHandle(ref, () => ({
      get ctx() {
        return Whiteboard.context;
      }
    }));

    return (
      <div
        style={styles.whiteboard}
        {...zwibblerConfig}
        ref={zwibblerEl}
        className="zwibbler"
      >
        <div z-canvas="" className="canvas" style={{ height: "100%" }} />
      </div>
    );
  }
);

const styles = {
  whiteboard: {
    flex: 1
  }
};
