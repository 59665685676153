import * as React from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { useMediaQuery } from "react-responsive";

import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { CloseCircleIcon, LogoIcon } from "components/Icons";

import TutorStatus from "../components/TutorStatus";
import Colors from "assets/theme/Colors";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { Event } from "events/Event";

export default function Toolbar() {
  const endingSession = useSelector(
    (state: StoreState) => state.ui.endingSession
  );
  const isLg = useMediaQuery({ minWidth: Layout.lg });

  const onEndSession = Event.dispatcher("session_show_end_session");

  return (
    <SafeAreaView style={styles.toolbarWrapper}>
      <View style={styles.topToolbar}>
        <TutorStatus />
        {isLg && (
          <LogoIcon
            width={100}
            height={Sizes["24px"]}
            color={Colors.brand.blue}
          />
        )}
        <View style={styles.actions}>
          <Button
            theme="flat"
            round={true}
            disabled={endingSession}
            testID="end-session"
            onClick={onEndSession}
          >
            <CloseCircleIcon width={24} height={24} color={Colors.ui.blue} />
          </Button>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  toolbarWrapper: {
    borderBottomWidth: 1,
    borderColor: Colors.ui.beige,
    backgroundColor: Colors.light.background,
    ...Spacing.pt2,
    ...Spacing.px2
  },
  topToolbar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: Sizes.navigationHeight
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  }
});
