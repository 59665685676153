import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import CarouselComponent from "react-native-anchor-carousel";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import { CircleIcon } from "components/Icons";

type CarouselProps = {
  items: Array<any>;
  itemElement: Function;
  containerWidth: number;
  itemWidth: number;
  index?: number;
  onNavigate?: (index: number) => void;
};

export default function Carousel(props: CarouselProps) {
  const carouselRef = useRef<typeof CarouselComponent>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    carouselRef.current?.scrollToIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (props.index !== undefined) navigate(props.index);
  }, [props.index]);

  function handleCarouselScrollEnd(_: any, index: number) {
    navigate(index);
  }

  function navigate(index: number) {
    setCurrentIndex(index);
    props.onNavigate?.(index);
  }

  return (
    <View style={styles.carousel}>
      <CarouselComponent
        data={props.items}
        renderItem={props.itemElement}
        inActiveOpacity={0.3}
        containerWidth={props.containerWidth}
        itemWidth={props.itemWidth}
        initialIndex={currentIndex}
        onScrollEnd={handleCarouselScrollEnd}
        ref={carouselRef}
      />
      <View style={styles.navigation}>
        {props.items.map((_, index) => (
          <TouchableOpacity
            key={`dot-${index}`}
            style={styles.navigationDot}
            onPress={() => navigate(index)}
          >
            <CircleIcon
              width={8}
              height={8}
              color={
                currentIndex === index ? Colors.ui.blue : Colors.grey.slate
              }
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  carousel: {
    display: "flex",
    flexDirection: "column"
  },
  navigation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    ...Spacing.my2
  },
  navigationDot: {
    ...Spacing.ma1
  }
});
