import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, View, Platform, SafeAreaView } from "react-native";
import { Camera as ExpoCamera, CameraType } from "expo-camera";

import { playSound, Sound } from "helpers/Audio";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { BackIcon, LogoIcon } from "components/Icons";
import Text from "components/Text";

import CameraButton from "./components/CameraButton";
import { Event } from "events/Event";

type CameraProps = {
  hasPermission: boolean;
};

export default function Camera(props: CameraProps) {
  const camera = useRef<ExpoCamera>();
  const [cameraType, setCameraType] = useState(CameraType.back);
  const [aspectRatio, setAspectRatio] = useState<string>("");

  const onBack = Event.dispatcher("camera_exit");
  const onSetPermission = (hasPermission: boolean) =>
    Event.dispatch("camera_set_permission", { hasPermission });

  useEffect(() => {
    (async () => {
      const { status } = await ExpoCamera.requestCameraPermissionsAsync();
      onSetPermission(status === "granted");
    })();
  }, []);

  async function handleCamera() {
    if (Platform.OS !== "android" || !camera.current || Boolean(aspectRatio))
      return;
    const supportedRatios = await camera.current.getSupportedRatiosAsync();
    const largestAspectRatio = supportedRatios[supportedRatios.length - 1];
    setAspectRatio(largestAspectRatio);
  }

  async function onTakePicture() {
    if (!props.hasPermission || !camera.current) return;
    camera.current.resumePreview();
    playSound(Sound.shutter);
    camera.current.takePictureAsync({
      base64: true,
      quality: 0.7,
      onPictureSaved: (photo) => {
        let dataUrl = `${photo.base64?.startsWith("data:image/")
            ? ""
            : "data:image/jpeg;base64,"
          }${photo.base64}`;
        Event.dispatch("camera_picture_taken", { photo: dataUrl });
        camera.current?.resumePreview();
      }
    });
    if (Platform.OS !== "android") camera.current.pausePreview();
  }

  function onFlip() {
    setCameraType(
      cameraType === CameraType.back
        ? CameraType.front
        : CameraType.back
    );
  }

  if (!props.hasPermission) {
    return (
      <SafeAreaView style={styles.default}>
        <View style={styles.row}>
          <Button
            theme="flat"
            round={true}
            style={styles.backButton}
            testID="camera-cancel"
            onClick={onBack}
          >
            <BackIcon color={Colors.ui.white} height={24} width={24} />
          </Button>
          <LogoIcon color={Colors.ui.white} height={24} width={100} />
        </View>
        {props.hasPermission === false && (
          <Text style={styles.defaultText}>
            Please go to your settings and enable your camera
          </Text>
        )}
      </SafeAreaView>
    );
  }
  return (
    <View style={styles.container} testID="camera">
      <ExpoCamera
        style={styles.camera}
        ratio={aspectRatio}
        type={cameraType}
        ref={camera}
        onCameraReady={handleCamera}
      >
        <SafeAreaView style={styles.row}>
          <Button
            theme="flat"
            round={true}
            style={styles.backButton}
            testID="camera-cancel"
            onClick={onBack}
          >
            <BackIcon color={Colors.ui.white} height={24} width={24} />
          </Button>
          <LogoIcon color={Colors.ui.white} height={24} width={100} />
        </SafeAreaView>
        <SafeAreaView style={[styles.row, styles.buttonContainer]}>
          <View />
          <Button
            style={styles.pictureButton}
            testID="camera-capture"
            onClick={onTakePicture}
          >
            Take picture
          </Button>
          <CameraButton style={styles.flipButton} onClick={onFlip} />
        </SafeAreaView>
      </ExpoCamera>
    </View>
  );
}

const styles = StyleSheet.create({
  default: {
    flex: 1,
    backgroundColor: Colors.ui.black
  },
  defaultText: {
    color: Colors.ui.white,
    textAlign: "center"
  },
  container: {
    flex: 1
  },
  camera: {
    flex: 1
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa4,
    ...Spacing.mt2
  },
  backButton: {
    position: "absolute",
    top: 40,
    left: Sizes["32px"]
  },
  backIcon: {
    width: Sizes["24px"],
    height: Sizes["24px"]
  },
  logo: {
    resizeMode: "contain",
    width: 100,
    height: Sizes["48px"]
  },
  buttonContainer: {
    position: "absolute",
    bottom: Sizes["16px"]
  },
  pictureButton: {
    ...Spacing.px4
  },
  flipButton: {
    position: "absolute",
    right: Sizes["16px"],
    bottom: 40
  }
});
