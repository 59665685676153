import { NotificationInterface } from "./interface";

class Notification implements NotificationInterface {
  async getToken() {}

  async register() {}

  resetBadgeCount() {}
}

const notifications = new Notification();

export default notifications;
