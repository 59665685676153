import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";

import Spacing from "assets/theme/Spacing";

import Card from "components/Card";
import Page from "components/Page";
import Text from "components/Text";

type FeedbackWrapperProps = {
  children: React.ReactNode;
};

export default function FeedbackWrapper(props: FeedbackWrapperProps) {
  const { height } = useWindowDimensions();
  return (
    <Page>
      <View style={[styles.wrapper, { minHeight: height }]}>
        <Text tag="h1">Rate Your Session</Text>
        <Card style={styles.card}>{props.children}</Card>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa2
  },
  card: {
    width: 450,
    maxWidth: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    ...Spacing.my4,
    ...Spacing.pa3
  }
});
