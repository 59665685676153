import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StateType = {
  assistmentsAssignmentId: string;
  assistmentsProblemId: string;
  assistmentsProblem: string;
  ascendMathProblem: string;
};

export const partnershipsSlice = createSlice({
  name: "partnerships",
  initialState: {
    assistmentsAssignmentId: "",
    assistmentsProblemId: "",
    assistmentsProblem: "",
    ascendMathProblem: ""
  } as StateType,
  reducers: {
    setAssistmentsAssignmentId: (state, action: PayloadAction<string>) => {
      state.assistmentsAssignmentId = action.payload;
    },
    setAssistmentsProblemId: (state, action: PayloadAction<string>) => {
      state.assistmentsProblemId = action.payload;
    },
    setAssistmentsProblem: (state, action: PayloadAction<string>) => {
      state.assistmentsProblem = action.payload;
    },
    setAscendMathProblem: (state, action: PayloadAction<string>) => {
      state.ascendMathProblem = action.payload;
    },
    resetAssistments(state) {
      state.assistmentsAssignmentId = "";
      state.assistmentsProblemId = "";
      state.assistmentsProblem = "";
    }
  }
});

export default partnershipsSlice.reducer;

export const {
  setAscendMathProblem,
  setAssistmentsProblem,
  setAssistmentsAssignmentId,
  setAssistmentsProblemId,
  resetAssistments
} = partnershipsSlice.actions;
