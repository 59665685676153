import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import {
  Platform,
  StyleSheet,
  View,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";

import Spacing from "assets/theme/Spacing";
import Text from "components/Text";

import Button from "components/Button";
import CaptureView, { CaptureViewAPI } from "components/CaptureView";
import TextArea from "components/TextArea";
import { MAIN_BUTTON_MIN_WIDTH } from "../views/Dashboard";
import StaticField from "components/Equations/StaticField";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import { XIcon } from "components/Icons";
import { Event } from "events/Event";

export default function TextSubmit() {
  const text = useSelector((state: StoreState) => state.ui.problemText);
  const equation = useSelector((state: StoreState) => state.ui.equation);

  const { width } = useWindowDimensions();
  const [generating, setGenerating] = useState(false);
  const captureRef = useRef<CaptureViewAPI>(null);
  async function onCapture() {
    setGenerating(true);
    const dataUrl = await captureRef.current?.capture();
    if (dataUrl) {
      Event.dispatch("dashboard_submit_text", {
        dataUrl,
        text,
        equation
      });
    }
    setGenerating(false);
  }

  const onSetText = (text: string) =>
    Event.dispatch("dashboard_change_text", { text });
  const onOpenEquation = Event.dispatcher("dashboard_select_equation");
  const onClearEquation = Event.dispatcher("dashboard_clear_equation");

  return (
    <View style={styles.textSubmit}>
      <View style={styles.captureWrapper}>
        <CaptureView ref={captureRef}>
          <View style={[styles.capture, { maxWidth: Math.min(width, 500) }]}>
            <Text style={styles.captureText}>{text}</Text>
            {Boolean(equation) && (
              <View style={styles.equationPreview}>
                <StaticField formula={`$$${equation}$$`} />
              </View>
            )}
          </View>
        </CaptureView>
      </View>

      <TextArea
        value={text}
        onChangeText={onSetText}
        testID="text-input"
        placeholder={`Example: Hi - I really need help graphing this equation. I have a test tomorrow and really don't know what to do.\n\nf(x) = x^2 - 3x + 2\n\nCan you help me with this?`}
      />
      {Platform.OS === "web" && (
        <TouchableOpacity
          testID="equation"
          style={styles.equationWrapper}
          onPress={onOpenEquation}
        >
          {equation ? (
            <View style={styles.equationPreview}>
              <View style={styles.equation}>
                <StaticField formula={`$$${equation}$$`} />
              </View>
              <TouchableOpacity
                testID="clear-equation"
                onPress={onClearEquation}
              >
                <XIcon width={16} height={16} color={Colors.grey.slate} />
              </TouchableOpacity>
            </View>
          ) : (
            <Text style={styles.equationPlaceholder}>
              Type an equation (optional)
            </Text>
          )}
        </TouchableOpacity>
      )}
      <Button
        disabled={!text.length && !equation.length}
        processing={generating}
        theme="secondary"
        style={styles.submitButton}
        testID="connect-button"
        onClick={onCapture}
      >
        Connect to a tutor
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  equationWrapper: {
    borderWidth: 2,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    ...Spacing.px2,
    ...Spacing.py1,
    ...Spacing.my2
  },
  equationPlaceholder: {
    color: Colors.grey.inactive
  },
  equationPreview: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24
  },
  equation: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  submitButton: {
    minWidth: MAIN_BUTTON_MIN_WIDTH,
    marginHorizontal: "auto",
    zIndex: -1,
    ...Spacing.mt3,
    ...Spacing.mb2
  },
  textSubmit: {
    display: "flex",
    flexDirection: "column",
    zIndex: -1,
    ...Spacing.ma2
  },
  captureWrapper: {
    opacity: 0,
    position: "absolute",
    zIndex: -1000
  },
  capture: {
    ...Spacing.pa2
  },
  captureText: {
    fontSize: 24,
    textAlign: "center"
  }
});
