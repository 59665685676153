import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { loadScript } from "helpers/Web";
import Logger, { AppEvents } from "helpers/Logger";

import { LanguageIcon } from "components/Icons";
import Colors from "assets/theme/Colors";
import { StyleSheet, View } from "react-native";
import Sizes from "assets/theme/Sizes";

type GoogleTranslateProps = {
  page: string;
  style?: object;
  useTabStyle?: boolean;
};

const languages = [{ code: "es", text: "Español" }];

export default function GoogleTranslate(props: GoogleTranslateProps) {
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(Cookies.get("googtrans")?.split("/")[2] ?? "");
    load();
  }, []);

  function getIframe() {
    return (window as any).document.getElementsByClassName(
      "goog-te-banner-frame"
    )[0] as any;
  }

  async function load() {
    await loadScript("//translate.google.com/translate_a/element.js");
    if (!(window as any).document.getElementById("google-translate-style")) {
      const styleTag = (window as any).document.createElement("style");
      styleTag.id = "google-translate-style";
      styleTag.type = "text/css";
      styleTag.innerHTML = `iframe.goog-te-banner-frame {
          opacity: 0;
          pointer-events: none;
        }
        #goog-gt-tt,
        #google-translate-select {
          display: none !important;
        }
        body {
          top: 0 !important;
        }
      `;
      (window as any).document.head.appendChild(styleTag);
    }

    setTimeout(onScriptLoad, 500);
  }

  async function onScriptLoad() {
    const TranslateElement = (window as any).google?.translate
      ?.TranslateElement;
    if (typeof TranslateElement === "function") {
      new TranslateElement(
        {
          includedLanguages: languages.map((lang) => lang.code).join(",")
        },
        "google-translate-select"
      );
    }
  }

  function onSelect(event: any) {
    const languageCode = event.target.value;
    Cookies.set("googtrans", `/auto/${languageCode}`);
    Logger.log(AppEvents.translationToggled, {
      language: languageCode,
      page: props.page,
      toggle_status: true
    });
    setLanguage(languageCode);
    (window as any).location.reload();
  }

  function onClose() {
    const iframe = getIframe();
    const closeLink = iframe.contentWindow.document.getElementsByClassName(
      "goog-close-link"
    )[0] as any;
    closeLink.click();
    setLanguage("");

    Cookies.remove("googtrans");
    Logger.log(AppEvents.translationToggled, {
      page: props.page,
      language: "",
      toggle_status: false
    });
  }

  return (
    <View
      style={[styles.wrapper, props.useTabStyle ? tabStyle : {}, props.style]}
    >
      <View style={[styles.select, { display: language ? "none" : "flex" }]}>
        <div id="google-translate-select" />
        <LanguageIcon width={16} height={16} color={Colors.ui.brown} />
        <select value={language} onChange={onSelect} style={elStyle}>
          <option disabled value="">
            Idioma
          </option>
          {languages.map((language) => (
            <option key={language.code} value={language.code}>
              {language.text}
            </option>
          ))}
        </select>
      </View>
      {Boolean(language) && (
        <button style={elStyle} onClick={onClose}>
          Stop translating
        </button>
      )}
    </View>
  );
}

const elStyle = {
  backgroundColor: "transparent",
  border: 0,
  outline: 0,
  color: Colors.ui.brown,
  fontSize: 14,
  cursor: "pointer",
  whiteSpace: "nowrap" as "nowrap"
};

const tabStyle = {
  position: "absolute",
  height: 32,
  width: "max-content",
  zIndex: 12,
  backgroundColor: Colors.light.background,
  borderWidth: 1,
  borderTopWidth: 0,
  borderBottomLeftRadius: Sizes.borderRadius,
  borderBottomRightRadius: Sizes.borderRadius,
  borderColor: Colors.ui.beige,
  paddingRight: Sizes["4px"],
  paddingLeft: Sizes["4px"],
  marginTop: -1,
  right: 24
};

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    zIndex: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  select: {
    flexDirection: "row"
  }
});
