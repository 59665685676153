import React from "react";
import { View, StyleSheet } from "react-native";

import Spacing from "assets/theme/Spacing";
import Page from "components/Page";

import HistoryContent from "../components/HistoryContent";
import HistoryNavigation from "../components/HistoryNavigation";

export default function History() {
  return (
    <Page>
      <View style={styles.wrapper} testID="history">
        <HistoryNavigation />
        <HistoryContent />
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    ...Spacing.pa3
  }
});
