import { configureStore } from "@reduxjs/toolkit";
import userReducer, {
  StateType as UserStateType,
  subscriber as userSubscriber,
  load as loadUser
} from "./User";
import uiReducer, { StateType as UIStateType } from "./UI";
import activityBulletinReducer, {
  StateType as ActivityBulletinStateType
} from "./ActivityBulletin";
import whiteboardReducer, {
  StateType as WhiteboardStateType
} from "./Whiteboard";
import partnershipsReducer, {
  StateType as PartnershipsStateType
} from "./Partnerships";
import messagesReducer, {
  subscriber as messagesSubscriber,
  StateType as MessagesStateType,
  load as loadMessages
} from "./Messages";
import sessionReducer, {
  subscriber as sessionSubscriber,
  StateType as SessionStateType,
  load as loadSession
} from "./Session";
import settingsReducer, {
  subscriber as settingsSubscriber,
  StateType as SettingsStateType,
  load as loadSettings
} from "./Settings";

import sessionHistoryReducer, {
  subscriber as sessionHistorySubscriber,
  load as loadSessionHistory,
  StateType as SessionHistoryStateType
} from "./SessionHistory";
import serverTimeReducer, {
  subscriber as serverTimeSubscriber,
  load as loadServerTime,
  StateType as ServerTimeStateType
} from "./ServerTime";

export const store = configureStore({
  reducer: {
    user: userReducer,
    ui: uiReducer,
    session: sessionReducer,
    messages: messagesReducer,
    settings: settingsReducer,
    sessionHistory: sessionHistoryReducer,
    serverTime: serverTimeReducer,
    activityBulletin: activityBulletinReducer,
    whiteboard: whiteboardReducer,
    partnerships: partnershipsReducer
  }
});

store.subscribe(() => {
  userSubscriber(store.getState().user);
  sessionSubscriber(store.getState().session);
  messagesSubscriber(store.getState().messages);
  sessionHistorySubscriber(store.getState().sessionHistory);
  settingsSubscriber(store.getState().settings);
  sessionHistorySubscriber(store.getState().sessionHistory);
  serverTimeSubscriber(store.getState().serverTime);
});

export default store;

export type StoreState = {
  session: SessionStateType;
  ui: UIStateType;
  user: UserStateType;
  settings: SettingsStateType;
  sessionHistory: SessionHistoryStateType;
  messages: MessagesStateType;
  serverTime: ServerTimeStateType;
  activityBulletin: ActivityBulletinStateType;
  whiteboard: WhiteboardStateType;
  partnerships: PartnershipsStateType;
};

export async function loadState() {
  return await Promise.all([
    loadUser(),
    loadSettings(store),
    loadSession(store),
    loadSessionHistory(store),
    loadServerTime(store),
    loadMessages(store)
  ]);
}
