import React from "react";
import { StyleSheet, View } from "react-native";
import { sessionIsReady } from "models/Session";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { Event } from "events/Event";

import Colors from "assets/theme/Colors";
import Typography from "assets/theme/Typography";

import Modal from "components/Modal";
import Text from "components/Text";

import Profile from "./Profile";
import TutorProfileImage from "components/TutorProfileImage";
import Spacing from "assets/theme/Spacing";

export default function TutorProfileModal() {
  const session = useSelector((state: StoreState) => state.session.current);
  const showTutorProfile = useSelector(
    (state: StoreState) => state.ui.showTutorProfileModal
  );
  const sessionStartCountdown = useSelector(
    (state: StoreState) => state.ui.sessionStartCountdown
  );
  const ready = sessionIsReady(session!);
  const onDismiss = Event.dispatcher("session_dismiss_tutor_profile");

  function getWaitingText() {
    if (sessionStartCountdown === 1) {
      return `Your session will start in ${sessionStartCountdown} second`;
    } else if (sessionStartCountdown > 0) {
      return `Your session will start in ${sessionStartCountdown} seconds`;
    }
    return "Your session will start soon";
  }

  if (!session?.tutor) return null;

  return (
    <Modal show={showTutorProfile} onDismiss={onDismiss}>
      {ready ? (
        <Text style={styles.readyText}>Your session has started!</Text>
      ) : (
          <Text style={styles.waitingText}>{getWaitingText()}</Text>
        )}
      <Text style={styles.subheader}>While you're waiting, get to know</Text>
      <Text style={styles.header}>{session.tutor!.profile.polite_name}</Text>
      <View style={styles.imageWrapper} testID="tutor-profile-modal">
        <TutorProfileImage tutor={session.tutor} />
      </View>
      <Profile />
    </Modal>
  );
}

const styles = StyleSheet.create({
  subheader: {
    ...Typography.accent.small,
    lineHeight: 12,
    color: Colors.grey.slate,
    textAlign: "center"
  },
  header: {
    ...Typography.accent.large,
    fontSize: 18,
    textAlign: "center"
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  readyText: {
    textAlign: "center",
    color: Colors.brand.green,
    fontWeight: "bold",
    ...Spacing.mb2
  },
  waitingText: {
    textAlign: "center",
    color: Colors.ui.blue,
    fontWeight: "bold",
    ...Spacing.mb2
  }
});
