import { AuthHelper, NetworkHelper, retry } from "@yups/utils";
import { MessageType } from "components/ChatRoom/types/Message";
import Logger, { APILogs, getLogTagFromEndPoint } from "helpers/Logger";
import Debug, { DebugLog } from "helpers/Debug";

import Device from "types/Device";
import Response from "types/Response";
import { AssistmentRequestData } from "models/Partnerships";
import FeatureDecision from "./FeatureDecision";
import { YupAPI } from "./YupAPI";
import User from "models/User";

enum Method {
  GET = "get",
  POST = "post",
  PUT = "put"
}

const statusTag = {
  success: "success:true",
  error: "success:false"
};

let retryCount = 0;

const WebService = {
  setRetries: function (retries: number) {
    retryCount = retries;
  },

  // Sign in
  signIn: async function (info: SignInRequest) {
    return await makeRequest(Method.POST, "/api/signin_codes/register", info);
  },

  verify: async function (info: VerifyRequest) {
    return await makeRequest(Method.PUT, "/api/signin_codes/verify", info);
  },
  googleSSO: async function (info: GoogleAuthRequest) {
    if (FeatureDecision.enableYupAPI("googleSSO")) {
      return await YupAPI.googleSSO(info);
    }
    return await makeRequest(Method.POST, "/api/google_sso", info);
  },
  cleverSSO: async function (code: string) {
    return await makeRequest(Method.POST, "/api/clever_sso", { code });
  },
  signUp: async function (info: SignUpRequest) {
    return await makeRequest(Method.POST, "/api/student", info);
  },

  // Settings
  syncTime: async function () {
    return await makeRequest(Method.GET, "/api/settings/server_time");
  },
  syncUserData: async function () {
    if (FeatureDecision.enableYupAPI("getStudent")) {
      const userId = User.get()?.id;
      return await YupAPI.getStudent(userId);
    }

    return await makeRequest(Method.PUT, "/api/sync_user_data");
  },
  getFeatures: async function () {
    if (FeatureDecision.enableYupAPI("getFeatures")) {
      return YupAPI.fetchFeatures();
    }
    return await makeRequest(Method.GET, `/api/features`);
  },

  // Dashboard
  getBulletins: async function () {
    return await makeRequest(Method.GET, "/api/activity_bulletins/bulletins");
  },

  // Session
  getSession: async function (sessionId: number) {
    if (FeatureDecision.enableYupAPI("createSession")) {
      return YupAPI.getSession(sessionId);
    }

    return await makeRequest(
      Method.GET,
      `/api/math_crunch_sessions/${sessionId}`,
      { omit_messages: true }
    );
  },
  createSession: async function (info: CreateSessionRequest) {
    if (FeatureDecision.enableYupAPI("createSession")) {
      return YupAPI.createSession(info);
    }

    return await makeRequest(Method.POST, "/api/create_session", info);
  },
  endSession: async function (info: EndSessionRequest) {
    if (FeatureDecision.enableYupAPI("endSession")) {
      return YupAPI.endSession(info.id, info);
    }
    return await makeRequest(Method.POST, "/api/terminate_session", info);
  },
  sendMessage: async function (info: MessageType) {
    if (FeatureDecision.enableYupAPI("sendMessage")) {
      return await YupAPI.sendMessage(info);
    }
    return await makeRequest(Method.POST, "api/message", info);
  },
  sessionGetNewMessages: async function (
    sessionId: number,
    latestMessageId: number
  ) {
    if (FeatureDecision.enableYupAPI("sessionGetNewMessages")) {
      return await YupAPI.getNewMessages(sessionId, latestMessageId);
    }
    return await makeRequest(
      Method.GET,
      `/api/math_crunch_sessions/${sessionId}/latest-messages/${latestMessageId}`
    );
  },
  sessionSendLatestMessageId: async function (
    sessionId: number,
    latestMessageId: number
  ) {
    if (FeatureDecision.enableYupAPI("sessionSendLatestMessageId")) {
      return await YupAPI.sendLatestMessageId(sessionId, latestMessageId);
    }
    return await makeRequest(
      Method.POST,
      `/api/math_crunch_sessions/${sessionId}/latest-messages/${latestMessageId}`
    );
  },
  getNewDeliveries: async function (sessionId: number) {
    if (FeatureDecision.enableYupAPI("getNewDeliveries")) {
      return await YupAPI.getNewMessageDeliveries(sessionId);
    }
    return await makeRequest(
      Method.GET,
      `/api/math_crunch_sessions/${sessionId}/latest-messages-status`
    );
  },
  likeTutorBioDetail: async function (bioName: string, tutorId: number) {
    return await makeRequest(
      Method.POST,
      `/api/tutor_bio_question/like/${bioName}/${tutorId}`
    );
  },
  unlikeTutorBioDetail: async function (bioName: string, tutorId: number) {
    return await makeRequest(
      Method.POST,
      `/api/tutor_bio_question/unlike/${bioName}/${tutorId}`
    );
  },
  reactToMessage: async function (payload: ReactToMessageRequest) {
    return await makeRequest(Method.POST, `/api/messages/reaction`, payload);
  },
  // Used for Android pusher workaround
  // authenticatePusherUser: async function (socket_id: string, channel_name: string) {
  //   return await makeRequest(Method.POST, AuthHelper.getPusherAuthEndpoint(), {socket_id, channel_name});
  // },

  // Post session
  submitSessionFeedback: async function (feedback: SubmitFeedbackRequest) {
    if (FeatureDecision.enableYupAPI("submitStudentFeedback")) {
      return YupAPI.submitStudentFeedback(feedback.id, feedback);
    }
    return await makeRequest(Method.POST, "/api/session_issues", feedback);
  },

  // Session history
  getSessionHistory: async function () {
    return await makeRequest(Method.GET, "/api/student/session_history");
  },
  getSessionTranscript: async function (sessionId: number) {
    return await makeRequest(
      Method.GET,
      `/api/student/session_history/${sessionId}`
    );
  },
  getSessionWhiteboardCaptures: async function (session_id: number) {
    return await makeRequest(Method.GET, `api/whiteboard_captures`, {
      session_id
    });
  },

  // Profile
  getFavoriteTutors: async function () {
    return await makeRequest(Method.GET, `/api/favorite_tutors`);
  },
  getSuggestedTutors: async function () {
    return await makeRequest(
      Method.GET,
      `/api/favorite_tutors/suggested_tutors`
    );
  },
  setFavoriteTutors: async function (favorite_tutor_ids: Array<number>) {
    return await makeRequest(Method.POST, `/api/favorite_tutors`, {
      favorite_tutor_ids
    });
  },

  // Partnerships
  signInByAssistments: async function (externalRef: string) {
    return await makeRequest(Method.POST, "/api/assistments/sign_in", {
      ext_ref: externalRef
    });
  },
  publishAssistmentProblem: async function (url: string) {
    return await makeRequest(Method.POST, "/api/assistments/publish_problem", {
      url
    });
  },
  signInByAscendMath: async function (externalRef: string) {
    return await makeRequest(Method.POST, "/api/ascend_math/sign_in", {
      ext_ref: externalRef
    });
  }
};

export default WebService;

NetworkHelper.onError(({ error, endPoint, method }) => {
  if (endPoint?.includes("log_entries")) {
    return;
  }
  Logger.log(APILogs.api, [
    method,
    getLogTagFromEndPoint(endPoint),
    statusTag.error,
    error?.message
  ]);
});

function logAPIRequestTime(startTime: Date, endPoint: string) {
  if (Debug.get(DebugLog.APIRequests)) {
    const delta = new Date().getTime() - startTime.getTime();
    if (delta > 500) {
      // highlight requests that take over 500ms in red
      console.log(`%c [API REQUEST] ${endPoint} - ${delta}ms`, "color: red");
    } else {
      console.log(`[API REQUEST] ${endPoint} - ${delta}ms`);
    }
  }
}

async function makeRequest(
  method: Method,
  endPoint: string,
  params: any = {}
): Promise<Response> {
  const response: Response = { success: false };

  const retries = retryCount;
  retryCount = 0;

  try {
    await retry(async () => {
      const ts = new Date();

      const result = await NetworkHelper[method]({
        endPoint,
        params
      });

      logAPIRequestTime(ts, endPoint);

      Logger.logTime(APILogs.api, getLogTagFromEndPoint(endPoint), ts);

      if (!("success" in result)) {
        // if the success flag isn't set, use status_code === 200
        // to determine if the request was successful
        result.success = result.status_code === 200;
      }

      if (result.status_code === 401) {
        // Handle redirect to login
        const user = require("models/User").default;
        user.handleUnauthorized();
      }

      response.data = {
        ...result,
        status_code: result.status_code
      };
      response.success = Boolean(result.success);

      if (result.success) {
        Logger.log(APILogs.api, [
          getLogTagFromEndPoint(endPoint),
          statusTag.success
        ]);
      }
    }, retries);
  } catch (error) {
    Logger.log(APILogs.api, [
      getLogTagFromEndPoint(endPoint),
      statusTag.error,
      "exception:true"
    ]);
    response.data = error;
  }

  return response;
}

// Request types.

export type SignInRequest = {
  email: string | null;
  phone_number: string | null;
};

export type VerifyRequest = {
  code: string;
  email: string | null;
  phone_number: string | null;
  device: Device;
};

export type GoogleAuthRequest = {
  email: string;
  id_token: string;
  scope: string;
  device_type: string;
};

export type SignUpRequest = {
  user: {
    first_name: string;
    last_name: string;
    email: string | null;
    phone_number: string | null;
  };
  device: Device;
};

export type CreateSessionRequest = {
  student_subtopic_id: number;
  assistments_data?: AssistmentRequestData;
  device?: Device;
};

export type ReactToMessageRequest = {
  message_id: number;
  user_id: number; // student id
  reaction_name: string;
  message_key: string;
  reacted_at: number;
  user_name: string; // student name
};

export type EndSessionRequest = {
  id: number; // TODO: Remove once yup-api end session endpoint is live
  ended_at: number; // TODO: Remove once yup-api end session endpoint is live
  ended_action: string;
  ended_by: string; // TODO: Remove once yup-api end_session endpoint is live
  reasons: Array<number>;
};

export type SubmitFeedbackRequest = {
  id: number; // TODO: Remove once yup-api end session endpoint is live
  rating: number;
  multiselect_feedback: Array<string>;
  feedback: string;
  math_crunch_session_id: number; // TODO: Remove once yup-api submit_student_feedback endpoint is live
  complaint: Array<string>; // TODO: Remove once yup-api submit_student_feedback endpoint is live
};
