import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useMediaQuery } from "react-responsive";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import { AvailableIcon, CelebrateIcon, RocketIcon } from "components/Icons";
import Snackbar from "components/Snackbar";
import { BulletinType } from "types/ActivityBulletin";
import { Event } from "events/Event";

const IconMap = {
  [BulletinType.SessionEnded]: CelebrateIcon,
  [BulletinType.SessionStarted]: RocketIcon,
  [BulletinType.TutorsAvailable]: AvailableIcon
};

export default function ActivityBulletin() {
  const bulletin = useSelector(
    (state: StoreState) => state.activityBulletin.bulletin
  );

  const isMd = useMediaQuery({ minWidth: Layout.md });
  const { width } = useWindowDimensions();
  if (!isMd || !bulletin) return null;

  const onGetNextBulletin = Event.dispatcher(
    "dashboard_load_activity_bulletin"
  );

  const Icon = IconMap[bulletin.type] ?? CelebrateIcon;
  return (
    <Snackbar
      text={bulletin.message}
      textStyle={styles.text}
      icon={<Icon width={32} height={32} color={Colors.ui.blue} />}
      style={[styles.snackbar, { maxWidth: width / 2 }]}
      delay={7000}
      onDismiss={onGetNextBulletin}
    />
  );
}

const styles = StyleSheet.create({
  snackbar: {
    backgroundColor: Colors.ui.white,
    borderRadius: Sizes["64px"],
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    zIndex: 1,
    ...Spacing.py2,
    ...Spacing.px3
  },
  text: {
    fontSize: 18,
    color: Colors.ui.black,
    ...Spacing.mx2
  }
});
