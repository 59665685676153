import {
  setSessions,
  setTranscript,
  setWhiteboardCaptures
} from "store/SessionHistory";
import store from "store";
import WebService from "helpers/WebService";
import { Image } from "react-native";
import { WhiteboardCapture } from "types/WhiteboardCapture";

export type HistoricalSession = {
  id: number;
  topic: string;
  tutor_formal_name: string;
  tutor_id: number;
  n_achievements: number;
  created_at: string;
  rating?: number;
};

const Errors = {
  transcriptFailed: "Unable to load transcript",
  whiteboardFailed: "Unable to load whiteboard",
  default: "Something went wrong. Please refresh the page"
};

const SessionHistory = {
  async load() {
    const response = await WebService.getSessionHistory();
    if (response.success) {
      store.dispatch(setSessions(response.data.sessions));
    } else {
      response.message = Errors.default;
    }
    return response;
  },
  async loadTranscript(sessionId: number) {
    const data = store.getState().sessionHistory.transcripts[sessionId];
    if (data) return { success: true, data };
    const response = await WebService.getSessionTranscript(sessionId);
    if (response.success) {
      store.dispatch(
        setTranscript({
          id: sessionId,
          ...response.data
        })
      );
    } else {
      response.message = response.data
        ? Errors.transcriptFailed
        : Errors.default;
    }
    return response;
  },
  async loadCaptures(sessionId: number) {
    const response = await WebService.getSessionWhiteboardCaptures(sessionId);
    if (response.success) {
      await this.preloadCaptureImages(response.data.data);
      store.dispatch(setWhiteboardCaptures(response.data.data));
    } else {
      response.message = response.data
        ? Errors.whiteboardFailed
        : Errors.default;
    }
    return response;
  },
  async preloadCaptureImages(captures: Array<WhiteboardCapture>) {
    for (let i = 0; i < captures.length; ++i) {
      try {
        await Image.prefetch(captures[i].url);
      } catch {}
    }
  }
};

export default SessionHistory;

export function getLogInfo() {
  // Do not disclose any personal information (name, email, phone...)
  const sessions = store.getState().sessionHistory.sessions;
  return sessions.map((session) => session?.id);
}
