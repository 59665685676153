import { PusherChannel, PusherChannelEvent, SessionEvent } from "@yups/utils";
import Logger, { PusherLogs } from "helpers/Logger";
import { Pusher, PusherEvent } from "helpers/Pusher";
import { Platform } from "react-native";

export class SessionChannel {
  static channel: PusherChannel | null = null;
  static async on(eventName: SessionEvent | PusherEvent, callback: Function) {
    try {
      const Session = require("models/Session").default;
      const session = Session.get();
      if (!this.channel) await this.create(session.channel_name);
      this.channel?.bind(eventName, callback);
    } catch {
      // TODO: Report to sentry
      Logger.log(PusherLogs.pusherSubscribedToChannelFailed);
    }
  }
  static remove(eventName: string, callback: Function) {
    this.channel?.unbind(eventName, callback);
  }
  static destroy() {
    this.channel?.unbind_all();
    this.channel = null;
  }
  static triggerThrottled(eventName: string, delay: number, data: object) {
    this.channel?.triggerThrottled(eventName, delay, this.formattedData(data));
  }
  static async trigger(eventName: string, data?: object) {
    const success = await this.channel?.trigger(eventName, this.formattedData(data));
    if (!success) { /* TODO: log/send to Sentry */ }
  }

  private static async create(channelName: string) {
    if(!channelName) return;

    this.channel = await Pusher.subscribeToChannel(channelName);
    this.channel.bind(PusherChannelEvent.subscriptionSucceeded, () =>
      Logger.log(PusherLogs.pusherSubscribedToChannel)
    );
    this.channel.bind(PusherChannelEvent.subscriptionFailed, () =>
      Logger.log(PusherLogs.pusherSubscribedToChannelFailed)
    );
  }
  private static formattedData(data?: object) {
    data = data ?? {}
    return ((Platform.OS === "android")? JSON.stringify(data) : data) as any;
  }
}
