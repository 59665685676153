import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Achievement as AchievementType } from "models/Settings";
import { User } from "models/User";

import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Card from "components/Card";
import Text from "components/Text";

import Achievement from "./Achievement";
import { useSelector } from "react-redux";
import { StoreState } from "store";

export default function Achievements() {
  const user = useSelector((state: StoreState) => state.user.user);
  const settings = useSelector((state: StoreState) => state.settings.settings);

  if (!user || !settings) return null;

  function getAchievements() {
    return settings!.achievements.definitions
      .filter(
        (achievement) => achievement.achievement_type !== "LOCKED_ACHIEVEMENT"
      )
      .map((achievement) => {
        const match = user!.achievements?.find(
          (a) => a.type === achievement.achievement_type
        );
        return {
          ...achievement,
          count: match?.count ?? 0
        };
      });
  }

  function getSubheader() {
    const count = (user!.achievements ?? []).reduce((s, a) => s + a.count, 0);
    return `You've earned ${count} ${
      count === 1 ? "achievement" : "achievements"
    }`;
  }

  return (
    <Card style={styles.card}>
      <Text style={styles.header}>Achievements</Text>
      <Text style={styles.subheader}>{getSubheader()}</Text>
      <View style={styles.achievements}>
        {getAchievements().map((achievement) => (
          <Achievement
            key={achievement.name}
            achievement={achievement}
            count={achievement.count}
          />
        ))}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    ...Spacing.pa2
  },
  header: {
    textAlign: "center",
    ...Typography.accent.large,
    fontSize: 20
  },
  subheader: {
    textAlign: "center",
    ...Spacing.mb2
  },
  achievements: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  }
});
