import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

import { DashboardUI, GeneralUI } from "models/UI";

import ExtensionController from "./ExtensionController";

class CameraController extends Controller {
  init() {
    Event.on("camera_exit", onExitCamera);
    Event.on("camera_set_permission", onSetCameraPermission);
    Event.on("camera_picture_taken", onPictureTaken);

    ExtensionController.init();
  }
  deinit() {
    Event.remove("camera_exit", onExitCamera);
    Event.remove("camera_set_permission", onSetCameraPermission);
    Event.remove("camera_picture_taken", onPictureTaken);

    ExtensionController.deinit();
  }
}

const controller = new CameraController();
export default controller;

function onSetCameraPermission({ hasPermission }: { hasPermission: boolean }) {
  GeneralUI.setHasCameraPermission(hasPermission);
}

function onPictureTaken({ photo }: { photo: string }) {
  DashboardUI.setPhoto(photo);
  Router.replace("ScratchBoard");
  DashboardUI.onPhotoTaken();
}

function onExitCamera() {
  DashboardUI.setPhoto("");
  Router.replace("Dashboard");
}
