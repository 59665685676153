import React from "react";
import {
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View
} from "react-native";

import Button from "components/Button";
import { XIcon } from "components/Icons";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import { SafeAreaView } from "react-native-safe-area-context";

type ModalProps = {
  show: boolean;
  width?: number;
  onDismiss?: () => void;
  children: React.ReactNode;
};
export default function Modal(props: ModalProps) {
  const { width, height } = useWindowDimensions();
  if (!props.show) return null;

  return (
    <>
      <View style={styles.overlay}></View>
      <View style={[styles.modal, { width, height }]}>
          <SafeAreaView style={styles.safeAreaView}>
            <ScrollView
              contentContainerStyle={[styles.scrollView, { maxHeight: height }]}
            >
            <View
              style={[
                styles.modalView,
                {
                  maxWidth: Math.min(width, 500),
                  minWidth: Math.min(200, width - 32)
                },
                props.width ? { width: props.width, maxWidth: props.width } : {}
              ]}
            >
              <View style={styles.closeHeader}>
                {props.onDismiss && (
                  <Button
                    testID="modal-close"
                    style={styles.button}
                    theme="flat"
                    onClick={props.onDismiss}
                  >
                    <XIcon width={20} height={20} color={Colors.grey.inactive} />
                  </Button>
                )}
              </View>
              <View style={styles.content}>{props.children}</View>
            </View>
          </ScrollView>
        </SafeAreaView>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    zIndex: 4
  },
  safeAreaView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1
  },
  scrollView: {
    alignItems: "center"
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "black",
    opacity: 0.4,
    zIndex: 4
  },
  modalView: {
    ...Spacing.mx2,
    ...Spacing.my4,
    backgroundColor: Colors.ui.white,
    borderRadius: Sizes.borderRadius
  },
  button: {
    ...Spacing.mt2
  },
  closeHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -Sizes["4px"]
  },
  content: {
    ...Spacing.px3,
    ...Spacing.pb3
  }
});
