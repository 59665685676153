import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import Carousel from "components/Carousel";
import Text from "components/Text";
import Image from "components/Image";
import Modal from "components/Modal";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { getSessionImages } from "models/SessionMessages";

const PADDING = Sizes["16px"];

function ImagePage(props: { item: Array<string>; imageStyle: object }) {
  const previewImage = useSelector(
    (state: StoreState) => state.ui.galleryPreviewImage
  );
  const onSetImage = (url: string) =>
    Event.dispatch("session_set_preview_image", { url });

  return (
    <View style={styles.imagePage}>
      {props.item.map((src) => (
        <TouchableOpacity
          key={src}
          testID="gallery-image"
          onPress={() => onSetImage(src)}
        >
          <Image
            uri={src}
            style={[
              styles.image,
              props.imageStyle,
              previewImage === src ? styles.selectedImage : {}
            ]}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
}

export default function ImageGallery() {
  const show = useSelector((state: StoreState) => state.ui.showImageGallery);
  const previewImage = useSelector(
    (state: StoreState) => state.ui.galleryPreviewImage
  );
  const messages = useSelector((state: StoreState) => state.messages.messages);
  const { width, height } = useWindowDimensions();
  const IMAGE_SIZE = Math.min(Sizes["128px"], width / 4);
  const [page, setPage] = useState(0);
  const imagesPerPage = Math.floor(width / IMAGE_SIZE) - 1;
  const maxWidth = imagesPerPage * IMAGE_SIZE + imagesPerPage * 8;
  const images = getSessionImages(messages);

  const onSetImage = (url: string) =>
    Event.dispatch("session_set_preview_image", { url });
  const onDismiss = Event.dispatcher("session_dismiss_image_gallery");
  const onDraw = Event.dispatcher("session_draw_on_image", {
    url: previewImage ?? images[0]
  });

  useEffect(() => onSetImage(images[0]), []);

  useEffect(() => {
    setPage(
      getImagePages().findIndex((images) => images.includes(previewImage ?? ""))
    );
  }, [previewImage]);

  function getImagePages() {
    const pages = [];
    const totalPages = Math.ceil(images.length / imagesPerPage);
    for (let i = 0; i < totalPages; i++) {
      pages.push(
        images.slice(i * imagesPerPage, i * imagesPerPage + imagesPerPage)
      );
    }
    return pages;
  }

  function renderItem(itemProps: { item: Array<string> }) {
    return (
      <ImagePage
        {...itemProps}
        imageStyle={{
          width: IMAGE_SIZE,
          height: IMAGE_SIZE
        }}
      />
    );
  }

  return (
    <Modal width={width - PADDING * 2} show={show} onDismiss={onDismiss}>
      <View style={styles.preview} testID="image-gallery-preview">
        <Text tag="h3">Images from this session</Text>
        <Image
          uri={previewImage ?? images[0]}
          style={[
            styles.previewImage,
            {
              width: width - PADDING * 2,
              height: height - PADDING * 2,
              maxWidth: width,
              maxHeight: height - 400
            }
          ]}
          maintainAspectRatio={true}
          maxSize={width - PADDING * 2}
        />
        <Button
          testID="image-gallery-draw"
          style={styles.drawButton}
          onClick={onDraw}
        >
          Draw
        </Button>
      </View>
      <View style={styles.images}>
        <Carousel
          items={getImagePages()}
          itemElement={renderItem}
          containerWidth={maxWidth}
          itemWidth={maxWidth}
          index={page}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  images: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    ...Spacing.pt3
  },
  imagePage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  image: {
    resizeMode: "cover",
    borderWidth: 2,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    ...Spacing.ma1
  },
  selectedImage: {
    borderColor: Colors.ui.blue
  },
  preview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  previewImage: {
    flexGrow: 1,
    ...Spacing.my2
  },
  drawButton: {
    width: 200
  }
});
