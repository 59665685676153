export default {
  "4px": 4,
  "8px": 8,
  "16px": 16,
  "24px": 24,
  "32px": 32,
  "48px": 48,
  "64px": 64,
  "96px": 96,
  "128px": 128,
  borderRadius: 8,
  navigationHeight: 58
};
