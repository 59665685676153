import React, { useEffect, useState } from "react";
import {
  Image as NativeImage,
  StyleSheet,
  ImageSourcePropType,
  View,
  useWindowDimensions
} from "react-native";

type BaseImageProps = {
  style?: any;
  maintainAspectRatio?: boolean;
  maxSize?: number;
};

type RemoteImageProps = BaseImageProps & {
  uri: string;
  src?: ImageSourcePropType;
};

type LocalImageProps = BaseImageProps & {
  uri?: string;
  src: ImageSourcePropType;
};

type ImageProps = RemoteImageProps | LocalImageProps;

export default React.memo(function Image(props: ImageProps) {
  const dimensions = useWindowDimensions();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (!shouldResize()) return;
    NativeImage.getSize(props.uri!, (imgWidth, imgHeight) => {
      setImageWidth(imgWidth);
      setImageHeight(imgHeight);
    });
  }, []);

  useEffect(() => {
    setSize();
  }, [dimensions, imageWidth, imageHeight]);

  function shouldResize() {
    return props.maintainAspectRatio && props.maxSize;
  }

  function setSize() {
    if (!shouldResize()) return;
    if (imageWidth > imageHeight) {
      const newWidth = Math.min(
        props.maxSize ?? imageWidth,
        imageWidth,
        dimensions.width
      );
      setWidth(newWidth);
      setHeight((newWidth * imageHeight) / Math.max(imageWidth, 1));
    } else {
      const newHeight = Math.min(
        props.maxSize ?? imageHeight,
        imageHeight,
        dimensions.height
      );
      setHeight(newHeight);
      setWidth((newHeight * imageWidth) / Math.max(imageHeight, 1));
    }
  }

  const source = props.src ?? {
    uri: props.uri,
    headers: {
      Accept: "*/*"
    }
  };

  const style = [styles.image, { width, height }, props.style];

  return (
    <View>
      <NativeImage source={source} style={style} />
    </View>
  );
});

const styles = StyleSheet.create({
  image: { resizeMode: "contain" }
});
