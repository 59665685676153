import { Event } from "events/Event";
import { Router } from "navigation/Router";
import Session, { closeSession, sessionRequiresRating } from "models/Session";
import Settings from "models/Settings";
import { SessionUI } from "models/UI";
import User from "models/User";
import store from "store";
import { Controller } from "types";

class SessionFeedbackController extends Controller {
  init() {
    Event.on("postsession_on_favorite_tutor", onAddTutorToFavorites);
    Event.on("postsession_on_unfavorite_tutor", onRemoveTutorFromFavorites);
    Event.on("postsession_on_dismiss_tutor_prompt", dismissFavoriteTutorPrompt);
    Event.on("postsession_set_reason", toggleFeedbackReason);
    Event.on("postsession_set_comment", setFeedbackComment);
    Event.on("postsession_on_submit_feedback", handleSubmit);

    onLoad();
  }
  deinit() {
    Event.remove("postsession_on_favorite_tutor", onAddTutorToFavorites);
    Event.remove("postsession_on_unfavorite_tutor", onRemoveTutorFromFavorites);
    Event.remove(
      "postsession_on_dismiss_tutor_prompt",
      dismissFavoriteTutorPrompt
    );
    Event.remove("postsession_set_reason", toggleFeedbackReason);
    Event.remove("postsession_set_comment", setFeedbackComment);
    Event.remove("postsession_on_submit_feedback", handleSubmit);
  }
}

const controller = new SessionFeedbackController();
export default controller;

function onLoad() {
  if (!Settings.get() || !User.get()) {
    Router.replace("SignIn");
  } else if (!Session.get() || !Session.get()!.tutor || !sessionRequiresRating(Session.get()!)) {
    goToDashboard();
  } else if (store.getState().ui.rating === null) {
    Router.replace("SessionRating");
  }
}

function goToDashboard() {
  closeSession();
  SessionUI.reset();
  User.sync();
  Router.replace("Dashboard");
}

async function onAddTutorToFavorites() {
  const session = Session.get();
  if (!session?.tutor) return;
  await User.addFavoriteTutor(
    session.tutor.id,
    "post-session",
    session.tutor.profile.polite_name
  );
  dismissFavoriteTutorPrompt();
}
async function onRemoveTutorFromFavorites() {
  const session = Session.get();
  if (!session?.tutor) return;
  await User.removeFavoriteTutor(
    session.tutor.id,
    "post-session",
    session.tutor.profile.polite_name
  );
  dismissFavoriteTutorPrompt();
}

function dismissFavoriteTutorPrompt() {
  SessionUI.setShowFavoriteTutorPrompt(false);
  submitFeedback();
}

function toggleFeedbackReason({ reason }: { reason: string }) {
  SessionUI.toggleReason(reason);
}

function setFeedbackComment({ comment }: { comment: string }) {
  SessionUI.setFeedbackComment(comment);
}

async function handleSubmit() {
  const session = Session.get();
  const user = User.get();
  const rating = store.getState().ui.rating;
  if (!session?.tutor || !user) return;

  const isFavorite = user.favorite_tutor_ids.includes(session.tutor.id);
  if (rating || isFavorite) SessionUI.setShowFavoriteTutorPrompt(true);
  else await submitFeedback();
}

async function submitFeedback() {
  SessionUI.setSubmittingFeedback(true);
  const { success, message } = await Session.submitFeedback(
    SessionUI.getFeedback()
  );

  if (success) {
    goToDashboard();
  }
  else if (message) {
    SessionUI.setError(message);
  }
  SessionUI.setSubmittingFeedback(false);
}
