import * as React from "react";
import { StyleSheet, View } from "react-native";
import { RootStackScreenProps } from "types";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import { LogoIcon } from "components/Icons";
import Page from "components/Page";

import OnboardingSlide from "../components/OnboardingSlide";
import OnboardingNavigation from "../components/OnboardingNavigation";

export default function Onboarding(props: RootStackScreenProps<"Onboarding">) {
  return (
    <Page>
      <View style={styles.wrapper} testID="onboarding">
        <View style={styles.logo}>
          <LogoIcon
            width={100}
            height={Sizes["32px"]}
            color={Colors.brand.blue}
          />
        </View>
        <OnboardingSlide />
        <OnboardingNavigation />
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    ...Spacing.pa4
  },
  logo: {
    ...Spacing.mb4
  }
});
