import * as React from "react";
import {
  View,
  StyleSheet,
  SafeAreaView,
  useWindowDimensions
} from "react-native";

import Image from "components/Image";
import Sizes from "assets/theme/Sizes";

import Modal from "components/Modal";
import { Event } from "events/Event";
import { StoreState } from "store";
import { useSelector } from "react-redux";

const PADDING = Sizes["64px"];

export default function SessionImage() {
  const dimensions = useWindowDimensions();
  const image = useSelector((state: StoreState) => state.ui.transcriptImage);

  const onDismiss = Event.dispatcher("history_transcript_dismiss_image");

  return (
    <Modal
      show={Boolean(image)}
      width={dimensions.width - PADDING}
      onDismiss={onDismiss}
    >
      <SafeAreaView>
        <View style={styles.preview} testID="transcript-image">
          {image && (
            <Image
              uri={image}
              maintainAspectRatio={true}
              maxSize={Math.min(dimensions.height, dimensions.width) - PADDING}
            />
          )}
        </View>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  preview: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});
