import AWS from "aws-sdk";
import Config from "helpers/Config";

const credentials = {
  accessKeyId: Config.AwsAccessKey,
  secretAccessKey: Config.AwsSecretKey,
  region: Config.AwsRegion
};

export enum Bucket {
  mobileUploads = "yup-mobile-temp-images",
  development = "math-crunch-dev",
  production = "math-crunch"
}

const bucketName: Bucket =
  process.env?.NODE_ENV === "development"
    ? Bucket.development
    : Bucket.production;

export const S3 = new AWS.S3(credentials);

export async function readFile(key: string): Promise<string> {
  return new Promise((resolve, reject) => {
    S3.getObject({ Bucket: bucketName, Key: key }, function (err, data) {
      if (err || !data.Body) {
        reject(err);
      } else {
        resolve(data.Body.toString("utf-8"));
      }
    });
  });
}

const yearInSeconds = 31536000;

interface getSignedUrlProps {
  bucket: Bucket;
  path: string;
}

export function getSignedUrl(props: getSignedUrlProps) {
  return S3.getSignedUrl("getObject", {
    Bucket: props.bucket,
    Key: props.path,
    Expires: yearInSeconds
  });
}

interface writeFileProps {
  imageData: Blob;
  path: string;
  contentType: string;
  bucket?: Bucket;
  onProgressCallback?: (percentsComplete: number) => void;
  onCompletionCallback?: (url: string) => void;
  onFailureCallback?: (error: Error) => void;
}

export function writeFile(props: writeFileProps) {
  const bucket = props.bucket ?? bucketName;
  const params: any = {
    Body: props.imageData,
    Bucket: bucket,
    ContentType: props.contentType,
    Key: props.path
  };
  const upload = new AWS.S3.ManagedUpload({ params, service: S3 });
  upload
    .on("httpUploadProgress", (event: AWS.S3.ManagedUpload.Progress) => {
      const percentsComplete: number = (event.loaded * 100) / event.total;
      props.onProgressCallback?.(percentsComplete);
    })
    .send((error: Error) => {
      if (error) {
        props.onFailureCallback?.(error);
        return;
      }
      delete params.Body;
      delete params.ContentType;
      params.Expires = yearInSeconds;
      props.onCompletionCallback?.(
        getSignedUrl({
          bucket,
          path: props.path
        })
      );
    });
  return upload;
}

interface deleteFileProps {
  bucket: Bucket;
  path: string;
}

export async function deleteFile(props: deleteFileProps) {
  return await S3.deleteObject({
    Bucket: props.bucket,
    Key: props.path
  }).promise();
}
