import { NavigationRef } from "navigation";
import { Event } from "events/Event";
import { AllStacks } from "types";

export const Router = {
  getCurrent() {
    const state = NavigationRef.current?.getState();
    return state?.routes[state.index ?? state.routes.length - 1];
  },
  navigate(routeName: keyof AllStacks, params?: object) {
    Event.dispatch("on_navigate", { routeName, params });
  },
  replace(routeName: keyof AllStacks, params?: object) {
    Event.dispatch("on_replace", { routeName, params });
  },
  openLink(url: string) {
    Event.dispatch("open_link", { url });
  }
};
