export enum BulletinType {
  SessionStarted = "session_started",
  SessionEnded = "session_ended",
  TutorsAvailable = "tutors_available"
}

export type ActivityBulletin = {
  type: BulletinType;
  message: string;
};
