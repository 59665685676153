import { Event } from "events/Event";
import { Platform } from "react-native";
import Config from "helpers/Config";
import { Router } from "navigation/Router";
import User from "models/User";
import { SignInUI } from "models/UI";
import store from "store";

import SignInBaseController from "./SignInBaseController";

class SignInController extends SignInBaseController {
  init() {
    super.init();
    Event.on("signin_set_identifier", setIdentifier);
    Event.on("signin_on_submit", onYupSignIn);
    Event.on("signin_on_clever_sso", onCleverSignIn);
    Event.on("signin_on_google_sso", onGoogleSignIn);

    onLoad();
  }
  deinit() {
    super.deinit();
    Event.remove("signin_set_identifier", setIdentifier);
    Event.remove("signin_on_submit", onYupSignIn);
    Event.remove("signin_on_clever_sso", onCleverSignIn);
    Event.remove("signin_on_google_sso", onGoogleSignIn);
  }
}

const controller = new SignInController();
export default controller;

function onLoad() {
  SignInUI.reset();
  const user = store.getState().user.user;
  if (user && (user.is_new_account || user?.has_existing_user === false)) {
    Router.replace("SignUp");
  } else if (user && !(user as any).errors) { // TODO: Remove- temporary fix for new users
    Router.replace("Dashboard");
  }
}

function setIdentifier({ identifier }: { identifier: string }) {
  SignInUI.setSignInIdentifier(identifier);
}

async function onYupSignIn({ identifier }: { identifier: string }) {
  SignInUI.setError("");
  SignInUI.setRegisteringUser(true);
  const { success, message } = await User.signIn(identifier);
  if (success) {
    Router.replace("Verification");
  } else if (message) {
    SignInUI.setError(message);
  }
  SignInUI.setRegisteringUser(false);
}

async function onGoogleSignIn({ idToken }: { idToken: string }) {
  SignInUI.setRegisteringUser(true);
  const { success, message } = await User.signInWithGoogle(
    idToken,
    Platform.OS
  );
  if (success) {
    Router.replace("Dashboard");
  } else if (message) {
    SignInUI.setError(message);
  }
  SignInUI.setRegisteringUser(false);
}

function onCleverSignIn() {
  const cleverClientId = encodeURIComponent(Config.cleverClientId);
  const cleverRedirectUri = encodeURIComponent(Config.cleverRedirectUri);
  Router.openLink(
    `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${cleverRedirectUri}&client_id=${cleverClientId}`
  );
}
