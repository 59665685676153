import React from "react";
import { View, StyleSheet } from "react-native";
import Colors from "assets/theme/Colors";

import { Activity } from "./types/Activity";
import { MessageType, MessageSender } from "./types/Message";

import MessageStream from "./MessageStream";
import MessageInput from "./MessageInput";
import ActivityIndicator from "./ActivityIndicator";

type ChatRoomProps = {
  sender: MessageSender;
  messages: Array<MessageType>;
  showTimestamps?: boolean;
  startFromTop?: boolean;
  showMessageInput?: boolean;
  activity?: Activity | null;
  name?: string;
  overrideInput?: string;
  messageInput: string;
  canReact: boolean;
  isMathTypingActive?: boolean;
};

ChatRoom.defaultProps = {
  sender: MessageSender.student,
  showTimestamps: true,
  startFromTop: false,
  showMessageInput: true,
  canReact: false
};

export default function ChatRoom(props: ChatRoomProps) {
  return (
    <View style={styles.chatroom}>
      <MessageStream
        messages={props.messages}
        showTimestamps={props.showTimestamps}
        sender={props.sender}
        startFromTop={props.startFromTop}
        canReact={props.canReact}
      />
      {props.activity && (
        <ActivityIndicator
          activity={props.activity}
          name={props.name ?? "Tutor"}
        />
      )}
      {props.showMessageInput && <MessageInput value={props.messageInput} />}
    </View>
  );
}

const styles = StyleSheet.create({
  chatroom: {
    backgroundColor: Colors.ui.white,
    flex: 1
  }
});
