import * as React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { useMediaQuery } from "react-responsive";
import { View, StyleSheet } from "react-native";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Card from "components/Card";
import Text from "components/Text";

import AccountActions from "../components/AccountActions";
import TextSubmit from "../components/TextSubmit";

import { MAIN_BUTTON_MIN_WIDTH } from "../views/Dashboard";
import { Event } from "events/Event";
import { Router } from "navigation/Router";

export default function DashboardActions() {
  const user = useSelector((state: StoreState) => state.user.user);

  const isLg = useMediaQuery({ minWidth: Layout.lg });
  const textStyles = [];

  if (!isLg) {
    textStyles.push(styles.textCenter);
  }

  function getSurveyLink() {
    let { email, phone_number } = user!;
    email = email ? email : "";
    phone_number = phone_number ? phone_number : "";
    return `https://yuptechnologies.typeform.com/to/WRuZoRbE?email=${email}&phone=${phone_number}&user=${user.id}`;
  }

  const onOpenDemoVideo = () => Router.openLink("https://vimeo.com/636647627");
  const onOpenSurvey = () => Router.openLink(getSurveyLink());
  const onUsePicture = Event.dispatcher("dashboard_select_picture_options");

  return (
    <Card>
      <View style={styles.main}>
        <View style={styles.subactions}>
          <AccountActions />
        </View>
        <Text tag="h2" style={[...textStyles, styles.header]}>
          Please describe your math problem
        </Text>

        {/* Main actions */}
        <TextSubmit />
        <View style={styles.separator}>
          <Text style={styles.separatorText}>or</Text>
        </View>
        <Button
          style={styles.mainButton}
          theme="secondary-outlined"
          testID="use-picture"
          onClick={onUsePicture}
        >
          Use a picture
        </Button>
      </View>

      {/* Get the most out of Yup */}
      {user?.last_session ? (
        <View style={styles.bottomSection}>
          <Text style={textStyles}>Had your 1st session?</Text>
          <Button testID="survey" theme="flat" onClick={onOpenSurvey}>
            Tell us more
          </Button>
        </View>
      ) : (
        <View style={styles.bottomSection}>
          <Text style={textStyles}>Want to see it in action?</Text>
          <Button theme="flat" testID="demo-video" onClick={onOpenDemoVideo}>
            Check it out
          </Button>
        </View>
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  main: {
    ...Spacing.pa3
  },
  header: {
    ...Spacing.my2
  },
  textCenter: {
    textAlign: "center"
  },
  subactions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },

  // Main buttons
  mainButton: {
    minWidth: MAIN_BUTTON_MIN_WIDTH,
    marginHorizontal: "auto",
    zIndex: -1,
    ...Spacing.mb2
  },
  separator: {
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    borderStyle: "solid",
    ...Spacing.my2
  },
  separatorText: {
    backgroundColor: Colors.ui.white,
    marginTop: -Sizes["16px"],
    ...Typography.accent.small,
    ...Spacing.px3,
    alignSelf: "center"
  },

  // Bottom sections
  bottomSection: {
    ...Spacing.pa3,
    borderTopWidth: 1,
    borderColor: Colors.ui.beige,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
});
