import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";

import { FlipCameraIcon } from "components/Icons";

type CameraButtonProps = {
  style?: object;
  onClick: () => void;
};

export default function CameraButton(props: CameraButtonProps) {
  return (
    <TouchableOpacity
      style={[styles.button, props.style]}
      onPress={props.onClick}
    >
      <FlipCameraIcon width={36} height={36} color={Colors.grey.iron} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: Colors.grey.concrete,
    borderRadius: Sizes.borderRadius
  }
});
