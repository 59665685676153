import React from "react";
import { StyleSheet, ScrollView, useWindowDimensions } from "react-native";

import Carousel from "components/Carousel";

import { BioDetail } from "models/Session";
import Spacing from "assets/theme/Spacing";

import ProfileDetail from "./ProfileDetail";
import ProfileStats from "./ProfileStats";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";

function ProfileRenderItem(itemProps: { item: BioDetail; index: number }) {
  return <ProfileDetail detail={itemProps.item} />;
}

export default function Profile() {
  const session = useSelector((state: StoreState) => state.session.current);
  const index = useSelector((state: StoreState) => state.ui.tutorProfileIndex);
  const { width } = useWindowDimensions();
  const maxWidth = Math.min(width - 16, 400);

  const tutor = session!.tutor!;
  const onNavigate = (index: number) =>
    Event.dispatch("session_on_navigate_tutor_detail", { index });

  return (
    <ScrollView
      style={[styles.profile, { maxWidth }]}
      testID="tutor-profile-view"
    >
      <ProfileStats />
      {tutor.profile.detailed_bio.length > 0 && (
        <Carousel
          index={index}
          items={tutor.profile.detailed_bio}
          itemElement={ProfileRenderItem}
          itemWidth={0.7 * maxWidth}
          containerWidth={maxWidth}
          onNavigate={onNavigate}
        />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  profile: {
    ...Spacing.pa3
  }
});
