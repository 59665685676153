import React, { useState } from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";

import Hoverable from "components/Hoverable";
import Text from "components/Text";

export type TooltipProps = {
  children: React.ReactNode;
  text: string;
  position?: "top" | "bottom";
};

const OFFSET = 30;

export default function Tooltip(props: TooltipProps) {
  const [hover, setHover] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  function getPosition() {
    const centerX = tooltipWidth / 2 - contentWidth / 2;

    switch (props.position) {
      case "top":
        return {
          marginLeft: -centerX,
          bottom: OFFSET
        };
      default:
        return {
          marginLeft: -centerX,
          top: OFFSET
        };
    }
  }

  function onLayout(event: LayoutChangeEvent) {
    setTooltipWidth(event.nativeEvent.layout.width);
  }

  function onContentLayout(event: LayoutChangeEvent) {
    setContentWidth(event.nativeEvent.layout.width);
  }

  return (
    <View style={styles.tooltip}>
      <Hoverable
        onHoverStart={() => setHover(true)}
        onHoverLeave={() => setHover(false)}
      >
        <View onLayout={onContentLayout}>{props.children}</View>
      </Hoverable>
      <View
        style={[
          styles.tooltipContent,
          { opacity: hover ? 1 : 0 },
          getPosition()
        ]}
        onLayout={onLayout}
      >
        <Text style={styles.tooltipText}>{props.text}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tooltip: {
    position: "relative"
  },
  tooltipContent: {
    position: "absolute",
    zIndex: 100,
    opacity: 0.8,
    backgroundColor: Colors.grey.iron,
    borderRadius: Sizes.borderRadius,
    paddingRight: Sizes["8px"],
    paddingLeft: Sizes["8px"],
    paddingTop: 2,
    paddingBottom: 2,
    width: "max-content",
    maxWidth: 300,
    pointerEvents: "none"
  },
  tooltipText: {
    color: Colors.ui.white,
    textAlign: "center",
    fontSize: 12
  }
});
