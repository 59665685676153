import { FunctionComponent } from "react";
import { ScreenType } from "types";

import SignInController from "./controllers/SignInController";
import VerificationController from "./controllers/VerificationController";
import CleverController from "./controllers/CleverController";
import SignUpController from "./controllers/SignUpController";
import WelcomeController from "./controllers/WelcomeController";
import OnboardingController from "./controllers/OnboardingController";

import SignInScreen from "./views/SignIn";
import VerificationScreen from "./views/Verification";
import SignUpScreen from "./views/SignUp";
import WelcomeScreen from "./views/Welcome";
import OnboardingScreen from "./views/Onboarding";
import CleverScreen from "./views/Clever";

export type Stack = {
  SignIn: undefined;
  Verification: undefined;
  SignUp: undefined;
  Welcome: undefined;
  Onboarding: undefined;
  Clever: {
    code: string;
  };
};

export const SignInScreens: Array<ScreenType> = [
  {
    name: "SignIn",
    path: "",
    controller: SignInController,
    component: SignInScreen as FunctionComponent
  },
  {
    name: "Verification",
    path: "verify",
    controller: VerificationController,
    component: VerificationScreen as FunctionComponent
  },
  {
    name: "Clever",
    path: "clever",
    controller: CleverController,
    component: CleverScreen as FunctionComponent
  },
  {
    name: "SignUp",
    path: "sign-up",
    controller: SignUpController,
    component: SignUpScreen as FunctionComponent
  },
  {
    name: "Welcome",
    path: "welcome",
    controller: WelcomeController,
    component: WelcomeScreen as FunctionComponent
  },
  {
    name: "Onboarding",
    path: "onboarding",
    controller: OnboardingController,
    component: OnboardingScreen as FunctionComponent
  }
];
