import React, { useEffect, useState, useRef } from "react";
import { Event } from "events/Event";
import { View, StyleSheet, TextInput } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { LogoIcon } from "components/Icons";
import Text from "components/Text";
import TextField from "components/TextField";
import Page from "components/Page";

export default function Token() {
  const code2Input = useRef<TextInput>(null);
  const code3Input = useRef<TextInput>(null);
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");

  const getCode = () => `${code1}${code2}${code3}`;
  const onSubmit = Event.dispatcher("mobile_upload_token_on_submit", {
    token: getCode()
  });

  useEffect(() => {
    setCode1(code1.toUpperCase());
    if (code1.length === 4) {
      code2Input.current?.focus();
    }
  }, [code1]);

  useEffect(() => {
    setCode2(code2.toUpperCase());
    if (code2.length === 3) {
      code3Input.current?.focus();
    }
  }, [code2]);

  useEffect(() => {
    setCode3(code3.toUpperCase());
  }, [code3]);

  const isValid = /^[A-Z0-9]{11}$/g.test(getCode());

  return (
    <Page>
      <View style={styles.wrapper}>
        <LogoIcon
          width={100}
          height={Sizes["32px"]}
          color={Colors.brand.blue}
        />
        <Text tag="h2" style={styles.header}>
          Take a picture
        </Text>
        <Text style={styles.prompt}>
          Please enter the code provided to you on the desktop student app
        </Text>
        <View style={styles.code}>
          <TextField
            maxLength={4}
            value={code1}
            autoFocus={true}
            style={styles.input}
            testID="code1"
            onChangeText={setCode1}
          />
          <Text style={styles.separator}>-</Text>
          <TextField
            ref={code2Input}
            maxLength={3}
            value={code2}
            style={styles.input}
            testID="code2"
            onChangeText={setCode2}
          />
          <Text style={styles.separator}>-</Text>
          <TextField
            ref={code3Input}
            maxLength={4}
            value={code3}
            style={styles.input}
            testID="code3"
            onChangeText={setCode3}
          />
        </View>
        <Button testID="submit" disabled={!isValid} onClick={onSubmit}>
          Submit Code
        </Button>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa2,
    ...Spacing.mt4
  },
  header: {
    ...Spacing.mb2,
    ...Spacing.mt4
  },
  prompt: {
    textAlign: "center"
  },
  code: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.my4
  },
  input: {
    maxWidth: Sizes["96px"]
  },
  separator: {
    fontWeight: "bold",
    fontSize: 20,
    ...Spacing.mx2
  }
});
