export function shuffle(arr: Array<any>) {
  let remainingSwaps = arr.length,
    temp,
    i;

  while (remainingSwaps) {
    i = Math.floor(Math.random() * remainingSwaps--);

    temp = arr[remainingSwaps];
    arr[remainingSwaps] = arr[i];
    arr[i] = temp;
  }

  return arr;
}
