import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { View } from "react-native";
import html2canvas from "html2canvas";

export interface CaptureViewAPI {
  capture: () => Promise<string>;
}

type CaptureViewProps = {
  children: React.ReactNode;
};

const CaptureView = forwardRef<CaptureViewAPI, CaptureViewProps>(
  (props, ref) => {
    const capture = useRef<View>(null);
    useImperativeHandle(ref, () => ({
      get capture() {
        return async () => {
          if (!capture.current) return "";
          const canvas = await html2canvas(capture.current, {
            allowTaint: true,
            useCORS: true
          });
          return (canvas as any).toDataURL();
        };
      }
    }));
    return <View ref={capture}>{props.children}</View>;
  }
);

export default CaptureView;
