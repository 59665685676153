import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum WhiteboardConnectionState {
  connected,
  disconnected,
  reconnecting
}

export type StateType = {
  state: WhiteboardConnectionState;
};

export const whiteboardSlice = createSlice({
  name: "whiteboard",
  initialState: {
    state: WhiteboardConnectionState.reconnecting
  } as StateType,
  reducers: {
    setConnectionState: (
      state,
      action: PayloadAction<WhiteboardConnectionState>
    ) => {
      state.state = action.payload;
    }
  }
});

export default whiteboardSlice.reducer;

export const { setConnectionState } = whiteboardSlice.actions;
