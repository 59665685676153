import React, { useEffect, useState, useRef } from "react";
import { Timers } from "@yups/utils";
import { format } from "date-fns";
import { View, Platform, StyleSheet, useWindowDimensions } from "react-native";
import Slider from "@react-native-community/slider";
import { useMediaQuery } from "react-responsive";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { PlayIcon, PauseIcon, WhiteboardIcon } from "components/Icons";
import Image from "components/Image";
import Modal from "components/Modal";
import Text from "components/Text";
import { useSelector } from "react-redux";
import { StoreState } from "store";

const PLAYER_TIMER = "whiteboard-player";

type WhiteboardPlayerProps = {
  timestamp: number;
  startTime: number;
  endTime: number;
  onTimestampChange: (timestamp: number) => void;
};

type WhiteboardPlayerWrapperProps = {
  children: React.ReactNode;
  onDismiss: () => void;
};

function formatTimestamp(time: number) {
  return format(new Date(time * 1000), "hh:mm:ssa").toLowerCase();
}

function WhiteboardPlayerWrapper(props: WhiteboardPlayerWrapperProps) {
  const [showModal, setShowModal] = useState(false);
  const isSm = useMediaQuery({ maxWidth: Layout.md });

  if (isSm || Platform.OS !== "web") {
    return (
      <>
        <Button
          style={styles.whiteboardButton}
          round={true}
          testID="open-whiteboard"
          onClick={() => setShowModal(true)}
        >
          <WhiteboardIcon width={24} height={24} color={Colors.grey.iron} />
        </Button>
        <Modal show={showModal} onDismiss={() => setShowModal(false)}>
          <View style={styles.player}>{props.children}</View>
        </Modal>
      </>
    );
  }

  return (
    <View style={styles.player}>
      <>{props.children}</>
    </View>
  );
}

export default function WhiteboardPlayer(props: WhiteboardPlayerProps) {
  const captures = useSelector(
    (state: StoreState) => state.sessionHistory.whiteboard_captures
  );
  const loading = useSelector(
    (state: StoreState) => state.ui.loadingSessionWhiteboardCaptures
  );
  const [playing, setPlaying] = useState(false);
  const index = useRef(0);
  const isSm = useMediaQuery({ maxWidth: Layout.md });
  const { width, height } = useWindowDimensions();

  const marks = captures.map(
    (capture) =>
      ((capture.captured_at - props.startTime) /
        (props.endTime - props.startTime)) *
      100
  );

  useEffect(() => {
    pause();
    if (captures.length) onChange(props.endTime);
  }, [captures.length]);

  useEffect(() => {
    const count = captures.length;
    if (!count) {
      return;
    } else if (props.timestamp <= captures[0].captured_at) {
      index.current = 0;
    } else if (props.timestamp > captures[count - 1].captured_at) {
      index.current = count - 1;
    } else {
      // Get first image past the timestamp
      index.current = captures.findIndex(
        (i: any) => i.captured_at >= props.timestamp
      );
    }
  }, [props.timestamp]);

  function onChange(timestamp: number) {
    props.onTimestampChange(Math.min(props.endTime, timestamp));
  }

  function pause() {
    Timers.clear(PLAYER_TIMER);
    setPlaying(false);
  }

  function play() {
    setPlaying(true);
    Timers.clear(PLAYER_TIMER);
    if (index.current === captures.length - 1) {
      index.current = 0;
      onChange(props.startTime);
    }
    loadNextFrame();
  }

  function loadNextFrame() {
    Timers.setTimeout({
      label: PLAYER_TIMER,
      callback: () => {
        if (index.current === captures.length - 1) {
          onChange(props.endTime);
          pause();
        } else {
          onChange(captures[index.current + 1].captured_at);
          loadNextFrame();
        }
      },
      delay: 1000
    });
  }

  if (loading || captures.length < 2) {
    return null;
  }

  return (
    <WhiteboardPlayerWrapper onDismiss={() => {}}>
      <Image
        uri={captures[index.current]?.url ?? ""}
        maintainAspectRatio={true}
        maxSize={isSm ? width : Math.min(width * 0.4, height * 0.7)}
      />
      <View style={styles.controls}>
        {playing ? (
          <Button
            testID="pause-whiteboard"
            style={styles.control}
            theme="flat"
            onClick={pause}
          >
            <PauseIcon color={Colors.ui.blue} width={24} height={24} />
          </Button>
        ) : (
          <Button
            testID="play-whiteboard"
            style={styles.control}
            theme="flat"
            onClick={play}
          >
            <PlayIcon color={Colors.ui.blue} width={24} height={24} />
          </Button>
        )}
        <View style={styles.track}>
          <View style={styles.markers}>
            {marks.map((mark) => (
              <View
                key={`mark-${mark}`}
                style={[styles.marker, { left: `${mark}%` }]}
              ></View>
            ))}
          </View>
          <Slider
            thumbTintColor={Colors.ui.blue}
            value={props.timestamp}
            onValueChange={onChange}
            minimumValue={props.startTime}
            maximumValue={props.endTime}
            minimumTrackTintColor={Colors.ui.blue}
            maximumTrackTintColor={Colors.grey.slate}
            onSlidingStart={pause}
          />
        </View>
        <Text style={styles.timestamp}>{formatTimestamp(props.timestamp)}</Text>
      </View>
    </WhiteboardPlayerWrapper>
  );
}

const styles = StyleSheet.create({
  whiteboardButton: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    maxWidth: 36,
    height: 36,
    backgroundColor: Colors.ui.beige,
    borderWidth: 1,
    borderColor: Colors.ui.tan,
    ...Spacing.px2
  },
  player: {
    height: "100%",
    backgroundColor: Colors.ui.beige,
    ...Spacing.pa2
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  control: {
    flexShrink: 1,
    ...Spacing.py2
  },
  track: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    overflow: "hidden"
  },
  markers: {
    position: "absolute",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  marker: {
    position: "absolute",
    backgroundColor: Colors.grey.slate,
    width: 2,
    height: 8
  },
  timestamp: {
    fontSize: 13,
    ...Spacing.pl2
  }
});
