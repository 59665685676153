import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";

import { StyleSheet, View } from "react-native";

import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import TextArea from "components/TextArea";

import ComplaintReasons from "./ComplaintReasons";

export default function FeedbackForm() {
  const selectedReasons = useSelector(
    (state: StoreState) => state.ui.feedbackReasons
  );
  const comment = useSelector((state: StoreState) => state.ui.feedbackComment);
  const submitting = useSelector(
    (state: StoreState) => state.ui.submittingFeedback
  );

  const onCommentChange = (comment: string) =>
    Event.dispatch("postsession_set_comment", { comment });
  const onSubmit = Event.dispatcher("postsession_on_submit_feedback");

  return (
    <>
      <ComplaintReasons />
      <View style={styles.textarea} testID="session-feedback">
        <TextArea
          placeholder="Leave a comment"
          value={comment}
          testID="comment"
          onChangeText={onCommentChange}
        />
      </View>
      <Button
        disabled={!selectedReasons.length}
        processing={submitting}
        style={styles.submitButton}
        testID="submit-feedback"
        onClick={onSubmit}
      >
        Submit Feedback
      </Button>
    </>
  );
}

const styles = StyleSheet.create({
  textarea: {
    ...Spacing.my3,
    width: "100%"
  },
  submitButton: {
    width: "100%"
  }
});
