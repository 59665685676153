import React, { useEffect, useState, useRef } from "react";
import Editor, { EquationEditor } from "./EquationEditor";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
  useWindowDimensions
} from "react-native";
import { Symbol } from "./types/Symbol";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";

import symbols from "./data/symbols.json";
import EquationSymbol from "./EquationSymbol";
import { Event } from "events/Event";

type EquationModalProps = {
  show: boolean;
  initialEquation?: string;
};

const Categories = Object.keys(symbols);

export default function EquationModal(props: EquationModalProps) {
  const [category, setCategory] = useState(Categories[0]);
  const [equation, setEquation] = useState("");
  const editor = useRef<EquationEditor | null>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setEquation(props.initialEquation ?? "");
  }, [props.initialEquation]);

  function isSelected(categoryText: string) {
    return category === categoryText;
  }

  function getSymbols() {
    return (symbols as any)[category].filter((symbol: Symbol) =>
      symbol.webOnly ? Platform.OS === "web" : true
    ) as Array<Symbol>;
  }

  function onInsertSymbol(symbol: string) {
    editor.current?.append(symbol);
  }

  const onSubmit = Event.dispatcher("equation_submitted", { equation });
  const onDismiss = Event.dispatcher("equation_dismissed");

  return (
    <Modal width={Math.min(width, 550)} show={props.show} onDismiss={onDismiss}>
      <Text tag="h2">Type an Equation</Text>
      <View style={styles.editor} testID="equation-editor">
        <Editor ref={editor} latex={equation} onChange={setEquation} />
      </View>
      <View style={styles.menu}>
        <View style={styles.categories}>
          {Categories.map((category) => (
            <TouchableOpacity
              key={category}
              onPress={() => setCategory(category)}
            >
              <Text
                style={[
                  styles.category,
                  isSelected(category) && styles.selectedCategory
                ]}
              >
                {category}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <ScrollView style={styles.symbolsWrapper}>
          <View style={[styles.symbols, { width: Math.min(width / 1.5, 380) }]}>
            {getSymbols().map((symbol) => (
              <EquationSymbol
                key={symbol.title}
                symbol={symbol}
                onClick={() => onInsertSymbol(symbol.key)}
              />
            ))}
          </View>
        </ScrollView>
      </View>
      <View style={styles.actions}>
        <Button
          testID="equation-submit"
          disabled={!equation}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  menu: {
    display: "flex",
    flexDirection: "row"
  },
  editor: {
    ...Spacing.py2,
    ...Spacing.mb2
  },
  categories: {
    ...Spacing.pr2
  },
  category: {
    borderBottomWidth: 2,
    borderColor: "transparent",
    ...Spacing.px2,
    ...Spacing.mb2,
    ...Typography.accent.small
  },
  selectedCategory: {
    color: Colors.ui.blue,
    borderColor: Colors.ui.blue
  },
  symbolsWrapper: {
    maxHeight: 280
  },
  symbols: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...Spacing.px2
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    ...Spacing.mt2
  }
});
