import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

import { DashboardUI } from "models/UI";
import Settings, { Subtopic } from "models/Settings";
import Session from "models/Session";
import User from "models/User";

import ExtensionController from "./ExtensionController";
import store from "store";
import SessionMessages from "models/SessionMessages";
import { Assistments } from "models/Partnerships";

class ScratchboardController extends Controller {
  init() {
    Event.on("scratchboard_exit", onExitScratchboard);
    Event.on("dashboard_scratchboard_request_tutor", onRequestTutor);
    Event.on(
      "dashboard_scratchboard_dismiss_topic_selection",
      onDismissTopicSelection
    );
    Event.on("dashboard_scratchboard_select_topic", selectTopic);
    Event.on("dashboard_scratchboard_submit_photo", onSubmitPhoto);

    ExtensionController.init();
    onLoad();
  }
  deinit() {
    Event.remove("scratchboard_exit", onExitScratchboard);
    Event.remove("dashboard_scratchboard_request_tutor", onRequestTutor);
    Event.remove(
      "dashboard_scratchboard_dismiss_topic_selection",
      onDismissTopicSelection
    );
    Event.remove("dashboard_scratchboard_select_topic", selectTopic);
    Event.remove("dashboard_scratchboard_submit_photo", onSubmitPhoto);

    ExtensionController.deinit();
  }
}

const controller = new ScratchboardController();
export default controller;

function onLoad() {
  if (!store.getState().ui.photo) Router.replace("Dashboard");
}

function onExitScratchboard() {
  DashboardUI.setPhoto("");
  Router.replace("Dashboard");
}

function getAutoFilledTopic() {
  const user = User.get();
  const settings = Settings.get();
  if (settings && user?.grade && user.grade <= 7) {
    return settings!.subtopics.find(
      (subtopic) => subtopic.name === "pre_algebra"
    );
  }
}

function onRequestTutor({ image }: { image: string }) {
  const topic = getAutoFilledTopic();
  if (topic) {
    selectTopic({ topic });
    onSubmitPhoto({ image, topic });
  } else {
    DashboardUI.setShowTopicSelection(true);
  }
}

function onDismissTopicSelection() {
  DashboardUI.setShowTopicSelection(false);
}

function selectTopic({ topic }: { topic: Subtopic }) {
  DashboardUI.setSelectedTopic(topic);
}

async function onSubmitPhoto({
  image,
  topic
}: {
  image: string;
  topic: Subtopic;
}) {
  DashboardUI.onTopicSubmitted(topic.label);
  DashboardUI.setCreatingSession(true);
  const response = await Session.create(topic.id, Assistments.getRequestData());
  if (!response.success) {
    DashboardUI.setError(response.message ?? "");
    Router.navigate("Dashboard");
  } else {
    await SessionMessages.sendImage(image);
    DashboardUI.reset();
    Assistments.reset();
    Router.replace("Session");
  }
  DashboardUI.setCreatingSession(false);
}
