import { FunctionComponent } from "react";
import { ScreenType } from "types";

import AscendMathController from "./controllers/AscendMathController";
import AssistmentsController from "./controllers/AssistmentsController";

import AscendMathScreen from "./views/AscendMath";
import AssistmentsScreen from "./views/Assistments";

export type Stack = {
  Assistments: undefined;
  AscendMath: undefined;
};

export const PartnershipsScreens: Array<ScreenType> = [
  {
    name: "Assistments",
    path: "assistments",
    controller: AssistmentsController,
    component: AssistmentsScreen as FunctionComponent
  },
  {
    name: "AscendMath",
    path: "ascendmath",
    controller: AscendMathController,
    component: AscendMathScreen as FunctionComponent
  }
];
