import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import {
  MessageReaction as MessageReactionType,
  MessageType,
  ReactionMap
} from "./types/Message";

import Colors from "assets/theme/Colors";
import Text from "components/Text";
import Spacing from "assets/theme/Spacing";
import { XIcon } from "components/Icons";

type ReactionColorMapType = {
  [key in MessageReactionType]: string;
};

const reactionColorMap: ReactionColorMapType = {
  [MessageReactionType.confused]: Colors.ui.red,
  [MessageReactionType.gotIt]: Colors.ui.green
};

type MessageReactionComponentProps = {
  show: boolean;
  message: MessageType;
  sent: boolean;
  onSelect?: (reaction: MessageReactionType) => void;
  onDismiss: () => void;
};

export default function MessageReaction(props: MessageReactionComponentProps) {
  function onSelect(name: MessageReactionType) {
    props.onSelect?.(name);
    props.onDismiss();
  }

  function getReactionInfo(name: MessageReactionType) {
    return {
      ...ReactionMap[name],
      color: reactionColorMap[name]
    };
  }

  const reaction = props.message.reaction?.name;

  return (
    <View style={styles.reaction}>
      {reaction && !props.show && (
        <View
          style={[
            styles.reactionBubble,
            {
              borderColor: reaction && getReactionInfo(reaction).color,
              alignSelf: props.sent ? "flex-start" : "flex-end",
              marginRight: props.sent ? 0 : -OPTION_SIZE / 4,
              marginLeft: props.sent ? -OPTION_SIZE / 2 : 0
            }
          ]}
        >
          <Text tag="h2">{getReactionInfo(reaction).emoji}</Text>
        </View>
      )}
      {props.show && props.onSelect && (
        <View
          style={[
            styles.reactionOptions,
            { alignSelf: props.sent ? "flex-start" : "flex-end" }
          ]}
        >
          {Object.values(MessageReactionType).map(
            (key: MessageReactionType) => (
              <TouchableOpacity
                key={key}
                testID="reaction-option"
                style={[
                  styles.reactionOption,
                  { borderColor: getReactionInfo(key).color }
                ]}
                onPress={() => onSelect(key)}
              >
                <Text tag="h2">{getReactionInfo(key).emoji}</Text>
              </TouchableOpacity>
            )
          )}
          <TouchableOpacity
            testID="dismiss-options"
            style={styles.closeReactionOptions}
            onPress={props.onDismiss}
          >
            <XIcon width={24} height={24} color={Colors.grey.slate} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const OPTION_SIZE = 32;

const ReactionStyle = {
  borderWidth: 1,
  width: OPTION_SIZE,
  height: OPTION_SIZE,
  borderRadius: OPTION_SIZE,
  backgroundColor: Colors.ui.white,
  display: "flex" as "flex",
  alignItems: "center" as "center",
  justifyContent: "center" as "center"
};

const styles = StyleSheet.create({
  reaction: {
    display: "flex"
  },
  reactionBubble: {
    ...ReactionStyle,
    marginTop: -OPTION_SIZE / 2,
    marginRight: -OPTION_SIZE / 3
  },
  reactionOptions: {
    backgroundColor: Colors.grey.concrete,
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    borderRadius: OPTION_SIZE,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    ...Spacing.py1
  },
  closeReactionOptions: {
    width: OPTION_SIZE,
    height: OPTION_SIZE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...Spacing.mx1
  },
  reactionOption: {
    ...ReactionStyle,
    ...Spacing.mx1
  }
});
