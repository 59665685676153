import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { Event } from "events/Event";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Sizes from "assets/theme/Sizes";

import Text from "components/Text";

export default function ComplaintReasons() {
  const positiveRating = useSelector((state: StoreState) => state.ui.rating);
  const selected = useSelector((state: StoreState) => state.ui.feedbackReasons);
  const settings = useSelector((state: StoreState) => state.settings.settings);

  const onToggleReason = (reason: string) =>
    Event.dispatch("postsession_set_reason", { reason });

  if (!settings) return null;

  const reasons = positiveRating
    ? settings.complaint_reasons.thumbs_up
    : settings.complaint_reasons.thumbs_down;

  function isSelected(reason: string) {
    return selected.includes(reason);
  }

  function getStyle(reason: string) {
    const toggle: any = [styles.reason];
    const text: any = [styles.reasonText];
    if (isSelected(reason)) {
      toggle.push(styles.selectedReason);
      text.push(styles.selectedReasonText);
    }
    return { toggle, text };
  }

  return (
    <View style={styles.wrapper}>
      {reasons.map((reason) => (
        <TouchableOpacity
          key={reason}
          style={getStyle(reason).toggle}
          testID="reason"
          onPress={() => onToggleReason(reason)}
        >
          <Text style={getStyle(reason).text}>{reason}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    ...Spacing.px2
  },
  reason: {
    borderWidth: 2,
    borderColor: Colors.brand.blue,
    borderRadius: Sizes["24px"],
    backgroundColor: Colors.ui.white,
    ...Spacing.px2,
    ...Spacing.ma1,
    ...Spacing.py1
  },
  reasonText: {
    color: Colors.brand.blue
  },
  selectedReason: {
    backgroundColor: Colors.brand.blue
  },
  selectedReasonText: {
    color: Colors.ui.white
  }
});
