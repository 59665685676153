import React from "react";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Image from "components/Image";
import Text from "components/Text";

export type ProfileCardProps = {
  children: React.ReactNode;
  imageUrl: string;
  title: string;
};

export default function ProfileCard(props: ProfileCardProps) {
  return (
    <View style={styles.card}>
      <View style={styles.action}>{props.children}</View>
      <Image style={styles.image} uri={props.imageUrl} />
      <Text style={styles.name}>{props.title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    borderWidth: 2,
    borderColor: Colors.ui.beige,
    borderRadius: Sizes.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%",
    ...Spacing.pa1
  },
  image: {
    width: Sizes["96px"],
    height: Sizes["96px"],
    ...Spacing.mb2
  },
  name: {
    ...Typography.accent.small,
    letterSpacing: 1,
    lineHeight: 24,
    textAlign: "center"
  },
  action: {
    position: "absolute",
    top: Sizes["4px"],
    right: Sizes["4px"]
  }
});
