import Config from "helpers/Config";

export type BioDetail = {
  name: string;
  question: string;
  answer: string;
  liked: boolean;
};

type TutorProfile = {
  polite_name: string;
  thumbs_up_ratio: number;
  years_teaching_experience: number;
  number_of_students: number;
  detailed_bio: Array<BioDetail>;
};

export type Tutor = {
  id: number;
  profile: TutorProfile;
  gender: string;
};

const numAvatars = 10;
export function getTutorAvatar(tutorId: number, tutorGender: string) {
  const idx = tutorId % numAvatars;
  return tutorGender === "female"
    ? `${Config.assetsUrl}tutors/tutor_female_${idx}.png`
    : `${Config.assetsUrl}tutors/tutor_male_${idx}.png`;
}
