import { FunctionComponent } from "react";
import { ScreenType } from "types";

import SessionRatingController from "./controllers/RatingController";
import SessionFeedbackController from "./controllers/FeedbackController";

import RatingScreen from "./views/Rating";
import FeedbackScreen from "./views/Feedback";

export type Stack = {
  SessionRating: undefined;
  SessionFeedback: undefined;
};

export const PostSessionScreens: Array<ScreenType> = [
  {
    name: "SessionRating",
    path: "session-rating",
    controller: SessionRatingController,
    component: RatingScreen as FunctionComponent
  },
  {
    name: "SessionFeedback",
    path: "session-feedback",
    controller: SessionFeedbackController,
    component: FeedbackScreen as FunctionComponent
  }
];
