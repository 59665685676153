import React from "react";
import { Event } from "events/Event";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import Config from "helpers/Config";
import Confetti from "react-native-confetti";

import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Button from "components/Button";
import Image from "components/Image";
import Text from "components/Text";
import Colors from "assets/theme/Colors";
import { LogoIcon } from "components/Icons";

export default function Welcome() {
  const user = useSelector((state: StoreState) => state.user.user);

  const handleConfetti = (confetti: Confetti) => {
    if (!Config.testing) confetti?.startConfetti();
  };
  const onFinish = Event.dispatcher("signin_on_finish_welcome");

  return (
    <View style={styles.wrapper}>
      <Confetti ref={handleConfetti} />
      <View style={styles.innerWrapper}>
        <View style={styles.logo}>
          <LogoIcon
            width={100}
            height={Sizes["32px"]}
            color={Colors.ui.white}
          />
        </View>
        <Text tag="h3" style={styles.subheader}>
          Welcome to Yup, {user?.name ?? ""}
        </Text>
        <Text tag="h1" style={styles.header}>
          You have unlimited access to 24/7 math tutoring
        </Text>
        <Image
          uri={`${Config.assetsUrl}yup-welcome.svg`}
          style={styles.image}
        />
        <Button
          style={styles.button}
          testID="welcome-button"
          onClick={onFinish}
        >
          Let's get started
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    overflow: "hidden",
    backgroundColor: Colors.brand.blue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  innerWrapper: {
    maxWidth: Sizes["128px"] * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...Spacing.pa4
  },
  logo: {
    ...Spacing.mb4
  },
  subheader: {
    ...Typography.accent.large,
    color: Colors.ui.white,
    opacity: 0.7,
    textAlign: "center"
  },
  header: {
    color: Colors.ui.white,
    textAlign: "center",
    ...Spacing.mb4
  },
  image: {
    resizeMode: "contain",
    height: 100,
    width: Sizes["128px"] * 2,
    marginBottom: -4
  },
  button: {
    width: Sizes["128px"] * 2.5,
    ...Spacing.ma0
  }
});
