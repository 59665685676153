import React, { useState } from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { useMediaQuery } from "react-responsive";

import Colors from "assets/theme/Colors";
import Layout from "assets/theme/Layout";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";
import Typography from "assets/theme/Typography";

import Card from "components/Card";
import {
  HistoryIcon,
  QuestionIcon,
  ProfileIcon,
  ProfileOutlineIcon,
  SignOutIcon
} from "components/Icons";
import Text from "components/Text";
import { Event } from "events/Event";

type AccountAction = {
  label: string;
  icon: React.ReactNode;
  onPress: () => void;
};

type AccountActionsMenuProps = {
  toggle: React.FunctionComponent;
  actions: Array<AccountAction>;
};

const IconProps = {
  width: 24,
  height: 24,
  color: Colors.grey.iron
};

function Toggle() {
  return (
    <View style={styles.account}>
      <ProfileIcon height={24} width={24} color={Colors.ui.blue} />
    </View>
  );
}
export default function AccountActions() {
  const isMd = useMediaQuery({ minWidth: Layout.md });

  const actions = [
    {
      label: "Session History",
      icon: <HistoryIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_go_to_history")
    },
    {
      label: "Profile",
      icon: <ProfileOutlineIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_go_to_profile")
    },
    {
      label: "Help",
      icon: <QuestionIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_open_faq")
    },
    {
      label: "Sign Out",
      icon: <SignOutIcon {...IconProps} />,
      onPress: Event.dispatcher("dashboard_sign_out")
    }
  ];

  if (isMd && Platform.OS === "web") {
    return <AccountActionsLarge toggle={Toggle} actions={actions} />;
  }

  return <AccountActionsSmall toggle={Toggle} actions={actions} />;
}

function AccountActionsSmall(props: AccountActionsMenuProps) {
  const { showActionSheetWithOptions } = useActionSheet();

  function onShow() {
    showActionSheetWithOptions(
      {
        options: props.actions.map((action) => action.label).concat(["Cancel"]),
        icons: props.actions.map((action) => action.icon),
        cancelButtonIndex: props.actions.length,
        textStyle: styles.smallText
      },
      onAction
    );
  }

  function onAction(index?: number) {
    if (index !== undefined) {
      props.actions[index]?.onPress();
    }
  }

  return (
    <TouchableOpacity testID="dashboard-actions" onPress={onShow}>
      <props.toggle />
    </TouchableOpacity>
  );
}

function AccountActionsLarge(props: AccountActionsMenuProps) {
  const [show, setShow] = useState(false);

  function onShow() {
    setShow(true);
    (window as any).addEventListener("click", onClose);
  }

  function onClose() {
    setShow(false);
    (window as any).removeEventListener("click", onClose);
  }

  function onAction(action: AccountAction) {
    action.onPress();
    onClose();
  }
  return (
    <>
      <TouchableOpacity onPress={onShow} testID="dashboard-actions">
        <props.toggle />
      </TouchableOpacity>
      {show && (
        <Card style={styles.menu}>
          {props.actions.map((action) => (
            <TouchableOpacity
              key={action.label}
              style={styles.action}
              onPress={() => onAction(action)}
            >
              <>
                {action.icon}
                <Text style={styles.text}>{action.label}</Text>
              </>
            </TouchableOpacity>
          ))}
        </Card>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  account: {
    backgroundColor: Colors.ui.lightBlue,
    borderRadius: Sizes["64px"],
    ...Spacing.pa1
  },
  smallText: {
    fontFamily: Typography.primary.regular
  },
  text: {
    fontFamily: Typography.primary.regular,
    ...Spacing.ml2
  },
  menu: {
    position: "absolute",
    right: -Sizes["128px"],
    ...Spacing.mt4
  },
  action: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...Spacing.pa2
  }
});
