export enum CleverError {
  other,
  noEmail,
  notStudent,
  invalidCode,
  notFound
}

export enum ErrorMessages {
  errorCleverSignInNoEmailInClever = "That Clever account has no email we can associate a Yup account with. You can try again or enter your email address or phone number manually.",
  errorCleverSignInNotAStudent = "The Yup account associated with that Clever account is not of a student. You can try again with another Clever account or enter your email address or phone number manually.",
  errorCleverSignInOtherError = "There was an error while signing you in with Clever. You can try again or enter your email address or phone number manually.",
  errorCleverSignInNoYupAccount = "There’s no Yup account associated with that Clever account. You can try again or enter your email address or phone number manually."
}

const errorCodeMessages = {
  [CleverError.other]: ErrorMessages.errorCleverSignInOtherError,
  [CleverError.noEmail]: ErrorMessages.errorCleverSignInNoEmailInClever,
  [CleverError.notStudent]: ErrorMessages.errorCleverSignInNotAStudent,
  [CleverError.invalidCode]: ErrorMessages.errorCleverSignInOtherError,
  [CleverError.notFound]: ErrorMessages.errorCleverSignInNoYupAccount
};

export function getCleverErrorMessage(errorCode: CleverError): string {
  return (
    errorCodeMessages[errorCode] ?? ErrorMessages.errorCleverSignInOtherError
  );
}
