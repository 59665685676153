import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HistoricalSession } from "models/SessionHistory";
import { SessionType } from "models/Session";
import Storage from "helpers/Storage";
import { WhiteboardCapture } from "types/WhiteboardCapture";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

const STORE_KEY = "yup-session-history";
export const storage = new Storage(STORE_KEY, clearSessionHistory);
function clearSessionHistory(): void {
  const { store } = require("./index");
  store.dispatch(sessionHistorySlice.actions.setSessions([]));
  store.dispatch(sessionHistorySlice.actions.setTranscripts({}));
}

export type StateType = {
  sessions: Array<HistoricalSession>;
  transcripts: { [key: number]: SessionType };
  whiteboard_captures: Array<WhiteboardCapture>;
};

export const sessionHistorySlice = createSlice({
  name: "sessionHistory",
  initialState: {
    sessions: [],
    transcripts: {},
    whiteboard_captures: []
  } as StateType,
  reducers: {
    setSessions: (state, action: PayloadAction<Array<HistoricalSession>>) => {
      state.sessions = [...action.payload].sort((a, b) =>
        a.created_at < b.created_at ? 1 : -1
      );
    },
    setTranscript: (state, action: PayloadAction<SessionType>) => {
      state.transcripts[action.payload.id] = action.payload;
    },
    setTranscripts: (
      state,
      action: PayloadAction<{ [key: number]: SessionType }>
    ) => {
      state.transcripts = action.payload;
    },
    setWhiteboardCaptures: (
      state,
      action: PayloadAction<Array<WhiteboardCapture>>
    ) => {
      state.whiteboard_captures =
        action.payload
          .map((capture) => ({
            ...capture,
            captured_at: new Date(capture.captured_at).getTime() / 1000
          }))
          .sort((a, b) => a.captured_at - b.captured_at) ?? [];
    }
  }
});

export default sessionHistorySlice.reducer;

export const {
  setSessions,
  setTranscript,
  setTranscripts,
  setWhiteboardCaptures
} = sessionHistorySlice.actions;

export async function subscriber(state: StateType) {
  const User = require("models/User").default;
  const user = User.get();
  const data = storage.getSync();
  if (user) {
    const sessions = state.sessions ?? [];
    const transcripts = data?.[user.identifier]?.transcripts ?? {};
    storage.setSync({
      [user.identifier]: {
        sessions,
        transcripts
      }
    });
  } else {
    storage.remove();
  }
}

export async function load(store: ToolkitStore) {
  const User = require("models/User").default;
  const history = await storage.get();
  const user = User.get();
  if (history && user) {
    const sessions = history[user.identifier]?.sessions;
    const transcripts = history[user.identifier]?.transcripts;
    if (sessions) {
      store.dispatch(sessionHistorySlice.actions.setSessions(sessions));
    }
    if (transcripts) {
      store.dispatch(sessionHistorySlice.actions.setTranscripts(transcripts));
    }
  }
}
