export enum InputButtonType {
  Options = "options",
  FileUpload = "file-upload",
  Camera = "camera",
  Send = "send",
  Equation = "equation",
  SessionImage = "session-image",
  Screenshot = "screenshot",
  MobileUpload = "mobile-upload",
  SpeechToText = "speech-to-text",
  StopSpeechToText = "stop-speech-to-text",
  TextToSpeech = "text-to-speech",
  StopTextToSpeech = "stop-text-to-speech"
}
