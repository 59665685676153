import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";

import CameraComponent from "components/Camera";

export default function Camera() {
  const hasPermission = useSelector(
    (state: StoreState) => state.ui.hasCameraPermission
  );

  return <CameraComponent hasPermission={hasPermission} />;
}
