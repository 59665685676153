import { Dimensions } from "react-native";
import { CanvasRenderingContext2D, Path2D } from "react-native-canvas";
import Colors from "assets/theme/Colors";

import {
  Point,
  Size,
  DrawSettings,
  BackgroundStyle,
  ScratchBoardOptions,
  defaultOptions
} from "./types/Scratchboard";

export default class ScratchBoardClass {
  canvas: any;
  context: CanvasRenderingContext2D;
  options: ScratchBoardOptions;
  background: any;
  isDrawing = false;
  points: Array<Point> = [];
  paths: Array<any> = [];
  currentPath: any | null = null;
  backgroundStyle: BackgroundStyle = BackgroundStyle.none;
  backgroundColor: string = Colors.ui.beige;

  constructor(canvas: any, options?: ScratchBoardOptions) {
    this.canvas = canvas;
    this.context = this.canvas.getContext("2d");
    this.options = {
      ...defaultOptions,
      ...(options ?? {})
    };
  }

  getPath2D() {
    // Override if needed
    return new Path2D(this.canvas);
  }

  get width() {
    return Dimensions.get("window").width;
  }

  get height() {
    return Dimensions.get("window").height;
  }

  pastePicture(): void {
    if (!this.canvas) return;
    this.canvas.width = this.width;
    this.canvas.height = this.height;

    this.clearCanvas();
    const { width, height } = this.backgroundImageSize();

    if (this.backgroundStyle !== BackgroundStyle.cover) {
      this.drawSquaredBackground();
    }

    this.context.drawImage(
      this.background,
      (this.canvas.width - width) >> 1,
      (this.canvas.height - height) >> 1,
      width,
      height
    );

    this.setDrawSettings({
      strokeColor: this.options.pencilStrokeColor ?? "",
      strokeWidth: this.options.pencilStrokeWidth ?? 0
    });
  }

  drawSquaredBackground(): void {
    const squareSize = this.options.squareSize || 30;

    this.setDrawSettings({
      strokeColor: this.options.squareStrokeColor ?? "",
      strokeWidth: this.options.squareStrokeWidth ?? 0
    });
    let offset = squareSize;
    // Draw horizontal lines
    while (offset < this.canvas.height) {
      this.context.beginPath();
      this.context.moveTo(0, offset);
      this.context.lineTo(this.canvas.width, offset);
      this.context.stroke();
      offset += squareSize;
    }
    // Draw vertical lines
    offset = squareSize;
    while (offset < this.canvas.width) {
      this.context.beginPath();
      this.context.moveTo(offset, 0);
      this.context.lineTo(offset, this.canvas.height);
      this.context.stroke();
      offset += squareSize;
    }
  }

  clearCanvas() {
    this.context.fillStyle = Colors.light.background;
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  backgroundImageSize(): Size {
    const { backgroundStyle } = this.options;
    let ratio = 1;
    if (backgroundStyle === BackgroundStyle.cover) ratio = this.coverRatio();
    if (backgroundStyle === BackgroundStyle.stretch)
      ratio = this.stretchRatio();
    if (backgroundStyle === BackgroundStyle.contain)
      ratio = this.containRatio();

    const padding = this.options.squareSize ?? 0;
    return {
      width: this.background.width * ratio - padding,
      height: this.background.height * ratio - padding
    };
  }

  coverRatio(): number {
    return Math.max(
      this.canvas.width / this.background.width,
      this.canvas.height / this.background.height
    );
  }

  stretchRatio(): number {
    return Math.min(
      (this.canvas.width - this.canvas.width * 0.1) / this.background.width,
      (this.canvas.height - this.canvas.height * 0.1) / this.background.height
    );
  }

  containRatio(): number {
    return Math.min(
      this.canvas.width / this.background.width,
      this.canvas.height / this.background.height
    );
  }

  setDrawSettings(settings: DrawSettings): void {
    this.context.lineWidth = settings.strokeWidth;
    this.context.strokeStyle = settings.strokeColor;
    this.context.lineJoin = this.context.lineCap = "round";
  }

  startDrawing(x: number, y: number) {
    this.isDrawing = true;
    this.currentPath = this.getPath2D();
    this.points.push({ x, y });
    this.context.beginPath();
  }

  stopDrawing() {
    if (this.points.length) {
      this.isDrawing = false;
      if (this.currentPath && this.points.length) {
        this.paths.push(this.currentPath);
      }
      this.points = [];
    }
  }

  draw(x: number, y: number) {
    if (!this.isDrawing) return;
    const lastPoint = this.points[this.points.length - 1];
    const newPoint = { x, y };
    this.points.push(newPoint);
    this.context.moveTo(x, y);
    const midPoint = this.midPointBtw(lastPoint, newPoint);
    this.currentPath.quadraticCurveTo(
      lastPoint.x,
      lastPoint.y,
      midPoint.x,
      midPoint.y
    );
    this.context.stroke(this.currentPath);
  }

  redraw() {
    this.pastePicture();
    this.paths.forEach((path: Path2D) => this.context.stroke(path));
  }

  midPointBtw(p1: Point, p2: Point): Point {
    return {
      x: p1.x + (p2.x - p1.x) / 2,
      y: p1.y + (p2.y - p1.y) / 2
    };
  }

  async getImageData(): Promise<string> {
    return await this.canvas.toDataURL("image/png");
  }

  undo() {
    this.paths.pop();
    this.redraw();
  }

  get isDirty() {
    return this.paths.length > 0;
  }
}
