import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";
import { SessionUI } from "models/UI";
import { Extension } from "helpers/ChromeExtension";

class ExtensionController extends Controller {
  init() {
    Event.on("extension_on_captured", onCapture);
    onLoad();
  }
  deinit() {
    Event.remove("extension_on_captured", onCapture);
  }
}

const controller = new ExtensionController();
export default controller;

async function onLoad() {
  if (Extension.isSupported() && (await Extension.isInstalled())) {
    Extension.subscribe();
  }
}

function onCapture({ dataUrl }: { dataUrl: string }) {
  SessionUI.onScreenshotSubmitted();
  SessionUI.setPhoto(dataUrl);
  Router.replace("SessionScratchBoard");
}
