import React, { useRef } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";

import Colors from "assets/theme/Colors";
import Sizes from "assets/theme/Sizes";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import CaptureView, { CaptureViewAPI } from "components/CaptureView";
import Card from "components/Card";
import { LogoIcon } from "components/Icons";
import Page from "components/Page";
import Text from "components/Text";

import { LoadingView } from "../components/LoadingView";
import { Event } from "events/Event";
import { useSelector } from "react-redux";
import { StoreState } from "store";

type ProblemCaptureProps = {
  children: React.ReactNode;
  partnerName: string;
};

export default function ProblemCapture(props: ProblemCaptureProps) {
  const user = useSelector((state: StoreState) => state.user.user);
  const signingIn = useSelector(
    (state: StoreState) => state.ui.signingInPartnership
  );
  const loading = useSelector(
    (state: StoreState) => state.ui.loadingPartnershipProblem
  );
  const signInSuccess = useSelector(
    (state: StoreState) => state.ui.signInPartnershipSuccess
  );
  const { height } = useWindowDimensions();
  const captureRef = useRef<CaptureViewAPI>(null);

  async function onCapture() {
    const dataUri = await captureRef.current?.capture();

    if (dataUri) {
      Event.dispatch("partnerships_on_submit", { dataUri });
    }
  }
  const onContinueToDashboard = Event.dispatcher("partnerships_on_go_to_dashboard");

  const signInError = !signInSuccess
    ? "Oops! We could not find your account"
    : "";
  const problemError = !props.children
    ? `Oops! We could not get your problem from ${props.partnerName}`
    : "";
  const isValid = props.children && signInSuccess && user;

  return (
    <Page>
      <View style={[styles.wrapper, { minHeight: height }]}>
        <View style={styles.logo}>
          <LogoIcon
            width={100}
            height={Sizes["32px"]}
            color={Colors.brand.blue}
          />
        </View>
        <Text tag="h1">Chat with a tutor now</Text>
        <Card style={styles.card}>
          <View style={styles.user}>
            <LoadingView
              loading={signingIn}
              error={signInError}
              showContactOption={true}
            >
              <Text tag="h2">Welcome, {user?.name}</Text>
            </LoadingView>
          </View>
          <LoadingView loading={loading} error={problemError}>
            <View testID="problem" style={styles.problemArea}>
              <CaptureView ref={captureRef}>{props.children}</CaptureView>
            </View>
          </LoadingView>
          <View style={styles.actions}>
            {isValid && (
              <Button testID="submit" style={styles.button} onClick={onCapture}>
                Let's get started with this problem
              </Button>
            )}
            <Button testID="continue" theme="flat" onClick={onContinueToDashboard}>
              or continue to Yup to add your own picture
            </Button>
          </View>
        </Card>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...Spacing.pa2
  },
  logo: {
    ...Spacing.my4
  },
  card: {
    width: "100%",
    maxWidth: "max-content",
    textAlign: "center",
    ...Spacing.my3,
    ...Spacing.pa3
  },
  user: {
    borderBottomWidth: 1,
    borderColor: Colors.ui.beige,
    ...Spacing.pb2
  },
  problemArea: {
    maxWidth: 600
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...Spacing.mt3
  },
  button: {
    maxWidth: 500,
    ...Spacing.mb4
  }
});
