import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import Card from "components/Card";
import Tooltip from "components/Tooltip";

interface ToolbarButtonBasics {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
  selected?: boolean;
  staysSelected?: boolean;
  disabled?: boolean;
  additional?: React.ReactNode;
}

interface ToolbarButtonProps extends ToolbarButtonBasics {}

interface ToolbarMenuProps extends ToolbarButtonBasics {
  show: boolean;
  children: React.ReactNode;
}

export function ToolbarButton(props: ToolbarButtonProps) {
  return (
    <Tooltip text={props.title}>
      <TouchableOpacity
        style={[styles.button, props.disabled && styles.disabled]}
        disabled={props.disabled}
        onPress={props.onClick}
      >
        {props.icon}
      </TouchableOpacity>
    </Tooltip>
  );
}

export function ToolbarMenu(props: ToolbarMenuProps) {
  return (
    <View style={styles.menuWrapper}>
      <Tooltip text={props.title}>
        <TouchableOpacity
          style={[styles.button, props.disabled && styles.disabled]}
          disabled={props.disabled}
          onPress={props.onClick}
        >
          {props.icon}
        </TouchableOpacity>
      </Tooltip>
      {props.show && <Card style={styles.menu}>{props.children}</Card>}
    </View>
  );
}

const styles = StyleSheet.create({
  menuWrapper: {
    position: "relative"
  },
  menu: {
    position: "absolute",
    zIndex: 1,
    ...Spacing.mt4
  },
  button: {
    backgroundColor: Colors.grey.concrete,
    borderWidth: 1,
    borderColor: Colors.ui.beige,
    borderRadius: 100,
    ...Spacing.pa1,
    ...Spacing.mx2,
    ...Spacing.my1
  },
  disabled: {
    opacity: 0.7
  }
});
