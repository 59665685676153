import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";

import store from "store";
import User from "models/User";

class ProfileController extends Controller {
  init() {
    Event.on("profile_on_favorite_tutor", onFavoriteTutor);
    Event.on("profile_on_unfavorite_tutor", onUnfavoriteTutor);

    onLoad();
  }
  deinit() {
    Event.remove("profile_on_favorite_tutor", onFavoriteTutor);
    Event.remove("profile_on_unfavorite_tutor", onUnfavoriteTutor);
  }
}

const controller = new ProfileController();
export default controller;

function onLoad() {
  const user = store.getState().user.user;
  const settings = store.getState().settings.settings;

  if (!user || !settings) {
    Router.replace("SignIn");
  } else {
    loadFavoriteTutorData();
  }
}

async function loadFavoriteTutorData() {
  await User.getFavoriteTutors();
  await User.getSuggestedTutors();
}

async function onFavoriteTutor({
  tutorId,
  tutorName
}: {
  tutorId: number;
  tutorName: string;
}) {
  User.clearSuggestedTutors();
  await User.addFavoriteTutor(tutorId, "profile", tutorName);
  await User.getFavoriteTutors();
  await User.getSuggestedTutors();
}

async function onUnfavoriteTutor({
  tutorId,
  tutorName
}: {
  tutorId: number;
  tutorName: string;
}) {
  User.clearSuggestedTutors();
  await User.removeFavoriteTutor(tutorId, "profile", tutorName);
  await User.getFavoriteTutors();
  await User.getSuggestedTutors();
}
