import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import Storage from "helpers/Storage";
import { SettingsType } from "models/Settings";

const STORE_KEY = "yup-settings";
export const storage = new Storage(STORE_KEY, clearSettings);
function clearSettings(): void {
  const { store } = require("./index");
  store.dispatch(settingsSlice.actions.setSettings(null));
}

export type StateType = {
  settings: SettingsType | null;
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settings: null
  } as StateType,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsType | null>) => {
      state.settings = action.payload;
    }
  }
});

export default settingsSlice.reducer;

export const { setSettings } = settingsSlice.actions;

export function subscriber(state: StateType) {
  if (state.settings) {
    storage.setSync(state.settings);
  } else if (state.settings === null) {
    storage.remove();
  }
}

export async function load(store: ToolkitStore) {
  const settings = await storage.get();
  if (settings) {
    store.dispatch(settingsSlice.actions.setSettings(settings));
  }
}
