import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "store";
import { StyleSheet, View } from "react-native";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";

import Button from "components/Button";
import { NextIcon } from "components/Icons";
import Modal from "components/Modal";
import SelectCollection from "components/SelectCollection";
import Text from "components/Text";
import { Event } from "events/Event";

type TopicSelectionProps = {
  onSubmit: () => void;
};

const TopicSymbol: { [key: string]: string } = {
  pre_algebra: "÷",
  algebra: "𝑥",
  geometry: "⬠",
  trig: "θ",
  probability: "♤",
  calculus: "∫"
};

export default function EndSession(props: TopicSelectionProps) {
  const show = useSelector((state: StoreState) => state.ui.showTopicSelection);
  const settings = useSelector((state: StoreState) => state.settings.settings);
  const selectedTopic = useSelector(
    (state: StoreState) => state.ui.selectedTopic
  );
  const creatingSession = useSelector(
    (state: StoreState) => state.ui.creatingSession
  );

  const subtopics = settings?.subtopics ?? [];

  const onDismiss = Event.dispatcher(
    "dashboard_scratchboard_dismiss_topic_selection"
  );
  function onSelect(topicLabel: string) {
    const label = topicLabel.split(" ").slice(1).join(" ");
    const topic = subtopics.find((t) => t.label === label);
    if (topic) Event.dispatch("dashboard_scratchboard_select_topic", { topic });
  }

  const topicLabels = subtopics.map((t) => `${TopicSymbol[t.name]} ${t.label}`);
  let selectedTopicLabel = "";
  if (selectedTopic) {
    selectedTopicLabel = `${TopicSymbol[selectedTopic.name]} ${
      selectedTopic.label
    }`;
  }

  return (
    <Modal show={show} onDismiss={onDismiss}>
      <Text tag="h2" style={styles.prompt}>
        What kind of problem is this?
      </Text>

      <View style={styles.topics}>
        <SelectCollection
          options={topicLabels}
          selected={selectedTopicLabel}
          testID="select-topic"
          onSelect={onSelect}
        />
      </View>
      <View style={styles.submitWrapper}>
        <Button
          round={true}
          disabled={!selectedTopic}
          processing={creatingSession}
          testID="start-session"
          onClick={props.onSubmit}
        >
          <NextIcon width={24} height={24} color={Colors.grey.iron} />
        </Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  prompt: {
    textAlign: "center",
    ...Spacing.mb3
  },
  submitWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  topics: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    ...Spacing.px2
  }
});
