import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions
} from "react-native";
import { StoreState } from "store";
import { useSelector } from "react-redux";
import { Event } from "events/Event";

import Colors from "assets/theme/Colors";
import Spacing from "assets/theme/Spacing";
import Sizes from "assets/theme/Sizes";
import Typography from "assets/theme/Typography";

import Card from "components/Card";
import Text from "components/Text";

import Profile from "./Profile";

export default function TutorProfileDropdown() {
  const session = useSelector((state: StoreState) => state.session.current);
  const showTutorProfile = useSelector(
    (state: StoreState) => state.ui.showTutorProfile
  );
  const { width, height } = useWindowDimensions();

  const onDismiss = Event.dispatcher("session_dismiss_tutor_profile");

  if (!session?.tutor || !showTutorProfile) return null;

  return (
    <TouchableOpacity
      style={[styles.wrapper, { width, height }]}
      testID="tutor-profile-close"
      onPress={onDismiss}
    >
      <TouchableWithoutFeedback onPress={(event) => event.stopPropagation()}>
        <Card style={[styles.profileCard, { maxHeight: height - 150 }]}>
          <Text style={styles.subheader}>Get to know</Text>
          <Text style={styles.header}>{session.tutor.profile.polite_name}</Text>
          <Profile />
        </Card>
      </TouchableWithoutFeedback>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    top: 0,
    left: 0,
    position: "absolute",
    zIndex: 4
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  profileCard: {
    position: "absolute",
    top: Sizes.navigationHeight,
    backgroundColor: Colors.light.background,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    ...Spacing.ma0,
    ...Spacing.pt2,
    zIndex: 5
  },
  subheader: {
    ...Typography.accent.small,
    lineHeight: 12,
    color: Colors.grey.slate,
    textAlign: "center"
  },
  header: {
    ...Typography.accent.large,
    fontSize: 18,
    textAlign: "center"
  }
});
