import { Event } from "events/Event";
import { Controller } from "types";

import { PartnershipsUI } from "models/UI";

import ProblemCaptureController from "./ProblemCaptureController";
import { AscendMath } from "models/Partnerships";
import { Router } from "navigation/Router";

class AscendMathController extends Controller {
  init() {
    Event.on("partnerships_on_reload", onLoad);

    onLoad();
    ProblemCaptureController.init();
  }
  deinit() {
    Event.remove("partnerships_on_reload", onLoad);
    ProblemCaptureController.deinit();
  }
}

const controller = new AscendMathController();
export default controller;

function onLoad() {
  Promise.all([signInWithAscendMath(), loadAscendMathProblem()]);
}

async function loadAscendMathProblem() {
  const route = Router.getCurrent();
  if (!route) return;

  const qParams = route.params ?? ({} as any);
  if (!qParams.problemId) {
    Router.navigate("Dashboard");
    return;
  }

  PartnershipsUI.setLoadingProblem(true);
  await AscendMath.getProblem(qParams);
  PartnershipsUI.setLoadingProblem(false);
}

async function signInWithAscendMath() {
  const route = Router.getCurrent();
  if (!route) return;

  PartnershipsUI.setSigningIn(true);
  const qParams = route.params ?? { studentId: "" };
  const { success } = await AscendMath.signIn((qParams as any).studentId);
  PartnershipsUI.setSignInSuccess(success);
  PartnershipsUI.setSigningIn(false);
}
