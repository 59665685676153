import { Event } from "events/Event";
import { Controller } from "types";
import { Router } from "navigation/Router";

import { SessionHistoryUI } from "models/UI";

import SessionHistory from "models/SessionHistory";

class TranscriptController extends Controller {
  init() {
    Event.on("history_transcript_dismiss_image", onDismissSessionImage);
    Event.on("chatroom_image_clicked", setTranscriptImage);

    onLoad();
  }
  deinit() {
    Event.remove("history_transcript_dismiss_image", onDismissSessionImage);
    Event.remove("chatroom_image_clicked", setTranscriptImage);
  }
}

const controller = new TranscriptController();
export default controller;

async function onLoad() {
  SessionHistoryUI.setTranscriptImage("");
  const route = Router.getCurrent() as any;
  if (!route) return;
  if (!route.params?.id) Router.replace("History");
  await loadTranscript(route.params.id);
}

async function loadTranscript(transcriptId: number) {
  SessionHistoryUI.setError("");
  SessionHistoryUI.setLoadingSessionTranscript(true);
  const { success, message } = await SessionHistory.loadTranscript(
    transcriptId
  );
  if (!success && message) {
    SessionHistoryUI.setError(message);
  } else {
    await loadWhiteboardCaptures(transcriptId);
  }

  SessionHistoryUI.setLoadingSessionTranscript(false);
}

async function loadWhiteboardCaptures(id: number) {
  SessionHistoryUI.setLoadingWhiteboardCaptures(true);
  const { success, message } = await SessionHistory.loadCaptures(id);
  if (!success && message) {
    SessionHistoryUI.setError(message);
  }
  SessionHistoryUI.setLoadingWhiteboardCaptures(false);
}

function setTranscriptImage({ image }: { image: string }) {
  SessionHistoryUI.setTranscriptImage(image);
}

function onDismissSessionImage() {
  SessionHistoryUI.setTranscriptImage("");
}
