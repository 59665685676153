import { version } from "version";

import {
  REACT_NATIVE_S3_SECRET_KEY,
  REACT_NATIVE_S3_REGION,
  REACT_NATIVE_S3_ACCESS_KEY,
  REACT_NATIVE_GOOGLE_CLIENT_ID,
  REACT_NATIVE_GOOGLE_CLIENT_ID_IOS,
  REACT_NATIVE_ENV,
  REACT_NATIVE_GOOGLE_CLIENT_ID_ANDROID,
  REACT_NATIVE_SENTRY_KEY,
  REACT_NATIVE_CLEVER_CLIENT_ID,
  REACT_NATIVE_CLEVER_REDIRECT_URI,
  REACT_NATIVE_GOOGLE_CHROME_EXTENSION_ID,
  REACT_NATIVE_GOOGLE_CHROME_EXTENSION_VERSION,
  REACT_NATIVE_USERSNAP_API_KEY,
  REACT_NATIVE_ZWIBBLER_SERVER_URL,
  REACT_NATIVE_NETLIFY_BUILD_ID,
  REACT_NATIVE_NETLIFY_COMMIT_REF,
  REACT_NATIVE_ENABLE_TESTING_MENU
} from "@env";

function GoogleClientKey() {
  return `${REACT_NATIVE_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;
}

function GoogleClientKeyIos() {
  return `${REACT_NATIVE_GOOGLE_CLIENT_ID_IOS}.apps.googleusercontent.com`;
}

function GoogleClientKeyAndroid() {
  return `${REACT_NATIVE_GOOGLE_CLIENT_ID_ANDROID}.apps.googleusercontent.com`;
}

function getTesting() {
  return process.env.REACT_NATIVE_TESTING == 'true'
}

function getEnv() {
  if(getTesting()) return "qa2";
  return REACT_NATIVE_ENV ?? "prod"
}

const Config = {
  testing: getTesting(),
  env: getEnv(),
  assetsUrl: "https://yup-ui.s3.amazonaws.com/",
  version,
  AwsSecretKey: REACT_NATIVE_S3_SECRET_KEY,
  AwsRegion: REACT_NATIVE_S3_REGION,
  AwsAccessKey: REACT_NATIVE_S3_ACCESS_KEY,
  sentryKey: REACT_NATIVE_SENTRY_KEY,
  googleClientKey: GoogleClientKey,
  googleClientKeyIos: GoogleClientKeyIos,
  googleClientKeyAndroid: GoogleClientKeyAndroid,
  cleverClientId: REACT_NATIVE_CLEVER_CLIENT_ID,
  cleverRedirectUri: REACT_NATIVE_CLEVER_REDIRECT_URI,
  sessionStartDelayInSeconds: 40,
  googleChromeExtensionId: REACT_NATIVE_GOOGLE_CHROME_EXTENSION_ID,
  googleChromeExtensionVersion: Number(
    REACT_NATIVE_GOOGLE_CHROME_EXTENSION_VERSION ?? 0
  ),
  usersnapKey: REACT_NATIVE_USERSNAP_API_KEY,
  zwibblerServerUrl: REACT_NATIVE_ZWIBBLER_SERVER_URL,
  buildId: REACT_NATIVE_NETLIFY_BUILD_ID ?? "",
  commitRef: REACT_NATIVE_NETLIFY_COMMIT_REF ?? "",
  enableTestingMenu: REACT_NATIVE_ENABLE_TESTING_MENU === "true" || __DEV__
};

export default Config;
