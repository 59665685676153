import Storage from "helpers/Storage";

export interface BotScriptAction {
  action_type: BotScriptActionType;
  cta_type: BotScriptCtaType;
  ctas: Array<string>;
  token: string;
  on_success_script_node_id: string;
  meta_data: BotScriptMetaData;
}

export interface BotScriptMetaData {
  placeholder?: string;
}

export interface BotScriptNode {
  actions?: Array<BotScriptAction>;
  messages: Array<Array<string>>;
  name: string;
  token: string;
  meta_data: BotScriptMetaData;
}

export interface BotScriptMap {
  [name: string]: BotScriptNode;
}

export interface BotScript {
  name: string;
  root_script_node_id: string;
  script_nodes: BotScriptMap;
}

export enum BotScriptActionType {
  button = "text",
  camera = "camera",
  textArea = "textfield"
}

export enum BotScriptCtaType {
  primary = "primary",
  secondary = "secondary"
}

export class BotScriptReader {
  storage: Storage;
  script: BotScript | null = null;
  currentKey: string = "";
  sessionId: number;

  constructor(sessionId: number) {
    this.sessionId = sessionId;
    this.storage = new Storage(`in_session_bot`, () => this.stop());
  }

  async loadProgress() {
    const data = await this.storage.get();
    return data?.[this.sessionId];
  }

  async setProgress() {
    await this.storage.set({ [this.sessionId]: this.currentKey });
  }

  async loadScript(script: BotScript) {
    this.script = script;
    this.currentKey = (await this.loadProgress()) ?? script.root_script_node_id;
  }

  getCurrent(): BotScriptNode | null {
    return this.script?.script_nodes[this.currentKey] ?? null;
  }

  async process(nextId: string) {
    this.currentKey = nextId;
    await this.setProgress();
    return this.getCurrent();
  }
  stop() {
    this.process("DONE");
  }
}

export default BotScriptReader;
