import * as React from "react";
import { Event } from "events/Event";
import { PreSessionActionProps } from ".";

import Button from "components/Button";

export function ActionCamera(props: PreSessionActionProps) {
  const onClick = Event.dispatcher("session_presession_camera_click", {
    message: props.action.ctas[0],
    nextNodeId: props.action.on_success_script_node_id
  });

  return (
    <Button theme={props.theme} onClick={onClick} testID="presession-camera">
      {props.action.ctas[0]}
    </Button>
  );
}
