import React, { useEffect, useRef } from "react";
import { View, StyleSheet, Animated } from "react-native";

import Config from "helpers/Config";
import { Tutor, getTutorAvatar } from "types/Tutor";
import {
  AnimatedHelper,
  Size,
  TutorImageCount
} from "../helpers/FindingTutorAnimation";

import Spinner from "components/Progress/Spinner";
import Colors from "assets/theme/Colors";

import Image from "components/Image";

type FindingTutorAnimationProps = {
  tutor: Tutor | null;
  ready: boolean;
};

export default function FindingTutorAnimation(
  props: FindingTutorAnimationProps
) {
  const spinnerColor = props.ready ? Colors.ui.green : Colors.ui.blue;
  const imgAnim = useRef(new Animated.Value(Size / 2)).current;

  const tutorImages: Array<string> = [];
  [...Array(TutorImageCount / 2)].forEach((_, id) => {
    tutorImages.push(`${Config.assetsUrl}tutors/tutor_female_${id}.png`);
    tutorImages.push(`${Config.assetsUrl}tutors/tutor_male_${id}.png`);
  });

  const animationHelper = useRef(new AnimatedHelper()).current;

  useEffect(() => {
    return () => {
      animationHelper.stop();
    };
  }, []);

  useEffect(() => {
    if (!props.tutor && !Config.testing) {
      animationHelper.start(imgAnim);
    } else {
      animationHelper.stop();
    }
  }, [props.tutor?.id]);

  const profile =
    props.tutor && getTutorAvatar(props.tutor.id, props.tutor.gender);

  return (
    <Spinner size={Size} animated={!props.ready} color={spinnerColor}>
      <View style={styles.tutor}>
        {profile ? (
          <Image uri={profile} style={styles.tutorFound} />
        ) : (
          <View>
            <Animated.View
              style={[
                styles.tutorWrapper,
                StyleSheet.absoluteFill,
                { transform: [{ translateX: imgAnim }] }
              ]}
            >
              {tutorImages.map((img) => (
                <Image uri={img} style={styles.tutorProfile} key={img} />
              ))}
            </Animated.View>
          </View>
        )}
      </View>
    </Spinner>
  );
}

const styles = StyleSheet.create({
  tutor: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tutorWrapper: {
    height: Size,
    display: "flex",
    flexDirection: "row",
    width: Size * TutorImageCount * 1.5
  },
  tutorFound: {
    height: Size,
    width: Size
  },
  tutorProfile: {
    height: Size,
    width: Size,
    marginRight: Size / 2
  }
});
