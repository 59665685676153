import WebService from "helpers/WebService";
import { setActivityBulletin } from "store/ActivityBulletin";
import store from "store";
import {
  ActivityBulletin as ActivityBulletinType,
  BulletinType
} from "types/ActivityBulletin";
import { Platform } from "react-native";
import { shuffle } from "helpers/Array";

class ActivityBulletin {
  index = 0;
  bulletins: Array<ActivityBulletinType> = [];

  isSupported() {
    return Platform.OS === "web";
  }
  private async loadBulletins() {
    const response = await WebService.getBulletins();
    if (response.success) {
      this.bulletins = response.data.bulletins;
      if (response.data.number_tutors_available < 2) {
        this.bulletins = this.bulletins.filter(
          (bulletin: ActivityBulletinType) =>
            bulletin.type !== BulletinType.TutorsAvailable
        );
      }
      this.bulletins = shuffle(this.bulletins);
    }
  }
  async load() {
    store.dispatch(setActivityBulletin(null));
    if (this.index >= this.bulletins.length - 1) {
      // Reached the end
      await this.loadBulletins();
      this.index = 0;
    } else {
      this.index += 1;
    }

    store.dispatch(setActivityBulletin(this.bulletins[this.index]));
  }
}

export default new ActivityBulletin();
