import { Event } from "events/Event";
import { Router } from "navigation/Router";
import { Controller } from "types";

import { DashboardUI, ExtensionUI } from "models/UI";
import { Extension } from "helpers/ChromeExtension";

class ExtensionController extends Controller {
  init() {
    Event.on("extension_on_captured", onCapture);
    onLoad();
  }
  deinit() {
    Event.remove("extension_on_captured", onCapture);
  }
}

const controller = new ExtensionController();
export default controller;

async function onLoad() {
  if (Extension.isSupported()) {
    const extensionIsInstalled = await Extension.isInstalled();
    ExtensionUI.setExtensionInstalled(extensionIsInstalled);
    if (extensionIsInstalled) {
      Extension.subscribe();
    }
  }
}

function onCapture({ dataUrl }: { dataUrl: string }) {
  DashboardUI.onScreenshotSubmitted();
  DashboardUI.setPhoto(dataUrl);
  Router.replace("ScratchBoard");
}
